import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {

  transform(arr: any[], key: string|((value: any) => {})|null, value: any = null): any[] {
    if (!key) {
      return arr;
    }

    if (typeof key === 'function') {
      return arr.filter(key);
    }

    return arr.filter(item => item[key] === value);
  }

}
