import {createReducer, on} from '@ngrx/store';
import IProcessState from '../interfaces/IProcessState';
import {
  setCandidate,
  setResearch,
  setSurvey,
  setTrace,
  setClient,
  unsetCandidate,
  unsetResearch,
  unsetTrace
} from '../actions/process.actions';

export const initialState: IProcessState = {
  research: null,
  candidate: null,
  survey: null,
  trace: null,
  client: null
};

const source = createReducer(
  initialState,
  on(setResearch, (state, research) => ({ ...state, research })),
  on(unsetResearch, (state) => ({ ...state, research: null })),
  on(setCandidate, (state, candidate) => ({ ...state, candidate })),
  on(unsetCandidate, (state) => ({ ...state, candidate: null })),
  on(setTrace, (state, { trace}) => ({ ...state, trace })),
  on(unsetTrace, (state) => ({ ...state, trace: null })),
  on(setSurvey, (state, survey) => ({ ...state, survey })),
  on(setClient, (state, client) => ({ ...state, client }))
);

export function processReducer(state: IProcessState, action: any): any {
  return source(state, action);
}
