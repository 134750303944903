import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeScreenComponent} from './screens/home-screen/home-screen.component';
import {AuthGuard} from './common/auth.guard';
import {LoginScreenComponent} from './screens/login-screen/login-screen.component';
import {UserScreenComponent} from './screens/user-screen/user-screen.component';
import {CandidateScreenComponent} from './screens/candidate-screen/candidate-screen.component';
import {TestSurveyScreenComponent} from './screens/test-survey-screen/test-survey-screen.component';
import {ClientsScreenComponent} from './screens/clients-screen/clients-screen.component';
import {ProjectsScreenComponent} from './screens/projects-screen/projects-screen.component';
import {GroupsScreenComponent} from './screens/groups-screen/groups-screen.component';
import {ProcessLandingScreenComponent} from './screens/process-landing-screen/process-landing-screen.component';
import {ProcessLoginScreenComponent} from './screens/process-login-screen/process-login-screen.component';
import {ProcessSignupScreenComponent} from './screens/process-signup-screen/process-signup-screen.component';
import {JobsScreenComponent} from './screens/jobs-screen/jobs-screen.component';
import {ProcessDoScreenComponent} from './screens/process-do-screen/process-do-screen.component';
import {ResearchScreenComponent} from './screens/research-screen/research-screen.component';
import {SettingsScreenComponent} from './screens/settings-screen/settings-screen.component';
import {ReportScreenComponent} from './screens/report-screen/report-screen.component';
import {OperationScreenComponent} from './screens/operation-screen/operation-screen.component';
import {CandidateSignupScreenComponent} from './screens/candidate-signup-screen/candidate-signup-screen.component';
import {
  ProcessLandingShareableScreenComponent
} from "./screens/process-landing-shareable-screen/process-landing-shareable-screen.component";

const routes: Routes = [
  {
    path: 'dashboard',
    component: HomeScreenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user-management',
    component: UserScreenComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'add',
        component: UserScreenComponent
      },
      {
        path: 'view/:id',
        component: UserScreenComponent
      },
      {
        path: 'edit/:id',
        component: UserScreenComponent
      }
    ]
  },
  {
    path: 'candidates',
    component: CandidateScreenComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'add',
        component: CandidateScreenComponent
      },
      {
        path: 'view/:id',
        component: CandidateScreenComponent
      },
      {
        path: 'edit/:id',
        component: CandidateScreenComponent
      }
    ]
  },
  {
    path: 'settings',
    component: SettingsScreenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/:what',
    component: SettingsScreenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/:what/:id',
    component: SettingsScreenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'research/:id',
    canActivate: [AuthGuard],
    component: ResearchScreenComponent
  },
  {
    path: 'research/:id/:resourceType/:resourceId',
    canActivate: [AuthGuard],
    component: ResearchScreenComponent
  },
  {
    path: 'report/:id/:resultId',
    canActivate: [AuthGuard],
    component: ReportScreenComponent
  },
  {
    path: 'report/:id/:resultId/:data',
    canActivate: [AuthGuard],
    component: ReportScreenComponent
  },
  {
    path: 'clients',
    component: ClientsScreenComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'add',
        component: ClientsScreenComponent
      },
      {
        path: 'view/:id',
        component: ClientsScreenComponent
      },
      {
        path: 'edit/:id',
        component: ClientsScreenComponent
      }
    ]
  },
  {
    path: 'projects',
    component: ProjectsScreenComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'add',
        component: ProjectsScreenComponent
      },
      {
        path: 'view/:id',
        component: ProjectsScreenComponent
      },
      {
        path: 'edit/:id',
        component: ProjectsScreenComponent
      }
    ]
  },
  {
    path: 'groups',
    component: GroupsScreenComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'add',
        component: GroupsScreenComponent
      },
      {
        path: 'view/:id',
        component: GroupsScreenComponent
      },
      {
        path: 'edit/:id',
        component: GroupsScreenComponent
      }
    ]
  },
  {
    path: 'jobs',
    component: JobsScreenComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'add',
        component: JobsScreenComponent
      },
      {
        path: 'view/:id',
        component: JobsScreenComponent
      },
      {
        path: 'edit/:id',
        component: JobsScreenComponent
      }
    ]
  },
  {
    path: 'tests-surveys',
    component: TestSurveyScreenComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'view/:id',
        component: TestSurveyScreenComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginScreenComponent
  },
  {
    path: 'process',
    children: [
      {
        path: 'landing',
        component: ProcessLandingScreenComponent
      },
      {
        path: 'landing-shareable',
        component: ProcessLandingShareableScreenComponent
      },
      {
        path: 'login',
        component: ProcessLoginScreenComponent
      },
      {
        path: 'signup',
        component: ProcessSignupScreenComponent
      },
      {
        path: 'do',
        component: ProcessDoScreenComponent
      }
    ]
  },
  {
    path: 'candidate/register',
    component: CandidateSignupScreenComponent
  },
  {
    path: 'operation',
    children: [
      {
        path: 'account-recover',
        component: OperationScreenComponent
      },
      {
        path: 'candidate-account-recover',
        component: OperationScreenComponent
      }
    ]
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
