<h2 mat-dialog-title>{{data.title}}</h2>

<mat-form-field appearance="outline" class="full-width" *ngIf="!data.showAsTable">
  <mat-label i18n *ngIf="data.label !== null">{{data.label}}</mat-label>
  <mat-select
    [compareWith]="data.compareWith ? data.compareWith : emptyCallback"
    i18n-placeholder [placeholder]="data.placeholder"
    [(ngModel)]="value"
  >
    <div class="search-input-wrapper" *ngIf="data.showSearch">
      <input matInput type="text" i18n-placeholder placeholder="Search" class="search-input" [(ngModel)]="searchInput" (ngModelChange)="onSearchValueChange($event)" />
    </div>
    <mat-option *ngFor="let item of filteredItems" [value]="item.value">
      {{item.label}}
    </mat-option>
  </mat-select>
</mat-form-field>

<ng-container *ngIf="data.showAsTable">
  <app-smart-table [rowData]="data.items" [columnDefs]="data.columnDefs" [rowSelection]="data.multipleSelection === true ? 'multiple' : 'single'" (selectionChanged)="onSelectionChange($event)"></app-smart-table>
</ng-container>

<div mat-dialog-actions align="end">
  <button mat-button (click)="cancel()" i18n>Cancel</button>
  <button mat-button (click)="confirm()" cdkFocusInitial i18n [disabled]="!value">Confirm</button>
</div>
