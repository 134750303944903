import {Component, Input, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import IResearch from '../../interfaces/IResearch';
import IElement from '../../interfaces/IElement';
import {ElementType} from '../../definitions/ElementType';
import IQuestionElement from '../../interfaces/IQuestionElement';
import {ProcessQuestionElementComponent} from '../process-question-element/process-question-element.component';
import {AIService} from '../../services/ai.service';
import {UtilsService} from '../../services/utils.service';
import {SurveyType} from '../../definitions/SurveyType';
import {AIAction} from '../../definitions/AIAction';
import IGroupElement from '../../interfaces/IGroupElement';
import {QuestionType} from '../../definitions/QuestionType';
import * as moment from 'moment';
import {GroupDisplayAs} from '../../definitions/GroupDisplayAs';
import {ApiService} from '../../services/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import SurveyFailCode from '../../definitions/SurveyFailCode';
import {MatDialog} from '@angular/material/dialog';
import {
  ProcessSurveyGivenAnswersDialogComponent
} from './process-survey-given-answers-dialog/process-survey-given-answers-dialog.component';
import * as _ from 'lodash';
import ISurvey from "../../interfaces/ISurvey";

@Component({
  selector: 'app-process-survey',
  templateUrl: './process-survey.component.html',
  styleUrls: ['./process-survey.component.styl']
})
export class ProcessSurveyComponent implements OnInit, OnDestroy {

  @Input() research: IResearch;
  @Input() survey: ISurvey;
  @Input() candidate: any;
  @ViewChildren('questionElement') questionComponents: QueryList<ProcessQuestionElementComponent>;
  startMessage: string = null;
  endMessage: string = null;
  previousElements: {index: number, parentIndex: number, rawValue: any, element: any}[] = [];
  private _selectedElement: IElement<any> = null;
  currentIndex = -1;
  parentIndex = -1;
  elementType = ElementType;
  rawValue: any = {};
  step = 0;
  startTime: any;
  endTime: any;
  duration: any;
  switchedFocus = 0;
  trackToken: string;
  surveyInterval: any;
  questionInterval: any;
  surveyIntervalRuns = 0;
  questionIntervalRuns = 0;
  failCode: SurveyFailCode = null;
  surveyFailCode = SurveyFailCode;
  surveyTimeLimit = null;
  questionTimeLimit = null;
  loadingNextStep = false;

  constructor(private AI: AIService, private utilsService: UtilsService, private api: ApiService, private snackbar: MatSnackBar, private matDialog: MatDialog) {
  }


  get selectedElement(): IElement<any> {
    return this._selectedElement;
  }

  set selectedElement(value: IElement<any>) {
    this._selectedElement = value;
    if (value.type === ElementType.QUESTION) {
      this.checkQuestionTimeLimit();
    }

    if (this.survey.timeLimitStartPoint !== null && this.survey.timeLimit !== null && value.code === this.survey.timeLimitStartPoint) {
      const minutes = Number(this.survey.timeLimit);

      if (!isNaN(minutes) && minutes > 0) {
        this.surveyTimeLimit = minutes;
        this.surveyInterval = setInterval(this.handleSurveyTimeLimit, 1000);
      }
    }
  }

  ngOnInit(): void {
    this.processElementsValue(this.survey.elements);

    window.addEventListener('keypress', this.handleKeyEvent);
  }

  ngOnDestroy(): void {
    window.removeEventListener('keypress', this.handleKeyEvent);
  }

  handleValueChange(ev: {value: any, element: IElement<IQuestionElement>}): void {
    if (this.rawValue.hasOwnProperty(ev.element.code)) {
      this.rawValue[ev.element.code] = ev.value;
    }

    setTimeout(() => {
      if (this.survey.nextStepConfirmation !== true && this.canGoNext()) {
        this.goNext();
      }
    });
  }

  canGoNext(): boolean {
    if (this.startMessage !== null || this.endMessage !== null) {
      return true;
    }

    const canGoQuestion = (element: IElement<any>) => {
      const value = this.rawValue[element.code] || null;
      const multiple = element.child.multiple === true;
      const required = element.child.required === true;

      switch (element.child.type) {
        case QuestionType.ARRAY_MATRIX:
          if (value === null || typeof value !== 'object') {
            return false;
          }

          if (required) {
            const distinctCodes = element.child.answers
              .map(answer => answer.code)
              .filter((code, index, self) => self.indexOf(code) === index);
            const selectedCodes = Object.keys(value)
              .reduce((arr, key) => [...arr, ...value[key]], [])
              .filter((code, index, self) => self.indexOf(code) === index);
            const missingCodes = distinctCodes.filter(code => selectedCodes.indexOf(code) === -1);

            if (missingCodes.length > 0) {
              return false;
            }
          }

          break;

        default:
          if (multiple && (!Array.isArray(value) || value.length === 0) && required) {
            return false;
          }

          if (!multiple && !value && required) {
            return false;
          }
      }

      return true;
    };

    if (this.selectedElement.type === ElementType.QUESTION) {
      if (!canGoQuestion(this.selectedElement)) {
        return false;
      }
    } else if (this.selectedElement.type === ElementType.GROUP) {
      const questions: IElement<IQuestionElement>[] = this.selectedElement.child.children || [];
      for (const question of questions) {
        if (!canGoQuestion(question)) {
          return false;
        }
      }
    }


    return true;
  }

  canGoPrev(): boolean {
    return this.previousElements.length > 0 && this.survey.allowAnswerChange === true;
  }

  goNext(): void {
    this.questionIntervalRuns = 0;
    if (this.questionInterval) {
      clearInterval(this.questionInterval);
      this.questionInterval = undefined;
    }
    if (this.startMessage) {
      this.startMessage = null;
      return;
    }

    if (this.endMessage) {
      this.endMessage = null;
      return;
    }

    const invalid = (element): boolean => {
      if (!element) {
        return true;
      }

      if (element.child.isActive !== true) {
        return true;
      }

      if (element.child.condition) {
        const conditionResult = this.AI.read(element.child.condition, {...this.rawValue});

        if (!conditionResult) {
          return true;
        }
      }

      return false;
    };

    const confirm = () => {
      let nextElement: IElement<any>;

      if (this.selectedElement && this.selectedElement.child.nextFormula) {
        const action = this.AI.read(this.selectedElement.child.nextFormula, {...this.rawValue});
        const translatedAction = this.AI.getTranslationFromAction(action);

        if (translatedAction.action !== AIAction.GO_TO) {
          console.error($localize`We could not find the route to the next element`);
          return;
        }

        const search = this.findElementByCode(translatedAction.params[0], this.survey.elements);

        if (search.index === -1) {
          console.error($localize`We could not find the route to the next element`);
          return;
        }

        nextElement = search.element;

        if (invalid(nextElement)) {
          console.error($localize`We could not find the route to the next element`);
          return;
        }

        this.previousElements.push({
          index: this.currentIndex,
          parentIndex: this.parentIndex,
          rawValue: {...Object.keys(this.rawValue).filter(key => key !== this.selectedElement.code).reduce((o, v) => ({ ...o, [v]: this.rawValue[v]}), {})},
          element: _.cloneDeep(this.selectedElement)
        });

        this.currentIndex = search.index;
        this.parentIndex = search.parentIndex;
        this.selectedElement = nextElement;
      } else {
        let nextIndex: number = this.currentIndex;
        let nextParentIndex: number = this.parentIndex;
        let parent: IElement<IGroupElement>;

        do {
          nextIndex += 1;
          parent = nextParentIndex > -1 ? this.survey.elements[nextParentIndex] : null;

          if (parent && nextIndex >= parent.child.children.length) {
            nextIndex = nextParentIndex + 1;
            nextParentIndex = -1;

            if (parent.child.endMessage) {
              this.endMessage = parent.child.endMessage;
            }

            parent = null;
          }

          if (!parent && nextIndex >= this.survey.elements.length) {
            this.api.trackResearchResult(this.research.id, {
              token: this.trackToken,
              currentCode: this.selectedElement.code,
              nextCode: null,
              answers: this.rawValue
            });
            this.endSurvey();
            return;
          }

          nextElement = this.getSelectedElement(false, nextIndex, nextParentIndex);

          if (nextElement.type === ElementType.GROUP && nextElement.child.children.length > 0) {
            if (nextElement.child.startMessage) {
              this.startMessage = nextElement.child.startMessage;
            }

            switch (nextElement.child.displayAs) {
              case GroupDisplayAs.ONE_PAGE:
                break;

              default:
                nextParentIndex = nextIndex;
                nextIndex = 0;

                nextElement = nextElement.child.children[nextIndex];
            }
          } else if (nextElement.type === ElementType.GROUP) {
            nextParentIndex = nextIndex;
            nextIndex = 0;
            nextElement = null;
          }
        } while (invalid(nextElement));

        this.previousElements.push({
          index: this.currentIndex,
          parentIndex: this.parentIndex,
          rawValue: {...Object.keys(this.rawValue).reduce((o, v) => ({ ...o, [v]: (v === this.selectedElement.code ? this.utilsService.getRawValue(this.selectedElement) : this.rawValue[v])}), {})},
          element: _.cloneDeep(this.selectedElement)
        });

        this.loadingNextStep = true;

        this.api.trackResearchResult(this.research.id, {
          token: this.trackToken,
          currentCode: this.selectedElement.code,
          nextCode: nextElement ? nextElement.code : null,
          answers: this.rawValue
        }).then(() => {
          this.loadingNextStep = false;
          this.currentIndex = nextIndex;
          this.parentIndex = nextParentIndex;
          this.selectedElement = nextElement;
        }).catch(error => {
          console.error(error);
          alert('Qualcosa non è andato a buon fine');
        });
      }
    };

    confirm();

    if (this.questionComponents) {
      this.questionComponents.toArray().forEach(questionComponent => {
        questionComponent.reset();
      });
    }
  }

  goPrev(previousElementIndex: number = null): void {
    if (this.previousElements.length === 0) {
      return;
    }

    let previousElement = this.previousElements[this.previousElements.length - 1];

    if (previousElementIndex !== null && previousElementIndex >= 0) {
      previousElement = this.previousElements[previousElementIndex];
    }

    this.currentIndex = previousElement.index;
    this.parentIndex = previousElement.parentIndex;
    this.selectedElement = this.getSelectedElement();

    if (previousElementIndex < 0) {
      this.rawValue = {...previousElement.rawValue};
    }

    if (this.questionComponents) {
      this.questionComponents.toArray().forEach(questionComponent => {
        questionComponent.reset(this.rawValue);
      });
    }

    this.previousElements.splice(this.previousElements.length - 1, 1);
  }

  getRawValue(): any {
    return this.rawValue;
  }

  private processElementsValue(elements: IElement<any>[]): void {
    for (const element of elements) {
      if (element.type === ElementType.GROUP) {
        this.processElementsValue(element.child.children);
        continue;
      }

      if (element.type !== ElementType.QUESTION) {
        continue;
      }

      this.rawValue[element.code] = this.utilsService.getRawValue(element);
    }
  }

  private getSelectedElement(reverse: boolean = false, currentIndex: number = null, parentIndex: number = null): IElement<any> {
    let selectedElement: IElement<any> = null;
    currentIndex = currentIndex !== null ? currentIndex : this.currentIndex;
    parentIndex = parentIndex !== null ? parentIndex : this.parentIndex;

    if (parentIndex > -1) {
      const parent = this.survey.elements[parentIndex];

      if (currentIndex > -1 && currentIndex < parent.child.children.length) {
        selectedElement = parent.child.children[currentIndex] || null;
      } else {
        selectedElement = parent || null;
      }
    } else {
      selectedElement = this.survey.elements[currentIndex] || null;
    }

    return selectedElement;
  }

  private findElementByCode(code: string, elements: IElement<any>[], parentIndex: number = -1): {
    index: number;
    parentIndex: number;
    element: IElement<any>;
  } {
    for (const [index, element] of elements.entries()) {
      if (element.code === code) {
        return {
          index,
          parentIndex,
          element
        };
      }

      if (element.type === ElementType.GROUP) {
        const result = this.findElementByCode(code, element.child.children, index);

        if (result.index !== -1) {
          return result;
        }
      }
    }

    return {
      index: -1,
      parentIndex: -1,
      element: null
    };
  }

  getSurveyType(): string {
    return this.survey.type === SurveyType.TEST ? $localize`test` : $localize`survey`;
  }

  endSurvey(): void {
    if (this.surveyInterval) {
      clearInterval(this.surveyInterval);
    }

    if (this.questionInterval) {
      clearInterval(this.questionInterval);
    }

    this.step = 2;
    this.endTime = moment();
    const duration = moment.duration(this.endTime.diff(this.startTime));
    this.duration = duration.asMinutes();
    const activity = this.AI.endActivityTracking();
    this.switchedFocus = activity.length;
    this.api.endResearchResult(this.research.id, {
      trackingActivity: activity,
      token: this.trackToken,
      answers: this.rawValue,
      failCode: this.failCode
    }).then().catch(error => {
      console.error(error);
      alert('Non siamo riusciti a chiudere la somministrazione del test a causa di un errore');
    });
  }

  startSurvey(): void {
    this.api.startResearchResult(this.research.id, {
      candidate: this.candidate,
      answers: this.rawValue
    }).then(token => {
      this.startTime = moment();
      this.step = 1;
      this.currentIndex = 0;
      this.selectedElement = this.getSelectedElement();

      if (this.survey.startMessage) {
        this.startMessage = this.survey.startMessage;
      }

      this.trackToken = token;

      this.AI.startActivityTracking();

      if (this.survey.timeLimit && this.survey.timeLimitStartPoint === null) {
        const minutes = Number(this.survey.timeLimit);

        if (!isNaN(minutes) && minutes > 0) {
          this.surveyTimeLimit = minutes;
          this.surveyInterval = setInterval(this.handleSurveyTimeLimit, 1000);
        }
      }
    }).catch(error => {
      console.error(error);
      alert('Non siamo riusciti a far partire la somministrazione del test a causa di un errore');
    });
  }

  getNextButtonLabel(): string {
    const countElements = this.survey.elements.length;
    const countChildren = this.parentIndex > -1 ? this.survey.elements[this.parentIndex].child.children.length : 0;
    const conditions = [
      this.parentIndex > -1 && this.parentIndex === countElements - 1 && this.currentIndex === countChildren - 1,
      this.parentIndex === -1 && this.currentIndex === countElements - 1
    ];

    if (conditions[0] || conditions[1]) {
      return $localize`Finish ` + this.getSurveyType();
    }

    return $localize`Next`;
  }

  getSurveyProgress(): number {
    const total = this.survey.elements.length;
    const current = this.parentIndex > -1 ? this.parentIndex + 1 : this.currentIndex + 1;

    return total > 0 ? Math.ceil(current * 100 / total) : 0;
  }

  openGivenAnswersNavigator(): void {
    this.matDialog.open(ProcessSurveyGivenAnswersDialogComponent, {
      data: {
        survey: this.survey,
        answers: _.cloneDeep(this.rawValue),
        previousElements: _.cloneDeep(this.previousElements)
      },
      width: '98vw',
      maxWidth: '400px'
    }).afterClosed().subscribe((item: any) => {
      this.goPrev(item.previousElementIndex);
    });
  }

  private handleSurveyTimeLimit = () => {
    const secondsRan = this.surveyIntervalRuns + 1;
    const minutesRan = Math.floor(secondsRan / 60);
    const minutes = this.survey.timeLimit;

    if (minutesRan >= minutes) {
      clearInterval(this.surveyInterval);
      this.surveyIntervalRuns = 0;
      this.surveyTimeLimit = null;
      this.failCode = SurveyFailCode.SURVEY_TIME_LIMIT;
      this.endSurvey();
    } else {
      this.surveyIntervalRuns = this.surveyIntervalRuns + 1;
    }
  }

  private handleQuestionTimeLimit = () => {
    const secondsRan = this.questionIntervalRuns + 1;
    const minutesRan = Math.floor(secondsRan / 60);
    const minutes = this.selectedElement.child.timeLimit > 0 ? this.selectedElement.child.timeLimit : this.survey.answerTimeLimit;

    if (minutesRan >= minutes) {
      clearInterval(this.questionInterval);
      this.questionIntervalRuns = 0;
      this.snackbar.open($localize`Tempo scaduto per rispondere alla domanda`, undefined, {
        duration: 2000
      });
      this.goNext();
    } else {
      this.questionIntervalRuns = secondsRan;
    }
  }

  private checkQuestionTimeLimit(): void {
    let minutes = NaN;

    if (this.selectedElement.child.timeLimit) {
      minutes = Number(this.selectedElement.child.timeLimit);
    } else if (this.survey.answerTimeLimit) {
      minutes = Number(this.survey.answerTimeLimit);
    }

    if (!isNaN(minutes) && minutes > 0) {
      this.questionIntervalRuns = 0;
      this.questionTimeLimit = minutes;
      this.questionInterval = setInterval(this.handleQuestionTimeLimit, 1000);
    } else {
      this.questionIntervalRuns = 0;
      this.questionTimeLimit = null;

      if (this.questionInterval) {
        clearInterval(this.questionInterval);
        this.questionInterval = undefined;
      }
    }
  }

  private handleKeyEvent = e => {
    if (e.key === 'Enter'
      && document.activeElement.tagName !== 'TEXTAREA'
      && this.canGoNext()
    ) {
      this.goNext();
    }
  }

}
