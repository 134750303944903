import {Component, Input, OnInit} from '@angular/core';
import IElement from '../../interfaces/IElement';
import ITextElement from '../../interfaces/ITextElement';
import IRichMediaElement from '../../interfaces/IRichMediaElement';
import {ElementType} from '../../definitions/ElementType';

@Component({
  selector: 'app-process-information-element',
  templateUrl: './process-information-element.component.html',
  styleUrls: ['./process-information-element.component.styl']
})
export class ProcessInformationElementComponent implements OnInit {

  @Input() element: IElement<any>;
  elementType = ElementType;

  constructor() { }

  ngOnInit(): void {
  }

}
