<ng-container *ngIf="loaded && privacyAccepted else loading">
  <app-process-layout [research]="research" [client]="client">
    <h1 class="spacing-top-50 text-upper text-bold" i18n>Benvenuto sulla nostra piattaforma digitale</h1>
    <p class="opacity-50 font-size-9 text-light" i18n>In order to be able to participate at this {{getSurveyType()}}, you must either sign up or register as your personal information and answers will be directly connected to your candidate profile by the system.</p>
    <div class="button-bar spacing-top-10">
      <a mat-flat-button class="text-upper" routerLink="/process/signup" [style.backgroundColor]="'rgba(164, 164, 164, 0.38)'" i18n>Create an account</a>
      <a mat-stroked-button class="text-upper spacing-left-5" routerLink="/process/login" [style.backgroundColor]="'rgba(164, 164, 164, 0.38)'" i18n>I already have an account</a>
    </div>
  </app-process-layout>
</ng-container>

<ng-template #loading>
  <ng-container *ngIf="privacyAccepted else privacyAgreement">
    <app-spinner></app-spinner>
  </ng-container>
</ng-template>

<ng-template #privacyAgreement>
  <div class="privacy-agreement">
    <h1 i18n>Privacy Policy</h1>
    <p i18n>Per continuare con la compilazione del test è necessario leggere e dare il consenso della nostra privacy policy, così come richiesto dalla legislazione della Comunità Europea relativa alla tutela dei dati personali e alla privacy (Regolamento UE n 679/2016 e successive disposizioni di adeguamento legislativo D.Lgs 101/2018).</p>
    <div class="d-flex flex-row align-center" *ngIf="research && research.anonymous">
      <button mat-button color="primary" [disabled]="privacyViewed" (click)="viewPrivacy()" i18n>Clicca qui per leggere la nostra privacy policy.</button>
      <button mat-button color="primary" class="ml-3" [disabled]="!privacyViewed" (click)="acceptPrivacy()" i18n>Accetta e procedi con il test.</button>
    </div>
    <div class="d-flex flex-row align-center" *ngIf="research && !research.anonymous">
      <button mat-button color="primary" [disabled]="privacyViewed" (click)="viewAndAcceptPrivacy()" i18n>Accetta e procedi con il test.</button>
    </div>
  </div>
</ng-template>
