import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import IPair from '../../interfaces/IPair';

@Component({
  selector: 'app-smart-list-dialog',
  templateUrl: './smart-list-dialog.component.html',
  styleUrls: ['./smart-list-dialog.component.styl']
})
export class SmartListDialogComponent implements OnInit {

  items: any[] = [];
  originalItems: any[] = [];
  title = 'No title provided';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SmartListDialogComponent>) {
    this.title = data.title;
  }

  ngOnInit(): void {
    this.data.get().then((data: any[]) => {
      this.items = [...data];
      this.originalItems = [...data];
    }).catch(console.error);
  }

  onItemChange(ev: any, item: any, index: number): void {
    this.items[index].label = ev.target.innerText;
  }

  add(): void {
    this.items.push({
      key: null,
      label: $localize`* New item *`
    });
  }

  delete(item: IPair, index: number): void {
    this.items.splice(index, 1);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.data.save(this.items, this.originalItems).then(() => this.dialogRef.close());
  }

}
