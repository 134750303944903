<div class="wrapper">
  <img class="heading-image" src="assets/images/candidate-header.jpg" *ngIf="!(research && client && client.logo)" />
  <div class="logo-substitute" *ngIf="research && client && client.logo" [style.backgroundColor]="research && client && client.primaryColor ? client.primaryColor : undefined">
    <img [src]="research && client && client.logo" [style.height]="research && client && client.logoHeight ? client.logoHeight + 'px' : undefined" />
  </div>
  <main>
    <ng-content></ng-content>
  </main>
  <footer [style.backgroundColor]="research && client && client.primaryColor ? client.primaryColor : undefined">
    <p i18n class="d-flex flex-row align-center spacing-inner-left-10 spacing-inner-right-10" [style.color]="research && client && client.primaryColor ? getFooterTextColor(client.primaryColor) : undefined">&copy; Telema Srl - All rights reserved <a class="spacing-left-auto" href="assets/docs/telema-privacy.pdf" target="_blank" [style.color]="research && client && client.primaryColor ? getFooterTextColor(client.primaryColor) : undefined">Privacy Policy</a> <a class="spacing-left-5" href="https://telemainternational.com" target="_blank" [style.color]="research && client && client.primaryColor ? getFooterTextColor(client.primaryColor) : undefined">Sito Web</a></p>
  </footer>
</div>
