<mat-form-field appearance="outline" class="full-width">
  <mat-label i18n>{{label}}</mat-label>
  <mat-select>
    <mat-option *ngFor="let option of options" [value]="option.value">
      {{option.label}}
    </mat-option>

    <div class="select-button-bar spacing-inner-left-3 spacing-inner-right-3 spacing-inner-top-5 spacing-inner-bottom-3">
      <button mat-stroked-button color="primary" (click)="add()">
        <mat-icon class="spacing-right-5">add</mat-icon>
        <span i18n>ADD</span>
      </button>
    </div>
  </mat-select>
</mat-form-field>
