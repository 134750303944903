<div *ngIf="privacyAccepted else privacyAgreement" class="container spacing-top-50 spacing-bottom-50">
  <img src="/assets/images/logo.svg" class="align-center d-block" />
  <mat-card class="spacing-top-10">
      <ng-container *ngIf="loaded else loading">

        <h1 class="text-center spacing-0 spacing-inner-0 text-bold" i18n>{{research.title}}</h1>

        <p *ngIf="!showAlreadyHaveAccount" class="text-center spacing-0 spacing-inner-0 spacing-top-2 opacity-50 spacing-bottom-25" i18n>Before you can participate to the research, you must create a candidate profile and fill in all the required fields.</p>

        <p *ngIf="showAlreadyHaveAccount" class="text-center spacing-0 spacing-inner-0 spacing-top-2 opacity-50 spacing-bottom-25" i18n>Before you can participate to the research, you must login with an existing candidate account.</p>

        <app-smart-form *ngIf="!showAlreadyHaveAccount" #createForm [fields]="fieldsCreateFiltered" colSize="6"></app-smart-form>

        <app-smart-form *ngIf="showAlreadyHaveAccount" #loginForm [fields]="fieldsLogin" colSize="6"></app-smart-form>

        <div class="row">
          <div class="col-sm-12 col-lg-6">
            <button *ngIf="!showAlreadyHaveAccount" mat-flat-button class="spacing-top-10 full-width" i18n (click)="alreadyHaveAnAccount()">Already have an account</button>
            <button *ngIf="showAlreadyHaveAccount" mat-flat-button class="spacing-top-10 full-width" i18n (click)="wantToCreateNewAccount()">I need to create an account</button>
          </div>
          <div class="col-sm-12 col-lg-6">
            <button *ngIf="!showAlreadyHaveAccount" mat-flat-button color="primary" class="spacing-top-10 full-width" i18n (click)="submit()" [disabled]="!createForm || createForm.form.invalid">Create account</button>
            <button *ngIf="showAlreadyHaveAccount" mat-flat-button color="primary" class="spacing-top-10 full-width" i18n (click)="submit()" [disabled]="!loginForm || loginForm.form.invalid">Login</button>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <button *ngIf="showAlreadyHaveAccount" (click)="openForgotPasswordDialog()" mat-flat-button i18n class="text-upper full-width">Forgot your password?</button>
          </div>
        </div>

      </ng-container>

  </mat-card>
</div>

<ng-template #loading>
  <app-spinner></app-spinner>
</ng-template>

<ng-template #privacyAgreement>
  <div class="container spacing-top-50 spacing-bottom-50">
    <mat-card class="spacing-top-10">
      <div class="privacy-agreement">
        <h1 i18n>Privacy Policy</h1>
        <p i18n>Per continuare con la compilazione del test è necessario leggere e dare il consenso della nostra privacy policy, così come richiesto dalla legislazione della Comunità Europea relativa alla tutela dei dati personali e alla privacy (Regolamento UE n 679/2016 e successive disposizioni di adeguamento legislativo D.Lgs 101/2018).</p>
        <div class="d-flex flex-row align-center">
          <button mat-button color="primary" [disabled]="privacyViewed" (click)="viewAndAcceptPrivacy()" i18n>Accetta e procedi con il test.</button>
        </div>
      </div>
    </mat-card>
  </div>
</ng-template>
