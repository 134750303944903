import { Component, OnInit } from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community';
import {MatDialog} from '@angular/material/dialog';
import {ReportScriptSelectionDialogComponent} from './report-script-selection-dialog/report-script-selection-dialog.component';

@Component({
  selector: 'app-research-report-cell-renderer',
  templateUrl: './research-report-cell-renderer.component.html',
  styleUrls: ['./research-report-cell-renderer.component.styl']
})
export class ResearchReportCellRendererComponent {

  params: ICellRendererParams|any;

  constructor(private dialog: MatDialog) {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  showScriptDialog(): void {
    const dialogRef = this.dialog.open(ReportScriptSelectionDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(response => {
      if (this.params.onReportSelection) {
        this.params.onReportSelection(response, this.params);
      }
    }, error => console.error(error));
  }

}
