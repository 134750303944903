<h2 class="spacing-top-10">{{element.child.question}}</h2>
<p *ngIf="element.child.description">{{element.child.description}}</p>
<ng-container [ngSwitch]="element.child.type">
  <div class="answers" *ngSwitchCase="questionType.CHOICE">
    <label>{{getLabel()}}</label>
    <div *ngIf="element.child.helperText !== null && element.child.helperText.length > 0" [innerHTML]="element.child.helperText"></div>
    <ng-container *ngIf="element.child.multiple !== true">
      <mat-radio-group class="radio-group" [(ngModel)]="value" (change)="changed($event)">
        <mat-radio-button class="spacing-5" *ngFor="let answer of element.child.answers" [value]="answer.code" color="primary">
          {{answer.label || answer.code}}
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>
    <div class="radio-group" *ngIf="element.child.multiple === true">
      <mat-checkbox class="spacing-5" color="primary" *ngFor="let answer of element.child.answers" [value]="answer.code" [checked]="value && value.indexOf(answer.code) > -1" (change)="checkboxChange($event.source.value)" [disabled]="element.child.maxAnswers && value.length === element.child.maxAnswers && value.indexOf(answer.code) === -1">{{answer.label || answer.code}}</mat-checkbox>
    </div>
  </div>
  <div class="single-answer" *ngSwitchCase="questionType.CHOICE_DROPDOWN">
    <div *ngIf="element.child.helperText !== null && element.child.helperText.length > 0" [innerHTML]="element.child.helperText"></div>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label i18n>{{getLabel()}}</mat-label>
      <mat-select [multiple]="element.child.multiple === true" [(ngModel)]="value" (ngModelChange)="changed($event)">
        <mat-option *ngFor="let answer of element.child.answers" [value]="answer.code">{{answer.label || answer.code}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="single-answer" *ngSwitchCase="questionType.SHORT_ANSWER">
    <div *ngIf="element.child.helperText !== null && element.child.helperText.length > 0" [innerHTML]="element.child.helperText"></div>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label i18n>Insert a short answer</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="value"
        (ngModelChange)="changed($event)"
      >
    </mat-form-field>
  </div>
  <div class="single-answer" *ngSwitchCase="questionType.LONG_ANSWER">
    <div *ngIf="element.child.helperText !== null && element.child.helperText.length > 0" [innerHTML]="element.child.helperText"></div>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label i18n>Insert a long answer</mat-label>
      <textarea
        matInput
        [(ngModel)]="value"
        (ngModelChange)="changed($event)"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="single-answer" *ngSwitchCase="questionType.DATE_INPUT">
    <div *ngIf="element.child.helperText !== null && element.child.helperText.length > 0" [innerHTML]="element.child.helperText"></div>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label i18n>Choose a date</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="value"
        (ngModelChange)="changed($event)"
        [matDatepicker]="datePicker"
        [readonly]="true"
      >
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker [touchUi]="true" #datePicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="single-answer" *ngSwitchCase="questionType.FILE_UPLOAD">
    <app-file-upload [(model)]="value" (modelChange)="changed($event)"></app-file-upload>
  </div>
  <div class="single-answer" *ngSwitchCase="questionType.RANKING_ORDERING">
    <div cdkDropList class="ranking-list" (cdkDropListDropped)="rankingDrop($event)">
      <div class="ranking-box" *ngFor="let answer of element.child.answers" cdkDrag>{{answer.label || answer.code}}</div>
    </div>
  </div>
  <div class="single-answer array-matrix" *ngSwitchCase="questionType.ARRAY_MATRIX">
    <table>
      <tr>
        <td></td>
        <td *ngFor="let column of getColumns()">
          {{column.name || column.code}}
        </td>
      </tr>
      <tr *ngFor="let answer of element.child.answers | distinctArray: 'code'">
        <td>{{answer.label || answer.code}}</td>
        <td *ngFor="let column of getColumns()">
          <mat-radio-button *ngIf="element.child.multiple !== true" color="primary" [name]="answer.code" (change)="matrixChange($event, answer.code, column.code)" [checked]="value && value[column.code].indexOf(answer.code) > -1"></mat-radio-button>
          <mat-checkbox *ngIf="element.child.multiple === true" color="primary" [name]="answer.code" (change)="matrixChange($event, answer.code, column.code)" [checked]="value && value[column.code].indexOf(answer.code) > -1"></mat-checkbox>
        </td>
      </tr>
    </table>
  </div>
</ng-container>
