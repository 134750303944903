import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayFilterBy'
})
export class ArrayFilterByPipe implements PipeTransform {

  transform(arr: any[], filterBy: (item: any, params?: boolean) => boolean, params: any = {}): any[] {
    if (!arr) {
      return [];
    }

    return arr.filter(item => filterBy(item, params));
  }

}
