<app-standard-layout>
  <ng-container *ngIf="view === null">
    <div class="spacing-inner-10">
      <div class="row">
        <div class="col-sm-3 col-xl-2">
          <button mat-raised-button class="square-button" (click)="goTo('education-institutes')">
            <div class="inner-button d-flex flex-column align-items-center text-upper">
              <mat-icon>school</mat-icon>
              <span i18n>Education Institutes</span>
            </div>
          </button>
        </div>
        <div class="col-sm-3 col-xl-2">
          <button mat-raised-button class="square-button" (click)="goTo('education-categories')">
            <div class="inner-button d-flex flex-column align-items-center text-upper">
              <mat-icon>category</mat-icon>
              <span i18n>Education Categories</span>
            </div>
          </button>
        </div>
        <div class="col-sm-3 col-xl-2">
          <button mat-raised-button class="square-button" (click)="goTo('education-levels')">
            <div class="inner-button d-flex flex-column align-items-center text-upper">
              <mat-icon>layers</mat-icon>
              <span i18n>Education Levels</span>
            </div>
          </button>
        </div>
        <div class="col-sm-3 col-xl-2">
          <button mat-raised-button class="square-button" (click)="goTo('skills')">
            <div class="inner-button d-flex flex-column align-items-center text-upper">
              <mat-icon>star</mat-icon>
              <span i18n>Skills</span>
            </div>
          </button>
        </div>
        <div class="col-sm-3 col-xl-2">
          <button mat-raised-button class="square-button" (click)="goTo('work-professions')">
            <div class="inner-button d-flex flex-column align-items-center text-upper">
              <mat-icon>engineering</mat-icon>
              <span i18n>Work Professions</span>
            </div>
          </button>
        </div>
        <div class="col-sm-3 col-xl-2">
          <button mat-raised-button class="square-button" (click)="goTo('work-sectors')">
            <div class="inner-button d-flex flex-column align-items-center text-upper">
              <mat-icon>group_work</mat-icon>
              <span i18n>Work Sectors</span>
            </div>
          </button>
        </div>
        <div class="col-sm-3 col-xl-2">
          <button mat-raised-button class="square-button" (click)="goTo('roles')">
            <div class="inner-button d-flex flex-column align-items-center text-upper">
              <mat-icon>admin_panel_settings</mat-icon>
              <span i18n>Roles</span>
            </div>
          </button>
        </div>
        <div class="col-sm-3 col-xl-2">
          <button mat-raised-button class="square-button" (click)="goTo('contract-types')">
            <div class="inner-button d-flex flex-column align-items-center text-upper">
              <mat-icon>description</mat-icon>
              <span i18n>Contract Types</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="view !== null">
    <div class="spacing-inner-10">
      <h1 i18n>Manage {{title}}</h1>
      <div class="button-bar spacing-bottom-10">
        <button mat-stroked-button (click)="goBack()">
          <mat-icon class="spacing-right-5">arrow_back</mat-icon>
          <span i18n>GO BACK</span>
        </button>
        <button mat-stroked-button class="spacing-left-5" (click)="add()" *ngIf="action === null">
          <mat-icon class="spacing-right-5">add</mat-icon>
          <span i18n>ADD</span>
        </button>
        <button mat-stroked-button color="primary" class="spacing-left-5" (click)="action === 'add' ? confirmAdd() : confirmEdit()" *ngIf="action === 'add' || action === 'edit'" [disabled]="!formComponent || formComponent.form.invalid">
          <mat-icon class="spacing-right-5">save</mat-icon>
          <span i18n>SAVE</span>
        </button>
      </div>

      <ng-container *ngIf="action === null">
        <mat-card *ngFor="let record of records; let i = index" [ngClass]="{'spacing-top-5': i > 0}">
          <div class="d-flex flex-row align-items-center full-width">
            {{getLabel(record)}}


            <button mat-icon-button class="spacing-left-auto" (click)="edit(record)">
              <mat-icon>edit</mat-icon>
            </button>

            <button mat-icon-button class="spacing-left-5" (click)="delete(record)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-card>
      </ng-container>

      <mat-card *ngIf="action === 'add' || action === 'edit'">
        <mat-card-header>
          <mat-card-title *ngIf="action === 'add'" i18n>Add new {{itemTitle}}</mat-card-title>
          <mat-card-title *ngIf="action === 'edit'" i18n>Edit {{getLabel(defaults)}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="spacing-top-5">
            <app-smart-form #formComponent [fields]="fields" [defaults]="defaults"></app-smart-form>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>

</app-standard-layout>
