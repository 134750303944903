import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {StoreModule} from '@ngrx/store';
import {authReducer} from './reducers/auth.reducer';
import { HomeScreenComponent } from './screens/home-screen/home-screen.component';
import { LoginScreenComponent } from './screens/login-screen/login-screen.component';
import {InterfaceModule} from './interface.module';
import { FooterComponent } from './components/footer/footer.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ForgotPasswordDialogComponent } from './components/forgot-password-dialog/forgot-password-dialog.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { NavigationComponent } from './components/navigation/navigation.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { StandardLayoutComponent } from './components/standard-layout/standard-layout.component';
import { UserScreenComponent } from './screens/user-screen/user-screen.component';
import { SmartTableComponent } from './components/smart-table/smart-table.component';
import {AgGridModule} from 'ag-grid-angular';
import { CheckboxCellRendererComponent } from './components/smart-table/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { StandardCellRendererComponent } from './components/standard-cell-renderer/standard-cell-renderer.component';
import { ActionsCellRendererComponent } from './components/smart-table/actions-cell-renderer/actions-cell-renderer.component';
import { TestSurveyScreenComponent } from './screens/test-survey-screen/test-survey-screen.component';
import {CandidateScreenComponent, CandidateScreenInviteDialogComponent} from './screens/candidate-screen/candidate-screen.component';
import { DataManagementScreenComponent } from './screens/data-management-screen/data-management-screen.component';
import { SmartFormComponent } from './components/smart-form/smart-form.component';
import { SmartDetailComponent } from './components/smart-detail/smart-detail.component';
import { DateCellRendererComponent } from './components/smart-table/date-cell-renderer/date-cell-renderer.component';
import {DateFormatPipe} from './common/DateFormatPipe';
import { SurveyDialogComponent } from './components/survey-dialog/survey-dialog.component';
import { SmartListComponent } from './components/smart-list/smart-list.component';
import { SmartListDialogComponent } from './components/smart-list-dialog/smart-list-dialog.component';
import { SurveyQuestionDialogComponent } from './components/survey-question-dialog/survey-question-dialog.component';
import { QuestionAnswersDialogComponent } from './components/question-answers-dialog/question-answers-dialog.component';
import {ClientsScreenComponent} from './screens/clients-screen/clients-screen.component';
import {ProjectsScreenComponent} from './screens/projects-screen/projects-screen.component';
import {GroupsScreenComponent} from './screens/groups-screen/groups-screen.component';
import { InputDialogComponent } from './components/input-dialog/input-dialog.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import { RichInputComponent } from './components/rich-input/rich-input.component';
import { ProcessLandingScreenComponent } from './screens/process-landing-screen/process-landing-screen.component';
import { ProcessLayoutComponent } from './components/process-layout/process-layout.component';
import { ProcessLoginScreenComponent } from './screens/process-login-screen/process-login-screen.component';
import { ProcessSignupScreenComponent } from './screens/process-signup-screen/process-signup-screen.component';
import { RunSurveyCellRendererComponent } from './components/run-survey-cell-renderer/run-survey-cell-renderer.component';
import { RunSurveyDialogComponent } from './components/run-survey-dialog/run-survey-dialog.component';
import {JobsScreenComponent} from './screens/jobs-screen/jobs-screen.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import {processReducer} from './reducers/process.reducer';
import {ProcessDoScreenComponent} from './screens/process-do-screen/process-do-screen.component';
import { ProcessSurveyComponent } from './components/process-survey/process-survey.component';
import { RandomizeArrayPipe } from './pipes/randomize-array.pipe';
import { ProcessQuestionElementComponent } from './components/process-question-element/process-question-element.component';
import { ProcessInformationElementComponent } from './components/process-information-element/process-information-element.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import {MatRippleModule} from '@angular/material/core';
import { ProcessGroupComponent } from './components/process-group/process-group.component';
import { SmartSelectComponent } from './components/smart-select/smart-select.component';
import { SmartVUDListComponent } from './components/smart-vudlist/smart-vudlist.component';
import { SmartVUDListDialogComponent } from './components/smart-vudlist/smart-vudlist-dialog/smart-vudlist-dialog.component';
import { GroupCellRendererComponent } from './components/smart-table/group-cell-renderer/group-cell-renderer.component';
import { GroupArrayPipe } from './common/group-array.pipe';
import { DistinctArrayPipe } from './common/distinct-array.pipe';
import { FindByPipe } from './common/find-by.pipe';
import { ResearchScreenComponent } from './screens/research-screen/research-screen.component';
import { ObjectCellRendererComponent } from './components/smart-table/object-cell-renderer/object-cell-renderer.component';
import {CommonModule} from '@angular/common';
import { QuestionAnswersCellRendererComponent } from './components/smart-table/question-answers-cell-renderer/question-answers-cell-renderer.component';
import { QuestionAnswersCellRendererDialogComponent } from './components/smart-table/question-answers-cell-renderer/question-answers-cell-renderer-dialog/question-answers-cell-renderer-dialog.component';
import { SettingsScreenComponent } from './screens/settings-screen/settings-screen.component';
import { FormFieldWrapComponent } from './components/form-field-wrap/form-field-wrap.component';
import {InputCrudComponent, InputCrudDialog} from './components/smart-form/input-crud/input-crud.component';
import { CandidateEducationComponent } from './components/candidate-education/candidate-education.component';
import { FilterByPipe } from './common/filter-by.pipe';
import { CandidateCourseComponent } from './components/candidate-course/candidate-course.component';
import { CandidateWorkExperienceComponent } from './components/candidate-work-experience/candidate-work-experience.component';
import { GenericCrudListComponent } from './components/generic-crud-list/generic-crud-list.component';
import {InputObjectAddDialog, InputObjectComponent, InputObjectDialog} from './components/smart-form/input-object/input-object.component';
import { ArrayFilterByPipe } from './pipes/array-filter-by.pipe';
import { ArrayMapByPipe } from './pipes/array-map-by.pipe';
import { ArrayManipulatePipe } from './pipes/array-manipulate.pipe';
import { ImportDialogComponent } from './components/import-dialog/import-dialog.component';
import {InputUploadBottomSheet, InputUploadComponent} from './components/input-upload/input-upload.component';
import { InputChipsComponent } from './components/input-chips/input-chips.component';
import {SmartCrudBottomSheet, SmartCrudComponent} from './components/smart-crud/smart-crud.component';
import { ButtonsCellRendererComponent } from './components/smart-table/buttons-cell-renderer/buttons-cell-renderer.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ResearchReportCellRendererComponent } from './components/smart-table/research-report-cell-renderer/research-report-cell-renderer.component';
import { ReportScriptSelectionDialogComponent } from './components/smart-table/research-report-cell-renderer/report-script-selection-dialog/report-script-selection-dialog.component';
import { ReportScreenComponent } from './screens/report-screen/report-screen.component';
import { PrivacyRejectedScreenComponent } from './screens/privacy-rejected-screen/privacy-rejected-screen.component';
import { SecondsToMinutesAndSecondsPipe } from './pipes/seconds-to-minutes-and-seconds.pipe';
import { AsDateTimePipe } from './pipes/as-date-time.pipe';
import {
  LongListCellRendererComponent,
  LongListCellRendererDialogComponent
} from './components/smart-table/long-list-cell-renderer/long-list-cell-renderer.component';
import { ObjectListFilterComponent } from './components/smart-table/object-list-filter/object-list-filter.component';
import { ProcessSurveyGivenAnswersDialogComponent } from './components/process-survey/process-survey-given-answers-dialog/process-survey-given-answers-dialog.component';
import { ResearchScreenExportConfirmationComponent } from './screens/research-screen/research-screen-export-confirmation/research-screen-export-confirmation.component';
import { OperationScreenComponent } from './screens/operation-screen/operation-screen.component';
import { CandidateSignupScreenComponent } from './screens/candidate-signup-screen/candidate-signup-screen.component';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import { PrivacyPolicyDialogComponent } from './components/privacy-policy-dialog/privacy-policy-dialog.component';
import {NgxEchartsModule} from 'ngx-echarts';
import { ProcessLandingShareableScreenComponent } from './screens/process-landing-shareable-screen/process-landing-shareable-screen.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HomeScreenComponent,
    LoginScreenComponent,
    FooterComponent,
    ForgotPasswordDialogComponent,
    NavigationComponent,
    TopbarComponent,
    StandardLayoutComponent,
    UserScreenComponent,
    SmartTableComponent,
    CheckboxCellRendererComponent,
    StandardCellRendererComponent,
    ActionsCellRendererComponent,
    TestSurveyScreenComponent,
    CandidateScreenComponent,
    DataManagementScreenComponent,
    SmartFormComponent,
    SmartDetailComponent,
    DateCellRendererComponent,
    DateFormatPipe,
    SurveyDialogComponent,
    SmartListComponent,
    SmartListDialogComponent,
    SurveyQuestionDialogComponent,
    QuestionAnswersDialogComponent,
    ClientsScreenComponent,
    ProjectsScreenComponent,
    GroupsScreenComponent,
    InputDialogComponent,
    RichInputComponent,
    ProcessLandingScreenComponent,
    ProcessLayoutComponent,
    ProcessLoginScreenComponent,
    ProcessSignupScreenComponent,
    RunSurveyCellRendererComponent,
    RunSurveyDialogComponent,
    JobsScreenComponent,
    SpinnerComponent,
    ProcessDoScreenComponent,
    ProcessSurveyComponent,
    RandomizeArrayPipe,
    ProcessQuestionElementComponent,
    ProcessInformationElementComponent,
    FileUploadComponent,
    ProcessGroupComponent,
    SmartSelectComponent,
    SmartVUDListComponent,
    SmartVUDListDialogComponent,
    GroupCellRendererComponent,
    GroupArrayPipe,
    DistinctArrayPipe,
    FindByPipe,
    ResearchScreenComponent,
    ObjectCellRendererComponent,
    QuestionAnswersCellRendererComponent,
    QuestionAnswersCellRendererDialogComponent,
    SettingsScreenComponent,
    FormFieldWrapComponent,
    InputCrudComponent,
    InputCrudDialog,
    CandidateEducationComponent,
    FilterByPipe,
    CandidateCourseComponent,
    CandidateWorkExperienceComponent,
    GenericCrudListComponent,
    InputObjectComponent,
    InputObjectDialog,
    InputObjectAddDialog,
    ArrayFilterByPipe,
    ArrayMapByPipe,
    ArrayManipulatePipe,
    ImportDialogComponent,
    InputUploadComponent,
    InputUploadBottomSheet,
    InputChipsComponent,
    SmartCrudComponent,
    SmartCrudBottomSheet,
    ButtonsCellRendererComponent,
    CandidateScreenInviteDialogComponent,
    ConfirmDialogComponent,
    ResearchReportCellRendererComponent,
    ReportScriptSelectionDialogComponent,
    ReportScreenComponent,
    PrivacyRejectedScreenComponent,
    SecondsToMinutesAndSecondsPipe,
    AsDateTimePipe,
    LongListCellRendererComponent,
    LongListCellRendererDialogComponent,
    ObjectListFilterComponent,
    ProcessSurveyGivenAnswersDialogComponent,
    ResearchScreenExportConfirmationComponent,
    OperationScreenComponent,
    CandidateSignupScreenComponent,
    PrivacyPolicyDialogComponent,
    ProcessLandingShareableScreenComponent,
    SafeHtmlPipe,
  ],
  imports: [
    BrowserModule,
    StoreModule.forRoot({
      auth: authReducer,
      process: processReducer
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    InterfaceModule,
    ReactiveFormsModule,
    HttpClientModule,
    AgGridModule.withComponents([]),
    FormsModule,
    CKEditorModule,
    MatRippleModule,
    CommonModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
