<div class="container spacing-top-50">
  <img src="/assets/images/logo.svg" class="align-center d-block" />
  <mat-card class="spacing-top-10">
    <h1 class="text-center spacing-0 spacing-inner-0 text-bold" i18n>Sign in to your Personal Area</h1>
    <p class="text-center spacing-0 spacing-inner-0 spacing-top-2 opacity-50" i18n>To manage and view platform’s data you must log in with your account</p>

    <form class="spacing-top-25" [formGroup]="loginForm" (keydown)="formSubmitOnEnter($event)">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label i18n>Email address</mat-label>
        <input matInput i18n-placeholder placeholder="Insert your email address" formControlName="email">
        <mat-icon matSuffix>account_circle</mat-icon>
        <mat-error *ngIf="loginForm.controls.email.invalid">{{getErrorMessage(loginForm.controls.email)}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width spacing-top-5">
        <mat-label i18n>Password</mat-label>
        <input matInput i18n-placeholder placeholder="Insert your password" type="password" formControlName="password">
        <mat-icon matSuffix>lock</mat-icon>
        <mat-error *ngIf="loginForm.controls.password.invalid">{{getErrorMessage(loginForm.controls.password)}}</mat-error>
      </mat-form-field>

      <div class="clearbox">
        <button mat-button i18n class="pull-right" tabindex="2" (click)="openForgotPasswordDialog()">Forgot your password?</button>
      </div>

      <button mat-flat-button color="primary" class="spacing-top-10 full-width" i18n (click)="login()" [disabled]="loginForm.invalid">Log In</button>
    </form>
  </mat-card>
</div>
