import {Component, OnInit, ViewChild} from '@angular/core';
import ISmartField from '../../interfaces/ISmartField';
import {ContentType} from '../../definitions/ContentType';
import {SmartFormComponent} from '../../components/smart-form/smart-form.component';
import {ApiService} from '../../services/api.service';
import {select, Store} from '@ngrx/store';
import IStore from '../../interfaces/IStore';
import IProcessState from '../../interfaces/IProcessState';
import IAuthState from '../../interfaces/IAuthState';
import {Router} from '@angular/router';
import IResearch from '../../interfaces/IResearch';
import {SurveyType} from '../../definitions/SurveyType';
import {AuthService} from '../../services/auth.service';
import {ProcessService} from '../../services/process.service';
import {SessionService} from '../../services/session.service';
import ISurvey from "../../interfaces/ISurvey";
import {ForgotPasswordDialogComponent} from "../../components/forgot-password-dialog/forgot-password-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import IClient from "../../interfaces/IClient";

@Component({
  selector: 'app-process-login-screen',
  templateUrl: './process-login-screen.component.html',
  styleUrls: ['./process-login-screen.component.styl']
})
export class ProcessLoginScreenComponent implements OnInit {

  fieldsLogin: ISmartField[] = [
    { key: 'authKey', label: $localize`Email / Phone`, required: true, type: ContentType.SHORT_TEXT },
    { key: 'password', label: $localize`Password`, required: true, type: ContentType.PASSWORD },
  ];

  research: IResearch = null;
  client: IClient = null;
  survey: ISurvey = null;
  trace: string;

  @ViewChild('loginComponent') loginComponent: SmartFormComponent;

  constructor(private dialog: MatDialog, private apiService: ApiService, private store: Store<IStore>, private router: Router, private authService: AuthService, private processService: ProcessService, private sessionService: SessionService) {
    this.store.pipe().subscribe((state: IStore) => {
      if (state.process.research === null) {
        this.router.navigateByUrl('/process/landing');
      } else if (state.auth.candidate !== null && state.process.candidate !== null) {
        this.router.navigateByUrl('/process/do');
      } else {
        this.research = state.process.research;
        this.survey = state.process.survey;
        this.client = state.process.client;
      }
    });
  }

  ngOnInit(): void {
  }

  login(): void {
    const value: {authKey: string, password: string} = this.loginComponent.form.getRawValue();
    this.apiService.candidateLogin(value.authKey, value.password, this.sessionService.getTrace()).then(response => {
      this.apiService.updateSubjectPrivacyAgreements(response.candidate.id, this.processService.getPrivacyAgreements()).then(() => {}).catch(console.error);
      this.authService.setCandidateToken(response.token);
      this.authService.setCandidate(response.candidate);
      this.processService.setCandidate(response.candidate);
    }).catch(() => {});
  }

  getSurveyType(): string {
    if (this.research === null) {
      return '';
    }

    if (this.survey.type === SurveyType.STANDARD) {
      return $localize`survey`;
    }

    return $localize`test`;
  }

  openForgotPasswordDialog(): void {
    const dialogRef = this.dialog.open(ForgotPasswordDialogComponent, {
      width: '98%',
      maxWidth: '300px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(email => {
      this.apiService.sendCandidateRecoveryLink(email).then().catch();
    });
  }

}
