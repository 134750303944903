import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '../../../../services/api.service';
import IExtractionScript from '../../../../interfaces/IExtractionScript';
import IReport from '../../../../interfaces/IReport';

@Component({
  selector: 'app-report-script-selection-dialog',
  templateUrl: './report-script-selection-dialog.component.html',
  styleUrls: ['./report-script-selection-dialog.component.styl']
})
export class ReportScriptSelectionDialogComponent {

  report: IReport = null;
  reports: IReport[] = [];

  constructor(
    public dialogRef: MatDialogRef<ReportScriptSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService
  ) {
    apiService.getReports().then(reports => {
      this.reports = reports;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
