import { Component, OnInit } from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community';
import {MatDialog} from '@angular/material/dialog';
import {QuestionAnswersCellRendererDialogComponent} from './question-answers-cell-renderer-dialog/question-answers-cell-renderer-dialog.component';

@Component({
  selector: 'app-question-answers-cell-renderer',
  templateUrl: './question-answers-cell-renderer.component.html',
  styleUrls: ['./question-answers-cell-renderer.component.styl']
})
export class QuestionAnswersCellRendererComponent {

  params: ICellRendererParams;

  constructor(private dialog: MatDialog) {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  openDialog(): void {
    this.dialog.open(QuestionAnswersCellRendererDialogComponent, {
      data: {
        params: this.params
      },
      autoFocus: false,
      width: '95%',
      maxWidth: '600px'
    });
  }

}
