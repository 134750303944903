import {Component, OnInit, ViewChild} from '@angular/core';
import IClient from '../../interfaces/IClient';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RouteAction} from '../../definitions/RouteAction';
import {ContentType} from '../../definitions/ContentType';
import IItem from '../../interfaces/IItem';
import IRole from '../../interfaces/IRole';
import {SmartFormComponent} from '../../components/smart-form/smart-form.component';
import ISmartField from '../../interfaces/ISmartField';
import IDetailField from '../../interfaces/IDetailField';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-client-screen',
  templateUrl: './clients-screen.component.html',
  styleUrls: ['./clients-screen.component.styl']
})
export class ClientsScreenComponent implements OnInit {

  @ViewChild('addForm') addForm: SmartFormComponent;
  @ViewChild('editForm') editForm: SmartFormComponent;
  action: RouteAction = RouteAction.LOADING;
  routeAction = RouteAction;
  clients: IClient[] = [];
  clientsTableColumnDefs = [
    { headerName: $localize`Code`, field: 'code', editable: false },
    { headerName: $localize`Name`, field: 'name', editable: false },
    { headerName: $localize`Company`, field: 'companyName', editable: false },
    { headerName: $localize`Registration Number`, field: 'registrationNumber', editable: false },
    { headerName: $localize`VAT Code`, field: 'vatNumber', editable: false },
    { headerName: $localize`Fiscal Code`, field: 'fiscalCode', editable: false },
    { headerName: $localize`Legal Name`, field: 'legalName', editable: false },
    { headerName: $localize`Website`, field: 'website', editable: false },
    { headerName: $localize`Phone`, field: 'phone', editable: false },
  ];
  clientFormFieldsCreate = [
    { key: 'code', label: $localize`Code`, required: true, type: ContentType.SHORT_TEXT },
    { key: 'name', label: $localize`Name`, required: true, type: ContentType.SHORT_TEXT },
    { key: 'companyName', label: $localize`Company Name`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'registrationNumber', label: $localize`Registration Number`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'vatNumber', label: $localize`VAT Code`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'fiscalCode', label: $localize`Fiscal Code`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'legalName', label: $localize`Legal Name`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'website', label: $localize`Website`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'phone', label: $localize`Phone`, required: false, type: ContentType.PHONE },
    { key: 'logo', label: $localize`Logo`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'logoHeight', label: $localize`Logo Height`, required: false, type: ContentType.INTEGER },
    { key: 'primaryColor', label: $localize`Primary Color`, required: false, type: ContentType.SHORT_TEXT }
  ];
  clientFormFieldsEdit = [...this.clientFormFieldsCreate];
  clientDetailFields: IDetailField[] = this.clientFormFieldsCreate.map(field => ({
    key: field.key,
    label: field.label,
    type: field.type
  }));
  selectedClientRows = 0;
  clientEntity: IClient = null;

  constructor(private apiService: ApiService, private activatedRoute: ActivatedRoute, private router: Router, public authService: AuthService) {
    this.onClientDelete = this.onClientDelete.bind(this);
    this.onClientEdit = this.onClientEdit.bind(this);
    this.onClientView = this.onClientView.bind(this);
  }

  ngOnInit(): void {
    if (this.activatedRoute.firstChild === null) {
      this.action = RouteAction.LISTING;
      this.fetchClients();
    } else {
      const snapshot = this.activatedRoute.firstChild.snapshot;

      if (snapshot.routeConfig.path.startsWith('add')) {
        this.goToAdd();
      } else if (snapshot.routeConfig.path.startsWith('view')) {
        this.goToView();
      } else if (snapshot.routeConfig.path.startsWith('edit')) {
        this.goToEdit();
      }
    }
  }

  fetchClients(): void {
    this.apiService.getClients().then((clients: IClient[]) => {
      this.clients = clients.filter(client => this.authService.hasBoundingPermission('client', 'view', client));
    });
  }

  save(): void {
    if (this.action === RouteAction.ADD) {
      if (this.addForm.form.invalid) {
        this.addForm.form.markAllAsTouched();
        return;
      }

      const client: IClient = this.addForm.form.getRawValue();

      this.apiService.createClient(client).then(() => {
        this.goToListing();
      });
    } else if (this.action === RouteAction.EDIT) {
      if (this.editForm.form.invalid) {
        this.editForm.form.markAllAsTouched();
        return;
      }

      const data: IClient = {
        ...this.clientEntity,
        ...this.editForm.form.getRawValue()
      };

      this.apiService.updateClient(data.code, data).then((client: IClient) => {
        this.clientEntity = client;
        this.editForm.defaults = client;
        this.editForm.setValue({
          ...client,
          password: null
        });
      });
    }
  }

  reset(): void {
    if (this.action === RouteAction.EDIT) {
      this.editForm.reset();
    } else if (this.action === RouteAction.ADD) {
      this.addForm.reset();
    }
  }

  goToListing(): void {
    this.fetchClients();
    this.router.navigateByUrl('/clients');
    this.action = RouteAction.LISTING;
  }

  goToAdd(): void {
    this.router.navigateByUrl('/clients/add');
    this.action = RouteAction.ADD;
  }

  goToEdit(data: IClient = null): void {
    if (data === null) {
      this.apiService.getClient(this.activatedRoute.firstChild.snapshot.params.id).then((client: IClient) => {
        this.clientEntity = client;
        this.action = RouteAction.EDIT;
      });
    } else {
      this.clientEntity = data;
      this.router.navigateByUrl('/clients/edit/' + data.code);
      this.action = RouteAction.EDIT;
    }
  }

  goToView(data: IClient = null): void {
    if (data === null) {
      this.apiService.getClient(this.activatedRoute.firstChild.snapshot.params.id).then((client: IClient) => {
        this.clientEntity = client;
        this.action = RouteAction.VIEW;
      });
    } else {
      this.clientEntity = data;
      this.router.navigateByUrl('/clients/view/' + data.code);
      this.action = RouteAction.VIEW;
    }
  }

  goBack(): void {
    switch (this.action) {
      case RouteAction.ADD:
      case RouteAction.EDIT:
      case RouteAction.VIEW:
        this.goToListing();
        break;

      default:
    }
  }

  onClientDelete(ev: any): void {
    this.apiService.deleteClient(ev.data.code).then(() => {
      this.fetchClients();
    });
  }

  onClientEdit(ev: any): void {
    this.goToEdit(ev.data);
  }

  onClientView(ev: any): void {
    this.goToView(ev.data);
  }
}


