import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import IResearch from '../../interfaces/IResearch';
import {SurveyType} from '../../definitions/SurveyType';
import {ProcessService} from '../../services/process.service';
import ISubject from '../../interfaces/ISubject';
import IAnonymousCandidate from '../../interfaces/IAnonymousCandidate';
import {AuthService} from '../../services/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {PrivacyPolicyDialogComponent} from '../../components/privacy-policy-dialog/privacy-policy-dialog.component';
import ISurvey from "../../interfaces/ISurvey";
import IPrivacyAgreements from "../../interfaces/IPrivacyAgreements";
import IClient from "../../interfaces/IClient";

@Component({
  selector: 'app-process-landing-screen',
  templateUrl: './process-landing-screen.component.html',
  styleUrls: ['./process-landing-screen.component.styl']
})
export class ProcessLandingScreenComponent implements OnInit {

  loaded = false;
  research: IResearch = null;
  survey: ISurvey = null;
  candidate: ISubject|IAnonymousCandidate = null;
  client: IClient = null;
  candidateId: string = null;
  researchId: string = null;
  surveyId: string = null;
  surveyType = SurveyType;
  trace: string = null;
  privacyAccepted = false;
  privacyViewed = false;

  constructor(private activatedRoute: ActivatedRoute, private apiService: ApiService, private processService: ProcessService, private authService: AuthService, private router: Router, private matDialog: MatDialog) { }

  ngOnInit(): void {
    this.processService.getResearchFromTraceId(this.activatedRoute.snapshot.queryParams.traceid || this.processService.getTrace()).then(async (response) => {
      const clientCode = response.research.client;

      if (clientCode) {
        this.client = await this.apiService.getClient(clientCode);
      }

      this.research = response.research;
      this.survey = response.survey;
      this.candidate = response.candidate || response.anonymousCandidate;
      this.candidateId = response.candidateId;
      this.researchId = response.researchId;
      this.surveyId = response.surveyId;
      this.trace = response.trace;
    });
  }

  viewPrivacy(): void {
    window.open('assets/docs/telema-privacy.pdf', '_blank');
    this.privacyViewed = true;
  }

  viewAndAcceptPrivacy(): void {
    this.matDialog.open(PrivacyPolicyDialogComponent, {
      width: '98vw',
      maxWidth: '1200px',
      data: {
        research: this.research,
        survey: this.survey
      }
    }).afterClosed().subscribe((privacyPolicyResult: IPrivacyAgreements) => {
      if (
        privacyPolicyResult.personalDataAgreement
        && privacyPolicyResult.personalSpecificDataAgreement
        && privacyPolicyResult.dataUsageForCandidateResearchAgreement
        && privacyPolicyResult.dataUsageForStatisticsAndReportsAgreement
      ) {
        this.processService.setPrivacyAgreements(privacyPolicyResult);
        this.acceptPrivacy();
      } else {
        window.location.href = 'https://telemainternational.com';
      }
    });
  }

  acceptPrivacy(): void {
    this.privacyAccepted = true;
    window.history.replaceState({}, document.title, '/process/landing');
    this.validateLoading();
  }

  validateLoading(): void {
    if (
      this.researchId !== null &&
      this.surveyId !== null &&
      this.candidateId !== null &&
      this.research !== null &&
      this.candidate !== null &&
      this.survey !== null &&
      this.trace !== null
    ) {
      this.processService.fillSession(this.trace, this.research, this.candidate, this.survey, this.client);

      if (this.research.anonymous) {
        this.authService.setCandidate(this.candidate);
        this.router.navigateByUrl('/process/do');
      }

      this.loaded = true;
    }
  }

  getSurveyType(): string {
    if (this.survey.type === SurveyType.STANDARD) {
      return $localize`survey`;
    }

    return $localize`test`;
  }

}
