import {Injectable, Injector} from '@angular/core';
import ISessionObject from '../interfaces/ISessionObject';
import {select, Store} from '@ngrx/store';
import IStore from '../interfaces/IStore';
import {Observable, Subject} from 'rxjs';
import IAuthState from '../interfaces/IAuthState';
import IUser from '../interfaces/IUser';
import {setCandidate, setCandidateToken, setToken, setUser, setUserClient, setUserRole} from '../actions/auth.actions';
import {SubscriptionCode} from '../definitions/SubscriptionCode';
import ISubject from '../interfaces/ISubject';
import {SessionService} from './session.service';
import IResearch from '../interfaces/IResearch';
import IAnonymousCandidate from '../interfaces/IAnonymousCandidate';
import {ApiService} from './api.service';
import {ContentType} from '../definitions/ContentType';
import IClient from '../interfaces/IClient';
import IItem from '../interfaces/IItem';
import {take} from "rxjs/operators";
import IRole from "../interfaces/IRole";
import {UtilsService} from "./utils.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private SESSION_NAME = 'SoniaStorage';
  private auth: Observable<IAuthState>;
  private user: IUser;
  private userRole: IRole;
  private userClient: IClient;
  subject: Subject<any>;

  constructor(private store: Store<IStore>, private sessionService: SessionService, private injector: Injector, private utilsService: UtilsService) {
    this.subject = new Subject();

    store.pipe(select('auth')).subscribe((response: IAuthState) => {
      if (response.user !== null && response.token !== null) {
        this.subject.next({
          code: SubscriptionCode.USER_SIGNED_IN,
          payload: null
        });

        this.user = response.user;
        this.userRole = response.userRole;
      }
    });
  }

  setUser(user: IUser): void {
    this.user = user;
    this.store.dispatch(setUser(user));
  }

  setUserRole(userRole: IRole): void {
    this.userRole = userRole;
    this.store.dispatch(setUserRole(userRole));
  }

  setUserClient(userClient: IClient): void {
    this.userClient = userClient;
    this.store.dispatch(setUserClient(userClient));
  }

  setCandidate(candidate: ISubject|IAnonymousCandidate): void {
    this.store.dispatch(setCandidate(candidate));
  }

  isAuthorised(): boolean {
    return this.sessionService.getAuthToken() !== null;
  }

  getUser(): IUser {
    return this.user;
  }

  getUserRole(): IRole {
    return this.userRole;
  }

  getUserClient(): IClient {
    return this.userClient;
  }

  hasPermission(permissionsRequired: any): boolean {
    if (!this.user) {
      return false;
    }

    if (!permissionsRequired || permissionsRequired.length === 0) {
      return true;
    }

    const permissions = this.getUserPermissions();

    for (const permissionRequired of permissionsRequired) {
      if (permissions.includes(permissionRequired)) {
        return true;
      }
    }

    return false;
  }

  getUserPermissions(): string[] {
    const userPermissions = (this.user && this.user.permissions) || [];
    const rolePermissions = (this.userRole && this.userRole.permissions) || [];

    return Array.from(new Set([...userPermissions, ...rolePermissions]));
  }

  getToken(): string {
    return this.sessionService.getAuthToken();
  }

  setToken(token: string): void {
    this.sessionService.setAuthToken(token);
    this.store.dispatch(setToken({ token }));
  }

  isCandidateAuthorised(): boolean {
    return this.sessionService.getCandidateToken() !== null;
  }

  getCandidateToken(): string {
    return this.sessionService.getCandidateToken();
  }

  setCandidateToken(token: string): void {
    this.sessionService.setCandidateToken(token);
    this.store.dispatch(setCandidateToken({ candidateToken: token }));
  }

  hasBoundingPermission(permissionGroup: string, permissionPiece: string, data: any): boolean {
    const userPermissions = this.getUserPermissions();
    const user = this.getUser();

    if (!permissionGroup) {
      return true;
    }

    if (userPermissions.includes(`${permissionGroup}_${permissionPiece}`)) {
      return true;
    }

    if (userPermissions.includes(`${permissionGroup}_${permissionPiece}_own`) && data.hasOwnProperty('createdBy') && data.createdBy === user.id) {
      return true;
    }

    if (userPermissions.includes(`${permissionGroup}_${permissionPiece}_org`) && data.hasOwnProperty('client') && user.hasOwnProperty('client') && data.client !== null && user.client === data.client) {
      return true;
    }

    if (userPermissions.includes(`${permissionGroup}_${permissionPiece}_org`) && data.hasOwnProperty('clients') && user.hasOwnProperty('client') && data.clients !== null && data.clients.includes(user.client)) {
      return true;
    }

    // if (userPermissions.includes(`${permissionGroup}_client_own_${permissionPiece}`) && data.hasOwnProperty('createdBy') && data.hasOwnProperty('client') && user.hasOwnProperty('client') && data.createdBy !== null && data.client !== null && user.client !== null && data.createdBy.id === user.id && data.client.id === user.client.id) {
    //   return true;
    // }

    return false;
  }

  getClientField(options: any = {}): any {
    let field: any = { key: options.multiple ? 'clients' : 'client', label: options.multiple ? $localize`Clients` : $localize`Client` };
    const authUser = this.getUser();

    if (this.isAdministrativeRole() || authUser.permissions.findIndex(permission => permission === 'client_view') > -1) {
      field = {
        ...field,
        type: ContentType.LIST,
        data: [],
        multiple: options && options.multiple === true,
        fetchData: () => this.fetchClientsAsItems(),
        render: (data: IClient) => data.name
      };
    } else {
      field = {
        ...field,
        type: ContentType.HIDDEN,
        defaultValue: this.user.client
      };
    }

    return field;
  }

  isAdministrativeRole(): boolean {
    const administrativeRoles = ['admin', 'developer'];

    return this.user !== null
      && this.user.role !== null
      && administrativeRoles.includes(this.user.role.toLowerCase());
  }

  private fetchClientsAsItems(): Promise<IItem[]> {
    const apiService: ApiService = this.injector.get(ApiService);

    return new Promise<IItem[]>(resolve => {
      apiService.getClients().then(clients => resolve(clients.map(client => ({
        value: client.code,
        label: client.name
      }))));
    });
  }
}
