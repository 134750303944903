import {Component, ElementRef, forwardRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
  selector: 'input-upload',
  templateUrl: './input-upload.component.html',
  styleUrls: ['./input-upload.component.styl'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputUploadComponent)
    }
  ]
})
export class InputUploadComponent implements OnInit, ControlValueAccessor {

  @Input() public label = '';
  @Input() autoUpload = false;
  @Input() multiple = false;
  @Input() accept: string[];
  @ViewChild('fileInput') fileInput: ElementRef;
  public uploading = false;
  private _value: any;
  private _temporaryValue: any;
  private changed: any = () => {};
  private touched: any = () => {};

  constructor(private bottomSheet: MatBottomSheet) { }

  ngOnInit(): void {
    if (this.multiple) {
      this.value = [];
      this.temporaryValue = [];
    } else {
      this.value = null;
      this.temporaryValue = null;
    }
  }

  get value(): any {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
    this.changed(this.value);
    this.touched(this.value);
  }


  get temporaryValue(): any {
    return this._temporaryValue;
  }

  set temporaryValue(value: any) {
    this._temporaryValue = value;
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  writeValue(obj: any): void {
    if (!obj) {
      return;
    }

    this.value = obj;
  }

  isArray(item: any): boolean {
    return Array.isArray(item);
  }

  openFileQueue(): void {
    this.bottomSheet.open(InputUploadBottomSheet, {
      data: {
        items: [...this.value]
      }
    });
  }

  onInputChange(event: any): void {
    if (this.autoUpload) {
      this.temporaryValue = this.multiple ? event.target.files : event.target.files.length > 0 ? event.target.files[0] : null;
    } else {
      this.value = this.multiple ? event.target.files : event.target.files.length > 0 ? event.target.files[0] : null;
    }
  }

  removeFile(): void {
    this.fileInput.nativeElement.value = '';
    this.value = null;
  }

}

@Component({
  selector: 'input-upload-bottom-sheet',
  templateUrl: 'input-upload-bottom-sheet.html',
  styleUrls: ['input-upload-bottom-sheet.styl']
})
export class InputUploadBottomSheet {
  items: any = [];

  constructor(private bottomSheetRef: MatBottomSheetRef<InputUploadBottomSheet>, @Inject(MAT_BOTTOM_SHEET_DATA) private data: any) {
    this.items = data.items || [];
  }

  openLink(event: any): void {
    this.bottomSheetRef.dismiss();
  }
}
