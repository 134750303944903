const dataTypesList = [
  {
    value: 'STRING',
    label: $localize`Text`
  },
  {
    value: 'NUMBER',
    label: $localize`Number`
  },
  {
    value: 'DATE',
    label: $localize`Date`
  },
  {
    value: 'BOOL',
    label: $localize`True/False`
  },
  {
    value: 'FILE',
    label: $localize`File`
  },
  {
    value: 'LIST',
    label: $localize`List`
  }
];

const allCandidateFieldsList = [{value: 'email', label: $localize`Email`}, {
  value: 'password',
  label: $localize`Password`
}, {value: 'firstName', label: $localize`First Name`}, {value: 'lastName', label: $localize`Last Name`}, {
  value: 'phone',
  label: $localize`Phone`
}, {value: 'fiscalCode', label: $localize`Fiscal Code`}, {value: 'profession', label: $localize`Profession`}, {
  value: 'birthDate',
  label: $localize`Birth Date`
}, {value: 'birthPlace', label: $localize`Birth Place`}, {value: 'gender', label: $localize`Gender`}, {
  value: 'resume',
  label: $localize`Resume`
}, {value: 'coverLetter', label: $localize`Cover Letter`}, {value: 'domicile', label: $localize`Domicile`}, {
  value: 'residence',
  label: $localize`Residence`
}, {value: 'privacyAgreements', label: $localize`Privacy Agreements`}, {value: 'education', label: $localize`Education`}, {
  value: 'courses',
  label: $localize`Courses`
}, {value: 'workExperience', label: $localize`Work Experience`}, {value: 'nationality', label: $localize`Nationality`}, {value: 'information', label: $localize`Information`}];

export { dataTypesList, allCandidateFieldsList };
