import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import ISmartField from '../../interfaces/ISmartField';
import {ContentType} from '../../definitions/ContentType';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import IItem from '../../interfaces/IItem';
import {of} from 'rxjs';
import IAction from '../../interfaces/IAction';

interface Tab {
  key: string;
  name: string;
}

@Component({
  selector: 'app-smart-form',
  templateUrl: './smart-form.component.html',
  styleUrls: ['./smart-form.component.styl']
})
export class SmartFormComponent implements OnInit, OnChanges {

  @Input() fields: ISmartField[] = [];
  @Input() defaults: any = {};
  @Input() params: any = {};
  @Input() colBreak = 'sm';
  @Input() colSize = '4';
  @Input() useTabs = false;
  @Input() tabs: Tab[] = [];
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;
  contentType = ContentType;

  constructor() { }

  ngOnInit(): void {
    this.params = {
      ...this.params,
      defaults: this.defaults
    };

    this.parseFields();
    this.buildFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  getErrorMessage(control: AbstractControl): string {
    if (control.errors.required === true) {
      return $localize`Field required`;
    }

    if (control.errors.email === true) {
      return $localize`Invalid email format`;
    }

    if (control.errors.pattern) {
      let passwordValue = control.errors.pattern.actualValue;
      console.log("*********passwordValue**********: ",passwordValue);
      console.log("*********passwordValue****!passwordValue.match('(?=.*[a-z])')******: ",!passwordValue.match('(?=.*[a-z])'));
      console.log("*********passwordValue****!passwordValue.match('(.*[0-9].*)')******: ",!passwordValue.match('(.*[0-9].*)'));
      console.log("*********passwordValue****!passwordValue.match('(?=.*[@$!%*£?&#-:;,._-])')******: ",!passwordValue.match('(?=.*[@$!%*£?&#-:;,._-])'));
      let error = "";
      if (!passwordValue.match('.{8,}'))
        error += "At least 8 characters long.";
      if (!passwordValue.match('^(?=.*[A-Z])'))
        error += "At least uppercase letter.";
      if (!passwordValue.match('(?=.*[a-z])')) {
        error += "At least lowercase letter.";
      }
      if (!passwordValue.match('(.*[0-9].*)'))
        error += "At least one digit.";
      if (!passwordValue.match('(?=.*[@$!%*£?&#-])')) {  
        error += "At least one special character (@$!%*£?&#-).";
      }

      return $localize`${error}`;
    }

    return '';
  }

  reset(): void {
    const value = {};
    this.fields.forEach(field => value[field.key] = field.defaultValue);
    this.form.reset(value);
  }

  private parseFields(): void {
    const tabs = [];

    this.fields = this.fields.map((field: ISmartField, index: number) => {
      const fieldExtended = {
        type: ContentType.SHORT_TEXT,
        required: false,
        readonly: false,
        disabled: false,
        defaultValue: null,
        label: '',
        placeholder: '',
        autocomplete: false,
        icon: null,
        data: [],
        filteredData: of([]),
        tab: null,
        displayAs: null,
        options: {},
        ...field
      };

      if (field.type === ContentType.BOOLEAN && fieldExtended.defaultValue === null) {
        fieldExtended.defaultValue = false;
      }

      if (this.defaults !== null && this.defaults.hasOwnProperty(fieldExtended.key)) {
        fieldExtended.defaultValue = this.defaults[fieldExtended.key];
      }

      if (field.fetchData) {
        field.fetchData(this.params).then((items: IItem[]) => {
          this.fields[index].data = items;
          this.fields[index].filteredData = of(items || []);
        });
      } else {
        field.filteredData = of([
          {
            value: 1,
            label: 'Misinto'
          },
          {
            value: 2,
            label: 'Milano'
          }
        ]);
      }

      return fieldExtended;
    });
  }

  private buildFormGroup(): void {
    const formControls: {[key: string]: AbstractControl} = {};

    this.fields.forEach((field: ISmartField) => {
      const validators = [];

      if (!field.key) {
        return;
      }

      if (field.required) {
        validators.push(Validators.required);
      }

      if (field.type === ContentType.EMAIL) {
        validators.push(Validators.email);
      }

      if (field.type === ContentType.PASSWORD) {
        const strongPasswordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*£?&#-])[A-Za-z\d@$!%*£?&#-]{8,}$/;
        validators.push(Validators.pattern(strongPasswordRegx));
      }

      const formControl = new FormControl({
        value: field.defaultValue,
        disabled: field.disabled
      }, validators);

      formControl.valueChanges.subscribe(value => {
        if (typeof field.options.onChange === 'function') {
          field.options.onChange(value, field, this.form, this.fields);
        }

        setTimeout(() => {
          this.valueChange.emit({
            form: this.form,
            fields: this.fields,
            field,
            value
          });
        });
      });

      formControls[field.key] = formControl;
    });

    this.form = new FormGroup(formControls);
  }

  compareWith(o1: any, o2: any): boolean {
    return o1 === o2;
  }

  getInputType(field: ISmartField): string {
    switch (field.type) {
      case ContentType.PASSWORD:
        return 'password';

      case ContentType.EMAIL:
        return 'email';

      case ContentType.INTEGER:
      case ContentType.NUMBER:
      case ContentType.DECIMAL:
        return 'number';

      default:
        return 'text';
    }
  }

  setValue(data: any): void {
    const value = {};

    this.fields.forEach(field => value[field.key] = null);

    Object.keys(data).forEach(key => {
      if (!value.hasOwnProperty(key)) {
        return;
      }

      value[key] = data[key];
    });

    this.form.setValue(value);
  }

  filterFields(item: any, params: any): boolean {
    if (params.tab === null) {
      return item.type !== ContentType.HIDDEN;
    }

    return item.tab === params.tab && item.type !== ContentType.HIDDEN;
  }

  getFields(tab: string): ISmartField[] {
    if (tab === null) {
      return this.fields.filter(field => field.type !== ContentType.HIDDEN);
    }

    return this.fields.filter(field => field.tab === tab && field.type !== ContentType.HIDDEN);
  }

  reloadDataByKey(key: string, item: any): void {
    const field = this.fields.find(value => value.key === key);

    if (field && item && field.fetchData) {
      field.fetchData(this.params).then(items => {
        field.data = items;
        field.data.push(item);
        field.filteredData = of(field.data || []);
      }).catch();
    } else if (field && item && !field.fetchData) {
      field.data.push(item);
      field.filteredData = of(field.data || []);
    } else if (field && field.fetchData) {
      field.fetchData(this.params).then(items => {
        field.data = items;
        field.filteredData = of(field.data || []);
      }).catch();
    }
  }

  getRawValue(): any {
    const raw = {...this.form.getRawValue()};

    this.fields.forEach(field => {
      if (field.readonly === true) {
        delete raw[field.key];
      }
    });

    return raw;
  }

  toggleAllSelection(field: ISmartField): any {
    const formValue = this.form.getRawValue();

    if (formValue.hasOwnProperty(field.key) && formValue[field.key].length === field.data.length) {
      formValue[field.key] = [];
      this.form.setValue(formValue);
    } else {
      formValue[field.key] = field.data.map(item => item.value);
      this.form.setValue(formValue);
    }
  }

}
