import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayMapBy'
})
export class ArrayMapByPipe implements PipeTransform {

  transform(arr: any[], mapBy: (item: any, params?: any) => any, params: any = {}): any[] {
    return arr.map(item => mapBy(item, params));
  }

}
