import {createReducer, on} from '@ngrx/store';
import {
  setCandidate,
  setCandidateToken, setRoles,
  setToken,
  setUser, setUserClient, setUserRole,
  unsetCandidate,
  unsetCandidateToken,
  unsetToken,
  unsetUser, unsetUserRole
} from '../actions/auth.actions';
import IAuthState from '../interfaces/IAuthState';
import IRole from '../interfaces/IRole';


export const initialState: IAuthState = {
  token: null,
  user: null,
  userRole: null,
  userClient: null,
  candidate: null,
  candidateToken: null,
  roles: [],
};

const authReducerSource = createReducer(
  initialState,
  on(setUser, (state: IAuthState, user) => ({ ...state, user })),
  on(unsetUser, (state: IAuthState) => ({ ...state, user: null })),
  on(setToken, (state: IAuthState, { token }) => ({ ...state, token })),
  on(unsetToken, (state: IAuthState) => ({ ...state, token: null })),
  on(setCandidate, (state, candidate) => ({ ...state, candidate })),
  on(unsetCandidate, state => ({ ...state, candidate: null })),
  on(setCandidateToken, (state: IAuthState, { candidateToken }) => ({ ...state, candidateToken })),
  on(unsetCandidateToken, (state: IAuthState) => ({ ...state, candidateToken: null })),
  on(setRoles, (state: IAuthState, { roles }) => ({ ...state, roles })),
  on(setUserRole, (state: IAuthState, userRole) => ({ ...state, userRole })),
  on(unsetUserRole, (state: IAuthState) => ({ ...state, userRole: null })),
  on(setUserClient, (state: IAuthState, userClient) => ({ ...state, userClient })),
);

export function authReducer(state: any, action: any): any {
  return authReducerSource(state, action);
}
