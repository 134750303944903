import {Component, forwardRef, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'input-chips',
  templateUrl: './input-chips.component.html',
  styleUrls: ['./input-chips.component.styl'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputChipsComponent)
    }
  ]
})
export class InputChipsComponent implements OnInit, ControlValueAccessor {

  private _value: any;
  private changed: any = () => {};
  private touched: any = () => {};

  constructor() { }

  ngOnInit(): void {
  }

  get value(): any {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
    this.changed(this.value);
    this.touched(this.value);
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

}
