import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findBy'
})
export class FindByPipe implements PipeTransform {

  transform(arr: any[], field: string, value: any): any {
    return arr.find(item => item[field] === value);
  }

}
