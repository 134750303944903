<app-standard-layout>
  <div class="spacing-inner-10">

    <!-- LISTING -->
    <mat-card *ngIf="action === routeAction.LISTING">
      <mat-card-header>
        <mat-card-title i18n>Candidates</mat-card-title>
        <mat-card-subtitle i18n>On current page you are able to view, edit, delete and create any subject account further used to complete or view tests and surveys results</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-5">
          <button mat-stroked-button color="primary" (click)="goToAdd()" *ngIf="authService.hasPermission(['candidate_create', 'candidate_create_org'])">
            <mat-icon class="spacing-right-5">add</mat-icon>
            <span i18n>ADD NEW CANDIDATE</span>
          </button>
          <button mat-stroked-button color="warn" [ngClass]="{'spacing-left-5': authService.hasPermission(['candidate_create'])}" *ngIf="authService.hasPermission(['candidate_delete'])" [disabled]="selectedNodes.length === 0" (click)="deleteSelected()">
            <mat-icon class="spacing-right-5">delete</mat-icon>
            <span i18n>DELETE SELECTED</span>
          </button>
          <button mat-stroked-button [ngClass]="{'spacing-left-5': authService.hasPermission(['candidate_create'])}" *ngIf="authService.hasPermission(['candidate_create'])" (click)="importData()">
            <mat-icon class="spacing-right-5">publish</mat-icon>
            <span i18n>IMPORT</span>
          </button>
        </div>
        <app-smart-table [rowData]="subjects" [columnDefs]="subjectsTableColumnDefs" (rowDelete)="onSubjectDelete($event)" (rowEdit)="onSubjectEdit($event)" (rowView)="onSubjectView($event)" rowSelection="multiple" (selectionChanged)="onSelectionChanged($event)" permissionGroup="candidate"></app-smart-table>
      </mat-card-content>
    </mat-card>

    <!-- ADD -->
    <mat-card *ngIf="action === routeAction.ADD">
      <mat-card-header>
        <mat-card-title i18n>Add Candidate</mat-card-title>
        <mat-card-subtitle i18n>Candidates</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
          <button mat-stroked-button class="spacing-left-5" (click)="reset()">
            <mat-icon class="spacing-right-5">refresh</mat-icon>
            <span i18n>RESET</span>
          </button>
          <button mat-stroked-button color="primary" class="spacing-left-5" (click)="save()">
            <mat-icon class="spacing-right-5">save</mat-icon>
            <span i18n>SAVE</span>
          </button>
        </div>
        <app-smart-form #addForm [fields]="subjectFormFieldsCreate"></app-smart-form>
      </mat-card-content>
    </mat-card>

    <!-- VIEW -->
    <mat-card *ngIf="action === routeAction.VIEW">
      <mat-card-header>
        <mat-card-title i18n>View Candidate</mat-card-title>
        <mat-card-subtitle i18n>Candidates</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
          <button mat-stroked-button class="spacing-left-5" (click)="openInviteDialog()">
            <mat-icon class="spacing-right-5">link</mat-icon>
            <span i18n>INVITE</span>
          </button>
        </div>
        <app-smart-detail [fields]="subjectDetailFields" [source]="subjectEntity"></app-smart-detail>
        <h3 class="separator-title" i18n>Work Experience</h3>
        <div class="mb-3 d-flex flex-column" *ngFor="let item of subjectEntity.workExperience">
          <strong>{{item.startDate | date : 'dd.MM.yyyy'}} - {{item.endDate ? (item.endDate | date : 'dd.MM.yyyy') : 'presente' }}</strong>
          <span>{{item.company ? item.company : ''}}</span>
          <p>{{item.description}}</p>
        </div>
        <h3 class="separator-title" i18n>Education</h3>
        <div class="mb-3 d-flex flex-column" *ngFor="let item of subjectEntity.education">
          <strong>{{item.startDate | date : 'dd.MM.yyyy'}} - {{item.endDate ? (item.endDate | date : 'dd.MM.yyyy') : 'presente' }}</strong>
          <span>{{item.institute ? item.institute.name + ' - ' : ''}}{{item.category ? item.category.name : ''}}</span>
          <p>{{item.description}}</p>
        </div>
        <h3 class="separator-title" i18n>Additional Information</h3>
        <div *ngFor="let item of subjectEntity.information | keyvalue">
          <strong>{{item.value.label}}</strong>
          <span class="ml-5" *ngIf="item.value.type !== 15">{{item.value.value}}</span>
          <span class="ml-5" *ngIf="item.value.type === 15"><a [href]="item.value.value.url" target="_blank">{{item.value.value.originalName}}</a></span>
        </div>
        <h3 class="separator-title" i18n>Privacy Acceptance</h3>
        <div class="row">
          <div class="col-md-6">
            <mat-checkbox [disabled]="true" [checked]="subjectEntity.privacyAgreements.personalDataAgreement" color="primary">
              Personal identification data
            </mat-checkbox>
          </div>
          <div class="col-md-6">
            <mat-checkbox [disabled]="true" [checked]="subjectEntity.privacyAgreements.personalSpecificDataAgreement" color="primary">
              Personal specific data
            </mat-checkbox>
          </div>
          <div class="col-md-6">
            <mat-checkbox [disabled]="true" [checked]="subjectEntity.privacyAgreements.marketingAgreement" color="primary">
              Data for promotions, commercial or marketing campaigns
            </mat-checkbox>
          </div>
          <div class="col-md-6">
            <mat-checkbox [disabled]="true" [checked]="subjectEntity.privacyAgreements.dataUsageForCandidateResearchAgreement" color="primary">
              Profiling with the sole purpose of research and evaluation
            </mat-checkbox>
          </div>
          <div class="col-md-6">
            <mat-checkbox [disabled]="true" [checked]="subjectEntity.privacyAgreements.dataUsageForStatisticsAndReportsAgreement" color="primary">
              Data for analysis and statistics
            </mat-checkbox>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="action === routeAction.VIEW" class="spacing-top-6">
      <mat-card-header>
        <mat-card-title i18n>Researches</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <app-smart-table #researchesTable [rowData]="researches" [columnDefs]="researchColumnDefs" [hideEdit]="true" (rowView)="onResearchView.bind(this)($event)" [hideDelete]="true" permissionGroup="research"></app-smart-table>
      </mat-card-content>
    </mat-card>

    <!-- EDIT -->
    <mat-card *ngIf="action === routeAction.EDIT">
      <mat-card-header>
        <mat-card-title i18n>Edit Candidate</mat-card-title>
        <mat-card-subtitle i18n>Candidates</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
          <button mat-stroked-button class="spacing-left-5" (click)="reset()">
            <mat-icon class="spacing-right-5">refresh</mat-icon>
            <span i18n>RESET</span>
          </button>
          <button mat-stroked-button color="primary" class="spacing-left-5" (click)="save()">
            <mat-icon class="spacing-right-5">save</mat-icon>
            <span i18n>SAVE</span>
          </button>
        </div>
        <app-smart-form #editForm [fields]="subjectFormFieldsEdit" [defaults]="subjectEntity"></app-smart-form>
      </mat-card-content>
    </mat-card>
  </div>
</app-standard-layout>
