import {Component, Input, OnInit} from '@angular/core';
import IResearch from '../../interfaces/IResearch';
import {pickTextColorBasedOnBgColor} from '../../utils/color-util';
import IClient from "../../interfaces/IClient";

@Component({
  selector: 'app-process-layout',
  templateUrl: './process-layout.component.html',
  styleUrls: ['./process-layout.component.styl']
})
export class ProcessLayoutComponent implements OnInit {

  @Input() research: IResearch;
  @Input() client: IClient;

  constructor() { }

  ngOnInit(): void {
  }

  getFooterTextColor(bgcolor: string): string {
    return pickTextColorBasedOnBgColor(bgcolor, '#ffffff', '#000000');
  }

}
