import {createAction, props} from '@ngrx/store';
import IResearch from '../interfaces/IResearch';
import ISubject from '../interfaces/ISubject';
import IAnonymousCandidate from '../interfaces/IAnonymousCandidate';
import ISurvey from '../interfaces/ISurvey';

export const setResearch = createAction('[Process State] Set Research', props<IResearch>());
export const unsetResearch = createAction('[Process State] Unset Research');
export const setCandidate = createAction('[Process State] Set Candidate', props<ISubject|IAnonymousCandidate>());
export const unsetCandidate = createAction('[Process State] Unset Candidate');
export const setTrace = createAction('[Process State] Set Trace', props<{trace: string}>());
export const unsetTrace = createAction('[Process State] Unset Trace');
export const setSurvey = createAction('[Process State] Set Survey', props<any>());
export const setClient = createAction('[Process State] Set Client', props<any>());
