<div class="progress-bar-wrapper" *ngIf="survey.showProgress">
  <mat-progress-bar mode="determinate" [value]="getSurveyProgress()" style="height: 30px"></mat-progress-bar>
  <span class="progress-text">{{getSurveyProgress()}}%</span>
</div>

<div class="research-timer">
  <span *ngIf="surveyTimeLimit && surveyTimeLimit > 0">Tempo rimasto per il test/survey: {{(surveyTimeLimit * 60) - surveyIntervalRuns | secondsToMinutesAndSeconds}}</span>
  <span class="ml-auto" *ngIf="questionTimeLimit && questionTimeLimit > 0">Tempo rimasto per la domanda: {{(questionTimeLimit * 60) - questionIntervalRuns | secondsToMinutesAndSeconds}}</span>
</div>

<div class="mt-5" *ngIf="step === 1 && research.showStepNavigation === true">
  <button mat-button (click)="openGivenAnswersNavigator()">
    <mat-icon>article</mat-icon>
    <span i18n class="ml-3">Given Answers</span>
  </button>
</div>

<ng-container *ngIf="step === 0">
  <h1 class="spacing-top-20 text-upper text-bold" i18n>{{research.title}}</h1>
  <p>{{survey.description}}</p>
  <div *ngIf="!research.anonymous" class="spacing-top-10">
    <h3 i18n class="text-upper text-bold">Candidate Information</h3>
    <div class="smart-grid">
      <div class="item"><strong i18n>First Name</strong></div>
      <div class="item"><span i18n>{{candidate.firstName}}</span></div>
      <div class="item"><strong i18n>Last Name</strong></div>
      <div class="item"><span i18n>{{candidate.lastName}}</span></div>

      <div class="item"><strong i18n>Email</strong></div>
      <div class="item"><span i18n>{{candidate.email}}</span></div>
      <div class="item"><strong i18n>Phone</strong></div>
      <div class="item"><span i18n>{{candidate.phone}}</span></div>

      <ng-container *ngIf="survey.timeLimit">
        <div class="item"><strong i18n>Tempo limite</strong></div>
        <div class="item"><span i18n>{{survey.timeLimit}} minuti</span></div>
      </ng-container>

      <ng-container *ngIf="survey.answerTimeLimit">
        <div class="item"><strong i18n>Tempo limite a domanda</strong></div>
        <div class="item"><span i18n>{{survey.answerTimeLimit}} minuti</span></div>
      </ng-container>
    </div>
  </div>
  <div class="button-bar spacing-bottom-10 justify-content-center">
    <button mat-stroked-button class="text-upper" color="primary" (click)="startSurvey()" [disabled]="survey.elements.length === 0" i18n>Start the {{getSurveyType()}}</button>
  </div>
</ng-container>

<ng-container *ngIf="step === 2">
  <h1 class="spacing-top-20 text-upper text-bold" i18n>{{research.title}} completed</h1>
  <p *ngIf="survey.endMessage">{{survey.endMessage}}</p>
  <p *ngIf="failCode === surveyFailCode.SURVEY_TIME_LIMIT" i18n>Ci dispiace però il tempo massimo per completare il test è scaduto.</p>
  <div *ngIf="!research.anonymous" class="spacing-top-10">
    <h3 i18n class="text-upper text-bold">Candidate Information</h3>
    <div class="smart-grid">
      <div class="item"><strong i18n>First Name</strong></div>
      <div class="item"><span i18n>{{candidate.firstName}}</span></div>
      <div class="item"><strong i18n>Last Name</strong></div>
      <div class="item"><span i18n>{{candidate.lastName}}</span></div>

      <div class="item"><strong i18n>Email</strong></div>
      <div class="item"><span i18n>{{candidate.email}}</span></div>
      <div class="item"><strong i18n>Phone</strong></div>
      <div class="item"><span i18n>{{candidate.phone}}</span></div>

      <div class="item"><strong i18n>Duration</strong></div>
      <div class="item"><span i18n>{{duration | number: '1.2-2'}} min</span></div>
      <div class="item"><strong i18n>Focus Loss</strong></div>
      <div class="item"><span i18n>{{switchedFocus}}</span></div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="startMessage !== null">
  <p class="text-center spacing-top-20">{{startMessage}}</p>
  <div class="button-bar spacing-bottom-10 justify-content-center">
    <button mat-stroked-button class="text-upper" (click)="goPrev()" [disabled]="!canGoPrev()" *ngIf="survey.allowAnswerChange === true" i18n>Previous</button>
    <button mat-stroked-button class="text-upper" color="primary" (click)="goNext()" [disabled]="!canGoNext()" [ngClass]="{'spacing-left-5': survey.allowAnswerChange === true}" i18n>Next</button>
  </div>
</ng-container>

<ng-container *ngIf="endMessage !== null">
  <p class="text-center spacing-top-20">{{endMessage}}</p>
  <div class="button-bar spacing-bottom-10 justify-content-center">
    <button mat-stroked-button class="text-upper" (click)="goPrev()" [disabled]="!canGoPrev()" *ngIf="survey.allowAnswerChange === true" i18n>Previous</button>
    <button mat-stroked-button class="text-upper" color="primary" (click)="goNext()" [disabled]="!canGoNext()" [ngClass]="{'spacing-left-5': survey.allowAnswerChange === true}" i18n>Next</button>
  </div>
</ng-container>

<ng-container *ngIf="selectedElement !== null && startMessage === null && endMessage === null && step === 1">
  <div class="elements-container">
    <app-spinner *ngIf="loadingNextStep" backgroundColor="rgba(255, 255, 255, 0.25)"></app-spinner>
    <ng-container [ngSwitch]="selectedElement.type">
      <app-process-information-element [element]="selectedElement" *ngSwitchCase="elementType.TEXT"></app-process-information-element>
      <app-process-information-element [element]="selectedElement" *ngSwitchCase="elementType.RICH_MEDIA"></app-process-information-element>
      <ng-container *ngSwitchCase="elementType.GROUP">
        <ng-container *ngFor="let element of selectedElement.child.children" [ngSwitch]="element.type">
          <app-process-information-element [element]="element" *ngSwitchCase="elementType.TEXT"></app-process-information-element>
          <app-process-information-element [element]="element" *ngSwitchCase="elementType.RICH_MEDIA"></app-process-information-element>
          <app-process-question-element *ngSwitchDefault #questionElement [element]="element" (valueChanged)="handleValueChange($event)" [rawValue]="rawValue"></app-process-question-element>
        </ng-container>
      </ng-container>
      <app-process-question-element #questionElement [element]="selectedElement" [rawValue]="rawValue" (valueChanged)="handleValueChange($event)" *ngSwitchDefault></app-process-question-element>
    </ng-container>
    <div class="button-bar spacing-bottom-10 spacing-top-10 justify-content-center">
      <button mat-stroked-button class="text-upper" (click)="goPrev()" [disabled]="!canGoPrev()" *ngIf="survey.allowAnswerChange === true" i18n>Previous</button>
      <button mat-stroked-button class="text-upper" color="primary" (click)="goNext()" [disabled]="!canGoNext()" [ngClass]="{'spacing-left-5': survey.allowAnswerChange === true}" i18n>{{getNextButtonLabel()}}</button>
    </div>
  </div>
</ng-container>
