import { Component, OnInit } from '@angular/core';
import IUser from '../../interfaces/IUser';
import {AuthService} from '../../services/auth.service';
import {ApiService} from '../../services/api.service';
import {EChartsOption} from 'echarts';

@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.styl']
})
export class HomeScreenComponent implements OnInit {

  user: IUser;
  dashboardData: any = {
    recentProjects: [],
    recentResearches: []
  };
  dailyTestActivityChartOptions: EChartsOption = {
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: [0, 0, 0, 0, 0, 0, 0],
        type: 'line',
      },
    ],
  };
  dailyTestActivityChartLoaded = false;
  mostActiveClientsLoaded = false;
  mostActiveClientsChartOptions: EChartsOption = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '40',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
        ]
      }
    ]
  };

  mostPopularTestsLoaded = false;
  mostPopularTestsChartOptions: EChartsOption = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '40',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
        ]
      }
    ]
  };

  constructor(private authService: AuthService, private apiService: ApiService) { }

  ngOnInit(): void {
    this.user = this.authService.getUser();
    this.apiService.getDailyActivity().then(result => {
      const data = [0, 0, 0, 0, 0, 0, 0];
      result.forEach(item => {
        data[Number(item._id) - 1] = Number(item.count);
      });
      this.dailyTestActivityChartOptions.series[0].data = data;
      this.dailyTestActivityChartLoaded = true;
    });
    this.apiService.getRecentProjects().then(result => {
      this.dashboardData.recentProjects = result;
    });
    this.apiService.getRecentResearches().then(result => {
      this.dashboardData.recentResearches = result;
    });
    this.apiService.getMostActiveClients().then(result => {
      this.mostActiveClientsChartOptions.series[0].data = result.map(o => ({
        name: o.item === 'null' ? $localize`N/A` : o.item,
        value: o.total
      }));

      this.mostActiveClientsLoaded = true;
    });
    this.apiService.getMostPopularTests().then(result => {
      this.mostPopularTestsChartOptions.series[0].data = result.map(o => ({
        name: o.item === 'null' ? $localize`N/A` : o.item,
        value: o.total
      }));

      this.mostPopularTestsLoaded = true;
    });
  }

}
