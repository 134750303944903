import { Injectable } from '@angular/core';
import ISessionObject from '../interfaces/ISessionObject';
import * as _ from 'lodash';
import IPrivacyAgreements from "../interfaces/IPrivacyAgreements";

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private SESSION_NAME = 'SoniaStorage';

  constructor() { }

  getAuthToken(): string {
    return this.getStorage().token;
  }

  setAuthToken(token: string): void {
    const storageObj: ISessionObject = this.getStorage();
    storageObj.token = token;
    this.saveStorage(storageObj);
  }

  getCandidateToken(): string {
    return this.getStorage().candidateToken;
  }

  setCandidateToken(token: string): void {
    const storageObj: ISessionObject = this.getStorage();
    storageObj.candidateToken = token;
    this.saveStorage(storageObj);
  }

  getTrace(): string {
    return this.getStorage().trace;
  }

  setTrace(value: string): void {
    this.push('trace', value);
  }

  setPrivacyAgreements(privacyAgreements: IPrivacyAgreements): void {
    this.push('candidatePrivacyAgreements', privacyAgreements);
  }

  getPrivacyAgreements(): IPrivacyAgreements {
    return this.getStorage().candidatePrivacyAgreements || {
      personalDataAgreement: false,
      personalSpecificDataAgreement: false,
      dataUsageForStatisticsAndReportsAgreement: false,
      marketingAgreement: false,
      dataUsageForCandidateResearchAgreement: false
    };
  }

  private push(path, value): void {
    const storageObj: ISessionObject = this.getStorage();
    _.set(storageObj, path, value);
    this.saveStorage(storageObj);
  }

  private saveStorage(storageObj: ISessionObject): void {
    const sessionString = JSON.stringify(storageObj);
    sessionStorage.setItem(this.SESSION_NAME, sessionString);
  }

  private getStorage(): ISessionObject {
    const sessionString: string = sessionStorage.getItem(this.SESSION_NAME);
    const defaultObj: ISessionObject = {
      token: null,
      trace: null,
      candidateToken: null,
      candidatePrivacyAgreements: {
        personalDataAgreement: false,
        personalSpecificDataAgreement: false,
        dataUsageForStatisticsAndReportsAgreement: false,
        marketingAgreement: false,
        dataUsageForCandidateResearchAgreement: false
      }
    };

    if (sessionString === null) {
      return defaultObj;
    }

    try {
      return JSON.parse(sessionString);
    } catch (e: any) {
      console.error(`Failed to parse session string: ${sessionString}`);

      return defaultObj;
    }
  }
}
