import { Component, OnInit } from '@angular/core';
import {AgFilterComponent} from 'ag-grid-angular';
import {IDoesFilterPassParams, IFilterParams} from 'ag-grid-community';

@Component({
  selector: 'app-object-list-filter',
  templateUrl: './object-list-filter.component.html',
  styleUrls: ['./object-list-filter.component.styl']
})
export class ObjectListFilterComponent implements AgFilterComponent {

  params: any;
  items: any[] = [];
  filteredItems: any[] = [];
  selectedItems: number[] = [];
  selectedBlank = true;
  searchInput = '';


  agInit(params: any): void {
    this.params = params;

    if (this.params.data) {
      this.items = [...this.params.data];
      this.filteredItems = [...this.params.data];
      this.selectedItems = this.params.data.map(item => item.id);
    } else if (this.params.fetchData) {
      this.params.fetchData().then(items => {
        this.items = [...items];
        this.filteredItems = [...items];
        this.selectedItems = items.map(item => item.id);
      }).catch(console.error);
    }
  }

  isFilterActive(): boolean {
    return true;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const values = params.data[this.params.colDef.field];
    const filteredValues = params.data[this.params.colDef.field].filter(item => this.selectedItems.includes(item.id));
    return filteredValues.length > 0 || (this.selectedBlank && values.length === 0);
  }

  getModel(): any {
    if (this.selectedItems.length === 0) {
      return null;
    }

    return this.selectedItems;
  }

  setModel(model: any): void {
    this.selectedItems = model;
  }

  updateFilter(): void {
    this.params.filterChangedCallback();
  }

  onCheckChange(event: any, item: any): void {
    const index = this.selectedItems.indexOf(item.id);

    if (index === -1) {
      this.selectedItems.push(item.id);
    } else if (index > -1) {
      this.selectedItems.splice(index, 1);
    }

    this.updateFilter();
  }

  onSelectAll(): void {
    if (this.selectedItems.length === this.items.length) {
      this.selectedItems = [];
      this.selectedBlank = false;
    } else {
      this.selectedItems = this.items.map(item => item.id);
      this.selectedBlank = true;
    }

    this.updateFilter();
  }

  onSelectBlank(): void {
    this.selectedBlank = !this.selectedBlank;
    this.updateFilter();
  }

  onSearch(value: any): void {
    if (value.length === 0) {
      this.filteredItems = [...this.items];
      return;
    }

    this.filteredItems = this.items.filter(item => {
      if (!this.params.filterItems) {
        return false;
      }

      return this.params.filterItems(item, value);
    });
  }

}
