import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-standard-layout',
  templateUrl: './standard-layout.component.html',
  styleUrls: ['./standard-layout.component.styl']
})
export class StandardLayoutComponent implements OnInit {

  navigationExpanded = false;

  constructor() { }

  ngOnInit(): void {
    this.checkWindowSize();
  }

  onNavigationExpansionChange(event: boolean): void {
    this.navigationExpanded = event;
  }

  private checkWindowSize(): void {
    this.navigationExpanded = window.innerWidth >= 1600;
  }

}
