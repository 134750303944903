<app-standard-layout>
  <div class="spacing-inner-10">

    <!-- LISTING -->
    <mat-card *ngIf="action === routeAction.LISTING">
      <mat-card-header>
        <mat-card-title i18n>User Management</mat-card-title>
        <mat-card-subtitle i18n>On current page you are able to view, edit, delete and create any user account further used to access the platform</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-5">
          <button mat-stroked-button color="primary" (click)="goToAdd()" *ngIf="authService.hasPermission(['user_create', 'user_create_org'])">
            <mat-icon class="spacing-right-5">add</mat-icon>
            <span i18n>ADD NEW USER</span>
          </button>
          <button mat-stroked-button color="warn" [ngClass]="{'spacing-left-5': authService.hasPermission(['user_create'])}" [disabled]="selectedUserRows === 0" *ngIf="authService.hasPermission(['user_delete'])">
            <mat-icon class="spacing-right-5">delete</mat-icon>
            <span i18n>DELETE SELECTED</span>
          </button>
        </div>
        <app-smart-table [rowData]="users" [columnDefs]="usersTableColumnDefs" (rowDelete)="onUserDelete($event)" (rowEdit)="onUserEdit($event)" (rowView)="onUserView($event)" permissionGroup="user"></app-smart-table>
      </mat-card-content>
    </mat-card>

    <!-- ADD -->
    <mat-card *ngIf="action === routeAction.ADD">
      <mat-card-header>
        <mat-card-title i18n>Add User</mat-card-title>
        <mat-card-subtitle i18n>User Management</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
          <button mat-stroked-button class="spacing-left-5" (click)="reset()">
            <mat-icon class="spacing-right-5">refresh</mat-icon>
            <span i18n>RESET</span>
          </button>
          <button mat-stroked-button color="primary" class="spacing-left-5" (click)="save()">
            <mat-icon class="spacing-right-5">save</mat-icon>
            <span i18n>SAVE</span>
          </button>
        </div>
        <app-smart-form #addForm [fields]="userFormFieldsCreate"></app-smart-form>
      </mat-card-content>
    </mat-card>

    <!-- VIEW -->
    <mat-card *ngIf="action === routeAction.VIEW">
      <mat-card-header>
        <mat-card-title i18n>View User</mat-card-title>
        <mat-card-subtitle i18n>User Management</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
        </div>
        <app-smart-detail [fields]="userDetailFields" [source]="userEntity"></app-smart-detail>
      </mat-card-content>
    </mat-card>

    <!-- EDIT -->
    <mat-card *ngIf="action === routeAction.EDIT">
      <mat-card-header>
        <mat-card-title i18n>Edit User</mat-card-title>
        <mat-card-subtitle i18n>User Management</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
          <button mat-stroked-button class="spacing-left-5" (click)="reset()">
            <mat-icon class="spacing-right-5">refresh</mat-icon>
            <span i18n>RESET</span>
          </button>
          <button mat-stroked-button color="primary" class="spacing-left-5" (click)="save()">
            <mat-icon class="spacing-right-5">save</mat-icon>
            <span i18n>SAVE</span>
          </button>
        </div>
        <app-smart-form #editForm [fields]="userFormFieldsEdit" [defaults]="userEntity"></app-smart-form>
      </mat-card-content>
    </mat-card>
  </div>
</app-standard-layout>
