import {Component, OnInit, ViewChild} from '@angular/core';
import IGroup from '../../interfaces/IGroup';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RouteAction} from '../../definitions/RouteAction';
import {ContentType} from '../../definitions/ContentType';
import IItem from '../../interfaces/IItem';
import IRole from '../../interfaces/IRole';
import {SmartFormComponent} from '../../components/smart-form/smart-form.component';
import ISmartField from '../../interfaces/ISmartField';
import IDetailField from '../../interfaces/IDetailField';
import ISubject from '../../interfaces/ISubject';
import IUser from '../../interfaces/IUser';
import IProject from '../../interfaces/IProject';
import {InputDialogComponent} from '../../components/input-dialog/input-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import IResearch from '../../interfaces/IResearch';
import IClient from '../../interfaces/IClient';
import {SmartTableComponent} from '../../components/smart-table/smart-table.component';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-group-screen',
  templateUrl: './groups-screen.component.html',
  styleUrls: ['./groups-screen.component.styl']
})
export class GroupsScreenComponent implements OnInit {

  @ViewChild('addForm') addForm: SmartFormComponent;
  @ViewChild('editForm') editForm: SmartFormComponent;
  @ViewChild('candidatesTable') candidatesTable: SmartTableComponent;
  @ViewChild('usersTable') usersTable: SmartTableComponent;
  @ViewChild('projectsTable') projectsTable: SmartTableComponent;

  action: RouteAction = RouteAction.LOADING;
  routeAction = RouteAction;
  groups: IGroup[] = [];
  groupsTableColumnDefs = [
    { headerName: $localize`Code`, field: 'code', editable: false, flex: 1 },
    { headerName: $localize`Name`, field: 'name', editable: false, flex: 1 },
  ];
  groupFormFieldsCreate = [
    { key: 'code', label: $localize`Code`, required: true, type: ContentType.SHORT_TEXT },
    { key: 'name', label: $localize`Name`, required: true, type: ContentType.SHORT_TEXT },
    this.authService.getClientField(),
    { key: 'candidates', type: ContentType.HIDDEN, defaultValue: [] },
    { key: 'users', type: ContentType.HIDDEN, defaultValue: [] },
    { key: 'projects', type: ContentType.HIDDEN, defaultValue: [] },
  ];
  groupFormFieldsEdit = [...this.groupFormFieldsCreate.filter(field => !['candidates', 'users', 'projects'].includes(field.key))];
  groupDetailFields: IDetailField[] = this.groupFormFieldsCreate.filter(field => !['candidates', 'users', 'projects'].includes(field.key)).map(field => ({
    key: field.key,
    label: field.label,
    type: field.type
  }));
  selectedGroupRows = 0;
  private _groupEntity: IGroup = null;
  subjectsData: ISubject[] = [];
  usersData: IUser[] = [];
  projectsData: IProject[] = [];
  researches: IResearch[] = [];
  connectedSubjects: ISubject[] = [];
  connectedProjects: IProject[] = [];
  researchColumnDefs = [
    { headerName: $localize`Title`, field: 'title' },
    { headerName: $localize`Anonymous`, field: 'anonymous', cellRenderer: 'checkboxRenderer' },
    { headerName: $localize`Job`, field: 'job.title' },
    { headerName: $localize`Groups`, field: 'groups', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 },
    { headerName: $localize`Projects`, field: 'projects', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 },
    { headerName: $localize`Candidates`, field: 'candidates', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 },
    { headerName: $localize`Anonymous Candidates`, field: 'anonymousCandidates', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 }
  ];
  candidatesColumnDefs: any[] = [
    { headerName: $localize`Email`, field: 'email', flex: 1 },
    { headerName: $localize`First Name`, field: 'firstName', flex: 1 },
    { headerName: $localize`Last Name`, field: 'lastName', flex: 1 },
    { headerName: $localize`Phone`, field: 'phone' },
    { headerName: $localize`Fiscal Code`, field: 'fiscalCode' }
  ];
  usersColumnDefs: any[] = [
    { headerName: $localize`Email`, field: 'email', flex: 1 },
    { headerName: $localize`First Name`, field: 'firstName', flex: 1 },
    { headerName: $localize`Last Name`, field: 'lastName', flex: 1 },
    { headerName: $localize`Phone`, field: 'phone' },
  ];
  projectsColumnDefs: any[] = [
    { headerName: $localize`Code`, field: 'code', editable: false, flex: 1 },
    { headerName: $localize`Name`, field: 'name', editable: false, flex: 1 }
  ];

  constructor(private apiService: ApiService, private activatedRoute: ActivatedRoute, private router: Router, private dialog: MatDialog, public authService: AuthService) {
    this.onGroupDelete = this.onGroupDelete.bind(this);
    this.onGroupEdit = this.onGroupEdit.bind(this);
    this.onGroupView = this.onGroupView.bind(this);
  }

  ngOnInit(): void {
    this.loadScreen().then(() => {}).catch(console.error);
  }

  async loadScreen(): Promise<any> {
    this.subjectsData = await this.apiService.getSubjects();
    this.projectsData = await this.apiService.getProjects();

    if (this.activatedRoute.firstChild === null) {
      this.action = RouteAction.LISTING;
      this.fetchGroups();
    } else {
      const snapshot = this.activatedRoute.firstChild.snapshot;

      if (snapshot.routeConfig.path.startsWith('add')) {
        this.goToAdd();
      } else if (snapshot.routeConfig.path.startsWith('view')) {
        this.goToView();
      } else if (snapshot.routeConfig.path.startsWith('edit')) {
        this.goToEdit();
      }
    }
  }

  fetchGroups(): void {
    this.apiService.getGroups().then((groups: IGroup[]) => {
      this.groups = groups.filter(group => this.authService.hasBoundingPermission('group', 'view', group));
    });
  }

  save(): void {
    if (this.action === RouteAction.ADD) {
      if (this.addForm.form.invalid) {
        this.addForm.form.markAllAsTouched();
        return;
      }

      const group: IGroup = this.addForm.form.getRawValue();

      this.apiService.createGroup(group).then(() => {
        this.goToListing();
      });
    } else if (this.action === RouteAction.EDIT) {
      if (this.editForm.form.invalid) {
        this.editForm.form.markAllAsTouched();
        return;
      }

      const data: IGroup = {
        ...this.groupEntity,
        ...this.editForm.form.getRawValue()
      };

      this.apiService.updateGroup(data.id, data).then((group: IGroup) => {
        this.groupEntity = group;
        this.editForm.defaults = group;
        this.editForm.setValue({
          ...group,
          password: null
        });
      });
    }
  }

  reset(): void {
    if (this.action === RouteAction.EDIT) {
      this.editForm.reset();
    } else if (this.action === RouteAction.ADD) {
      this.addForm.reset();
    }
  }

  goToListing(): void {
    this.fetchGroups();
    this.router.navigateByUrl('/groups');
    this.action = RouteAction.LISTING;
  }

  goToAdd(): void {
    this.router.navigateByUrl('/groups/add');
    this.action = RouteAction.ADD;
  }

  goToEdit(data: IGroup = null): void {
    if (data === null) {
      this.apiService.getGroup(this.activatedRoute.firstChild.snapshot.params.id).then((group: IGroup) => {
        this.groupEntity = group;
        this.action = RouteAction.EDIT;
      });
    } else {
      this.groupEntity = data;
      this.router.navigateByUrl('/groups/edit/' + data.id);
      this.action = RouteAction.EDIT;
    }
  }

  goToView(data: IGroup = null): void {
    if (data === null) {
      this.apiService.getGroup(this.activatedRoute.firstChild.snapshot.params.id).then((group: IGroup) => {
        this.groupEntity = group;
        this.fetchResearches();
        this.action = RouteAction.VIEW;
      });
    } else {
      this.groupEntity = data;
      this.fetchResearches();
      this.router.navigateByUrl('/groups/view/' + data.id);
      this.action = RouteAction.VIEW;
    }
  }

  goBack(): void {
    switch (this.action) {
      case RouteAction.ADD:
      case RouteAction.EDIT:
      case RouteAction.VIEW:
        this.goToListing();
        break;

      default:
    }
  }

  onGroupDelete(ev: any): void {
    this.apiService.deleteGroup(ev.data.id).then(() => {
      this.fetchGroups();
    });
  }

  onGroupEdit(ev: any): void {
    this.goToEdit(ev.data);
  }

  onGroupView(ev: any): void {
    this.goToView(ev.data);
  }

  connectCandidate(): void {
    const existingSubjects = this.groupEntity.candidates || [];

    const dialogRef = this.dialog.open(InputDialogComponent, {
      data: {
        title: $localize`Select a Candidate`,
        label: null,
        multipleSelection: true,
        items: this.subjectsData.filter(candidate => !existingSubjects.includes(candidate.id)),
        showAsTable: true,
        columnDefs: [
          { headerName: $localize`Email`, field: 'email', editable: false },
          { headerName: $localize`First Name`, field: 'firstName', editable: false },
          { headerName: $localize`Last Name`, field: 'lastName', editable: false },
          { headerName: $localize`Phone`, field: 'phone', editable: false },
          { headerName: $localize`Fiscal Code`, field: 'fiscalCode', editable: false }
        ]
      },
      width: '95vw'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== null && result.length > 0) {
        const candidateIdsToAdd = result.filter(o => !this.groupEntity.candidates.includes(o.id)).map(o => o.id);
        this.apiService.updateGroup(this.groupEntity.id, {
          ...this.groupEntity,
          candidates: [
            ...this.groupEntity.candidates,
            ...candidateIdsToAdd
          ]
        }).then(updatedGroup => this.groupEntity = updatedGroup).catch(console.error);
      }
    });
  }

  connectUser(): void {
    const existingUsers = this.groupEntity.users || [];

    const dialogRef = this.dialog.open(InputDialogComponent, {
      data: {
        title: $localize`Select a User`,
        label: null,
        items: this.usersData.filter(user => !existingUsers.includes(user.id)),
        showAsTable: true,
        columnDefs: [
          { headerName: $localize`Email`, field: 'email', editable: false },
          { headerName: $localize`First Name`, field: 'firstName', editable: false },
          { headerName: $localize`Last Name`, field: 'lastName', editable: false },
          { headerName: $localize`Phone`, field: 'phone', editable: false },
          { headerName: $localize`Active`, field: 'isActive', cellRenderer: 'checkboxRenderer' },
          { headerName: $localize`Role`, field: 'role.name' }
        ]
      },
      width: '95vw'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== null) {
        this.groupEntity.users.push(result);
        this.apiService.updateGroup(this.groupEntity.id, this.groupEntity).then(() => {
          this.usersTable.gridApi.applyTransaction({
            add: [result]
          });
        }).catch(console.error);
      }
    });
  }

  connectProject(): void {
    const existingProjects = this.groupEntity.projects || [];

    const dialogRef = this.dialog.open(InputDialogComponent, {
      data: {
        title: $localize`Select a Project`,
        label: null,
        showSearch: true,
        filter: (item, value) => item.label.toLowerCase().startsWith(value.toLowerCase()),
        items: this.projectsData.filter(project => !existingProjects.includes(project.id)).map(project => ({ value: project, label: `${project.name}` }))
      },
      width: '400px'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== null && !this.groupEntity.projects.includes(result.id)) {
        this.apiService.updateGroup(this.groupEntity.id, {
          ...this.groupEntity,
          projects: [
            ...this.groupEntity.projects,
            result.id
          ]
        }).then(updatedGroup => this.groupEntity = updatedGroup).catch(console.error);
      }
    });
  }

  deleteCandidate(event: any): void {
    this.apiService.updateGroup(this.groupEntity.id, {
      ...this.groupEntity,
      candidates: this.groupEntity.candidates.filter(id => id !== event.data.id)
    }).then(updatedGroup => this.groupEntity = updatedGroup).catch(console.error);
  }

  deleteUser(event: any): void {
    this.groupEntity.users.splice(event.node.rowIndex, 1);
    this.apiService.updateGroup(this.groupEntity.id, this.groupEntity).then(() => {
      this.usersTable.gridApi.applyTransaction({
        remove: [event.data]
      });
    }).catch(console.error);
  }

  deleteProject(event: any): void {
    this.apiService.updateGroup(this.groupEntity.id, {
      ...this.groupEntity,
      projects: this.groupEntity.projects.filter(id => id !== event.data.id)
    }).then(updatedGroup => this.groupEntity = updatedGroup).catch(console.error);
  }

  onResearchView(ev: any): void {
    this.router.navigateByUrl(`/research/${ev.data.id}/group/${this.groupEntity.id}`, {
      state: {
        from: 'group',
        id: this.groupEntity.id
      }
    });
  }

  get groupEntity(): IGroup {
    return this._groupEntity;
  }

  set groupEntity(value: IGroup) {

    this._groupEntity = value;

    this.fetchConnectedSubjects();
    this.fetchConnectedProjects();
  }

  private fetchConnectedProjects(): void {
    if (!this.groupEntity) {
      this.connectedProjects = [];
      return;
    }

    this.connectedProjects = this.projectsData.filter(project => this.groupEntity.projects.includes(project.id));
  }

  private fetchConnectedSubjects(): void {
    if (!this.groupEntity) {
      this.connectedSubjects = [];
      return;
    }

    this.connectedSubjects = this.subjectsData.filter(subject => this.groupEntity.candidates.includes(subject.id));
  }

  private fetchResearches(): void {
    this.apiService.getResearchesByGroup(this.groupEntity)
      .then(researches => this.researches = researches).catch();
  }

  private fetchClientsAsItems(): Promise<IItem[]> {
    return new Promise<IItem[]>(resolve => {
      this.apiService.getClients().then(clients => resolve(clients.map(client => ({
        value: client,
        label: client.name
      }))));
    });
  }
}


