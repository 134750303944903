<app-topbar (updateNavigationExpansion)="onNavigationExpansionChange($event)" [navigationExpanded]="navigationExpanded"></app-topbar>
<div class="side-by-side">
  <app-navigation [expanded]="navigationExpanded"></app-navigation>
  <div class="below-eachother">
    <main>
      <div class="margin-trick">
        <ng-content></ng-content>
      </div>
    </main>
    <footer>
      <p i18n class="d-flex flex-row align-center spacing-inner-left-10 spacing-inner-right-10">
        <span>&copy; Telema Srl - All rights reserved</span>
        <a class="spacing-left-auto" href="assets/docs/telema-privacy.pdf" target="_blank">Privacy Policy</a> <a href="https://telemainternational.com" class="spacing-left-5" target="_blank" >Sito Web</a>
      </p>
    </footer>
  </div>
</div>
