<h2 mat-dialog-title i18n>Manage Question Answers</h2>
<mat-dialog-content>

  <ng-container *ngIf="hasAnswers()">
    <div class="button-bar spacing-bottom-5">
      <button mat-stroked-button color="primary" class="spacing-right-5" *ngIf="hasAnswers()" (click)="addAnswer()">
        <mat-icon class="spacing-right-5">add</mat-icon>
        <span *ngIf="data.element.child.type !== questionType.ARRAY_MATRIX" i18n>ADD ANSWER</span>
        <span *ngIf="data.element.child.type === questionType.ARRAY_MATRIX" i18n>ADD ROW</span>
      </button>

      <app-smart-vudlist title="Manage Columns" icon="view_column" i18n-title [fields]="matrix.fields" [itemBase]="matrix.base" [items]="matrix.items" btnStyle="stroked" menuWidth="250px" [getItemLabel]="matrix.getItemLabel" (change)="onColumnsChange($event)" *ngIf="data.element.child.type === questionType.ARRAY_MATRIX"></app-smart-vudlist>
    </div>

    <app-smart-table #answersGrid [rowData]="rowData" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowDragManaged]="true" [pagination]="false" [hideEdit]="true" [hideView]="true" [gridHeight]="250" (rowDelete)="onAnswerDelete($event)" [groupParams]="groupParams"></app-smart-table>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close i18n (click)="onCancel()">Cancel</button>
  <button mat-button i18n (click)="onConfirm()">Confirm</button>
</mat-dialog-actions>
