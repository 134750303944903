import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentType} from '../../definitions/ContentType';
import {CandidateEducationComponent} from '../../components/candidate-education/candidate-education.component';
import IItem from '../../interfaces/IItem';
import {CandidateCourseComponent} from '../../components/candidate-course/candidate-course.component';
import {CandidateWorkExperienceComponent} from '../../components/candidate-work-experience/candidate-work-experience.component';
import {ApiService} from '../../services/api.service';
import {SmartFormComponent} from '../../components/smart-form/smart-form.component';
import ISubject from '../../interfaces/ISubject';

@Component({
  selector: 'app-candidate-signup-screen',
  templateUrl: './candidate-signup-screen.component.html',
  styleUrls: ['./candidate-signup-screen.component.styl']
})
export class CandidateSignupScreenComponent implements OnInit {

  @ViewChild('form') form: SmartFormComponent;

  candidateFullName: string = null;

  fields: any[] = [
    { key: 'email', label: $localize`Email`, type: ContentType.EMAIL, required: true },
    { key: 'password', label: $localize`Password`, type: ContentType.PASSWORD, required: true },
    { key: 'firstName', label: $localize`First Name`, type: ContentType.SHORT_TEXT, required: true },
    { key: 'lastName', label: $localize`Last Name`, type: ContentType.SHORT_TEXT, required: true },
    { key: 'phone', label: $localize`Phone`, type: ContentType.PHONE },
    { key: 'fiscalCode', label: $localize`Fiscal Code`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'profession', label: $localize`Profession`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'birthDate', label: $localize`Birth Date`, required: false, type: ContentType.DATE },
    { key: 'birthPlace', label: $localize`Birth Place`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'gender', label: $localize`Gender`, type: ContentType.LIST, data: [ { value: 'MALE', label: $localize`Male` }, { value: 'FEMALE', label: $localize`Female` }, { value: 'OTHER', label: $localize`Other` }] },
    { key: 'resume', label: $localize`Resume`, required: false, type: ContentType.FILE },
    { key: 'coverLetter', label: $localize`Cover Letter`, required: false, type: ContentType.FILE },
    { key: 'domicile', label: $localize`Domicile`, required: false, type: ContentType.ADDRESS },
    { key: 'residence', label: $localize`Residence`, required: false, type: ContentType.ADDRESS },
    {
      key: 'education',
      label: $localize`Education`,
      required: false,
      type: ContentType.CRUD,
      options: {
        title: $localize`Education`,
        renderer: CandidateEducationComponent,
        fields: [
          { key: 'title', label: $localize`Title` },
          {
            key: 'level',
            label: $localize`Level`,
            required: true,
            type: ContentType.LIST,
            data: [],
            fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getEducationLevels().then(educationLevels => {
                resolve(educationLevels.map(educationLevel => ({
                  value: educationLevel,
                  label: educationLevel.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id
          },
          { key: 'startDate', label: $localize`Start Date`, type: ContentType.DATE },
          { key: 'endDate', label: $localize`End Date`, type: ContentType.DATE },
          { key: 'institute', label: $localize`Institute`, type: ContentType.LIST, data: [], fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getEducationInstitutes().then(entities => {
                resolve(entities.map(entity => ({
                  value: entity,
                  label: entity.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id },
          {
            key: 'category',
            label: $localize`Category`,
            type: ContentType.LIST,
            data: [],
            fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getEducationCategories().then(educationCategories => {
                resolve(educationCategories.map(educationCategory => ({
                  value: educationCategory,
                  label: educationCategory.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id,
            options: {
              groupBy: 'value.macro'
            }
          },
          { key: 'grade', label: $localize`Grade` },
          { key: 'withDistinction', label: $localize`With Distinction`, type: ContentType.BOOLEAN, displayAs: 'toggle' },
          { type: ContentType.BLANK },
          { key: 'description', label: $localize`Description`, type: ContentType.LONG_TEXT, colSize: '12' },
        ]
      },
      defaultValue: []
    },
    {
      key: 'courses',
      label: $localize`Courses`,
      required: false,
      type: ContentType.CRUD,
      options: {
        title: $localize`Courses`,
        renderer: CandidateCourseComponent,
        fields: [
          {
            key: 'course',
            label: $localize`Course`,
            type: ContentType.SHORT_TEXT,
            required: true,
          },
          { key: 'startDate', label: $localize`Start Date`, type: ContentType.DATE },
          { key: 'endDate', label: $localize`End Date`, type: ContentType.DATE },
          { key: 'grade', label: $localize`Grade` },
          { key: 'withDistinction', label: $localize`With Distinction`, type: ContentType.BOOLEAN, displayAs: 'toggle' },
          { type: ContentType.BLANK },
          { key: 'description', label: $localize`Description`, type: ContentType.LONG_TEXT, colSize: '12' },
        ]
      },
      defaultValue: []
    },
    {
      key: 'workExperience',
      label: $localize`Work Experience`,
      required: false,
      type: ContentType.CRUD,
      options: {
        title: $localize`Work Experience`,
        renderer: CandidateWorkExperienceComponent,
        fields: [
          { key: 'position', label: $localize`Position` },
          {
            key: 'company',
            label: $localize`Company`,
            type: ContentType.SHORT_TEXT
          },
          { key: 'startDate', label: $localize`Start Date`, type: ContentType.DATE },
          { key: 'endDate', label: $localize`End Date`, type: ContentType.DATE },
          {
            key: 'workSector',
            label: $localize`Sector`,
            required: false,
            type: ContentType.LIST,
            data: [],
            fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getWorkSectors().then(workSectors => {
                resolve(workSectors.map(workSector => ({
                  value: workSector,
                  label: workSector.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id
          },
          {
            key: 'workProfession',
            label: $localize`Profession`,
            type: ContentType.LIST,
            data: [],
            fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getWorkProfessions().then(workProfessions => {
                resolve(workProfessions.map(workProfession => ({
                  value: workProfession,
                  label: workProfession.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id
          },
          {
            key: 'contractType',
            label: $localize`Contract Type`,
            type: ContentType.LIST,
            data: [],
            fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getWorkContractTypes().then(contractTypes => {
                resolve(contractTypes.map(contractType => ({
                  value: contractType,
                  label: contractType.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id
          },
          { key: 'annualGrossIncome', label: $localize`Annual Gross Income` },
          { key: 'annualNetIncome', label: $localize`Annual Net Income` },
          { key: 'description', label: $localize`Description`, type: ContentType.LONG_TEXT, colSize: '12' },
        ]
      },
      defaultValue: []
    }
  ];

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
  }

  submit(): void {
    if (this.form.form.invalid) {
      this.form.form.markAllAsTouched();
      return;
    }

    const rawValue: any = this.form.form.getRawValue();
    const data: ISubject = {courses: [], education: [], information: {}, privacyAgreements: {
        dataUsageForCandidateResearchAgreement: false,
        personalDataAgreement: false,
        personalSpecificDataAgreement: false,
        dataUsageForStatisticsAndReportsAgreement: false,
        marketingAgreement: false
    }, workExperience: []};

    Object.keys(rawValue).forEach(key => {
      data[key] = rawValue[key];
    });


    this.apiService.createSubject(data).then((subject: ISubject) => {
      this.candidateFullName = subject.firstName + ' ' + subject.lastName;
    }).catch(() => {});
  }

}
