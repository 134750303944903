<div class="ag-filter-wrapper ag-focus-managed">
  <div class="ag-filter-body-wrapper ag-set-filter-body-wrapper">
    <div class="ag-set-filter">
      <div role="presentation" class="ag-mini-filter ag-labeled ag-label-align-left ag-text-field ag-input-field">
        <div class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
          <input class="ag-input-field-input ag-text-field-input" type="text"
                 aria-label="Search filter values" placeholder="Search..." [(ngModel)]="searchInput" (ngModelChange)="onSearch($event)">
        </div>
      </div>
      <div *ngIf="filteredItems.length === 0" class="ag-filter-no-matches" i18n>No matches.</div>
      <div *ngIf="filteredItems.length > 0" class="ag-set-filter-list" role="presentation">
        <div class="filter-options-list">
          <div class="filter-option">
            <div class="ag-set-filter-item">
              <!--AG-CHECKBOX-->
              <div role="presentation" class="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
                <div class="ag-input-field-label ag-label ag-checkbox-label" i18n>(Select All)</div>
                <div class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" [ngClass]="{'ag-checked': items.length === selectedItems.length && selectedBlank}" role="presentation">
                  <input class="ag-input-field-input ag-checkbox-input" type="checkbox" (click)="onSelectAll()">
                </div>
              </div>
            </div>
          </div>
          <div class="filter-option">
            <div class="ag-set-filter-item">
              <!--AG-CHECKBOX-->
              <div role="presentation" class="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
                <div class="ag-input-field-label ag-label ag-checkbox-label" i18n>(Blank)</div>
                <div class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" [ngClass]="{'ag-checked': selectedBlank}" role="presentation">
                  <input class="ag-input-field-input ag-checkbox-input" type="checkbox" (click)="onSelectBlank()">
                </div>
              </div>
            </div>
          </div>
          <div class="filter-option" *ngFor="let item of filteredItems">
            <div class="ag-set-filter-item">
              <!--AG-CHECKBOX-->
              <div role="presentation" class="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
                <div class="ag-input-field-label ag-label ag-checkbox-label" i18n>{{params.getItemLabel && params.getItemLabel(item)}}</div>
                <div class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" [ngClass]="{'ag-checked': selectedItems.indexOf(item.id) > -1}" role="presentation">
                  <input class="ag-input-field-input ag-checkbox-input" type="checkbox" (click)="onCheckChange($event, item)">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="ag-virtual-list-viewport ag-filter-virtual-list-viewport ag-focus-managed" role="listbox">
          <div class="ag-tab-guard ag-tab-guard-top" role="presentation" tabindex="0"></div>
          <div class="ag-virtual-list-container ag-filter-virtual-list-container"
               style="height: 64px;">
            <div class="ag-virtual-list-item ag-filter-virtual-list-item" role="option" aria-setsize="2"
                 aria-posinset="1" tabindex="-1" aria-selected="true" aria-checked="true"
                 style="height: 32px; top: 0px;">

            </div>
            <div class="ag-virtual-list-item ag-filter-virtual-list-item" role="option" aria-setsize="2"
                 aria-posinset="2" tabindex="-1" aria-checked="false" style="height: 32px; top: 32px;">
              <div class="ag-set-filter-item">
                <div role="presentation" class="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
                  <div class="ag-input-field-label ag-label ag-checkbox-label" i18n>(None)</div>
                  <div class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" [ngClass]="{'ag-checked': selectedItems.indexOf(1) > -1}" role="presentation">
                    <input class="ag-input-field-input ag-checkbox-input" type="checkbox" (change)="onCheckChange($event, 1)">
                  </div>
                </div>
              </div>
            </div>
            <div class="ag-virtual-list-item ag-filter-virtual-list-item" role="option" aria-setsize="2"
                 aria-posinset="2" tabindex="-1" aria-checked="false" style="height: 32px; top: 32px;" *ngFor="let item of filteredItems; let i = index">
              <div class="ag-set-filter-item">
                <div role="presentation" class="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
                  <div class="ag-input-field-label ag-label ag-checkbox-label">{{params.getItemLabel && params.getItemLabel(item)}}</div>
                  <div class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" [ngClass]="{'ag-checked': selectedItems.indexOf(item.id) > -1}" role="presentation">
                    <input class="ag-input-field-input ag-checkbox-input" type="checkbox" (change)="onCheckChange($event, item)">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ag-tab-guard ag-tab-guard-bottom" role="presentation" tabindex="0"></div>
        </div>-->
      </div>
    </div>
  </div>
</div>
