import { Component } from '@angular/core';
import {ApiService} from "../../services/api.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-run-survey-cell-renderer',
  templateUrl: './run-survey-cell-renderer.component.html',
  styleUrls: ['./run-survey-cell-renderer.component.styl']
})
export class RunSurveyCellRendererComponent {

  params: any;
  canRunSurvey = false;

  constructor(private authService: AuthService) {
  }

  agInit(params: any): void {
    this.params = params;

    this.canRunSurvey = this.authService.hasBoundingPermission('research', 'create', this.params.data);
  }

  runSurvey(): void {
    if (this.params.context.runSurvey) {
      this.params.context.runSurvey(this.params.data);
    }
  }

}
