<app-standard-layout>
  <div class="spacing-inner-10">

    <!-- LISTING -->
    <mat-card *ngIf="action === routeAction.LISTING">
      <mat-card-header>
        <mat-card-title i18n>Projects</mat-card-title>
        <mat-card-subtitle i18n>On current page you are able to view, edit, delete and create any project account further used to complete or view tests and surveys results</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-5">
          <button mat-stroked-button color="primary" (click)="goToAdd()" *ngIf="authService.hasPermission(['project_create', 'project_create_org'])">
            <mat-icon class="spacing-right-5">add</mat-icon>
            <span i18n>ADD NEW PROJECT</span>
          </button>
          <button mat-stroked-button color="warn" [ngClass]="{'spacing-left-5': authService.hasPermission(['project_create'])}" *ngIf="authService.hasPermission(['project_delete'])" [disabled]="selectedProjectRows === 0">
            <mat-icon class="spacing-right-5">delete</mat-icon>
            <span i18n>DELETE SELECTED</span>
          </button>
        </div>
        <app-smart-table [rowData]="projects" [columnDefs]="projectsTableColumnDefs" (rowDelete)="onProjectDelete($event)" (rowEdit)="onProjectEdit($event)" (rowView)="onProjectView($event)" permissionGroup="project"></app-smart-table>
      </mat-card-content>
    </mat-card>

    <!-- ADD -->
    <mat-card *ngIf="action === routeAction.ADD">
      <mat-card-header>
        <mat-card-title i18n>Add Project</mat-card-title>
        <mat-card-subtitle i18n>Projects</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
          <button mat-stroked-button class="spacing-left-5" (click)="reset()">
            <mat-icon class="spacing-right-5">refresh</mat-icon>
            <span i18n>RESET</span>
          </button>
          <button mat-stroked-button color="primary" class="spacing-left-5" (click)="save()">
            <mat-icon class="spacing-right-5">save</mat-icon>
            <span i18n>SAVE</span>
          </button>
        </div>
        <app-smart-form #addForm [fields]="projectFormFieldsCreate"></app-smart-form>
      </mat-card-content>
    </mat-card>

    <!-- VIEW -->
    <ng-container *ngIf="action === routeAction.VIEW">
      <mat-card>
        <mat-card-header>
          <mat-card-title i18n>View Project</mat-card-title>
          <mat-card-subtitle i18n>Projects</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div class="button-bar spacing-bottom-10">
            <button mat-stroked-button (click)="goBack()">
              <mat-icon class="spacing-right-5">arrow_back</mat-icon>
              <span i18n>GO BACK</span>
            </button>
          </div>
          <app-smart-detail [fields]="projectDetailFields" [source]="projectEntity"></app-smart-detail>
        </mat-card-content>
      </mat-card>

      <mat-card class="spacing-top-10">
        <mat-card-header>
          <mat-card-title i18n>Researches</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-smart-table #researchesTable [rowData]="researches" [columnDefs]="researchColumnDefs" [hideEdit]="true" (rowView)="onResearchView.bind(this)($event)" [hideDelete]="true" permissionGroup="research"></app-smart-table>
        </mat-card-content>
      </mat-card>

      <mat-card class="spacing-top-10">
        <mat-card-header>
          <mat-card-title i18n>Connected Candidates</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="button-bar spacing-bottom-10">
            <button mat-stroked-button color="primary" (click)="connectCandidate()">
              <mat-icon class="spacing-right-5">insert_link</mat-icon>
              <span i18n>CONNECT CANDIDATE</span>
            </button>
          </div>

          <app-smart-table #candidatesTable [rowData]="connectedSubjects" [columnDefs]="candidatesColumnDefs" [hideView]="true" [hideEdit]="true" (rowDelete)="deleteCandidate($event)" permissionGroup="candidate"></app-smart-table>

        </mat-card-content>
      </mat-card>

      <mat-card class="spacing-top-10">
        <mat-card-header>
          <mat-card-title i18n>Connected Groups</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="button-bar spacing-bottom-10">
            <button mat-stroked-button color="primary" (click)="connectGroup()">
              <mat-icon class="spacing-right-5">insert_link</mat-icon>
              <span i18n>CONNECT GROUP</span>
            </button>
          </div>

          <app-smart-table #groupsTable [rowData]="connectedGroups" [columnDefs]="groupsColumnDefs" [hideView]="true" [hideEdit]="true" (rowDelete)="deleteGroup($event)" permissionGroup="group"></app-smart-table>

        </mat-card-content>
      </mat-card>
    </ng-container>

    <!-- EDIT -->
    <mat-card *ngIf="action === routeAction.EDIT">
      <mat-card-header>
        <mat-card-title i18n>Edit Project</mat-card-title>
        <mat-card-subtitle i18n>Projects</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
          <button mat-stroked-button class="spacing-left-5" (click)="reset()">
            <mat-icon class="spacing-right-5">refresh</mat-icon>
            <span i18n>RESET</span>
          </button>
          <button mat-stroked-button color="primary" class="spacing-left-5" (click)="save()">
            <mat-icon class="spacing-right-5">save</mat-icon>
            <span i18n>SAVE</span>
          </button>
        </div>
        <app-smart-form #editForm [fields]="projectFormFieldsEdit" [defaults]="projectEntity"></app-smart-form>
      </mat-card-content>
    </mat-card>
  </div>
</app-standard-layout>
