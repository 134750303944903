import { Injectable } from '@angular/core';
import {UtilsService} from './utils.service';
import {AIAction} from '../definitions/AIAction';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AIService {

  private userActivity: {
    outDate: any,
    inDate: any,
    data: any
  }[] = [];

  private data: any = {};

  constructor(private utilsService: UtilsService) { }

  getTranslationFromAction(action: any[]): { action: AIAction, params: any[] } {
    const asString = action.join(' ');

    for (const key in AIAction) {
      if (!AIAction.hasOwnProperty(key)) {
        continue;
      }

      const availableAction = AIAction[key];

      if (asString.startsWith(availableAction)) {
        const withoutActionAsString = asString.substring(availableAction.length + 1, asString.length);
        const withoutAction = withoutActionAsString.split(' ');

        return {
          action: AIAction[key],
          params: withoutAction
        };
      }
    }

    return {
      action: AIAction.DO_NOTHING,
      params: []
    };
  }

  read(source: string|null, extras: any = {}): any {
    if (!source) {
      return ['DO', 'NOTHING'];
    }

    if (source.startsWith('SWITCH') && source.endsWith('END')) {
      return this.readSwitchExpression(source, extras);
    } else if (source.startsWith('IF') && source.endsWith('END')) {
      return null;
    } else if (source.startsWith('$QUESTION')) {
      return this.readCondition(source, extras);
    }

    const action = this.readAction(source, extras);

    if (action.join(' ') !== 'UNKNOWN ACTION') {
      return action;
    }

    return ['THE', 'RESULT', 'IS', this.readExpression(source, extras)];
  }

  readExpression(source: string, extras: any = {}): boolean {
    const allowedCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789#';
    const characters = source.split('');
    const levels: any = [];
    let level: any = levels;
    const parents: any = [];
    let chars = [];
    let words = [];

    for (const [i, character] of characters.entries()) {
      if (allowedCharacters.indexOf(character) > -1) {
        chars.push(character);
        continue;
      }

      if (character === '(') {
        level.push([]);
        parents.push(level);
        level = level[level.length - 1];
        continue;
      }

      if (character === ')') {
        if (chars.length > 0) {
          words.push(chars.join(''));
          chars = [];
        }

        if (words.length > 0) {
          level.push(words.join(' '));
          words = [];
        }

        level = parents[parents.length - 1];
        parents.splice(parents.length - 1, 1);
        continue;
      }

      if (character === ' ') {
        words.push(chars.join(''));
        chars = [];
        const lastWord = words[words.length - 1];

        if (lastWord === 'AND' || lastWord === 'OR') {
          level.push(words.slice(0, words.length - 1).join(' '));
          level.push(lastWord);
          words = [];
        }
      }
    }

    if (chars.length > 0) {
      words.push(chars.join(''));
      chars = [];
    }

    if (words.length > 0) {
      level.push(words.join(' '));
      words = [];
    }

    const processExpression = (expression) => {
      const regex = /([A-Za-z0-9#]+) ((NOT |)(EQUALS|CONTAINS|GREATER THAN|LESS THAN)) ([A-Za-z0-9#]+)/gm;
      const match = regex.exec(expression);
      let result = false;

      if (match && match.length === 6) {
        let left: any = match[1];
        let right: any = match[5];
        const isNegated = match[3] !== '';
        const verb = match[4];

        if (left.startsWith('#')) {
          left = extras[left.substring(1, left.length)];
        } else if (left.startsWith('[') && left.endsWith(']')) {
          left = left.substring(1, left.length - 1).split(',');
        }

        if (right.startsWith('#')) {
          right = extras[right.substring(1, right.length)];
        } else if (right.startsWith('[') && right.endsWith(']')) {
          right = right.substring(1, right.length - 1).split(',');
        }

        if (!left || !right) {
          return false;
        }

        switch (verb) {
          case 'EQUALS':
            if (Array.isArray(left) && Array.isArray(right)) {
              result = this.utilsService.eqArrays(left, right);
            } else if (Array.isArray(right)) {
              result = right.indexOf(left) > -1;
            } else {
              // tslint:disable-next-line:triple-equals
              result = left == right;
            }

            break;

          case 'CONTAINS':
            if (Array.isArray(left) && Array.isArray(right)) {
              result = this.utilsService.containsArrays(left, right);
            } else if (Array.isArray(right)) {
              result = right.indexOf(left) > -1;
            }

            break;

          case 'GREATER THAN':
            result = left > right;
            break;

          case 'LESS THAN':
            result = left < right;
            break;

          default:
        }

        return isNegated ? !result : result;
      }

      return result;
    };

    const getResult = (expressions) => {
      let result = false;
      let comparison = null;
      let expressionResult;

      for (const expression of expressions) {
        if (expression === 'AND' || expression === 'OR') {
          comparison = expression;
          continue;
        }

        if (Array.isArray(expression)) {
          expressionResult = getResult(expression);
        } else {
          expressionResult = processExpression(expression);
        }

        if (comparison === 'AND') {
          result = result && expressionResult;
        } else if (comparison === 'OR') {
          result = result || expressionResult;
        } else {
          result = expressionResult;
        }
      }

      return result;
    };

    return getResult(levels);
  }

  readAction(source: string, extras: any = {}): any[] {
    if (source.startsWith('GO TO')) {
      return source.split(' ');
    }

    return ['UNKNOWN', 'ACTION'];
  }

  readSwitchExpression(source: string, extras: any = {}): any[] {
    if (!source.startsWith('SWITCH') || !source.endsWith('END')) {
      return ['DO', 'NOTHING'];
    }

    const words = source.split(' ');
    let variableToCompare;
    let testcase = false;
    let phrase = [];
    let found = false;

    for (const word of words) {
      if (word === 'SWITCH') {
        continue;
      }

      if (!variableToCompare) {
        if (word.startsWith('#')) {
          variableToCompare = extras[word.substring(1, word.length)];
        } else {
          variableToCompare = word;
        }

        continue;
      }

      if (word === 'WHEN') {
        if (found) {
          break;
        }

        testcase = true;
        phrase = [];
        continue;
      }

      if (word === 'END') {
        break;
      }

      if (word === 'DEFAULT') {
        if (found) {
          break;
        }

        found = true;
        continue;
      }

      if (word === 'THEN') {
        testcase = false;

        // tslint:disable-next-line:triple-equals
        if (variableToCompare == phrase.join(' ')) {
          found = true;
        }

        phrase = [];

        continue;
      }

      if (testcase || found) {
        phrase.push(word);
      }
    }

    return phrase.length > 0 ? phrase : ['DO', 'NOTHING'];
  }

  startActivityTracking(): void {
    this.userActivity = [];
    document.addEventListener('visibilitychange', this.trackActivity);
  }

  endActivityTracking(): {inDate: any, outDate: any, data: any}[] {
    document.removeEventListener('visibilitychange', this.trackActivity);
    const userActivity = [...this.userActivity];
    this.userActivity = [];
    return userActivity;
  }

  trackActivity(ev: any): void {
    const count = (this.userActivity && this.userActivity.length) || 0;
    const last = count > 0 ? this.userActivity[count - 1] : null;

    if (!this.userActivity) {
      this.userActivity = [];
    }

    if (document.hidden) {
      this.userActivity.push({
        outDate: moment(),
        inDate: null,
        data: this.data.trackActivity || null
      });
    } else if (last !== null && last.inDate === null) {
      this.userActivity[count - 1].inDate = moment();
    }
  }

  getData(): any {
    return this.data;
  }

  setData(data: any): any {
    this.data = data;
  }

  readCondition(source: any, extras: {}): boolean {
    const regex = /^\$QUESTION\(([^\)]+)\) (EQUALS|NOT EQUALS) (.*)$/;
    const match = source.match(regex);

    if (match !== null && match.length === 4) {
      let value = null;
      let matchValue = null;

      if (extras.hasOwnProperty(match[1])) {
        value = Number(extras[match[1]]);

        if (isNaN(value)) {
          value = extras[match[1]] + '';
        }
      }

      if (isNaN(Number(match[3]))) {
        matchValue = match[3] + '';
      } else {
        matchValue = Number(match[3]);
      }

      switch (match[2].toUpperCase()) {
        case 'EQUALS':
          return matchValue === value;

        case 'NOT EQUALS':
          return matchValue !== value;

        default:
      }
    }

    return false;
  }
}
