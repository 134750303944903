<div class="bottom-sheet-wrapper">
  <div class="row">
    <div class="col">
      <button mat-stroked-button color="primary" class="full-width" (click)="showForm()">
        <mat-icon class="spacing-right-5">add</mat-icon>
        <span i18n>CREATE</span>
      </button>
    </div>
    <div class="col">
      <button mat-stroked-button class="full-width" [disabled]="itemsList.selectedOptions.selected.length === 0" (click)="deleteSelected()">
        <mat-icon class="spacing-right-5">delete</mat-icon>
        <span i18n>DELETE SELECTED</span>
      </button>
    </div>
  </div>
  <div class="row" *ngIf="!displayForm">
    <div class="col">
      <mat-selection-list #itemsList>
        <mat-list-option *ngFor="let item of items" color="primary" [value]="item" checkboxPosition="before">
          <div class="full-width d-flex flex-row align-items-center">
            <span>{{getItemLabel(item)}}</span>
            <button mat-icon-button class="spacing-left-auto">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button class="spacing-left-5">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>
  <div class="row" *ngIf="displayForm">
    <div class="col">
      <app-smart-form fields=""></app-smart-form>
    </div>
  </div>
</div>
