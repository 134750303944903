<app-standard-layout>
  <div class="spacing-inner-10">

    <!-- LISTING -->
    <mat-card *ngIf="action === routeAction.LISTING">
      <mat-card-header>
        <mat-card-title i18n>Groups</mat-card-title>
        <mat-card-subtitle i18n>On current page you are able to view, edit, delete and create any group account further used to complete or view tests and surveys results</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-5">
          <button mat-stroked-button color="primary" (click)="goToAdd()" *ngIf="authService.hasPermission(['group_create', 'group_create_org'])">
            <mat-icon class="spacing-right-5">add</mat-icon>
            <span i18n>ADD NEW GROUP</span>
          </button>
          <button mat-stroked-button color="warn" [ngClass]="{'spacing-left-5': authService.hasPermission(['group_create'])}" *ngIf="authService.hasPermission(['group_delete'])" [disabled]="selectedGroupRows === 0">
            <mat-icon class="spacing-right-5">delete</mat-icon>
            <span i18n>DELETE SELECTED</span>
          </button>
        </div>
        <app-smart-table [rowData]="groups" [columnDefs]="groupsTableColumnDefs" (rowDelete)="onGroupDelete($event)" (rowEdit)="onGroupEdit($event)" (rowView)="onGroupView($event)" permissionGroup="group"></app-smart-table>
      </mat-card-content>
    </mat-card>

    <!-- ADD -->
    <mat-card *ngIf="action === routeAction.ADD">
      <mat-card-header>
        <mat-card-title i18n>Add Group</mat-card-title>
        <mat-card-subtitle i18n>Groups</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
          <button mat-stroked-button class="spacing-left-5" (click)="reset()">
            <mat-icon class="spacing-right-5">refresh</mat-icon>
            <span i18n>RESET</span>
          </button>
          <button mat-stroked-button color="primary" class="spacing-left-5" (click)="save()">
            <mat-icon class="spacing-right-5">save</mat-icon>
            <span i18n>SAVE</span>
          </button>
        </div>
        <app-smart-form #addForm [fields]="groupFormFieldsCreate"></app-smart-form>
      </mat-card-content>
    </mat-card>

    <!-- VIEW -->
    <ng-container *ngIf="action === routeAction.VIEW">
      <mat-card>
        <mat-card-header>
          <mat-card-title i18n>View Group</mat-card-title>
          <mat-card-subtitle i18n>Groups</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div class="button-bar spacing-bottom-10">
            <button mat-stroked-button (click)="goBack()">
              <mat-icon class="spacing-right-5">arrow_back</mat-icon>
              <span i18n>GO BACK</span>
            </button>
          </div>
          <app-smart-detail [fields]="groupDetailFields" [source]="groupEntity"></app-smart-detail>
        </mat-card-content>
      </mat-card>

      <mat-card class="spacing-top-10">
        <mat-card-header>
          <mat-card-title i18n>Researches</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-smart-table #researchesTable [rowData]="researches" [columnDefs]="researchColumnDefs" [hideEdit]="true" (rowView)="onResearchView.bind(this)($event)" [hideDelete]="true" permissionGroup="research"></app-smart-table>
        </mat-card-content>
      </mat-card>

      <mat-card class="spacing-top-10">
        <mat-card-header>
          <mat-card-title i18n>Connected Candidates</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="button-bar spacing-bottom-10">
            <button mat-stroked-button color="primary" (click)="connectCandidate()">
              <mat-icon class="spacing-right-5">insert_link</mat-icon>
              <span i18n>CONNECT CANDIDATE</span>
            </button>
          </div>

          <app-smart-table #candidatesTable [rowData]="connectedSubjects" [columnDefs]="candidatesColumnDefs" [hideView]="true" [hideEdit]="true" (rowDelete)="deleteCandidate($event)" permissionGroup="candidate"></app-smart-table>

        </mat-card-content>
      </mat-card>

      <mat-card class="spacing-top-10">
        <mat-card-header>
          <mat-card-title i18n>Connected Projects</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="button-bar spacing-bottom-10">
            <button mat-stroked-button color="primary" (click)="connectProject()">
              <mat-icon class="spacing-right-5">insert_link</mat-icon>
              <span i18n>CONNECT PROJECT</span>
            </button>
          </div>

          <app-smart-table #projectsTable [rowData]="connectedProjects" [columnDefs]="projectsColumnDefs" [hideView]="true" [hideEdit]="true" (rowDelete)="deleteProject($event)" permissionGroup="project"></app-smart-table>

        </mat-card-content>
      </mat-card>
    </ng-container>

    <!-- EDIT -->
    <mat-card *ngIf="action === routeAction.EDIT">
      <mat-card-header>
        <mat-card-title i18n>Edit Group</mat-card-title>
        <mat-card-subtitle i18n>Groups</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
          <button mat-stroked-button class="spacing-left-5" (click)="reset()">
            <mat-icon class="spacing-right-5">refresh</mat-icon>
            <span i18n>RESET</span>
          </button>
          <button mat-stroked-button color="primary" class="spacing-left-5" (click)="save()">
            <mat-icon class="spacing-right-5">save</mat-icon>
            <span i18n>SAVE</span>
          </button>
        </div>
        <app-smart-form #editForm [fields]="groupFormFieldsEdit" [defaults]="groupEntity"></app-smart-form>
      </mat-card-content>
    </mat-card>
  </div>
</app-standard-layout>
