import {Component, forwardRef, Inject, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import ISmartField from '../../../interfaces/ISmartField';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ContentType} from '../../../definitions/ContentType';
import {UtilsService} from '../../../services/utils.service';

@Component({
  selector: 'app-input-object',
  templateUrl: './input-object.component.html',
  styleUrls: ['./input-object.component.styl'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputObjectComponent),
      multi: true
    }
  ]
})
export class InputObjectComponent implements OnInit, ControlValueAccessor {

  @Input() field: ISmartField;
  private _value: any;
  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }


  get value(): any {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
    this.onChange(this._value);
    this.onTouch(this._value);
  }

  openDialog(): void {
    this.dialog.open(InputObjectDialog, {
      data: {
        field: this.field,
        value: this.value,
        onValueChange: this.onValueChange.bind(this)
      },
      width: '90vw',
      autoFocus: false
    });
  }

  onValueChange(updatedValue: any): Promise<any> {
    return new Promise(resolve => {
      this.value = updatedValue;
      resolve(this.value);
    });
  }

  openAddDialog(): void {
    this.dialog.open(InputObjectAddDialog, {
      data: {
        value: this.value
      },
      autoFocus: false
    });
  }
}

interface DialogData {
  field: ISmartField;
  value: any;
  onValueChange: (updatedValue: any) => Promise<any>;
}

@Component({
  selector: 'input-object-dialog',
  templateUrl: './input-object-dialog.html',
  styleUrls: ['./input-object-dialog.styl']
})
export class InputObjectDialog implements OnInit {
  editing: string = null;
  editingPropertyValue: any = null;
  editingPropertyType: any = ContentType.SHORT_TEXT;
  editingPropertyLabel: string = null;
  value: any;
  field: ISmartField;
  availableTypes = [
    { value: ContentType.SHORT_TEXT, label: $localize`Text` },
    { value: ContentType.NUMBER, label: $localize`Number` },
    { value: ContentType.INTEGER, label: $localize`Integer` },
    { value: ContentType.MULTILIST, label: $localize`List` },
    { value: ContentType.DATE, label: $localize`Date` },
    { value: ContentType.BOOLEAN, label: $localize`Boolean` }
  ];
  contentType = ContentType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public utils: UtilsService,
    public dialog: MatDialog
  ) {
    this.value = {...this.data.value};
    this.field = this.data.field;
  }

  ngOnInit(): void {
  }

  startEditing(key: string): void {
    this.editingPropertyType = this.value[key].type;
    this.editingPropertyValue = this.utils.deconstructValue(this.value[key].value, this.value[key].type);
    this.editingPropertyLabel = this.value[key].label;
    this.editing = key;
  }

  stopEditing(key: string, cancel = false): void {
    this.editing = null;

    if (cancel) {
      this.editingPropertyValue = null;
      this.editingPropertyType = ContentType.SHORT_TEXT;
      this.editingPropertyLabel = null;
    } else {
      this.value = {
        ...this.value,
        [key]: {
          value: this.utils.constructValue(this.editingPropertyValue, this.editingPropertyType, this.utils.getDefaultValueByType(this.editingPropertyType)),
          label: this.editingPropertyLabel,
          type: this.editingPropertyType
        }
      };
      this.editingPropertyValue = null;
      this.editingPropertyType = ContentType.SHORT_TEXT;
      this.editingPropertyLabel = null;
      this.notify();
    }
  }

  onPropertyTypeChange(): void {
    this.editingPropertyValue = this.utils.deconstructValue(this.utils.constructValue(this.editingPropertyValue, this.editingPropertyType), this.editingPropertyType);
  }

  getDisplayValue(value: any, type: ContentType): any {
    return this.utils.getDisplayValue(value, type);
  }

  getTypeDisplayValue(type: ContentType): any {
    return this.utils.translateContentType(type);
  }

  onDeleteItem(propertyName: string): void {
    if (this.value.hasOwnProperty(propertyName)) {
      delete this.value[propertyName];
      this.notify();
    }
  }

  openAddDialog(): void {
    this.dialog.open(InputObjectAddDialog, {
      autoFocus: false
    }).afterClosed().subscribe(({propertyName, propertyLabel}) => {
      if (!propertyName) {
        return;
      }

      if (this.value.hasOwnProperty(propertyName)) {
        return;
      }

      this.value[propertyName] = {
        value: null,
        label: propertyLabel,
        type: ContentType.SHORT_TEXT
      };

      this.notify();
    });
  }

  private notify(): void {
    this.data.onValueChange(this.value).then(updatedValue => {
      this.value = {...updatedValue};
    });
  }
}

@Component({
  selector: 'input-object-add-dialog',
  templateUrl: './input-object-add-dialog.html',
  styleUrls: ['./input-object-add-dialog.styl']
})
export class InputObjectAddDialog {
  propertyName: string = null;

  constructor(private dialogRef: MatDialogRef<InputObjectAddDialog>, private utils: UtilsService) {
  }

  confirm(): void {
    this.dialogRef.close({
      propertyName: this.utils.convertToCamelCase(this.propertyName),
      propertyLabel: this.propertyName
    });
  }
}
