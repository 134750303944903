import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.styl']
})
export class ForgotPasswordDialogComponent implements OnInit {

  forgotPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  constructor(private dialogRef: MatDialogRef<ForgotPasswordDialogComponent>) { }

  ngOnInit(): void {
  }

  getErrorMessage(field: AbstractControl): string {
    if (field.errors.required) {
      return $localize`The field is required`;
    }

    if (field.errors.email) {
      return $localize`The field is supposed to be a valid email address`;
    }

    return '';
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  recover(): void {
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.dialogRef.close(this.forgotPasswordForm.getRawValue().email);
  }

}
