import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import ISubject from '../../interfaces/ISubject';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RouteAction} from '../../definitions/RouteAction';
import {ContentType} from '../../definitions/ContentType';
import {SmartFormComponent} from '../../components/smart-form/smart-form.component';
import ISmartField from '../../interfaces/ISmartField';
import IDetailField from '../../interfaces/IDetailField';
import IResearch from '../../interfaces/IResearch';
import {CandidateEducationComponent} from '../../components/candidate-education/candidate-education.component';
import {Observable} from 'rxjs';
import IItem from '../../interfaces/IItem';
import {CandidateCourseComponent} from '../../components/candidate-course/candidate-course.component';
import {CandidateWorkExperienceComponent} from '../../components/candidate-work-experience/candidate-work-experience.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ImportDialogComponent} from '../../components/import-dialog/import-dialog.component';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import IClient from '../../interfaces/IClient';
import {AuthService} from '../../services/auth.service';
import * as nationalitiesData from '../../../assets/data/nationalities.json';
import * as moment from 'moment';


@Component({
  selector: 'app-subject-screen',
  templateUrl: './candidate-screen.component.html',
  styleUrls: ['./candidate-screen.component.styl']
})
export class CandidateScreenComponent implements OnInit {

  @ViewChild('addForm') addForm: SmartFormComponent;
  @ViewChild('editForm') editForm: SmartFormComponent;
  action: RouteAction = RouteAction.LOADING;
  routeAction = RouteAction;
  subjects: ISubject[] = [];
  subjectsTableColumnDefs = [
    { headerName: $localize`Email`, field: 'email', editable: false },
    { headerName: $localize`First Name`, field: 'firstName', editable: false },
    { headerName: $localize`Last Name`, field: 'lastName', editable: false },
    { headerName: $localize`Phone`, field: 'phone', editable: false },
    { headerName: $localize`Fiscal Code`, field: 'fiscalCode', editable: false },
    { headerName: $localize`Profession`, field: 'profession', editable: false },
    { headerName: $localize`Birth Date`, field: 'birthDate', editable: false, cellRenderer: 'dateRenderer' },
    { headerName: $localize`Birth Place`, field: 'phone', editable: false },
    { headerName: $localize`Gender`, field: 'gender', editable: false },
    { headerName: $localize`Client`, field: 'client' }
  ];
  subjectFormFieldsCreate = [
    { key: 'email', label: $localize`Email`, required: true, type: ContentType.EMAIL },
    { key: 'password', label: $localize`Password`, required: false, type: ContentType.PASSWORD },
    { key: 'firstName', label: $localize`First Name`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'lastName', label: $localize`Last Name`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'phone', label: $localize`Phone`, type: ContentType.PHONE },
    { key: 'fiscalCode', label: $localize`Fiscal Code`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'profession', label: $localize`Profession`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'birthDate', label: $localize`Birth Date`, required: false, type: ContentType.DATE },
    { key: 'birthPlace', label: $localize`Birth Place`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'nationality', label: $localize`Nationality`, required: false, type: ContentType.LIST, data: nationalitiesData.data.map(item => ({ value: item, label: item })) },
    { key: 'gender', label: $localize`Gender`, type: ContentType.LIST, data: [ { value: 'MALE', label: $localize`Male` }, { value: 'FEMALE', label: $localize`Female` }, { value: 'OTHER', label: $localize`Other` }] },
    { key: 'resume', label: $localize`Resume`, required: false, type: ContentType.FILE },
    { key: 'coverLetter', label: $localize`Cover Letter`, required: false, type: ContentType.FILE },
    { key: 'domicile', label: $localize`Domicile`, required: false, type: ContentType.ADDRESS },
    { key: 'residence', label: $localize`Residence`, required: false, type: ContentType.ADDRESS },
    this.authService.getClientField(),
    // { key: 'privacyAgreements', label: $localize`Privacy Agreements`, required: false, type: ContentType.OBJECT, defaultValue: {} },
    {
      key: 'education',
      label: $localize`Education`,
      required: false,
      type: ContentType.CRUD,
      options: {
        title: $localize`Education`,
        renderer: CandidateEducationComponent,
        fields: [
          { key: 'title', label: $localize`Title` },
          {
            key: 'level',
            label: $localize`Level`,
            type: ContentType.LIST,
            required: true,
            data: [],
            fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getEducationLevels().then(educationLevels => {
                resolve(educationLevels.map(educationLevel => ({
                  value: educationLevel,
                  label: educationLevel.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id
          },
          { key: 'startDate', label: $localize`Start Date`, type: ContentType.DATE },
          { key: 'endDate', label: $localize`End Date`, type: ContentType.DATE },
          { key: 'institute', label: $localize`Institute`, type: ContentType.LIST, data: [], fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getEducationInstitutes().then(educationInstitutes => {
                resolve(educationInstitutes.map(educationInstitute => ({
                  value: educationInstitute,
                  label: educationInstitute.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id },
          {
            key: 'category',
            label: $localize`Category`,
            type: ContentType.LIST,
            data: [],
            fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getEducationCategories().then(educationCategories => {
                resolve(educationCategories.map(educationCategory => ({
                  value: educationCategory,
                  label: educationCategory.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id,
            options: {
              groupBy: 'value.macro'
            }
          },
          { key: 'grade', label: $localize`Grade` },
          { key: 'withDistinction', label: $localize`With Distinction`, type: ContentType.BOOLEAN, displayAs: 'toggle' },
          { type: ContentType.BLANK },
          { key: 'description', label: $localize`Description`, type: ContentType.LONG_TEXT, colSize: '12' },
        ]
      },
      defaultValue: []
    },
    {
      key: 'courses',
      label: $localize`Courses`,
      required: false,
      type: ContentType.CRUD,
      options: {
        title: $localize`Courses`,
        renderer: CandidateCourseComponent,
        fields: [
          {
            key: 'course',
            label: $localize`Course`,
            type: ContentType.SHORT_TEXT,
            required: true
          },
          { key: 'startDate', label: $localize`Start Date`, type: ContentType.DATE },
          { key: 'endDate', label: $localize`End Date`, type: ContentType.DATE },
          { key: 'grade', label: $localize`Grade` },
          { key: 'withDistinction', label: $localize`With Distinction`, type: ContentType.BOOLEAN, displayAs: 'toggle' },
          { type: ContentType.BLANK },
          { key: 'description', label: $localize`Description`, type: ContentType.LONG_TEXT, colSize: '12' },
        ]
      },
      defaultValue: []
    },
    {
      key: 'workExperience',
      label: $localize`Work Experience`,
      required: false,
      type: ContentType.CRUD,
      options: {
        title: $localize`Work Experience`,
        renderer: CandidateWorkExperienceComponent,
        fields: [
          { key: 'position', label: $localize`Position` },
          {
            key: 'company',
            label: $localize`Company`,
            type: ContentType.SHORT_TEXT
          },
          { key: 'startDate', label: $localize`Start Date`, type: ContentType.DATE },
          { key: 'endDate', label: $localize`End Date`, type: ContentType.DATE },
          {
            key: 'workSector',
            label: $localize`Sector`,
            required: false,
            type: ContentType.LIST,
            data: [],
            fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getWorkSectors().then(workSectors => {
                resolve(workSectors.map(workSector => ({
                  value: workSector,
                  label: workSector.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id
          },
          {
            key: 'workProfession',
            label: $localize`Profession`,
            type: ContentType.LIST,
            data: [],
            fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getWorkProfessions().then(workProfessions => {
                resolve(workProfessions.map(workProfession => ({
                  value: workProfession,
                  label: workProfession.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id
          },
          {
            key: 'contractType',
            label: $localize`Contract Type`,
            type: ContentType.LIST,
            data: [],
            fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getWorkContractTypes().then(contractTypes => {
                resolve(contractTypes.map(contractType => ({
                  value: contractType,
                  label: contractType.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id
          },
          { key: 'annualGrossIncome', label: $localize`Annual Gross Income` },
          { key: 'annualNetIncome', label: $localize`Annual Net Income` },
          { key: 'description', label: $localize`Description`, type: ContentType.LONG_TEXT, colSize: '12' },
        ]
      },
      defaultValue: []
    },
    { key: 'information', label: $localize`Information`, required: false, type: ContentType.OBJECT, defaultValue: {} },
  ];
  researchColumnDefs = [
    { headerName: $localize`Title`, field: 'title' },
    { headerName: $localize`Anonymous`, field: 'anonymous', cellRenderer: 'checkboxRenderer' },
    { headerName: $localize`Job`, field: 'job.title' },
    { headerName: $localize`Groups`, field: 'groups', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 },
    { headerName: $localize`Projects`, field: 'projects', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 },
    { headerName: $localize`Candidates`, field: 'candidates', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 },
    { headerName: $localize`Anonymous Candidates`, field: 'anonymousCandidates', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 }
  ];
  subjectFormFieldsEdit = this.subjectFormFieldsCreate.map((field: ISmartField) => {
    if (field.key === 'password') {
      field.required = false;
    }

    return field;
  });
  subjectDetailFields: IDetailField[] = [
    { key: 'email', label: $localize`Email address`, type: ContentType.EMAIL },
    { key: 'firstName', label: $localize`First Name`, type: ContentType.SHORT_TEXT },
    { key: 'lastName', label: $localize`Last Name`, type: ContentType.SHORT_TEXT },
    { key: 'phone', label: $localize`Phone`, type: ContentType.SHORT_TEXT },
    { key: 'fiscalCode', label: $localize`Fiscal Code`, type: ContentType.SHORT_TEXT },
    { key: 'profession', label: $localize`Profession`, type: ContentType.SHORT_TEXT },
    { key: 'birthDate', label: $localize`Birth Date`, type: ContentType.DATE },
    { key: 'resume', label: $localize`Resume`, type: ContentType.FILE },
    { key: 'coverLetter', label: $localize`Cover Letter`, type: ContentType.FILE },
    { key: 'age', label: $localize`Age`, type: ContentType.INTEGER, render: (data, record) => {
      if (!record.birthDate) {
        return null;
      }

      const dob = moment(record.birthDate);

      return moment().diff(dob, 'years');
      } },
    { key: 'birthPlace', label: $localize`Birth Place`, type: ContentType.SHORT_TEXT },
    { key: 'gender', label: $localize`Gender`, type: ContentType.SHORT_TEXT },
    // { key: 'education', label: $localize`Education`, type: ContentType.CRUD },
    // { key: 'workExperience', label: $localize`Work Experience`, type: ContentType.CRUD },
    // { key: 'information', label: $localize`Information`, type: ContentType.OBJECT }
  ];
  selectedNodes = [];
  subjectEntity: ISubject = null;
  researches: IResearch[] = [];

  constructor(private apiService: ApiService, private activatedRoute: ActivatedRoute, private router: Router, private dialog: MatDialog, public authService: AuthService) {
    this.onSubjectDelete = this.onSubjectDelete.bind(this);
    this.onSubjectEdit = this.onSubjectEdit.bind(this);
    this.onSubjectView = this.onSubjectView.bind(this);
  }

  ngOnInit(): void {
    if (this.activatedRoute.firstChild === null) {
      this.action = RouteAction.LISTING;
      this.fetchSubjects();
    } else {
      const snapshot = this.activatedRoute.firstChild.snapshot;

      if (snapshot.routeConfig.path.startsWith('add')) {
        this.goToAdd();
      } else if (snapshot.routeConfig.path.startsWith('view')) {
        this.goToView();
      } else if (snapshot.routeConfig.path.startsWith('edit')) {
        this.goToEdit();
      }
    }
  }

  fetchSubjects(): void {
    this.apiService.getSubjects().then((subjects: ISubject[]) => {
      this.subjects = subjects.filter(subject => this.authService.hasBoundingPermission('candidate', 'view', subject));
    });
  }

  save(): void {
    if (this.action === RouteAction.ADD) {
      if (this.addForm.form.invalid) {
        this.addForm.form.markAllAsTouched();
        return;
      }

      const subject: ISubject = this.addForm.form.getRawValue();

      this.apiService.createSubject(subject).then(() => {
        this.goToListing();
      });
    } else if (this.action === RouteAction.EDIT) {
      if (this.editForm.form.invalid) {
        this.editForm.form.markAllAsTouched();
        return;
      }

      const data: ISubject = {
        ...this.subjectEntity,
        ...this.editForm.form.getRawValue()
      };

      this.apiService.updateSubject(data.id, data).then((subject: ISubject) => {
        this.subjectEntity = subject;
        this.editForm.defaults = subject;
        this.editForm.setValue({
          ...subject,
          password: null
        });
      });
    }
  }

  reset(): void {
    if (this.action === RouteAction.EDIT) {
      this.editForm.reset();
    } else if (this.action === RouteAction.ADD) {
      this.addForm.reset();
    }
  }

  goToListing(): void {
    this.researches = [];
    this.fetchSubjects();
    this.router.navigateByUrl('/candidates');
    this.action = RouteAction.LISTING;
  }

  goToAdd(): void {
    this.router.navigateByUrl('/candidates/add');
    this.action = RouteAction.ADD;
  }

  goToEdit(data: ISubject = null): void {
    if (data === null) {
      this.apiService.getSubject(this.activatedRoute.firstChild.snapshot.params.id).then((subject: ISubject) => {
        this.subjectEntity = subject;
        this.action = RouteAction.EDIT;
      });
    } else {
      this.subjectEntity = data;
      this.router.navigateByUrl('/candidates/edit/' + data.id);
      this.action = RouteAction.EDIT;
    }
  }

  goToView(data: ISubject = null): void {
    if (data === null) {
      this.apiService.getSubject(this.activatedRoute.firstChild.snapshot.params.id).then((subject: ISubject) => {
        this.subjectEntity = subject;
        this.fetchResearches();
        this.action = RouteAction.VIEW;
      });
    } else {
      this.subjectEntity = data;
      this.fetchResearches();
      this.router.navigateByUrl('/candidates/view/' + data.id);
      this.action = RouteAction.VIEW;
    }
  }

  goBack(): void {
    switch (this.action) {
      case RouteAction.ADD:
      case RouteAction.EDIT:
      case RouteAction.VIEW:
        this.goToListing();
        break;
      default:
    }
  }

  onSubjectDelete(ev: any): void {
    this.apiService.deleteSubject(ev.data.id).then(() => {
      this.fetchSubjects();
    });
  }

  onSubjectEdit(ev: any): void {
    this.goToEdit(ev.data);
  }

  onSubjectView(ev: any): void {
    this.goToView(ev.data);
  }

  onResearchView(ev: any): void {
    this.router.navigateByUrl(`/research/${ev.data.id}/candidate/${this.subjectEntity.id}`, {
      state: {
        from: 'candidate',
        id: this.subjectEntity.id
      }
    });
  }

  onSelectionChanged(event: any): void {
    this.selectedNodes = event.api.getSelectedRows();
  }

  deleteSelected(): void {
    const promises = [];

    this.selectedNodes.forEach(node => {
      promises.push(this.apiService.deleteSubject(node.id));
    });

    Promise.all(promises).then(results => {
      this.fetchSubjects();
      this.selectedNodes = [];
    }).catch();
  }

  importData(): void {
    this.dialog.open(ImportDialogComponent, {
      autoFocus: false,
      width: '90vw',
      height: '70vh',
      maxWidth: '400px',
      maxHeight: '250px'
    }).afterClosed().subscribe(refresh => {
      if (refresh) {
        this.fetchSubjects();
      }
    });
  }

  openInviteDialog(): void {
    this.dialog.open(CandidateScreenInviteDialogComponent, {
      width: '100%',
      maxWidth: '400px',
      data: {
        candidate: this.subjectEntity
      }
    });
  }

  private fetchResearches(): void {
    this.apiService.getResearchesByCandidate(this.subjectEntity)
      .then(researches => this.researches = researches).catch();
  }

  private fetchClientsAsItems(): Promise<IItem[]> {
    return new Promise<IItem[]>(resolve => {
      this.apiService.getClients().then(clients => resolve(clients.map(client => ({
        value: client.code,
        label: client.name
      }))));
    });
  }
}

@Component({
  selector: 'app-subject-screen-invite-dialog',
  templateUrl: './candidate-screen-invite-dialog.component.html',
  styleUrls: ['./candidate-screen-invite-dialog.component.styl']
})
export class CandidateScreenInviteDialogComponent implements OnInit {
  selected: IResearch = null;
  searchValue = '';
  researches: IResearch[] = [];
  filteredResearches: IResearch[] = [];

  constructor(public dialogRef: MatDialogRef<CandidateScreenInviteDialogComponent>, private api: ApiService, @Inject(MAT_DIALOG_DATA) public data: { candidate: ISubject }, private snackbar: MatSnackBar) {
    this.api.getResearches().then(researches => {
      this.researches = researches;
      this.filteredResearches = [...this.researches];
    }).catch(console.error);
  }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    this.api.connectCandidate(this.selected.id, this.data.candidate.id).then(() => {
      this.snackbar.open($localize`Candidate invited to the research`, undefined, { duration: 2000 });
      this.dialogRef.close();
    }).catch(console.error);
  }

  filterResearches(): void {
    this.filteredResearches = this.researches.filter(research => research.title.toLowerCase().startsWith(this.searchValue.toLowerCase()));
  }
}


