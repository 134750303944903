<h1 mat-dialog-title i18n>Invite Candidate</h1>
<div mat-dialog-content>
  <mat-form-field appearance="outline" class="full-width">
    <mat-label i18n>Research</mat-label>
    <mat-select [(ngModel)]="selected">
      <div class="spacing-inner-3">
        <mat-form-field class="full-width">
          <input matInput placeholder="Search..." i18n-placeholder [(ngModel)]="searchValue" (ngModelChange)="filterResearches()" autocomplete="off" />
        </mat-form-field>
      </div>

      <mat-option *ngFor="let research of filteredResearches" [value]="research">
        {{research.title}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()" i18n>Close</button>
  <button mat-button (click)="confirm()" i18n>Confirm</button>
</div>
