<h2 mat-dialog-title i18n>Start a Research Study for survey {{data.survey.title}}</h2>
<mat-dialog-content>
  <mat-tab-group headerPosition="top" animationDuration="0ms">
    <mat-tab class="spacing-inner-top-10" label="General" i18n-label>
      <div class="spacing-inner-top-10">
        <app-smart-form #smartForm [fields]="fields" colSize="6"></app-smart-form>
      </div>
    </mat-tab>
    <mat-tab label="Jobs" i18n-label>
      <div class="spacing-inner-top-10">
        <h2 i18n>Select a job</h2>
        <p><small i18n>Connect to a job by clicking on the row. To de-select a row, hold down Control and click on the selected row.</small></p>
        <app-smart-table #jobsTable [columnDefs]="jobColumnDefs" [hideActions]="true" [rowData]="jobs" rowSelection="single" [gridHeight]="500"></app-smart-table>
      </div>
    </mat-tab>
    <mat-tab label="Candidates" i18n-label>
      <div class="spacing-inner-top-10">
        <h2 i18n>Select candidates</h2>
        <p><small i18n>Connect to one or more candidates by clicking on the row. To de-select a row, hold down Control and click on the selected row you wish to de-select.</small></p>
        <app-smart-table #candidatesTable [columnDefs]="candidateColumnDefs" [hideActions]="true" [rowData]="candidates" rowSelection="multiple" [gridHeight]="500" permisionGroup="candidate"></app-smart-table>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close i18n (click)="cancel()">Cancel</button>
  <button mat-button i18n (click)="askForConfirmation()" [disabled]="smartForm.form.invalid">Conferma</button>
</mat-dialog-actions>
