import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import IElement from '../../interfaces/IElement';
import IQuestionElement from '../../interfaces/IQuestionElement';
import IQuestionAnswer from '../../interfaces/IQuestionAnswer';
import {QuestionType} from '../../definitions/QuestionType';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {UtilsService} from '../../services/utils.service';

@Component({
  selector: 'app-process-question-element',
  templateUrl: './process-question-element.component.html',
  styleUrls: ['./process-question-element.component.styl']
})
export class ProcessQuestionElementComponent implements OnInit, OnChanges {

  @Input() element: any;
  @Input() rawValue: any = {};
  @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() loaded: EventEmitter<any> = new EventEmitter<any>();
  value: any = null;
  questionType = QuestionType;

  constructor(private utils: UtilsService) { }

  ngOnInit(): void {
    this.value = this.utils.getRawValue(this.element, this.rawValue);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.element) {
      this.value = this.utils.getRawValue(this.element, this.rawValue);
    }
  }

  checkboxChange(value: any): void {
    if (Array.isArray(this.value)) {
      const index = this.value.indexOf(value);

      if (index > -1) {
        this.value.splice(index, 1);
      } else {
        this.value.push(value);
      }
    }

    this.changed(this.value);
  }

  changed(value: any): void {
    this.valueChanged.emit({
      value: this.value,
      element: this.element
    });
  }

  getLabel(): string {
    const required = this.element && this.element.child.required === true;
    if (this.element.child.multiple === true) {
      return required ? $localize`Choose one or multiple` : $localize`Choose none or multiple`;
    }

    return required ? $localize`Choose one` : $localize`Choose none or one`;
  }

  getAnswersByGroup(group: string): IQuestionAnswer[] {
    return this.element.child.answers.filter(answer => answer.group === group);
  }

  getColumns(): any[] {
    return (this.element.child.extras && (this.element.child.extras.columns || [])) || [];
  }

  matrixChange({checked}: any, answerCode: string, columnCode: string): void {
    if (!this.value.hasOwnProperty(columnCode)) {
      return;
    }

    let index = this.value[columnCode].indexOf(answerCode);

    if (index === -1 && (checked === true || checked === undefined)) {
      this.value[columnCode].push(answerCode);
    } else if (index > -1 && checked === false) {
      this.value[columnCode].splice(index, 1);
    }

    if (checked === undefined) {
      for (const key in this.value) {
        if (!this.value.hasOwnProperty(key) || key === columnCode) {
          continue;
        }

        index = this.value[key].indexOf(answerCode);

        if (index > -1) {
          this.value[key].splice(index, 1);
        }
      }
    }

    this.changed(this.value);
  }

  rankingDrop(event: CdkDragDrop<string[]>): void {
    // moveItemInArray(this.element.child.answers, event.previousIndex, event.currentIndex);
  }

  reset(rawValue: any = {}): void {
    this.value = this.utils.getRawValue(this.element, rawValue);
  }

}
