import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-date-cell-renderer',
  templateUrl: './date-cell-renderer.component.html',
  styleUrls: ['./date-cell-renderer.component.styl']
})
export class DateCellRendererComponent {

  params: any;

  agInit(params: any): void {
    this.params = params;
  }

}
