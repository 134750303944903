import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {MatDialog} from '@angular/material/dialog';
import {SurveyDialogComponent} from '../../components/survey-dialog/survey-dialog.component';
import ISurveyGroup from '../../interfaces/ISurveyGroup';
import IPair from '../../interfaces/IPair';
import ISurvey from '../../interfaces/ISurvey';
import {RouteAction} from '../../definitions/RouteAction';
import {ActivatedRoute, Router} from '@angular/router';
import ISmartField from '../../interfaces/ISmartField';
import {ContentType} from '../../definitions/ContentType';
import * as languages from '../../../assets/data/languages.json';
import * as questionTypes from '../../../assets/data/question-types.json';
import IDetailField from '../../interfaces/IDetailField';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {SurveyQuestionDialogComponent} from '../../components/survey-question-dialog/survey-question-dialog.component';
import {ElementType} from '../../definitions/ElementType';
import {QuestionAnswersDialogComponent} from '../../components/question-answers-dialog/question-answers-dialog.component';
import {QuestionType} from '../../definitions/QuestionType';
import IGroupElement from '../../interfaces/IGroupElement';
import IElement from '../../interfaces/IElement';
import {UtilsService} from '../../services/utils.service';
import {RunSurveyCellRendererComponent} from '../../components/run-survey-cell-renderer/run-survey-cell-renderer.component';
import {RunSurveyDialogComponent} from '../../components/run-survey-dialog/run-survey-dialog.component';
import IResearch from '../../interfaces/IResearch';
import * as moment from 'moment';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-test-survey-screen',
  templateUrl: './test-survey-screen.component.html',
  styleUrls: ['./test-survey-screen.component.styl']
})
export class TestSurveyScreenComponent implements OnInit {

  action: RouteAction = RouteAction.LOADING;
  routeAction = RouteAction;
  elementType = ElementType;
  questionType = QuestionType;
  productTableColumnDefs = [
    { headerName: '', field: '', cellRenderer: 'runCellComponent', width: 50, filter: false, sortable: false, suppressMenu: true, pinned: 'left' },
    { headerName: $localize`Type`, field: 'type' },
    { headerName: $localize`Title`, field: 'title' },
    { headerName: $localize`Language`, field: 'language' },
    { headerName: $localize`Group`, field: 'group.name' },
    { headerName: $localize`Display Mode`, field: 'displayMode' },
    { headerName: $localize`Anonymous`, field: 'anonymous', cellRenderer: 'checkboxRenderer' },
    { headerName: $localize`Stard Date`, field: 'startDate', cellRenderer: 'dateRenderer' },
    { headerName: $localize`End Date`, field: 'endDate', cellRenderer: 'dateRenderer' },
    { headerName: $localize`Parent`, field: 'parent.title' },
    { headerName: $localize`Last Update`, field: 'updatedAt', valueFormatter: params => moment(params.value).format('YYYY-MM-DD HH:mm:ss') }
  ];
  researchColumnDefs = [
    { headerName: $localize`Title`, field: 'title' },
    { headerName: $localize`Anonymous`, field: 'anonymous', cellRenderer: 'checkboxRenderer' },
    { headerName: $localize`Job`, field: 'job.title' },
    { headerName: $localize`Groups`, field: 'groups', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 },
    { headerName: $localize`Projects`, field: 'projects', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 },
    { headerName: $localize`Candidates`, field: 'candidates', valueGetter: params => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 }
  ];
  productFrameworkComponents = {
    runCellComponent: RunSurveyCellRendererComponent
  };
  productGridContext = {
    runSurvey: (survey: ISurvey) => this.runSurvey(survey)
  };
  fieldsDetail: IDetailField[] = [
    { key: 'type', label: $localize`Type`, tab: 'general' },
    { key: 'title', label: $localize`Title`, type: ContentType.SHORT_TEXT, tab: 'general' },
    { key: 'language', label: $localize`Language`, type: ContentType.LIST, tab: 'general' },
    { key: 'group', label: $localize`Group`, type: ContentType.LIST, tab: 'general', render: (group: ISurveyGroup) => group ? group.name : '' },
    { key: 'startDate', label: $localize`Start Date`, type: ContentType.DATE, tab: 'general' },
    { key: 'endDate', label: $localize`End Date`, type: ContentType.DATE, tab: 'general' },
    { key: 'startMessage', label: $localize`Start Message`, type: ContentType.LONG_TEXT, tab: 'general' },
    { key: 'endMessage', label: $localize`End Message`, type: ContentType.LONG_TEXT, tab: 'general' },
    { key: 'anonymous', label: $localize`Anonymous`, type: ContentType.BOOLEAN, tab: 'general' },
    { key: 'displayMode', label: $localize`Display Mode`, type: ContentType.LIST, tab: 'presentation' },
    { key: 'timeLimit', label: $localize`Time Limit (minutes)`, type: ContentType.INTEGER, tab: 'presentation' },
    { key: 'answerTimeLimit', label: $localize`Answer Time Limit (minutes)`, type: ContentType.INTEGER, tab: 'presentation' },
    { key: 'showProgress', label: $localize`Show Progress`, type: ContentType.BOOLEAN, tab: 'presentation'},
    { key: 'allowAnswerChange', label: $localize`Allow Answer Change`, type: ContentType.BOOLEAN, tab: 'presentation' },
    { key: 'nextStepConfirmation', label: $localize`Next Step Confirmation`, type: ContentType.BOOLEAN, tab: 'presentation' },
  ];
  surveys: ISurvey[] = [];
  selectedSurvey: ISurvey = null;
  selectedGroup: IElement<IGroupElement> = null;
  selectedGroupIndex = -1;
  elements: IElement<any>[] = [];
  researches: IResearch[] = [];

  constructor(private apiService: ApiService, private dialog: MatDialog, private activatedRoute: ActivatedRoute, private router: Router, private utils: UtilsService, public authService: AuthService) {
    this.fetchGroupsAsPair = this.fetchGroupsAsPair.bind(this);
    this.saveSurveyGroups = this.saveSurveyGroups.bind(this);
    this.onRowView = this.onRowView.bind(this);
    this.runSurvey = this.runSurvey.bind(this);
  }

  ngOnInit(): void {
    if (this.activatedRoute.firstChild === null) {
      this.action = RouteAction.LISTING;
      this.fetchProducts();
    } else {
      const snapshot = this.activatedRoute.firstChild.snapshot;

      if (snapshot.routeConfig.path.startsWith('view')) {
        this.goToView();
      }
    }
  }

  goToView(): void {
    if (this.selectedSurvey === null) {
      this.apiService.getSurvey(this.activatedRoute.firstChild.snapshot.params.id).then((survey: ISurvey) => {
        this.selectedSurvey = survey;
        this.fetchResearches();
        this.elements = this.selectedSurvey.elements;
        this.action = RouteAction.VIEW;
      }).catch();
    } else {
      this.router.navigateByUrl('/tests-surveys/view/' + this.selectedSurvey.id);
      this.action = RouteAction.VIEW;
    }
  }

  fetchProducts(): void {
    this.apiService.getSurveys().then((surveys: ISurvey[]) => {
      this.surveys = surveys.filter(survey => this.authService.hasBoundingPermission('survey', 'view', survey));
    }).catch();
  }

  openSurveyDialog(survey: ISurvey = null): void {
    let title = $localize`New Survey`;

    if (survey !== null) {
      title = $localize`Edit Survey`;
    }

    const dialogRef = this.dialog.open(SurveyDialogComponent, {
      data: {
        title,
        survey
      },
      width: '90%'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.fetchProducts();
    });
  }

  openGroup(index: number): void {
    this.selectedGroupIndex = index;
    this.selectedGroup = this.selectedSurvey.elements[this.selectedGroupIndex];
    this.elements = this.selectedGroup.child.children || [];
  }

  fetchGroupsAsPair(): Promise<IPair[]> {
    return new Promise<IPair[]>((resolve: any, reject: any) => {
      this.apiService.getSurveyGroups().then((groups: ISurveyGroup[]) => {
        resolve(groups.map(group => ({
          key: group.id,
          label: group.name
        })));
      });
    });
  }

  goBackFromGroup(): void {
    this.selectedGroupIndex = -1;
    this.selectedGroup = null;
    this.elements = this.selectedSurvey.elements;
  }

  saveSurveyGroups(pairs: IPair[], originalPairs: IPair[]): Promise<void> {
    return new Promise<void>((resolve: any, reject: any) => {
      const surveyGroups: ISurveyGroup[] = [];
      const deleteSurveyGroups: ISurveyGroup[] = [];

      pairs.filter((pair: IPair) => pair.key === null).forEach((pair: IPair) => {
        surveyGroups.push({
          id: null,
          name: pair.label,
          parent: null
        });
      });

      const pairKeys = pairs.filter(pair => pair.key !== null).map(pair => pair.key);

      originalPairs.filter(pair => pair.key !== null && !pairKeys.includes(pair.key)).forEach(pair => {
        deleteSurveyGroups.push({
          id: pair.key,
          name: pair.label,
          parent: null
        });
      });

      this.apiService.multipleSaveSurveyGroups(surveyGroups, deleteSurveyGroups).then(() => {
        resolve();
      }).catch(() => reject());
    });
  }

  onRowView(ev: any): void {
    this.selectedSurvey = ev.data;
    this.fetchResearches();
    this.elements = this.selectedSurvey.elements;
    this.goToView();
  }

  goBack(): void {
    switch (this.action) {
      case RouteAction.ADD:
      case RouteAction.EDIT:
      case RouteAction.VIEW:
        this.goToListing();
        break;

      default:
    }
  }

  goToListing(): void {
    this.fetchProducts();
    this.researches = [];
    this.router.navigateByUrl('/tests-surveys');
    this.action = RouteAction.LISTING;
  }

  dropElement(event: CdkDragDrop<string[]>): void {
    if (this.selectedGroupIndex > -1) {
      moveItemInArray(this.selectedSurvey.elements[this.selectedGroupIndex].child.children, event.previousIndex, event.currentIndex);
    } else {
      moveItemInArray(this.selectedSurvey.elements, event.previousIndex, event.currentIndex);
    }

    this.selectedSurvey.elements[event.previousIndex].updatedAt = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    this.selectedSurvey.elements[event.currentIndex].updatedAt = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');

    this.updateSurvey(this.selectedSurvey);
  }

  openQuestionDialog(index: number = -1): void {
    const dialogRef = this.dialog.open(SurveyQuestionDialogComponent, {
      data: {
        title: index === -1 ? $localize`Create New Question` : $localize`Edit Question`,
        element: this.getElement(index),
        allowGroup: this.selectedGroupIndex === -1
      },
      width: '90%'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (!result) {
        return;
      }

      const survey: ISurvey = this.selectedSurvey;

      if (this.selectedGroupIndex > -1 && index > -1) {
        survey.elements[this.selectedGroupIndex].child.children[index] = result;
        survey.elements[this.selectedGroupIndex].updatedAt = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      } else if (this.selectedGroupIndex > -1 && index === -1) {
        result.order = survey.elements[this.selectedGroupIndex].child.children.length;
        survey.elements[this.selectedGroupIndex].child.children.push(result);
        survey.elements[this.selectedGroupIndex].updatedAt = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      } else if (this.selectedGroupIndex === -1 && index > -1) {
        survey.elements[index] = result;
        survey.elements[index].updatedAt = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      } else {
        result.order = survey.elements.length;
        result.updatedAt = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        survey.elements.push(result);
      }

      this.updateSurvey(survey);
    });
  }

  deleteElement(index: number): void {
    if (this.selectedGroupIndex > -1) {
      this.selectedSurvey.elements[this.selectedGroupIndex].child.children.splice(index, 1);
    } else {
      this.selectedSurvey.elements.splice(index, 1);
    }

    this.updateSurvey(this.selectedSurvey);
  }

  duplicateElement(index: number): void {
    const element = {...this.getElement(index), updatedAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')};
    element.code += '.COPY' + (this.elements.length + 1);

    if (this.selectedGroupIndex > -1) {
      this.selectedSurvey.elements[this.selectedGroupIndex].child.children.splice(index + 1, 0, element);
    } else {
      this.selectedSurvey.elements.splice(index + 1, 0, element);
    }

    this.updateSurvey(this.selectedSurvey);
  }

  saveSurvey(): void {
    this.apiService.updateSurvey(this.selectedSurvey.id, this.selectedSurvey).then(survey => {
      this.selectedSurvey = survey;
    }).catch();
  }

  openAnswersDialog(index: number): void {
    const dialogRef = this.dialog.open(QuestionAnswersDialogComponent, {
      data: {
        element: this.getElement(index)
      },
      width: '90%'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (!result) {
        return;
      }

      const survey: ISurvey = this.selectedSurvey;

      if (this.selectedGroupIndex > -1) {
        survey.elements[this.selectedGroupIndex].updatedAt = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        survey.elements[this.selectedGroupIndex].child.children[index].child.answers = result.data;
        survey.elements[this.selectedGroupIndex].child.children[index].child.extras = {
          ...survey.elements[this.selectedGroupIndex].child.children[index].child.extras,
          ...result.extras
        };
      } else {
        survey.elements[index].updatedAt = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        survey.elements[index].child.answers = result.data;
        survey.elements[index].child.extras = {
          ...survey.elements[index].child.extras,
          ...result.extras
        };
      }

      this.updateSurvey(survey);
    });
  }

  hasAnswers(type: QuestionType): boolean {
    return ![QuestionType.DATE_INPUT, QuestionType.FILE_UPLOAD, QuestionType.LONG_ANSWER, QuestionType.YES_NO, QuestionType.SHORT_ANSWER].includes(type);
  }

  onRowDelete(ev: any): void {
    this.apiService.deleteSurvey(ev.data.id).then(() => this.fetchProducts());
  }

  onRowEdit(ev: any): void {
    this.openSurveyDialog(ev.data);
  }

  runSurvey(survey: ISurvey): void {
    const dialogRef = this.dialog.open(RunSurveyDialogComponent, {
      data: {
        survey,
      },
      width: '98vw'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== null) {
      }
    });
  }

  getElement(index: number): IElement<any> {
    if (index === -1) {
      return null;
    }

    return this.getElements()[index];
  }

  getElements(): IElement<any>[] {
    if (this.selectedGroupIndex > -1) {
      return this.selectedSurvey.elements[this.selectedGroupIndex].child.children;
    }

    return this.selectedSurvey.elements;
  }

  onResearchView(ev: any): void {
    this.router.navigateByUrl(`/research/${ev.data.id}/survey/${this.selectedSurvey.id}`, {
      state: {
        from: 'survey',
        id: this.selectedSurvey.id
      }
    });
  }

  onResearchDelete(ev: any): void {
    this.apiService.deleteResearch(ev.data.id).then(() => this.fetchResearches()).catch();
  }

  private updateSurvey(survey: ISurvey): void {
    this.apiService.updateSurvey(survey.id, survey).then(updatedSurvey => {
      const surveyIndex = this.surveys.findIndex(item => item.id === updatedSurvey.id);
      if (surveyIndex > -1) {
        this.surveys[surveyIndex] = updatedSurvey;
      }

      this.selectedSurvey = updatedSurvey;

      if (this.selectedGroupIndex > -1) {
        this.selectedGroup = this.selectedSurvey.elements[this.selectedGroupIndex];
        this.elements = this.selectedSurvey.elements[this.selectedGroupIndex].child.children;
      } else {
        this.elements = this.selectedSurvey.elements;
      }
    });
  }

  private fetchResearches(): void {
    this.apiService.getResearchesBySurvey(this.selectedSurvey)
      .then(researches => this.researches = researches.filter(research => this.authService.hasBoundingPermission('research', 'view', research))).catch();
  }
}
