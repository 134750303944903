import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import ISmartField from '../../../interfaces/ISmartField';
import {SmartFormComponent} from '../../smart-form/smart-form.component';

@Component({
  selector: 'app-smart-vudlist-dialog',
  templateUrl: './smart-vudlist-dialog.component.html',
  styleUrls: ['./smart-vudlist-dialog.component.styl']
})
export class SmartVUDListDialogComponent implements OnInit {

  @ViewChild('formComponent') formComponent: SmartFormComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { fields: ISmartField[], item: any, title: string }, private dialogRef: MatDialogRef<SmartVUDListDialogComponent>) { }

  ngOnInit(): void {
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    if (this.formComponent.form.invalid) {
      return;
    }

    this.dialogRef.close(this.formComponent.form.getRawValue());
  }

}
