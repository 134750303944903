<app-process-layout [research]="research" [client]="client">
  <h1 class="spacing-top-20 text-upper text-bold" i18n>Sign up as a candidate to participate for this survey/test</h1>
  <p class="opacity-50 font-size-9 text-light spacing-bottom-0 spacing-inner-bottom-0" i18n>Please fill in all the information required in order to correctly signing up for a candidate account. In case you already have an account registered with us as a candidate (even for previous surveys or tests), please <a routerLink="/process/login">click here and signin to your account</a>.</p>

  <mat-card class="spacing-top-10 spacing-bottom-10">
    <mat-card-content>
      <app-smart-form #createForm [fields]="fieldsCreateFiltered" colSize="6"></app-smart-form>
    </mat-card-content>
    <mat-card-actions class="text-center">
      <a mat-button routerLink="/process/login" i18n>I ALREADY HAVE AN ACCOUNT</a>
      <button mat-button i18n color="primary" (click)="create()" [disabled]="!createForm || createForm.form.invalid">CONFIRM AND SIGN UP</button>
    </mat-card-actions>
  </mat-card>
</app-process-layout>
