import {Injectable} from '@angular/core';
import {QuestionType} from '../definitions/QuestionType';
import {ContentType} from '../definitions/ContentType';
import * as moment from 'moment';
import {Store} from "@ngrx/store";
import IStore from "../interfaces/IStore";
import {take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private store: Store<IStore>) { }

  getStoreState(): IStore {
    let state: IStore;

    this.store.pipe(take(1)).subscribe(s => state = s);

    return state;
  }

  multiLevelSearch(arr: any[], compare: (obj: any) => boolean, getChildren: (obj: any) => any[]|null, level: number = 0, parent: any = null): { level: number, index: number, obj: any, parent: any }|null {
    for (let a = 0; a < arr.length; a++) {
      const item = arr[a];
      const children = getChildren(item);

      if (children !== null) {
        const result = this.multiLevelSearch(children, compare, getChildren, level + 1, item);

        if (result !== null) {
          return result;
        } else if (compare(item)) {
          return {
            level,
            index: a,
            obj: item,
            parent
          };
        }
      } else if (compare(item)) {
        return {
          level,
          index: a,
          obj: item,
          parent
        };
      }
    }

    return null;
  }

  eqArrays(arr1: any[], arr2: any[]): boolean {
    for (const item of arr1) {
      if (arr2.indexOf(item) === -1) {
        return false;
      }
    }

    return true;
  }

  containsArrays(arr1: any[], arr2: any[]): boolean {
    const existing = [];

    for (const item of arr1) {
      if (arr2.indexOf(item) === -1) {
        return false;
      }

      existing.push(item);
    }

    for (const item of arr2) {
      if (existing.indexOf(item) > -1) {
        continue;
      }

      if (arr1.indexOf(item) === -1) {
        return false;
      }
    }

    return true;
  }

  getRawValue(element: any, rawValue: any = {}): any {
    let value = rawValue[element.code] || null;

    if (value !== null) {
      return value;
    }

    const multiple = element.child.multiple === true;

    switch (element.child.type) {
      case QuestionType.ARRAY_MATRIX:
        const columns = (element.child.extras && (element.child.extras.columns || [])) || [];
        value = columns.reduce((obj, curr) => ({
          ...obj,
          [curr.code]: []
        }), {});
        break;

      case QuestionType.CHOICE:
      case QuestionType.FILE_UPLOAD:
      case QuestionType.CHOICE_DROPDOWN:
        value = multiple ? [] : null;
        break;

      default:
        value = null;
    }

    return value;
  }

  constructValue(value: any, type: ContentType, fallbackValue?: any): any {
    switch (type) {
      case ContentType.BOOLEAN:
        if (value === true) {
          return true;
        }

        if (value === 1) {
          return true;
        }

        if (typeof value === 'string' && value.toLowerCase() === 'yes') {
          return true;
        }

        if (typeof value === 'string' && value.toLowerCase() === 'y') {
          return true;
        }

        return fallbackValue || false;

      case ContentType.NUMBER:
        value = Number(value);

        return isNaN(value) ? fallbackValue : value;

      case ContentType.INTEGER:
        value = Number(value);

        return Number.isInteger(value) ? value : fallbackValue;

      case ContentType.DATE:
        value = moment(value);

        return value.isValid() ? value : fallbackValue;

      case ContentType.LIST:
        if (typeof value === 'string') {
          value = value.split(',').map(item => item.trim());
        }

        return Array.isArray(value) ? value : fallbackValue;

      default:
        return value || fallbackValue;
    }
  }

  deconstructValue(value: any, type: ContentType, fallbackValue?: any): any {
    switch (type) {
      case ContentType.LIST:
        return Array.isArray(value) ? value.join(',') : fallbackValue;

      default:
        return value || fallbackValue;
    }
  }

  getDefaultValueByType(type: ContentType): any {
    switch (type) {
      case ContentType.DECIMAL:
      case ContentType.NUMBER:
        return 0.0;

      case ContentType.INTEGER:
        return 0;

      case ContentType.DATE:
        return null;

      case ContentType.BOOLEAN:
        return false;

      case ContentType.LIST:
        return null;

      case ContentType.MULTILIST:
        return [];

      default:
        return null;
    }
  }

  getDisplayValue(value: any, type: ContentType): any {
    switch (type) {
      case ContentType.DATE:
        if (moment.isMoment(value)) {
          return value.format('DD/MM/YYYY');
        }

        const momentValue = moment(value);

        if (!momentValue.isValid()) {
          return '';
        }

        return momentValue.format('DD/MM/YYYY');

      case ContentType.BOOLEAN:
        return value === true ? $localize`Yes` : $localize`No`;

      case ContentType.MULTILIST:
        return Array.isArray(value) ? value.join(',') : '';

      default:
        return value;
    }
  }

  translateContentType(contentType: ContentType): string {
    switch (contentType) {
      case ContentType.MULTILIST:
        return $localize`List`;
      case ContentType.SHORT_TEXT:
        return $localize`Short Text`;
      case ContentType.LIST:
        return $localize`List`;
      case ContentType.BOOLEAN:
        return $localize`Boolean`;
      case ContentType.DATE:
        return $localize`Date`;
      case ContentType.INTEGER:
        return $localize`Integer`;
      case ContentType.NUMBER:
        return $localize`Number`;
      case ContentType.ADDRESS:
        return $localize`Address`;
      case ContentType.BLANK:
        return $localize`Blank`;
      case ContentType.CRUD:
        return $localize`CRUD`;
      case ContentType.DECIMAL:
        return $localize`Decimal`;
      case ContentType.EMAIL:
        return $localize`Email`;
      case ContentType.FILE:
        return $localize`File`;
      case ContentType.HIDDEN:
        return $localize`Hidden`;
      case ContentType.LONG_TEXT:
        return $localize`Long Text`;
      case ContentType.MAP:
        return $localize`Hash Map`;
      case ContentType.OBJECT:
        return $localize`Object`;
      case ContentType.PASSWORD:
        return $localize`Password`;
      case ContentType.PHONE:
        return $localize`Phone`;
      case ContentType.RICH_TEXT:
        return $localize`Rich Text`;
      default:
        return ContentType[contentType].replace('_', ' ').replace(/^([a-z])|\s+([a-z])/g, ($1) => {
          return $1.toUpperCase();
        });
    }
  }

  convertToCamelCase(input: string): string {
    const split = (input + '').split(' ');

    return split.map((item, index) => {
      item = item.toLowerCase();

      if (index === 0) {
        return item;
      }

      return item.substring(0, 1).toUpperCase() + item.substring(1, item.length);
    }).join('');
  }
}

