<h2 mat-dialog-title>{{title}}</h2>

<mat-list role="list">
  <mat-list-item role="listitem" *ngFor="let item of items; let i = index">
    <input type="text" mat-line [(ngModel)]="item.label" />
    <button mat-icon-button (click)="delete(item, i)">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-list-item>
</mat-list>

<div mat-dialog-actions align="end">
  <button mat-icon-button (click)="add()">
    <mat-icon>add_box</mat-icon>
  </button>
  <button mat-button (click)="cancel()" i18n>Cancel</button>
  <button mat-button (click)="save()" cdkFocusInitial i18n>Confirm</button>
</div>
