<app-standard-layout>
  <div class="spacing-inner-10">

    <!-- LISTING -->
    <mat-card *ngIf="action === routeAction.LISTING">
      <mat-card-header>
        <mat-card-title i18n>Clients</mat-card-title>
        <mat-card-subtitle i18n>On current page you are able to view, edit, delete and create any client account further used to complete or view tests and surveys results</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-5">
          <button mat-stroked-button color="primary" (click)="goToAdd()" *ngIf="authService.hasPermission(['client_create'])">
            <mat-icon class="spacing-right-5">add</mat-icon>
            <span i18n>ADD NEW CLIENT</span>
          </button>
          <button mat-stroked-button color="warn" [ngClass]="{'spacing-left-5': authService.hasPermission(['client_create'])}" *ngIf="authService.hasPermission(['client_delete'])" [disabled]="selectedClientRows === 0">
            <mat-icon class="spacing-right-5">delete</mat-icon>
            <span i18n>DELETE SELECTED</span>
          </button>
        </div>
        <app-smart-table [rowData]="clients" [columnDefs]="clientsTableColumnDefs" (rowDelete)="onClientDelete($event)" (rowEdit)="onClientEdit($event)" (rowView)="onClientView($event)" permissionGroup="client"></app-smart-table>
      </mat-card-content>
    </mat-card>

    <!-- ADD -->
    <mat-card *ngIf="action === routeAction.ADD">
      <mat-card-header>
        <mat-card-title i18n>Add Client</mat-card-title>
        <mat-card-subtitle i18n>Clients</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
          <button mat-stroked-button class="spacing-left-5" (click)="reset()">
            <mat-icon class="spacing-right-5">refresh</mat-icon>
            <span i18n>RESET</span>
          </button>
          <button mat-stroked-button color="primary" class="spacing-left-5" (click)="save()">
            <mat-icon class="spacing-right-5">save</mat-icon>
            <span i18n>SAVE</span>
          </button>
        </div>
        <app-smart-form #addForm [fields]="clientFormFieldsCreate"></app-smart-form>
      </mat-card-content>
    </mat-card>

    <!-- VIEW -->
    <mat-card *ngIf="action === routeAction.VIEW">
      <mat-card-header>
        <mat-card-title i18n>View Client</mat-card-title>
        <mat-card-subtitle i18n>Clients</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
        </div>
        <app-smart-detail [fields]="clientDetailFields" [source]="clientEntity"></app-smart-detail>
      </mat-card-content>
    </mat-card>

    <!-- EDIT -->
    <mat-card *ngIf="action === routeAction.EDIT">
      <mat-card-header>
        <mat-card-title i18n>Edit Client</mat-card-title>
        <mat-card-subtitle i18n>Clients</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
          <button mat-stroked-button class="spacing-left-5" (click)="reset()">
            <mat-icon class="spacing-right-5">refresh</mat-icon>
            <span i18n>RESET</span>
          </button>
          <button mat-stroked-button color="primary" class="spacing-left-5" (click)="save()">
            <mat-icon class="spacing-right-5">save</mat-icon>
            <span i18n>SAVE</span>
          </button>
        </div>
        <app-smart-form #editForm [fields]="clientFormFieldsEdit" [defaults]="clientEntity"></app-smart-form>
      </mat-card-content>
    </mat-card>
  </div>
</app-standard-layout>
