import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import IQuestionElement from '../../../interfaces/IQuestionElement';
import IElement from '../../../interfaces/IElement';
import * as _ from 'lodash';

@Component({
  selector: 'app-process-survey-given-answers-dialog',
  templateUrl: './process-survey-given-answers-dialog.component.html',
  styleUrls: ['./process-survey-given-answers-dialog.component.styl']
})
export class ProcessSurveyGivenAnswersDialogComponent implements OnInit {

  items: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private matDialogRef: MatDialogRef<ProcessSurveyGivenAnswersDialogComponent>) {
    this.items = this.getItems();
  }

  ngOnInit(): void {
  }

  getItems(): {
    element: IElement<IQuestionElement>,
    previousElementIndex: number
  }[] {
    const elements: any[] = [];

    const processChildren = (children, parentIndex = -1) => {
      children.forEach((element, index) => {
        if (element.type === 'QUESTION') {
          const foundIndex = this.data.previousElements.findIndex(o => o.element.code === element.code);

          if (foundIndex > -1) {
            elements.push({
              element,
              previousElement: _.cloneDeep(this.data.previousElements[foundIndex]),
              previousElementIndex: foundIndex
            });
          }
        } else if (element.type === 'GROUP') {
          processChildren(element.children, index);
        }
      });
    };

    processChildren(this.data.survey.elements);

    return elements;
  }

  navigateToItem(item: any): void {
    this.matDialogRef.close(item);
  }

}
