<app-standard-layout>
  <div class="spacing-inner-10">

    <!-- LISTING -->
    <mat-card *ngIf="action === routeAction.LISTING">
      <mat-card-header>
        <mat-card-title i18n>Jobs</mat-card-title>
        <mat-card-subtitle i18n>On current page you are able to view, edit, delete and create any job</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-5">
          <button mat-stroked-button color="primary" (click)="goToAdd()" *ngIf="authService.hasPermission(['job_create', 'job_create_org'])">
            <mat-icon class="spacing-right-5">add</mat-icon>
            <span i18n>ADD NEW JOB</span>
          </button>
          <button mat-stroked-button color="warn" [ngClass]="{'spacing-left-5': authService.hasPermission(['job_create'])}" *ngIf="authService.hasPermission(['job_delete'])" [disabled]="selectedJobRows === 0">
            <mat-icon class="spacing-right-5">delete</mat-icon>
            <span i18n>DELETE SELECTED</span>
          </button>
        </div>
        <app-smart-table [rowData]="jobs" [columnDefs]="jobsTableColumnDefs" (rowDelete)="onJobDelete($event)" (rowEdit)="onJobEdit($event)" (rowView)="onJobView($event)" permissionGroup="job"></app-smart-table>
      </mat-card-content>
    </mat-card>

    <!-- ADD -->
    <mat-card *ngIf="action === routeAction.ADD">
      <mat-card-header>
        <mat-card-title i18n>Add Job</mat-card-title>
        <mat-card-subtitle i18n>Jobs</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
          <button mat-stroked-button class="spacing-left-5" (click)="reset()">
            <mat-icon class="spacing-right-5">refresh</mat-icon>
            <span i18n>RESET</span>
          </button>
          <button mat-stroked-button color="primary" class="spacing-left-5" (click)="save()">
            <mat-icon class="spacing-right-5">save</mat-icon>
            <span i18n>SAVE</span>
          </button>
        </div>
        <app-smart-form #addForm [fields]="jobFormFieldsCreate"></app-smart-form>
      </mat-card-content>
    </mat-card>

    <!-- VIEW -->
    <ng-container *ngIf="action === routeAction.VIEW">
      <mat-card>
        <mat-card-header>
          <mat-card-title i18n>View Job</mat-card-title>
          <mat-card-subtitle i18n>Jobs</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div class="button-bar spacing-bottom-10">
            <button mat-stroked-button (click)="goBack()">
              <mat-icon class="spacing-right-5">arrow_back</mat-icon>
              <span i18n>GO BACK</span>
            </button>
          </div>
          <app-smart-detail [fields]="jobDetailFields" [source]="jobEntity"></app-smart-detail>
        </mat-card-content>
      </mat-card>

      <mat-card class="spacing-top-10">
        <mat-card-header>
          <mat-card-title i18n>Researches</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-smart-table #researchesTable [rowData]="researches" [columnDefs]="researchColumnDefs" [hideEdit]="true" (rowView)="onResearchView.bind(this)($event)" [hideDelete]="true" permissionGroup="research"></app-smart-table>
        </mat-card-content>
      </mat-card>
    </ng-container>

    <!-- EDIT -->
    <mat-card *ngIf="action === routeAction.EDIT">
      <mat-card-header>
        <mat-card-title i18n>Edit Job</mat-card-title>
        <mat-card-subtitle i18n>Jobs</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
          <button mat-stroked-button class="spacing-left-5" (click)="reset()">
            <mat-icon class="spacing-right-5">refresh</mat-icon>
            <span i18n>RESET</span>
          </button>
          <button mat-stroked-button color="primary" class="spacing-left-5" (click)="save()">
            <mat-icon class="spacing-right-5">save</mat-icon>
            <span i18n>SAVE</span>
          </button>
        </div>
        <app-smart-form #editForm [fields]="jobFormFieldsEdit" [defaults]="jobEntity"></app-smart-form>
      </mat-card-content>
    </mat-card>
  </div>
</app-standard-layout>
