<div class="wrapper">
  <div class="item d-flex flex-column mb-3 p-3 pt-2 pb-2" *ngFor="let item of items" (click)="selectItem(item)" [ngClass]="{selected: selectedItems.indexOf(item) > -1}">
    <div class="row align-items-center">
      <div class="col-md-10">
        <h3 class="m-0 text-bold">{{item.name}}</h3>
      </div>
      <div class="col-md-2 text-right">
        <button mat-icon-button (click)="editItem($event, item)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="openItem($event, item)" class="ml-3" *ngIf="hasChildren(item)">
          <mat-icon>visibility</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
