<div class="d-flex flex-row">
  <div class="d-flex flex-column flex-grow-1" style="max-width: 100%">
    <input #fileInput class="d-none" id="file-upload-input" type="file" (change)="onInputChange($event)" [disabled]="uploading" [accept]="accept.join(',')" />
    <label for="file-upload-input" class="mat-stroked-button mat-button-base mat-primary text-upper clearbox flex-grow-1 d-flex flex-column justify-content-center" matRipple [matRippleUnbounded]="false" [matRippleDisabled]="uploading" [ngClass]="{'mat-button-disabled': uploading}">
      <div class="d-flex flex-row align-items-center">
        <span *ngIf="(!multiple && !value) || multiple" i18n>Choose a file</span>
        <span *ngIf="!multiple && value" class="text-trim">{{autoUpload ? value.originalName : value.name}}</span>
        <mat-spinner color="primary" *ngIf="uploading" [diameter]="15" class="spacing-left-5"></mat-spinner>
        <button mat-icon-button class="spacing-left-auto" *ngIf="!multiple && value" (click)="removeFile()">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </label>
  </div>
  <div class="ml-2" *ngIf="multiple">
    <button mat-icon-button [matBadge]="value.length > 0 ? value.length : undefined" matBadgePosition="after" matBadgeColor="primary" [disabled]="value.length === 0" (click)="openFileQueue()">
      <mat-icon>queue</mat-icon>
    </button>
  </div>
</div>
