import {Component, Inject, OnInit} from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-long-list-cell-renderer',
  templateUrl: './long-list-cell-renderer.component.html',
  styleUrls: ['./long-list-cell-renderer.component.styl']
})
export class LongListCellRendererComponent {

  params: ICellRendererParams;

  constructor(public dialog: MatDialog) {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  openDialog(): void {
    this.dialog.open(LongListCellRendererDialogComponent, {
      data: {
        params: this.params
      },
      width: '200px'
    });
  }

}


@Component({
  selector: 'app-long-list-cell-renderer-dialog',
  templateUrl: './long-list-cell-renderer-dialog.html'
})
export class LongListCellRendererDialogComponent {

  items: any[] = [];
  params: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.params = data.params;
    this.items = data.params.data[data.params.colDef.field];
  }

  getItemName(item: any): string {
    return this.params.colDef.cellRendererParams.displayField(item);
  }

}
