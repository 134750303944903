<div class="wrapper">
  <button *ngIf="btnStyle === 'raised'" [color]="btnColor" mat-raised-button (click)="showOverMenu = true">
    <mat-icon class="spacing-right-5" *ngIf="icon">{{icon}}</mat-icon>
    <span>{{title}}</span>
  </button>

  <button *ngIf="btnStyle === 'flat'" [color]="btnColor" mat-flat-button (click)="showOverMenu = true">
    <mat-icon class="spacing-right-5" *ngIf="icon">{{icon}}</mat-icon>
    <span>{{title}}</span>
  </button>

  <button *ngIf="btnStyle === 'stroked'" [color]="btnColor" mat-stroked-button (click)="showOverMenu = true">
    <mat-icon class="spacing-right-5" *ngIf="icon">{{icon}}</mat-icon>
    <span>{{title}}</span>
  </button>

  <div class="overmenu-overlay" *ngIf="showOverMenu" (click)="showOverMenu = false"></div>

  <div class="overmenu d-flex flex-column" [ngStyle]="{width: menuWidth}" *ngIf="showOverMenu">
    <div class="items flex-grow-1 spacing-bottom-3" cdkDropList (cdkDropListDropped)="dropItem($event)">
      <div class="item spacing-inner-top-3 spacing-inner-bottom-3 spacing-inner-left-5 spacing-inner-right-5 d-flex flex-row align-items-center" cdkDrag *ngFor="let item of items; let itemIndex = index">
        <span>{{getItemLabel(item)}}</span>

        <button mat-icon-button class="spacing-left-auto" (click)="editItem(item, itemIndex)">
          <mat-icon>edit</mat-icon>
        </button>

        <button mat-icon-button class="spacing-left-1" (click)="deleteItem(item, itemIndex)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <button class="btn-add spacing-left-3 spacing-bottom-3 spacing-top-auto" mat-stroked-button color="primary" (click)="addItem()">
      <mat-icon class="spacing-right-5">add</mat-icon>
      <span i18n>ADD</span>
    </button>
  </div>
</div>
