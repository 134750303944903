<h1 mat-dialog-title i18n>Import data from file</h1>
<div mat-dialog-content>
  <app-form-field-wrap class="full-width">
    <input-upload [(ngModel)]="file" [accept]="['.csv']"></input-upload>
  </app-form-field-wrap>
</div>
<div mat-dialog-actions class="d-flex flex-row justify-content-end">
  <button mat-button (click)="cancel()" i18n>Cancel</button>
  <button mat-button (click)="confirm()" i18n>Confirm</button>
</div>
