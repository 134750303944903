import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {MatDialog} from '@angular/material/dialog';
import ISmartField from '../../interfaces/ISmartField';
import {SmartVUDListDialogComponent} from './smart-vudlist-dialog/smart-vudlist-dialog.component';

declare type ThemePalette = 'primary' | 'accent' | 'warn' | undefined;

@Component({
  selector: 'app-smart-vudlist',
  templateUrl: './smart-vudlist.component.html',
  styleUrls: ['./smart-vudlist.component.styl']
})
export class SmartVUDListComponent implements OnInit {

  @Input() icon?: string;
  @Input() title: string;
  @Input() items: any[] = [];
  @Input() fields: ISmartField[] = [];
  @Input() itemBase: any = {};
  @Input() btnStyle = 'raised';
  @Input() btnColor: ThemePalette = undefined;
  @Input() menuWidth = '100%';
  showOverMenu = false;
  @Output() change: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() getItemLabel = (item) => item.label || $localize`No Label`;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.items = [...this.items];
  }

  addItem(): void {
    this.showItemDialog($localize`Add new item`, result => {
      if (!result) {
        return;
      }

      this.items.push({
        ...this.itemBase,
        ...result
      });

      this.change.emit(this.items);
    });
  }

  editItem(item: any, index: number): void {
    this.showItemDialog($localize`Edit` + ` ${this.getItemLabel(item)}`, result => {
      if (!result) {
        return;
      }

      this.items[index] = {
        ...this.items[index],
        ...result
      };

      this.change.emit(this.items);
    }, item);
  }

  deleteItem(item: any, index: number): void {
    this.items.splice(index, 1);
    this.change.emit(this.items);
  }

  dropItem(ev: CdkDragDrop<any[]>): void {
    moveItemInArray(this.items, ev.previousIndex, ev.currentIndex);
    this.change.emit(this.items);
  }

  private showItemDialog(title: string, callback: (result) => void, item: any = null): void {
    const dialogRef = this.dialog.open(SmartVUDListDialogComponent, {
      width: '90%',
      data: {
        title,
        fields: this.fields,
        item
      },
      autoFocus: false,
      maxWidth: '500px'
    });

    dialogRef.afterClosed().subscribe(callback);
  }

}
