import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'groupArray'
})
export class GroupArrayPipe implements PipeTransform {

  transform(arr: any[], groupBy: string): any {
    const groups = {};

    for (const item of arr) {
      const groupName = _.get(item, groupBy, null);

      if (!groupName) {
        continue;
      }

      if (!groups.hasOwnProperty(groupName)) {
        groups[groupName] = [];
      }

      groups[groupName].push(item);
    }

    console.log(groups);

    return groups;
  }

}
