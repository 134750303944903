import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-rejected-screen',
  templateUrl: './privacy-rejected-screen.component.html',
  styleUrls: ['./privacy-rejected-screen.component.styl']
})
export class PrivacyRejectedScreenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
