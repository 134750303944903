<div class="file-upload-container" [ngClass]="{'inline': inline === true}">
  <div class="inline-uploaded-file-wrapper" *ngIf="model && inline === true">
    <div class="d-flex flex-row align-items-center">
      <span>{{model.originalName}}</span>
      <button mat-icon-button class="spacing-left-auto" (click)="removeItem(model)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>

  <ng-container *ngIf="(!model && inline === true) || inline !== true">
    <input class="d-none" id="file-upload-input" [multiple]="multiple === true" type="file" (change)="onChange($event)" [disabled]="uploading" />
    <label for="file-upload-input" class="mat-stroked-button mat-button-base mat-primary d-block-inner text-upper clearbox" matRipple [matRippleUnbounded]="false" [matRippleDisabled]="uploading" [ngClass]="{'mat-button-disabled': uploading}">
      <div class="d-flex flex-row align-items-center">
        <span>{{label}}</span>
        <mat-spinner color="primary" *ngIf="uploading" [diameter]="15" class="spacing-left-5"></mat-spinner>
      </div>
    </label>
  </ng-container>
</div>

<mat-list *ngIf="((isArray(model) && model.length > 0) || model) && inline !== true">
  <ng-container *ngIf="isArray(model)">
    <mat-list-item *ngFor="let file of model">
      <mat-icon mat-list-icon>description</mat-icon>
      <div mat-line>{{file.originalName}}</div>
    </mat-list-item>
  </ng-container>
  <ng-container *ngIf="!isArray(model)">
    <mat-list-item>
      <mat-icon mat-list-icon>description</mat-icon>
      <div mat-line>{{model.originalName}}</div>
    </mat-list-item>
  </ng-container>
</mat-list>

