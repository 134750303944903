import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import ISmartField from '../../interfaces/ISmartField';
import {ContentType} from '../../definitions/ContentType';
import {ElementType} from '../../definitions/ElementType';
import {SmartFormComponent} from '../smart-form/smart-form.component';
import {ApiService} from '../../services/api.service';
import * as elementQuestionTypes from '../../../assets/data/question-element-types.json';
import IElement from '../../interfaces/IElement';
import {GroupDisplayAs} from '../../definitions/GroupDisplayAs';
import IQuestionElement from '../../interfaces/IQuestionElement';
import IGroupElement from '../../interfaces/IGroupElement';
import ITextElement from '../../interfaces/ITextElement';
import IRichMediaElement from '../../interfaces/IRichMediaElement';
import {QuestionType} from '../../definitions/QuestionType';
import * as moment from 'moment';

@Component({
  selector: 'app-survey-question-dialog',
  templateUrl: './survey-question-dialog.component.html',
  styleUrls: ['./survey-question-dialog.component.styl']
})
export class SurveyQuestionDialogComponent implements OnInit {

  elementType = ElementType;
  selectedElementType: ElementType = null;
  fieldsCreateQuestion: ISmartField[] = [
    { key: 'code', label: $localize`Code`, required: true, type: ContentType.SHORT_TEXT },
    { key: 'question', label: $localize`Question`, required: true, type: ContentType.SHORT_TEXT },
    { key: 'type', label: $localize`Type`, required: true, type: ContentType.LIST, data: (elementQuestionTypes as any).default.map(item => ({ value: item.code, label: item.text })) },
    { key: 'description', label: $localize`Description`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'group', label: $localize`Group`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'condition', label: $localize`Condition`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'scoreFormula', label: $localize`Score Formula`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'nextFormula', label: $localize`Next Formula`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'validation', label: $localize`Validation`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'timeLimit', label: $localize`Time Limit`, required: false, type: ContentType.INTEGER },
    { key: 'maxAnswers', label: $localize`Max Answers`, required: false, type: ContentType.INTEGER },
    { key: 'isActive', label: $localize`Active`, required: false, defaultValue: true, type: ContentType.BOOLEAN, displayAs: 'toggle' },
    { key: 'required', label: $localize`Mandatory`, required: false, type: ContentType.BOOLEAN, displayAs: 'toggle', defaultValue: false },
    { key: 'multiple', label: $localize`Multiple`, required: false, type: ContentType.BOOLEAN, displayAs: 'toggle', defaultValue: false },
    { key: 'helperText', label: $localize`Helper Text`, required: false, type: ContentType.RICH_TEXT },
    { key: 'category', label: $localize`Category`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'categoryDescription', label: $localize`Category Description`, required: false, type: ContentType.LONG_TEXT },
    { key: 'subcategory', label: $localize`Subcategory`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'subcategoryDescription', label: $localize`Subcategory Description`, required: false, type: ContentType.LONG_TEXT },
    { key: 'childOrder', label: $localize`Order`, required: false, type: ContentType.INTEGER }
  ];
  fieldsCreateText: ISmartField[] = [
    { key: 'code', label: $localize`Code`, required: true, type: ContentType.SHORT_TEXT },
    { key: 'description', label: $localize`Description`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'group', label: $localize`Group`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'condition', label: $localize`Condition`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'nextFormula', label: $localize`Next Formula`, required: false, type: ContentType.SHORT_TEXT },
    { type: ContentType.BLANK },
    { key: 'isActive', label: $localize`Active`, required: false, defaultValue: true, type: ContentType.BOOLEAN, displayAs: 'toggle' }
  ];
  fieldsCreateRichMedia: ISmartField[] = [
    { key: 'code', label: $localize`Code`, required: true, type: ContentType.SHORT_TEXT },
    { key: 'group', label: $localize`Group`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'condition', label: $localize`Condition`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'nextFormula', label: $localize`Next Formula`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'isActive', label: $localize`Active`, required: false, defaultValue: true, type: ContentType.BOOLEAN, displayAs: 'toggle' },
    { type: ContentType.BLANK },
    { key: 'content', label: $localize`Content`, required: false, type: ContentType.RICH_TEXT },
  ];
  fieldsCreateGroup: ISmartField[] = [
    { key: 'code', label: $localize`Code`, required: true, type: ContentType.SHORT_TEXT },
    { key: 'description', label: $localize`Description`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'startMessage', label: $localize`Start Message`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'endMessage', label: $localize`End Message`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'condition', label: $localize`Condition`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'nextFormula', label: $localize`Next Formula`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'displayAs', label: $localize`Display As`, required: true, type: ContentType.LIST, data: Object.keys(GroupDisplayAs).map(key => ({ value: key, label: key })) },
    { key: 'isActive', label: $localize`Active`, required: false, defaultValue: true, type: ContentType.BOOLEAN, displayAs: 'toggle' },
    { key: 'children', type: ContentType.HIDDEN, defaultValue: [] },
  ];
  defaultData: any = {};
  @ViewChild('formComponent') formComponent: SmartFormComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SurveyQuestionDialogComponent>, private apiService: ApiService) {
    if (data.element !== null) {
      this.defaultData = {
        ...data.element,
        ...data.element.child
      };

      delete this.defaultData.child;
      this.selectedElementType = data.element.type;
    }
  }

  ngOnInit(): void {
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onConfirm(): void {
    this.formComponent.form.markAllAsTouched();

    if (this.formComponent.form.invalid) {
      return;
    }

    const rawValue = this.formComponent.form.getRawValue();
    const childValue = Object.keys(rawValue).filter(key => ['code', 'order', 'child'].indexOf(key) === -1).reduce((obj, curr) => ({ ...obj, [curr]: rawValue[curr] }), {});

    let updatedElement: IElement<IQuestionElement|IGroupElement|ITextElement|IRichMediaElement>;

    if (this.data.element !== null) {
      updatedElement = { ...this.data.element };

      if (updatedElement.type !== this.selectedElementType) {
        updatedElement.child = {
          ...this.constructChildElement(),
          ...childValue
        };
      } else {
        updatedElement.child = {
          ...updatedElement.child,
          ...childValue
        };
      }

      updatedElement.code = rawValue.code;
      updatedElement.type = this.selectedElementType;
    } else {
      updatedElement = {
        code: rawValue.code,
        order: 0,
        type: this.selectedElementType,
        updatedAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        child: {
          ...this.constructChildElement(),
          ...childValue
        }
      };
    }

    this.dialogRef.close(updatedElement);
  }

  private constructChildElement(): any {
    switch (this.selectedElementType) {
      case ElementType.QUESTION:
        return {
          answers: [],
          extras: {},
          condition: null,
          defaultAnswer: null,
          description: null,
          group: null,
          helperText: null,
          isActive: true,
          multiple: false,
          nextFormula: null,
          question: null,
          required: true,
          scoreFormula: null,
          timeLimit: null,
          type: QuestionType.CHOICE,
          validation: null
        };

      case ElementType.TEXT:
        return {
          condition: null,
          description: null,
          group: null,
          isActive: true,
          nextFormula: null
        };

      case ElementType.GROUP:
        return {
          children: [],
          condition: null,
          description: null,
          displayAs: GroupDisplayAs.NORMAL,
          endMessage: null,
          isActive: true,
          randomOrder: false,
          startMessage: null
        };

      case ElementType.RICH_MEDIA:
        return {
          condition: null,
          content: null,
          group: null,
          isActive: true,
          nextFormula: null
        };

      default:
    }

    return null;
  }

}
