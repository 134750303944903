<div class="row">
  <div class="col-12">
    <button mat-stroked-button color="primary" class="full-width add-chip">
      <mat-icon>add_box</mat-icon>
      <span i18n>ADD NEW CHIP</span>
    </button>
  </div>
  <div class="col-12">

  </div>
</div>
