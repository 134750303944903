import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-field-wrap',
  templateUrl: './form-field-wrap.component.html',
  styleUrls: ['./form-field-wrap.component.styl']
})
export class FormFieldWrapComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
