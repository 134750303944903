import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayManipulate'
})
export class ArrayManipulatePipe implements PipeTransform {

  transform(arr: any[], filterBy: (item: any, params?: boolean) => boolean, mapBy: (item: any, params?: any) => any, params: any = {}): unknown {
    return arr.filter(item => filterBy(item, params)).map(item => mapBy(item, params));
  }

}
