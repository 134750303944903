<app-process-layout [research]="research" [client]="client">
  <h1 class="spacing-top-20 text-upper text-bold" i18n>Sign in as a candidate to participate for this {{getSurveyType()}}</h1>
  <p class="opacity-50 font-size-9 text-light spacing-bottom-0 spacing-inner-bottom-0" i18n>In case you do not have an account registered with us, please <a routerLink="/process/signup">click here and sign up for an account</a>.</p>

  <mat-card class="spacing-top-10 spacing-bottom-10">
    <mat-card-content>
      <app-smart-form #loginComponent [fields]="fieldsLogin" colSize="6"></app-smart-form>
    </mat-card-content>
    <mat-card-actions class="text-center">
      <a mat-button routerLink="/process/signup" i18n>I DO NOT HAVE AN ACCOUNT</a>
      <button mat-button i18n (click)="openForgotPasswordDialog()" class="text-upper">Forgot your password?</button>
      <button mat-button i18n color="primary" (click)="login()" [disabled]="loginComponent.form.invalid">CONFIRM AND SIGN IN</button>
    </mat-card-actions>
  </mat-card>
</app-process-layout>
