<form [formGroup]="forgotPasswordForm">
  <h1 mat-dialog-title i18n>Forgot password</h1>
  <div mat-dialog-content>
    <p i18n class="opacity-50">Please insert your account's email address in the form below</p>
    <mat-form-field appearance="outline" class="full-width spacing-top-5">
      <mat-label i18n>Email address</mat-label>
      <input matInput i18n-placeholder placeholder="Insert your email address" formControlName="email">
      <mat-icon matSuffix>account_circle</mat-icon>
      <mat-error *ngIf="forgotPasswordForm.controls.email.invalid">{{getErrorMessage(forgotPasswordForm.controls.email)}}</mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" i18n>Cancel</button>
    <button mat-button (click)="recover()" i18n>Recover</button>
  </div>
</form>
