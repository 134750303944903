export enum QuestionType {
  CHOICE = 'CHOICE',
  CHOICE_DROPDOWN = 'CHOICE_DROPDOWN',
  CHOICE_REGULAR = 'CHOICE_REGULAR',
  ARRAY_MATRIX = 'ARRAY_MATRIX',
  SHORT_ANSWER = 'SHORT_ANSWER',
  LONG_ANSWER = 'LONG_ANSWER',
  DATE_INPUT = 'DATE_INPUT',
  FILE_UPLOAD = 'FILE_UPLOAD',
  RANKING_ORDERING = 'RANKING_ORDERING',
  YES_NO = 'YES_NO'
}
