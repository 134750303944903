import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'toDate'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any, ...args): any {
    if (value === null || value === undefined) {
      return '';
    }

    return moment(value).format('DD/MM/YYYY');
  }
}
