import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import IReport from '../../interfaces/IReport';
import IResearchResult from '../../interfaces/IResearchResult';
import { jsPDF } from 'jspdf';
import IReportResults from '../../interfaces/IReportResults';
import {MatSnackBar} from '@angular/material/snack-bar';
import IReportResultsDataset from '../../interfaces/IReportResultsDataset';
import IReportResultsTopic from '../../interfaces/IReportResultsTopic';
import html2canvas from 'html2canvas';
import {xor} from 'lodash';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {ElementType} from '../../definitions/ElementType';
import ISurvey from '../../interfaces/ISurvey';
import IResearch from '../../interfaces/IResearch';
import ISubject from '../../interfaces/ISubject';

@Component({
  selector: 'app-report-screen',
  templateUrl: './report-screen.component.html',
  styleUrls: ['./report-screen.component.styl']
})
export class ReportScreenComponent implements OnInit {

  loading = true;
  report: IReport = null;
  researchResult: IResearchResult = null;
  decodedData: any = {};
  results: IReportResults = {
    datasets: [],
    topics: []
  };
  datasetDescriptions: {[key: string]: string} = {};
  topicResults: {[key: string]: IReportResultsTopic} = {};
  chartData: any[] = [];
  chartScheme: any = {
    domain: [
      '#e1e1e1'
    ]
  };
  chartOptions: any = {};
  reportExtras: any = [ { name: 'Ciao', value: 10 }];
  reportTopics: any[] = [];
  survey: ISurvey = null;
  research: IResearch = null;
  candidate: ISubject = null;
  show: boolean = false;
  

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute, private snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    this.apiService.getReport(this.route.snapshot.params.id).then(async (report) => {
      this.report = report;
      this.reportTopics = [...report.topics];
      this.researchResult = await this.apiService.getResearchResult(this.route.snapshot.params.resultId);
      this.candidate = await this.apiService.getSubject(this.researchResult.candidate);
      this.research = await this.apiService.getResearch(this.researchResult.research);
      this.survey = await this.apiService.getSurvey(this.research.survey);
      this.calculateExtras();
      this.checkLoadingStatus();
    }).catch(console.error);
  }



  calculateExtras(): void {
    const extras = this.report.extras || [];

    const reportExtras = [];

    extras.forEach(extra => {
      reportExtras.push({
        value: this.parseFormula(extra.formula),
        name: extra.name
      });
    });

    this.reportExtras = [...reportExtras];
  }

  parseFormula(formula: string): any {
      if (this.researchResult === null) {
        return;
      }

      const pattern = /([ +-\/*]+|)([A-Z]+\(([^)]+)\))/gm;
      const clean = formula.replace(/ /g, '');
      const found = clean.match(pattern);
      const scoreMap = {};

      this.survey.elements.forEach(element => {
        if (element.type === ElementType.QUESTION) {
          if (!scoreMap.hasOwnProperty(element.code)) {
            scoreMap[element.code] = {};
          }

          element.child.answers.forEach(answerElement => {
            let score = Number(answerElement.score);
            let value = Number(answerElement.value);

            if (isNaN(score)) { score = null; }
            if (isNaN(value)) { value = null; }
            scoreMap[element.code][answerElement.code] = score || value || 0;
          });
        }
      });

      let result = 0;

      for (let i = 0; i < found.length; i++) {
        let piece = found[i].trim();
        let symbol = null;

        if (['/', '*', '-', '+'].indexOf(piece.charAt(0)) > -1) {
          symbol = piece.charAt(0);
          piece = piece.substring(1, piece.length);
        }

        const match = piece.match(/ABS\(([^)]+)\)/);
        const calcMatch = match[1].match(/(-|\+|\/|\*|)([A-Za-z0-9]+)/gm);
        let value = 0;

        for (let a = 0; a < calcMatch.length; a++) {
          let tmp = calcMatch[a];
          let tmpSymbol = null;

          if (['+', '-', '/', '*'].indexOf(tmp.charAt(0)) > -1) {
            tmpSymbol = tmp.charAt(0);
            tmp = tmp.substring(1, tmp.length);
          }

          const answerCode = this.researchResult.answers.hasOwnProperty(tmp) ? this.researchResult.answers[tmp] : null;
          let answerValue = 0;

          if (answerCode && scoreMap.hasOwnProperty(tmp) && scoreMap[tmp].hasOwnProperty(answerCode)) {
            answerValue = scoreMap[tmp][answerCode];
          }

          if (tmpSymbol === '-') {
            value -= answerValue;
          } else {
            value += answerValue;
          }

        }

        let realValue = 0;

        if (piece.startsWith('ABS')) {
          realValue = Math.abs(value);
        }

        if (symbol === '-') {
          result -= realValue;
        } else {
          result += realValue;
        }
      }

      return result;
  }


  downloadReport(): void {
    //this.ngZone.run(() => { this.show = true; }) 
    this.show = true;
    const generator = async () => {
      const canvas1 = await html2canvas(document.querySelector('.page-1'), {scale: 1});
      let canvas2;

      if (this.chartData.length > 0) {
        canvas2 = await html2canvas(document.querySelector('.page-2'), {scale: 1});
      }

      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4'
      });

      let newCanvasHeight = canvas1.height * doc.internal.pageSize.width / canvas1.width;

      doc.addImage(canvas1.toDataURL('image/png'), 'PNG', 0, 0, doc.internal.pageSize.width, newCanvasHeight);

      if (this.chartData.length > 0) {
        doc.addPage('a4', 'landscape');

        newCanvasHeight = canvas2.height * doc.internal.pageSize.width / canvas2.width;
        console.log("canvas2.height : ", canvas2.height );
        console.log("doc.internal.pageSize.width: ",doc.internal.pageSize.width);
        console.log("canvas2.width: ",canvas2.width);
        console.log("newCanvasHeight : ", newCanvasHeight );
        //297 X 161
        doc.addImage(canvas2.toDataURL('image/png'), 'PNG', 0, 0, doc.internal.pageSize.width, newCanvasHeight);
        //prova x resize
        //doc.addImage(canvas2.toDataURL('image/png'), 'PNG', 20, 60, 230, 125);
      }

      doc.save('report.pdf');
      this.show = false;

    };

    generator().then(() => {}).catch(() => {});

  }

  public getYLabelTick = (value: string) => {
    const groupTopics = this.report.topics.filter(topic => topic.group).map(topic => topic.name);

    if (groupTopics.includes(value)) {
      return '<strong>' + value + '</strong>';
    }

    return value;
  }

  private setChartOptions(): any {
    const chartDataMap = this.chartData.reduce((acc, curr) => ({
      ...acc,
      [curr.name]: curr.value
    }), {});

    const groupTopics = this.report.topics.filter(topic => topic.group).map(topic => topic.name);

    this.chartOptions = {
      xAxis: {
        type: 'value',
        min: 25,
        max: 75,
        interval: 5
      },
      yAxis: {
        type: 'category',
        data: this.report.topics.reverse().map(topic => topic.name),
        axisLabel: {
          formatter: value => {
            if (groupTopics.includes(value)) {
              return '{b|' + value + '}';
            }

            return value;
          },
          rich: {
            b: {
              fontWeight: 'bold'
            }
          }
        }
      },
      color: ['#bebebe', '#c0c0c0'],
      series: [
        {
          data: this.report.topics.map(topic => {
            if (!chartDataMap.hasOwnProperty(topic.name)) {
              return 0;
            }

            return chartDataMap[topic.name];
          }),
          itemStyle: {
            color: ev => {
              const topic = this.report.topics[ev.dataIndex];

              if (topic.group) {
                return '#8f8f8f';
              }

              return '#bebebe';
            }
          },
          type: 'bar',
          legendHoverLink: false,
          label: {
            show: false
          },
          markArea: {
            itemStyle: {
              color: 'rgba(255, 173, 177, 0.4)'
            },
            data: [
              [
                {
                  xAxis: 45
                },
                {
                  xAxis: 55
                }
              ]
            ]
          }
        }
      ]
    };
  }

  private checkLoadingStatus(): void {
    if (this.report && this.researchResult) {

      const groupTopics = this.report.topics.filter(topic => topic.group).map(topic => topic.name);

      this.loading = false;
      this.apiService.getReportResults(this.report.id, this.researchResult.id).then(reportResults => {
        this.results = reportResults;
        reportResults.datasets.forEach(item => {
          this.datasetDescriptions[item.key] = item.parsedDescription;
        });

        const chartData: any[] = [];

        let firstDataset: any;

        if (reportResults.datasets.length > 0) {
          firstDataset = reportResults.datasets[0].key;
        }
        reportResults.topics.forEach(item => {
          this.topicResults[item.name] = item;

          if (firstDataset) {
            chartData.push({
              name: item.name,
              value: item.datasetResults[firstDataset].pst
            });
          }
        });
        this.chartData = [...chartData];
        this.setChartOptions();
      }).catch(console.error);
    }
  }

testShow(): any {
   if (this.show ===false) {
     this.show = true; 
   } else {
    this.show = false; 
   }

}

 delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

}
