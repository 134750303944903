<h2 mat-dialog-title i18n>Result Answers</h2>
<div mat-dialog-content>
  <div class="question" *ngFor="let item of QA">
    <p class="text-primary"><strong>{{item.code}} - {{item.title}}</strong></p>
    <ng-container *ngIf="item.type === questionType.ARRAY_MATRIX">
      <ng-container *ngFor="let valueItem of item.value | keyvalue">
        <p *ngIf="item.multiple">
          <span><strong>{{valueItem.key}}</strong></span>:
          <span>{{valueItem.value.join(',')}}</span>
        </p>
        <p *ngIf="!item.multiple">
          <span><strong>{{valueItem.key}}</strong></span>:
          <span>{{valueItem.value}}</span>
        </p>
      </ng-container>
    </ng-container>
    <p *ngIf="item.type !== questionType.ARRAY_MATRIX && item.multiple">{{item.value.join(',')}}</p>
    <p *ngIf="item.type !== questionType.ARRAY_MATRIX && !item.multiple">{{item.value}}</p>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button class="ml-auto" (click)="download()">Download</button>
  <button mat-button mat-dialog-close (click)="close()" class="ml-2">Close</button>
</div>
