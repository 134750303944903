import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distinctArray'
})
export class DistinctArrayPipe implements PipeTransform {

  transform(arr: any[], distinctBy: string): any[] {
    return arr.filter((value, index, self) => self.findIndex((item => item[distinctBy] === value[distinctBy])) === index);
  }

}
