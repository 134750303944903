import {Component, OnInit, ViewChild} from '@angular/core';
import IUser from '../../interfaces/IUser';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RouteAction} from '../../definitions/RouteAction';
import {ContentType} from '../../definitions/ContentType';
import IItem from '../../interfaces/IItem';
import IRole from '../../interfaces/IRole';
import {SmartFormComponent} from '../../components/smart-form/smart-form.component';
import ISmartField from '../../interfaces/ISmartField';
import IDetailField from '../../interfaces/IDetailField';
import IClient from '../../interfaces/IClient';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-user-screen',
  templateUrl: './user-screen.component.html',
  styleUrls: ['./user-screen.component.styl']
})
export class UserScreenComponent implements OnInit {

  @ViewChild('addForm') addForm: SmartFormComponent;
  @ViewChild('editForm') editForm: SmartFormComponent;
  action: RouteAction = RouteAction.LOADING;
  routeAction = RouteAction;
  users: IUser[] = [];
  usersTableColumnDefs = [
    { headerName: $localize`Email`, field: 'email', editable: false },
    { headerName: $localize`First Name`, field: 'firstName', editable: false },
    { headerName: $localize`Last Name`, field: 'lastName', editable: false },
    { headerName: $localize`Phone`, field: 'phone', editable: false },
    { headerName: $localize`Active`, field: 'isActive', cellRenderer: 'checkboxRenderer' },
    { headerName: $localize`Role`, field: 'role' }
  ];
  userFormFieldsCreate = [
    { key: 'email', label: $localize`Email`, required: true, type: ContentType.EMAIL },
    { key: 'password', label: $localize`Password`, required: true, type: ContentType.PASSWORD },
    { key: 'firstName', label: $localize`First Name`, required: true, type: ContentType.SHORT_TEXT },
    { key: 'lastName', label: $localize`Last Name`, required: true, type: ContentType.SHORT_TEXT },
    { key: 'phone', label: $localize`Phone`, required: false, type: ContentType.PHONE },
    { key: 'role', label: $localize`Role`, required: true, type: ContentType.LIST, fetchData: () => this.fetchRoles() },
    this.authService.getClientField(),
    { key: 'permissions', label: $localize`Permissions`, required: false, type: ContentType.LIST, data: [], fetchData: () => this.fetchPermissionsAsItems(), render: (data: IClient) => data.name, multiple: true, defaultValue: [] },
    { key: 'isActive', label: $localize`Active`, required: true, type: ContentType.BOOLEAN, defaultValue: true }
  ];
  userFormFieldsEdit = this.userFormFieldsCreate.map((field: ISmartField) => {
    if (field.key === 'password') {
      field.required = true;
    }

    return field;
  });
  userDetailFields: IDetailField[] = [
    { key: 'email', label: $localize`Email address`, type: ContentType.EMAIL },
    { key: 'isActive', label: $localize`Active`, type: ContentType.BOOLEAN },
    { key: 'firstName', label: $localize`First Name`, type: ContentType.SHORT_TEXT },
    { key: 'lastName', label: $localize`Last Name`, type: ContentType.SHORT_TEXT },
    { key: 'role', label: $localize`Role`, type: ContentType.SHORT_TEXT }
  ];
  selectedUserRows = 0;
  userEntity: IUser = null;

  constructor(private apiService: ApiService, private activatedRoute: ActivatedRoute, private router: Router, public authService: AuthService) {
    this.onUserDelete = this.onUserDelete.bind(this);
    this.onUserEdit = this.onUserEdit.bind(this);
    this.onUserView = this.onUserView.bind(this);
  }

  ngOnInit(): void {
    if (this.activatedRoute.firstChild === null) {
      this.action = RouteAction.LISTING;
      this.fetchUsers();
    } else {
      const snapshot = this.activatedRoute.firstChild.snapshot;

      if (snapshot.routeConfig.path.startsWith('add')) {
        this.goToAdd();
      } else if (snapshot.routeConfig.path.startsWith('view')) {
        this.goToView();
      } else if (snapshot.routeConfig.path.startsWith('edit')) {
        this.goToEdit();
      }
    }
  }

  fetchUsers(): void {
    this.apiService.getUsers().then((users: IUser[]) => {
      this.users = users.filter(user => this.authService.hasBoundingPermission('user', 'view', user));
    });
  }

  fetchRoles(): Promise<IItem[]> {
    return new Promise<IItem[]>((resolve: any, reject: any) => {
      this.apiService.getRoles().then((roles: IRole[]) => {
        resolve(roles.map((role: IRole) => ({
          value: role.id,
          label: role.name
        })));
      });
    });
  }

  fetchClientsAsItems(): Promise<IItem[]> {
    return new Promise<IItem[]>(resolve => {
      this.apiService.getClients().then(clients => resolve(clients.map(client => ({
        value: client.code,
        label: client.name
      }))));
    });
  }

  fetchPermissionsAsItems(): Promise<IItem[]> {
    return new Promise<IItem[]>(resolve => {
      this.apiService.getPermissions().then(permissions => resolve(permissions.map(permission => ({
        value: permission.code,
        label: permission.name
      })).sort((a, b) => a.label >= b.label ? 1 : -1 )));
    });
  }

  save(): void {
    if (this.action === RouteAction.ADD) {
      if (this.addForm.form.invalid) {
        this.addForm.form.markAllAsTouched();
        return;
      }

      const user: IUser = this.addForm.form.getRawValue();

      this.apiService.createUser(user).then(() => {
        this.goToListing();
      });
    } else if (this.action === RouteAction.EDIT) {
      if (this.editForm.form.invalid) {
        this.editForm.form.markAllAsTouched();
        return;
      }

      const data: IUser = {
        ...this.userEntity,
        ...this.editForm.form.getRawValue()
      };

      this.apiService.updateUser(data.id, data).then((user: IUser) => {
        this.userEntity = user;
        this.editForm.defaults = user;
        this.editForm.setValue({
          ...user,
          password: null
        });
      });
    }
  }

  reset(): void {
    if (this.action === RouteAction.EDIT) {
      this.editForm.reset();
    } else if (this.action === RouteAction.ADD) {
      this.addForm.reset();
    }
  }

  goToListing(): void {
    this.fetchUsers();
    this.router.navigateByUrl('/user-management');
    this.action = RouteAction.LISTING;
  }

  goToAdd(): void {
    this.router.navigateByUrl('/user-management/add');
    this.action = RouteAction.ADD;
  }

  goToEdit(data: IUser = null): void {
    if (data === null) {
      this.apiService.getUser(this.activatedRoute.firstChild.snapshot.params.id).then((user: IUser) => {
        this.userEntity = user;
        this.action = RouteAction.EDIT;
      });
    } else {
      this.userEntity = data;
      this.router.navigateByUrl('/user-management/edit/' + data.id);
      this.action = RouteAction.EDIT;
    }
  }

  goToView(data: IUser = null): void {
    if (data === null) {
      this.apiService.getUser(this.activatedRoute.firstChild.snapshot.params.id).then((user: IUser) => {
        this.userEntity = user;
        this.action = RouteAction.VIEW;
      });
    } else {
      this.userEntity = data;
      this.router.navigateByUrl('/user-management/view/' + data.id);
      this.action = RouteAction.VIEW;
    }
  }

  goBack(): void {
    switch (this.action) {
      case RouteAction.ADD:
      case RouteAction.EDIT:
      case RouteAction.VIEW:
        this.goToListing();
        break;

      default:
    }
  }

  onUserDelete(ev: any): void {
    this.apiService.deleteUser(ev.data.id).then(() => {
      this.fetchUsers();
    });
  }

  onUserEdit(ev: any): void {
    this.goToEdit(ev.data);
  }

  onUserView(ev: any): void {
    this.goToView(ev.data);
  }
}


