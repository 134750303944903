import {createAction, props} from '@ngrx/store';
import IUser from '../interfaces/IUser';
import ISubject from '../interfaces/ISubject';
import IAnonymousCandidate from '../interfaces/IAnonymousCandidate';
import IRole from '../interfaces/IRole';
import IClient from '../interfaces/IClient';

export const setUser = createAction('[State Authentication] Set User', props<IUser>());
export const unsetUser = createAction('[State Authentication] Unset User');
export const setToken = createAction('[State Authentication] Set Token', props<{token: string}>());
export const unsetToken = createAction('[State Authentication] Unset Token');
export const setCandidate = createAction('[State Authentication] Set Candidate', props<ISubject|IAnonymousCandidate>());
export const unsetCandidate = createAction('[State Authentication] Unset Candidate');
export const setCandidateToken = createAction('[State Authentication] Set Candidate Token', props<{candidateToken: string}>());
export const unsetCandidateToken = createAction('[State Authentication] Unset Candidate Token');
export const setRoles = createAction('[State Authentication] Set Roles', props<{roles: IRole[]}>());
export const setUserRole = createAction('[State Authentication] Set User Role', props<IRole>());
export const unsetUserRole = createAction('[State Authentication] Unset User Role');
export const setUserClient = createAction('[State Authentication] Set User Client', props<IClient>());
