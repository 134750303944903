<p *ngFor="let item of items">{{item.name}}</p>
<div class="wrapper">
  <div class="item d-flex flex-column mb-3" *ngFor="let item of items" (click)="selectItem(item)" [ngClass]="{selected: selectedItems.indexOf(item) > -1}">
    <header class="mb-4">
      <div class="row align-items-center">
        <div class="col-md-5">
          <h3 class="m-0 mb-1 text-bold">{{item.title}}</h3>
          <span class="label">{{item.category.name}}</span>
        </div>
        <div class="col-md-5 d-flex flex-column">
          <p class="m-0 p-0">{{item.startDate | date: 'yyyy'}} - {{item.endDate | date: 'yyyy'}}</p>
          <p class="m-0 p-0"><span i18n>Score</span> {{item.grade}}<span *ngIf="item.withDistinction === true" i18n> with distinction</span>
          </p>
        </div>
        <div class="col-md-2 text-right">
          <button mat-icon-button (click)="editItem($event, item)">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
    </header>

    <main>
      {{item.description}}
    </main>

    <mat-divider class="mt-3"></mat-divider>
  </div>
</div>
