import { Component, OnInit } from '@angular/core';
import {select, Store} from '@ngrx/store';
import IStore from '../../interfaces/IStore';
import IProcessState from '../../interfaces/IProcessState';
import {Router} from '@angular/router';
import IResearch from '../../interfaces/IResearch';
import ISubject from '../../interfaces/ISubject';
import IAnonymousCandidate from '../../interfaces/IAnonymousCandidate';
import {AuthService} from '../../services/auth.service';
import {ProcessService} from '../../services/process.service';
import ISurvey from "../../interfaces/ISurvey";
import IClient from "../../interfaces/IClient";

@Component({
  selector: 'app-process-login-screen',
  templateUrl: './process-do-screen.component.html',
  styleUrls: ['./process-do-screen.component.styl']
})
export class ProcessDoScreenComponent implements OnInit {

  loaded = false;
  research: IResearch = null;
  survey: ISurvey = null;
  client: IClient = null;
  candidate: ISubject|IAnonymousCandidate = null;

  constructor(private store: Store<IStore>, private router: Router, private processService: ProcessService, private authService: AuthService) {
    this.store.pipe().subscribe((state: IStore) => {
      if (state.process.research === null || state.process.candidate === null || state.auth.candidate === null) {
        this.router.navigateByUrl('/process/landing');
      } else if (!this.research || !this.candidate) {
        this.loaded = true;
        this.research = state.process.research;
        this.candidate = state.process.candidate;
        this.survey = state.process.survey;
        this.client = state.process.client;
      }
    });
  }

  ngOnInit(): void {
  }

}
