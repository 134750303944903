<ag-grid-angular
  class="ag-theme-material ag-grid"
  [style]="{height: gridHeight + 'px'}"
  [context]="gridContext"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [pagination]="pagination"
  [paginationPageSize]="paginationSize"
  [defaultColDef]="defaultColDef"
  [frameworkComponents]="frameworkComponents"
  [rowSelection]="rowSelection"
  [rowDragManaged]="rowDragManaged"
  [animateRows]="animateRows"
  [groupUseEntireRow]="true"
  [groupRowRendererParams]="groupRowRendererParams"
  [columnTypes]="columnTypes"
  [suppressRowClickSelection]="true"
  [statusBar]="statusBar"
  (selectionChanged)="onSelectionChanged($event)"
  (gridReady)="onGridReady($event)"
></ag-grid-angular>
