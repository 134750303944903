<ng-container *ngIf="group.child.startMessage && current === null">
  <p class="text-center spacing-top-20">{{group.child.startMessage}}</p>
  <div class="button-bar spacing-bottom-10 spacing-top-auto justify-content-center">
    <button mat-stroked-button class="text-upper" (click)="prev()" [disabled]="!canGoPrev()" *ngIf="research.survey.allowStepChange === true" i18n>Previous</button>
    <button mat-stroked-button class="text-upper" color="primary" (click)="next()" [disabled]="!canGoNext()" [ngClass]="{'spacing-left-5': research.survey.allowStepChange === true}" i18n>Next</button>
  </div>
</ng-container>

<ng-container *ngIf="group.child.endMessage && current === null && (currentIndex + 1) === group.child.children.length">
  <p class="text-center spacing-top-20">{{group.child.endMessage}}</p>
  <div class="button-bar spacing-bottom-10 spacing-top-auto justify-content-center">
    <button mat-stroked-button class="text-upper" (click)="prev()" [disabled]="!canGoPrev()" *ngIf="research.survey.allowStepChange === true" i18n>Previous</button>
    <button mat-stroked-button class="text-upper" color="primary" (click)="next()" [disabled]="!canGoNext()" [ngClass]="{'spacing-left-5': research.survey.allowStepChange === true}" i18n>Next</button>
  </div>
</ng-container>

<ng-container *ngIf="currentIndex > -1 && current !== null">
  <ng-container [ngSwitch]="current.type" *ngIf="group.child.displayAs === groupDisplayAs.NORMAL">
    <app-process-information-element [element]="current" *ngSwitchCase="elementType.TEXT"></app-process-information-element>
    <app-process-information-element [element]="current" *ngSwitchCase="elementType.RICH_MEDIA"></app-process-information-element>
    <app-process-group #groupComponent [group]="current" *ngSwitchCase="elementType.GROUP"></app-process-group>
    <app-process-question-element #questionElement [element]="current" (valueChanged)="onValueChange($event)" *ngSwitchDefault></app-process-question-element>
  </ng-container>
  <div class="button-bar spacing-bottom-10 spacing-top-auto justify-content-center">
    <button mat-stroked-button class="text-upper" (click)="prev()" [disabled]="!canGoPrev()" *ngIf="research.survey.allowStepChange === true" i18n>Previous</button>
    <button mat-stroked-button class="text-upper" color="primary" (click)="next()" [disabled]="!canGoNext()" [ngClass]="{'spacing-left-5': research.survey.allowStepChange === true}" i18n>Next</button>
  </div>
</ng-container>
