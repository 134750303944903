import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-standard-cell-renderer',
  templateUrl: './standard-cell-renderer.component.html',
  styleUrls: ['./standard-cell-renderer.component.styl']
})
export class StandardCellRendererComponent {

  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  getValue(): any {
    if (this.params.colDef && this.params.colDef.field && this.params.colDef.field.indexOf('.') > -1) {
      return _.get(this.params.data, this.params.colDef.field, null);
    }

    return this.params.valueFormatted || this.params.value;
  }

}
