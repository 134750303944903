import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ForgotPasswordDialogComponent} from '../../components/forgot-password-dialog/forgot-password-dialog.component';
import {ApiService} from '../../services/api.service';
import IAuthResponse from '../../interfaces/IAuthResponse';
import {AuthService} from '../../services/auth.service';
import {Observable, Subject, Subscription} from 'rxjs';
import ISubscriptionResponse from '../../interfaces/ISubscriptionResponse';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.styl']
})
export class LoginScreenComponent implements OnInit {

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  private subscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.subscription = this.authService.subject.subscribe((response: ISubscriptionResponse) => {
      this.subscription.unsubscribe();
      this.router.navigateByUrl('/dashboard');
    });
  }

  getErrorMessage(field: AbstractControl): string {
    if (field.errors.required) {
      return $localize`The field is required`;
    }

    if (field.errors.email) {
      return $localize`The field is supposed to be a valid email address`;
    }

    return '';
  }

  login(): void {
    if (this.loginForm.invalid) {
      return;
    }

    const value = this.loginForm.getRawValue();

    this.apiService.login(value.email, value.password).then(async (response: IAuthResponse) => {
      this.authService.setToken(response.token);
      this.authService.setUserRole(await this.apiService.getRole(response.user.role));
      if (response.user.client) {
        this.authService.setUserClient(await this.apiService.getClient(response.user.client));
      }
      this.authService.setUser(response.user);
    });
  }

  openForgotPasswordDialog(): void {
    const dialogRef = this.dialog.open(ForgotPasswordDialogComponent, {
      width: '98%',
      maxWidth: '300px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(email => {
      this.apiService.sendRecoveryLink(email).then().catch();
    });
  }

  formSubmitOnEnter(ev: any): boolean {
    if (ev.key === 'Enter' || ev.keyCode === 13) {
      if (!this.loginForm.invalid) {
        this.login();
      }

      ev.stopPropagation();
      ev.preventDefault();
      return false;
    }

    return true;
  }

}
