<h1 mat-dialog-title i18n>Add new property</h1>
<mat-dialog-content>
  <div class="d-flex flex-column">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label i18n>Property Name</mat-label>
      <input name="property-name" matInput type="text" [(ngModel)]="propertyName" />
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" i18n>Close</button>
  <button mat-button color="primary" [disabled]="!propertyName" (click)="confirm()" i18n>Confirm</button>
</mat-dialog-actions>
