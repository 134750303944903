import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import IResearch from '../../interfaces/IResearch';
import ISurvey from "../../interfaces/ISurvey";

@Component({
  selector: 'app-privacy-policy-dialog',
  templateUrl: './privacy-policy-dialog.component.html',
  styleUrls: ['./privacy-policy-dialog.component.styl']
})
export class PrivacyPolicyDialogComponent implements OnInit {

  personalDataAgreement = false;
  personalSpecificDataAgreement = false;
  marketingAgreement = false;
  dataUsageForCandidateResearchAgreement = false;
  dataUsageForStatisticsAndReportsAgreement = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {research: IResearch, survey: ISurvey}, private dialogRef: MatDialogRef<PrivacyPolicyDialogComponent>) { }

  ngOnInit(): void {
  }

  close(rejected: boolean = false): void {
    this.dialogRef.close({
      personalDataAgreement: rejected ? false : this.personalDataAgreement,
      personalSpecificDataAgreement: rejected ? false : this.personalSpecificDataAgreement,
      marketingAgreement: rejected ? false : this.marketingAgreement,
      dataUsageForCandidateResearchAgreement: rejected ? false : this.dataUsageForCandidateResearchAgreement,
      dataUsageForStatisticsAndReportsAgreement: rejected ? false : this.dataUsageForStatisticsAndReportsAgreement
    });
  }

}
