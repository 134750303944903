import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-smart-select',
  templateUrl: './smart-select.component.html',
  styleUrls: ['./smart-select.component.styl']
})
export class SmartSelectComponent implements OnInit {

  @Input() label: string;
  @Input() options: {label: string, value: any}[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  add(): void {

  }

}
