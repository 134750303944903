import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import IRestResponse from '../interfaces/IRestResponse';
import IAuthResponse from '../interfaces/IAuthResponse';
import {MatSnackBar} from '@angular/material/snack-bar';
import IUser from '../interfaces/IUser';
import IRole from '../interfaces/IRole';
import ISubject from '../interfaces/ISubject';
import ISurvey from '../interfaces/ISurvey';
import ISurveyGroup from '../interfaces/ISurveyGroup';
import IClient from '../interfaces/IClient';
import IGroup from '../interfaces/IGroup';
import IProject from '../interfaces/IProject';
import {AuthService} from './auth.service';
import IJob from '../interfaces/IJob';
import IResearch from '../interfaces/IResearch';
import ICandidateAuthResponse from '../interfaces/ICandidateAuthResponse';
import IFile from '../interfaces/IFile';
import IResearchTrackRequestBody from '../interfaces/IResearchTrackRequestBody';
import IResearchResult from '../interfaces/IResearchResult';
import IEducationLevel from '../interfaces/IEducationLevel';
import IEducationCategory from '../interfaces/IEducationCategory';
import IEducationInstitute from '../interfaces/IEducationInstitute';
import IEducationCourse from '../interfaces/IEducationCourse';
import ICompany from '../interfaces/ICompany';
import IWorkSector from '../interfaces/IWorkSector';
import IWorkProfession from '../interfaces/IWorkProfession';
import IJobContractType from '../interfaces/IJobContractType';
import IExtractionScript from '../interfaces/IExtractionScript';
import IPermission from '../interfaces/IPermission';
import IReport from '../interfaces/IReport';
import IReportResults from '../interfaces/IReportResults';
import ISkill from '../interfaces/ISkill';
import IPrivacyAgreements from "../interfaces/IPrivacyAgreements";

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  BASE_URL = '/api/v1';

  constructor(private http: HttpClient, private snackBar: MatSnackBar, private authService: AuthService) { }

  /* AUTHENTICATION */

  login(email: string, password: string): Promise<IAuthResponse> {
    return new Promise<IAuthResponse>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/auth/login`, {
        email,
        password
      }).subscribe((response: IRestResponse<IAuthResponse>) => {
        this.handleHttpSuccess<IAuthResponse>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IAuthResponse>(error);
        reject();
      });
    });
  }

  candidateLogin(emailOrPhone: string, password: string, trace: string): Promise<ICandidateAuthResponse> {
    return new Promise<ICandidateAuthResponse>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/auth/login/candidate`, {
        authKey: emailOrPhone,
        password,
        trace
      }).subscribe((response: IRestResponse<ICandidateAuthResponse>) => {
        this.handleHttpSuccess<ICandidateAuthResponse>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ICandidateAuthResponse>(error);
        reject();
      });
    });
  }

  getUserByToken(token: string): Promise<IUser> {
    return new Promise<IUser> ((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/auth/retrieve`).subscribe((response: IRestResponse<IUser>) => {
        this.handleHttpSuccess<IUser>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IUser>(error);
        reject();
      });
    });
  }

  /* USERS */

  getUsers(): Promise<IUser[]> {
    return new Promise<IUser[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/user`).subscribe((response: IRestResponse<IUser[]>) => {
        this.handleHttpSuccess<IUser[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IUser[]>(error);
        reject();
      });
    });
  }

  getUser(id: string): Promise<IUser> {
    return new Promise<IUser>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/user/${id}`).subscribe((response: IRestResponse<IUser>) => {
        this.handleHttpSuccess<IUser>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IUser>(error);
        reject();
      });
    });
  }

  createUser(user: IUser): Promise<IUser> {
    return new Promise<IUser>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/user`, user).subscribe((response: IRestResponse<IUser>) => {
        this.handleHttpSuccess<IUser>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IUser>(error);
        reject();
      });
    });
  }

  updateUser(id: string, user: IUser): Promise<IUser> {
    return new Promise<IUser>((resolve: any, reject: any) => {
      this.http.put(`${this.BASE_URL}/user/${id}`, user).subscribe((response: IRestResponse<IUser>) => {
        this.handleHttpSuccess<IUser>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IUser>(error);
        reject();
      });
    });
  }

  deleteUser(id: string): Promise<IUser> {
    return new Promise<IUser>((resolve: any, reject: any) => {
      this.http.delete(`${this.BASE_URL}/user/${id}`).subscribe((response: IRestResponse<IUser>) => {
        this.handleHttpSuccess<IUser>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IUser>(error);
        reject();
      });
    });
  }

  /* ROLES */

  getRoles(): Promise<IRole[]> {
    return new Promise<IRole[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/role`).subscribe((response: IRestResponse<IRole[]>) => {
        this.handleHttpSuccess<IRole[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IRole[]>(error);
        reject();
      });
    });
  }

  getRole(id: string): Promise<IRole> {
    return new Promise<IRole>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/role/${id}`).subscribe((response: IRestResponse<IRole>) => {
        this.handleHttpSuccess<IRole>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IRole>(error);
        reject();
      });
    });
  }

  createRole(role: IRole): Promise<IRole> {
    return new Promise<IRole>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/role`, role).subscribe((response: IRestResponse<IRole>) => {
        this.handleHttpSuccess<IRole>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IRole>(error);
        reject();
      });
    });
  }

  updateRole(id: string, role: IRole): Promise<IRole> {
    return new Promise<IRole>((resolve: any, reject: any) => {
      this.http.put(`${this.BASE_URL}/role/${id}`, role).subscribe((response: IRestResponse<IRole>) => {
        this.handleHttpSuccess<IRole>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IRole>(error);
        reject();
      });
    });
  }

  deleteRole(id: string): Promise<IRole> {
    return new Promise<IRole>((resolve: any, reject: any) => {
      this.http.delete(`${this.BASE_URL}/role/${id}`).subscribe((response: IRestResponse<IRole>) => {
        this.handleHttpSuccess<IRole>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IRole>(error);
        reject();
      });
    });
  }

  /* EDUCATION LEVELS */

  getEducationLevels(): Promise<IEducationLevel[]> {
    return new Promise<IEducationLevel[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/education/level`).subscribe((response: IRestResponse<IEducationLevel[]>) => {
        this.handleHttpSuccess<IEducationLevel[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IEducationLevel[]>(error);
        reject();
      });
    });
  }

  getEducationLevel(id: string): Promise<IEducationLevel> {
    return new Promise<IEducationLevel>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/education/level/${id}`).subscribe((response: IRestResponse<IEducationLevel>) => {
        this.handleHttpSuccess<IEducationLevel>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IEducationLevel>(error);
        reject();
      });
    });
  }

  createEducationLevel(data: IEducationLevel): Promise<IEducationLevel> {
    return new Promise<IEducationLevel>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/education/level`, data).subscribe((response: IRestResponse<IEducationLevel>) => {
        this.handleHttpSuccess<IEducationLevel>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IEducationLevel>(error);
        reject();
      });
    });
  }

  updateEducationLevel(id: string, data: IEducationLevel): Promise<IEducationLevel> {
    return new Promise<IEducationLevel>((resolve: any, reject: any) => {
      this.http.put(`${this.BASE_URL}/education/level/${id}`, data).subscribe((response: IRestResponse<IEducationLevel>) => {
        this.handleHttpSuccess<IEducationLevel>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IEducationLevel>(error);
        reject();
      });
    });
  }

  deleteEducationLevel(id: string): Promise<IEducationLevel> {
    return new Promise<IEducationLevel>((resolve: any, reject: any) => {
      this.http.delete(`${this.BASE_URL}/education/level/${id}`).subscribe((response: IRestResponse<IEducationLevel>) => {
        this.handleHttpSuccess<IEducationLevel>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IEducationLevel>(error);
        reject();
      });
    });
  }

  /* EDUCATION CATEGORIES */

  getEducationCategories(): Promise<IEducationCategory[]> {
    return new Promise<IEducationCategory[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/education/category`).subscribe((response: IRestResponse<IEducationCategory[]>) => {
        this.handleHttpSuccess<IEducationCategory[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IEducationCategory[]>(error);
        reject();
      });
    });
  }

  getEducationCategory(id: string): Promise<IEducationCategory> {
    return new Promise<IEducationCategory>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/education/category/${id}`).subscribe((response: IRestResponse<IEducationCategory>) => {
        this.handleHttpSuccess<IEducationCategory>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IEducationCategory>(error);
        reject();
      });
    });
  }

  createEducationCategory(data: IEducationCategory): Promise<IEducationCategory> {
    return new Promise<IEducationCategory>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/education/category`, data).subscribe((response: IRestResponse<IEducationCategory>) => {
        this.handleHttpSuccess<IEducationCategory>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IEducationCategory>(error);
        reject();
      });
    });
  }

  updateEducationCategory(id: string, data: IEducationCategory): Promise<IEducationCategory> {
    return new Promise<IEducationCategory>((resolve: any, reject: any) => {
      this.http.put(`${this.BASE_URL}/education/category/${id}`, data).subscribe((response: IRestResponse<IEducationCategory>) => {
        this.handleHttpSuccess<IEducationCategory>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IEducationCategory>(error);
        reject();
      });
    });
  }

  deleteEducationCategory(id: string): Promise<IEducationCategory> {
    return new Promise<IEducationCategory>((resolve: any, reject: any) => {
      this.http.delete(`${this.BASE_URL}/education/category/${id}`).subscribe((response: IRestResponse<IEducationCategory>) => {
        this.handleHttpSuccess<IEducationCategory>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IEducationCategory>(error);
        reject();
      });
    });
  }

  /* EDUCATION INSTISTUTES */

  getEducationInstitutes(): Promise<IEducationInstitute[]> {
    return new Promise<IEducationInstitute[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/education/institute`).subscribe((response: IRestResponse<IEducationInstitute[]>) => {
        this.handleHttpSuccess<IEducationInstitute[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IEducationInstitute[]>(error);
        reject();
      });
    });
  }

  getEducationInstitute(id: string): Promise<IEducationInstitute> {
    return new Promise<IEducationInstitute>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/education/institute/${id}`).subscribe((response: IRestResponse<IEducationInstitute>) => {
        this.handleHttpSuccess<IEducationInstitute>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IEducationInstitute>(error);
        reject();
      });
    });
  }

  createEducationInstitute(data: IEducationInstitute): Promise<IEducationInstitute> {
    return new Promise<IEducationInstitute>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/education/institute`, data).subscribe((response: IRestResponse<IEducationInstitute>) => {
        this.handleHttpSuccess<IEducationInstitute>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IEducationInstitute>(error);
        reject();
      });
    });
  }

  updateEducationInstitute(id: string, data: IEducationInstitute): Promise<IEducationInstitute> {
    return new Promise<IEducationInstitute>((resolve: any, reject: any) => {
      this.http.put(`${this.BASE_URL}/education/institute/${id}`, data).subscribe((response: IRestResponse<IEducationInstitute>) => {
        this.handleHttpSuccess<IEducationInstitute>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IEducationInstitute>(error);
        reject();
      });
    });
  }

  deleteEducationInstitute(id: string): Promise<IEducationInstitute> {
    return new Promise<IEducationInstitute>((resolve: any, reject: any) => {
      this.http.delete(`${this.BASE_URL}/education/institute/${id}`).subscribe((response: IRestResponse<IEducationInstitute>) => {
        this.handleHttpSuccess<IEducationInstitute>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IEducationInstitute>(error);
        reject();
      });
    });
  }

  /* SUBJECTS */

  getSubjects(): Promise<ISubject[]> {
    return new Promise<ISubject[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/subject`).subscribe((response: IRestResponse<ISubject[]>) => {
        this.handleHttpSuccess<ISubject[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISubject[]>(error);
        reject();
      });
    });
  }

  getSubject(id: string): Promise<ISubject> {
    return new Promise<ISubject>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/subject/${id}`).subscribe((response: IRestResponse<ISubject>) => {
        this.handleHttpSuccess<ISubject>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISubject>(error);
        reject();
      });
    });
  }

  createSubject(subject: ISubject): Promise<ISubject> {
    return new Promise<ISubject>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/subject`, subject).subscribe((response: IRestResponse<ISubject>) => {
        this.handleHttpSuccess<ISubject>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISubject>(error);
        reject();
      });
    });
  }

  updateSubject(id: string, subject: ISubject): Promise<ISubject> {
    return new Promise<ISubject>((resolve: any, reject: any) => {
      this.http.put(`${this.BASE_URL}/subject/${id}`, subject).subscribe((response: IRestResponse<ISubject>) => {
        this.handleHttpSuccess<ISubject>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISubject>(error);
        reject();
      });
    });
  }

  updateSubjectPrivacyAgreements(id: string, privacyAgreements: IPrivacyAgreements): Promise<void> {
    return new Promise<void>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/subject/${id}/privacy-agreements`, privacyAgreements).subscribe((response: IRestResponse<void>) => {
        this.handleHttpSuccess<void>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<void>(error);
        reject();
      });
    });
  }

  deleteSubject(id: string): Promise<ISubject> {
    return new Promise<ISubject>((resolve: any, reject: any) => {
      this.http.delete(`${this.BASE_URL}/subject/${id}`).subscribe((response: IRestResponse<ISubject>) => {
        this.handleHttpSuccess<ISubject>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISubject>(error);
        reject();
      });
    });
  }

  getResearchesByCandidate(candidate: ISubject): Promise<IResearch[]> {
    return new Promise<IResearch[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/subject/${candidate.id}/researches`).subscribe((response: IRestResponse<IResearch[]>) => {
        this.handleHttpSuccess<IResearch[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IResearch[]>(error);
        reject();
      });
    });
  }

  /* SURVEYS */

  getSurveys(): Promise<ISurvey[]> {
    return new Promise<ISurvey[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/survey`).subscribe((response: IRestResponse<ISurvey[]>) => {
        this.handleHttpSuccess<ISurvey[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISurvey[]>(error);
        reject();
      });
    });
  }

  getSurvey(id: string): Promise<ISurvey> {
    return new Promise<ISurvey>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/survey/${id}`).subscribe((response: IRestResponse<ISurvey>) => {
        this.handleHttpSuccess<ISurvey>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISurvey>(error);
        reject();
      });
    });
  }

  createSurvey(role: ISurvey): Promise<ISurvey> {
    return new Promise<ISurvey>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/survey`, role).subscribe((response: IRestResponse<ISurvey>) => {
        this.handleHttpSuccess<ISurvey>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISurvey>(error);
        reject();
      });
    });
  }

  updateSurvey(id: string, survey: ISurvey): Promise<ISurvey> {
    return new Promise<ISurvey>((resolve: any, reject: any) => {
      this.http.put(`${this.BASE_URL}/survey/${id}`, survey).subscribe((response: IRestResponse<ISurvey>) => {
        this.handleHttpSuccess<ISurvey>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISurvey>(error);
        reject();
      });
    });
  }

  deleteSurvey(id: string): Promise<ISurvey> {
    return new Promise<ISurvey>((resolve: any, reject: any) => {
      this.http.delete(`${this.BASE_URL}/survey/${id}`).subscribe((response: IRestResponse<ISurvey>) => {
        this.handleHttpSuccess<ISurvey>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISurvey>(error);
        reject();
      });
    });
  }

  startResearch(research: IResearch): Promise<IResearch> {
    return new Promise<IResearch>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/survey/run`, research).subscribe((response: IRestResponse<IResearch>) => {
        this.handleHttpSuccess<IResearch>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IResearch>(error);
        reject();
      });
    });
  }

  startResearchResult(researchId: string, requestBody: IResearchTrackRequestBody): Promise<string> {
    const body: IResearchTrackRequestBody = {
      anonymousCandidate: null,
      answers: {},
      candidate: null,
      currentCode: null,
      lastCode: null,
      nextCode: null,
      token: null,
      trackingActivity: [],
      ...requestBody
    };

    return new Promise<string>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/research/${researchId}/start`, body).subscribe((response: IRestResponse<string>) => {
        this.handleHttpSuccess<string>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<string>(error);
        reject();
      });
    });
  }

  trackResearchResult(researchId: string, requestBody: IResearchTrackRequestBody): Promise<null> {
    const body: IResearchTrackRequestBody = {
      anonymousCandidate: null,
      answers: {},
      candidate: null,
      currentCode: null,
      lastCode: null,
      nextCode: null,
      token: null,
      trackingActivity: [],
      ...requestBody
    };

    return new Promise<null>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/research/${researchId}/track`, body).subscribe((response: IRestResponse<null>) => {
        this.handleHttpSuccess<null>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<null>(error);
        reject();
      });
    });
  }

  endResearchResult(researchId: string, requestBody: IResearchTrackRequestBody): Promise<null> {
    const body: IResearchTrackRequestBody = {
      anonymousCandidate: null,
      answers: {},
      candidate: null,
      currentCode: null,
      lastCode: null,
      nextCode: null,
      token: null,
      trackingActivity: [],
      failCode: null,
      ...requestBody
    };

    return new Promise<null>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/research/${researchId}/end`, body).subscribe((response: IRestResponse<null>) => {
        this.handleHttpSuccess<null>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<null>(error);
        reject();
      });
    });
  }

  getResearchesBySurvey(survey: ISurvey): Promise<IResearch[]> {
    return new Promise<IResearch[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/survey/${survey.id}/researches`).subscribe((response: IRestResponse<IResearch[]>) => {
        this.handleHttpSuccess<IResearch[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IResearch[]>(error);
        reject();
      });
    });
  }

  getResearchCandidateLink(researchId: string, candidateId: string): Promise<string> {
    return new Promise<string>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/research/${researchId}/invitation/${candidateId}`).subscribe((response: IRestResponse<string>) => {
        this.handleHttpSuccess<string>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<string>(error);
        reject();
      });
    });
  }

  getResearchShareLink(researchId: string): Promise<string> {
    return new Promise<string>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/research/${researchId}/link`).subscribe((response: IRestResponse<string>) => {
        this.handleHttpSuccess<string>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<string>(error);
        reject();
      });
    });
  }

  deconstructResearchShareLink(link: string): Promise<any> {
    return new Promise<any>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/research/deconstruct/link`, link).subscribe((response: IRestResponse<any>) => {
        this.handleHttpSuccess<any>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<any>(error);
        reject();
      });
    });
  }

  sendCandidateReminder(researchId: string, candidateId: string): Promise<null> {
    return new Promise<null>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/research/${researchId}/reminder/${candidateId}`).subscribe((response: IRestResponse<null>) => {
        this.handleHttpSuccess<null>(response);
        resolve();
      }, (error: any) => {
        this.handleHttpError<null>(error);
        reject();
      });
    });
  }

  sendEveryoneReminder(researchId: string): Promise<null> {
    return new Promise<null>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/research/${researchId}/reminder`).subscribe((response: IRestResponse<null>) => {
        this.handleHttpSuccess<null>(response);
        resolve();
      }, (error: any) => {
        this.handleHttpError<null>(error);
        reject();
      });
    });
  }

  exportResearchHorizontally(researchId: string, withScoring: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const params: any = {};
      params.scoring = withScoring;

      this.http.get(`${this.BASE_URL}/research/${researchId}/export/horizontal`, {
        responseType: 'blob',
        params
      }).subscribe(blob => {
        resolve();
        const newBlob = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.download = 'export.xlsx';
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      }, (error: any) => {
        this.handleHttpError<null>(error);
        reject();
      });
    });
  }

  connectCandidate(researchId: string, candidateId: string): Promise<null> {
    return new Promise<null>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/research/${researchId}/connect/${candidateId}`).subscribe((response: IRestResponse<null>) => {
        this.handleHttpSuccess<null>(response);
        resolve();
      }, (error: any) => {
        this.handleHttpError<null>(error);
        reject();
      });
    });
  }

  connectAndTraceCandidate(researchId: string, candidateId: string): Promise<string> {
    return new Promise<string>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/research/${researchId}/connect-trace/${candidateId}`).subscribe((response: IRestResponse<string>) => {
        this.handleHttpSuccess<string>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<string>(error);
        reject();
      });
    });
  }

  connectAndTraceAnonymousCandidate(researchId: string): Promise<string> {
    return new Promise<string>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/research/${researchId}/connect-trace`).subscribe((response: IRestResponse<string>) => {
        this.handleHttpSuccess<string>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<string>(error);
        reject();
      });
    });
  }

  /* RESEARCHES */

  getResearches(): Promise<IResearch[]> {
    return new Promise<IResearch[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/research`).subscribe((response: IRestResponse<IResearch[]>) => {
        this.handleHttpSuccess<IResearch[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IResearch[]>(error);
        reject();
      });
    });
  }

  getResearch(id: string): Promise<IResearch> {
    return new Promise<IResearch>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/research/${id}`).subscribe((response: IRestResponse<IResearch>) => {
        this.handleHttpSuccess<IResearch>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IResearch>(error);
        reject();
      });
    });
  }

  getResearchCandidates(id: string): Promise<ISubject[]> {
    return new Promise<ISubject[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/research/${id}/candidates`).subscribe((response: IRestResponse<ISubject[]>) => {
        this.handleHttpSuccess<ISubject[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISubject[]>(error);
        reject();
      });
    });
  }

  createResearch(research: IResearch): Promise<IResearch> {
    return new Promise<IResearch>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/research`, research).subscribe((response: IRestResponse<IResearch>) => {
        this.handleHttpSuccess<IResearch>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IResearch>(error);
        reject();
      });
    });
  }

  updateResearch(id: string, research: IResearch): Promise<IResearch> {
    return new Promise<IResearch>((resolve: any, reject: any) => {
      this.http.put(`${this.BASE_URL}/research/${id}`, research).subscribe((response: IRestResponse<IResearch>) => {
        this.handleHttpSuccess<IResearch>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IResearch>(error);
        reject();
      });
    });
  }

  deleteResearch(id: string): Promise<IResearch> {
    return new Promise<IResearch>((resolve: any, reject: any) => {
      this.http.delete(`${this.BASE_URL}/research/${id}`).subscribe((response: IRestResponse<IResearch>) => {
        this.handleHttpSuccess<IResearch>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IResearch>(error);
        reject();
      });
    });
  }

  getResultsByResearch(research: IResearch): Promise<IResearchResult[]> {
    return new Promise<IResearchResult[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/research/${research.id}/results`).subscribe((response: IRestResponse<IResearchResult[]>) => {
        this.handleHttpSuccess<IResearchResult[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IResearchResult[]>(error);
        reject();
      });
    });
  }

  /* SURVEY GROUPS */

  getSurveyGroups(): Promise<ISurveyGroup[]> {
    return new Promise<ISurveyGroup[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/survey-group`).subscribe((response: IRestResponse<ISurveyGroup[]>) => {
        this.handleHttpSuccess<ISurveyGroup[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISurveyGroup[]>(error);
        reject();
      });
    });
  }

  getSurveyGroup(id: string): Promise<ISurveyGroup> {
    return new Promise<ISurveyGroup>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/survey-group/${id}`).subscribe((response: IRestResponse<ISurveyGroup>) => {
        this.handleHttpSuccess<ISurveyGroup>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISurveyGroup>(error);
        reject();
      });
    });
  }

  createSurveyGroup(role: ISurveyGroup): Promise<ISurveyGroup> {
    return new Promise<ISurveyGroup>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/survey-group`, role).subscribe((response: IRestResponse<ISurveyGroup>) => {
        this.handleHttpSuccess<ISurveyGroup>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISurveyGroup>(error);
        reject();
      });
    });
  }

  updateSurveyGroup(id: string, role: ISurveyGroup): Promise<ISurveyGroup> {
    return new Promise<ISurveyGroup>((resolve: any, reject: any) => {
      this.http.put(`${this.BASE_URL}/survey-group/${id}`, role).subscribe((response: IRestResponse<ISurveyGroup>) => {
        this.handleHttpSuccess<ISurveyGroup>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISurveyGroup>(error);
        reject();
      });
    });
  }

  multipleSaveSurveyGroups(surveyGroups: ISurveyGroup[], deleteSurveyGroups: ISurveyGroup[] = []): Promise<void> {
    return new Promise<void>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/survey-group/multiple`, {surveyGroups, deleteSurveyGroups}).subscribe((response: IRestResponse<any>) => {
        this.handleHttpSuccess<any>(response);
        resolve();
      }, (error: any) => {
        this.handleHttpError<any>(error);
        reject();
      });
    });
  }

  deleteSurveyGroup(id: string): Promise<ISurveyGroup> {
    return new Promise<ISurveyGroup>((resolve: any, reject: any) => {
      this.http.delete(`${this.BASE_URL}/survey-group/${id}`).subscribe((response: IRestResponse<ISurveyGroup>) => {
        this.handleHttpSuccess<ISurveyGroup>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISurveyGroup>(error);
        reject();
      });
    });
  }

  /* CLIENTS */

  getClients(): Promise<IClient[]> {
    return new Promise<IClient[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/client`).subscribe((response: IRestResponse<IClient[]>) => {
        this.handleHttpSuccess<IClient[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IClient[]>(error);
        reject();
      });
    });
  }

  getClient(id: string): Promise<IClient> {
    return new Promise<IClient>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/client/${id}`).subscribe((response: IRestResponse<IClient>) => {
        this.handleHttpSuccess<IClient>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IClient>(error);
        reject();
      });
    });
  }

  createClient(role: IClient): Promise<IClient> {
    return new Promise<IClient>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/client`, role).subscribe((response: IRestResponse<IClient>) => {
        this.handleHttpSuccess<IClient>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IClient>(error);
        reject();
      });
    });
  }

  updateClient(id: string, role: IClient): Promise<IClient> {
    return new Promise<IClient>((resolve: any, reject: any) => {
      this.http.put(`${this.BASE_URL}/client/${id}`, role).subscribe((response: IRestResponse<IClient>) => {
        this.handleHttpSuccess<IClient>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IClient>(error);
        reject();
      });
    });
  }

  deleteClient(id: string): Promise<IClient> {
    return new Promise<IClient>((resolve: any, reject: any) => {
      this.http.delete(`${this.BASE_URL}/client/${id}`).subscribe((response: IRestResponse<IClient>) => {
        this.handleHttpSuccess<IClient>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IClient>(error);
        reject();
      });
    });
  }

  /* PERMISSIONS */

  getPermissions(): Promise<IPermission[]> {
    return new Promise<IPermission[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/permission`).subscribe((response: IRestResponse<IPermission[]>) => {
        this.handleHttpSuccess<IPermission[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IPermission[]>(error);
        reject();
      });
    });
  }

  /* PROJECTS */

  getProjects(): Promise<IProject[]> {
    return new Promise<IProject[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/project`).subscribe((response: IRestResponse<IProject[]>) => {
        this.handleHttpSuccess<IProject[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IProject[]>(error);
        reject();
      });
    });
  }

  getProject(id: string): Promise<IProject> {
    return new Promise<IProject>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/project/${id}`).subscribe((response: IRestResponse<IProject>) => {
        this.handleHttpSuccess<IProject>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IProject>(error);
        reject();
      });
    });
  }

  getConnectedProjectSubjects(id: string): Promise<ISubject[]> {
    return new Promise<ISubject[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/project/${id}/subjects`).subscribe((response: IRestResponse<ISubject[]>) => {
        this.handleHttpSuccess<ISubject[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISubject[]>(error);
        reject();
      });
    });
  }

  getConnectedProjectGroups(id: string): Promise<IGroup[]> {
    return new Promise<IGroup[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/project/${id}/groups`).subscribe((response: IRestResponse<IGroup[]>) => {
        this.handleHttpSuccess<IGroup[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IGroup[]>(error);
        reject();
      });
    });
  }

  connectProjectSubjects(id: string, subjectIds: string[]): Promise<ISubject[]> {
    return new Promise<ISubject[]>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/project/${id}/subjects`, subjectIds).subscribe((response: IRestResponse<ISubject[]>) => {
        this.handleHttpSuccess<ISubject[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISubject[]>(error);
        reject();
      });
    });
  }

  disconnectProjectSubjects(id: string, subjectIds: string[]): Promise<ISubject[]> {
    return new Promise<ISubject[]>((resolve: any, reject: any) => {
      this.http.request('delete', `${this.BASE_URL}/project/${id}/subjects`, {
        body: subjectIds
      }).subscribe((response: IRestResponse<ISubject[]>) => {
        this.handleHttpSuccess<ISubject[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISubject[]>(error);
        reject();
      });
    });
  }

  createProject(role: IProject): Promise<IProject> {
    return new Promise<IProject>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/project`, role).subscribe((response: IRestResponse<IProject>) => {
        this.handleHttpSuccess<IProject>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IProject>(error);
        reject();
      });
    });
  }

  updateProject(id: string, project: IProject): Promise<IProject> {
    return new Promise<IProject>((resolve: any, reject: any) => {
      this.http.put(`${this.BASE_URL}/project/${id}`, project).subscribe((response: IRestResponse<IProject>) => {
        this.handleHttpSuccess<IProject>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IProject>(error);
        reject();
      });
    });
  }

  deleteProject(id: string): Promise<IProject> {
    return new Promise<IProject>((resolve: any, reject: any) => {
      this.http.delete(`${this.BASE_URL}/project/${id}`).subscribe((response: IRestResponse<IProject>) => {
        this.handleHttpSuccess<IProject>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IProject>(error);
        reject();
      });
    });
  }

  getResearchesByProject(project: IProject): Promise<IResearch[]> {
    return new Promise<IResearch[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/project/${project.id}/researches`).subscribe((response: IRestResponse<IResearch[]>) => {
        this.handleHttpSuccess<IResearch[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IResearch[]>(error);
        reject();
      });
    });
  }

  /* GROUPS */

  getGroups(): Promise<IGroup[]> {
    return new Promise<IGroup[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/group`).subscribe((response: IRestResponse<IGroup[]>) => {
        this.handleHttpSuccess<IGroup[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IGroup[]>(error);
        reject();
      });
    });
  }

  getGroup(id: string): Promise<IGroup> {
    return new Promise<IGroup>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/group/${id}`).subscribe((response: IRestResponse<IGroup>) => {
        this.handleHttpSuccess<IGroup>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IGroup>(error);
        reject();
      });
    });
  }

  getConnectedGroupSubjects(id: string): Promise<ISubject[]> {
    return new Promise<ISubject[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/group/${id}/subjects`).subscribe((response: IRestResponse<ISubject[]>) => {
        this.handleHttpSuccess<ISubject[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISubject[]>(error);
        reject();
      });
    });
  }

  getConnectedGroupProjects(id: string): Promise<IProject[]> {
    return new Promise<IProject[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/group/${id}/projects`).subscribe((response: IRestResponse<IProject[]>) => {
        this.handleHttpSuccess<IProject[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IProject[]>(error);
        reject();
      });
    });
  }

  connectGroupSubjects(id: string, subjectIds: string[]): Promise<ISubject[]> {
    return new Promise<ISubject[]>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/group/${id}/subjects`, subjectIds).subscribe((response: IRestResponse<ISubject[]>) => {
        this.handleHttpSuccess<ISubject[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISubject[]>(error);
        reject();
      });
    });
  }

  disconnectGroupSubjects(id: string, subjectIds: string[]): Promise<ISubject[]> {
    return new Promise<ISubject[]>((resolve: any, reject: any) => {
      this.http.request('delete', `${this.BASE_URL}/group/${id}/subjects`, {
        body: subjectIds
      }).subscribe((response: IRestResponse<ISubject[]>) => {
        this.handleHttpSuccess<ISubject[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISubject[]>(error);
        reject();
      });
    });
  }

  createGroup(role: IGroup): Promise<IGroup> {
    return new Promise<IGroup>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/group`, role).subscribe((response: IRestResponse<IGroup>) => {
        this.handleHttpSuccess<IGroup>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IGroup>(error);
        reject();
      });
    });
  }

  updateGroup(id: string, role: IGroup): Promise<IGroup> {
    return new Promise<IGroup>((resolve: any, reject: any) => {
      this.http.put(`${this.BASE_URL}/group/${id}`, role).subscribe((response: IRestResponse<IGroup>) => {
        this.handleHttpSuccess<IGroup>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IGroup>(error);
        reject();
      });
    });
  }

  deleteGroup(id: string): Promise<IGroup> {
    return new Promise<IGroup>((resolve: any, reject: any) => {
      this.http.delete(`${this.BASE_URL}/group/${id}`).subscribe((response: IRestResponse<IGroup>) => {
        this.handleHttpSuccess<IGroup>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IGroup>(error);
        reject();
      });
    });
  }

  getResearchesByGroup(group: IGroup): Promise<IResearch[]> {
    return new Promise<IResearch[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/group/${group.id}/researches`).subscribe((response: IRestResponse<IResearch[]>) => {
        this.handleHttpSuccess<IResearch[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IResearch[]>(error);
        reject();
      });
    });
  }

  /* JOBS */

  getJobs(): Promise<IJob[]> {
    return new Promise<IJob[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/job`).subscribe((response: IRestResponse<IJob[]>) => {
        this.handleHttpSuccess<IJob[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IJob[]>(error);
        reject();
      });
    });
  }

  getJob(id: string): Promise<IJob> {
    return new Promise<IJob>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/job/${id}`).subscribe((response: IRestResponse<IJob>) => {
        this.handleHttpSuccess<IJob>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IJob>(error);
        reject();
      });
    });
  }

  getConnectedJobSubjects(id: string): Promise<ISubject[]> {
    return new Promise<ISubject[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/job/${id}/subjects`).subscribe((response: IRestResponse<ISubject[]>) => {
        this.handleHttpSuccess<ISubject[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISubject[]>(error);
        reject();
      });
    });
  }

  connectJobSubjects(id: string, subjectIds: string[]): Promise<ISubject[]> {
    return new Promise<ISubject[]>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/job/${id}/subjects`, subjectIds).subscribe((response: IRestResponse<ISubject[]>) => {
        this.handleHttpSuccess<ISubject[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISubject[]>(error);
        reject();
      });
    });
  }

  disconnectJobSubjects(id: string, subjectIds: string[]): Promise<ISubject[]> {
    return new Promise<ISubject[]>((resolve: any, reject: any) => {
      this.http.request('delete', `${this.BASE_URL}/job/${id}/subjects`, {
        body: subjectIds
      }).subscribe((response: IRestResponse<ISubject[]>) => {
        this.handleHttpSuccess<ISubject[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<ISubject[]>(error);
        reject();
      });
    });
  }

  createJob(job: IJob): Promise<IJob> {
    return new Promise<IJob>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/job`, job).subscribe((response: IRestResponse<IJob>) => {
        this.handleHttpSuccess<IJob>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IJob>(error);
        reject();
      });
    });
  }

  updateJob(id: string, job: IJob): Promise<IJob> {
    return new Promise<IJob>((resolve: any, reject: any) => {
      this.http.put(`${this.BASE_URL}/job/${id}`, job).subscribe((response: IRestResponse<IJob>) => {
        this.handleHttpSuccess<IJob>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IJob>(error);
        reject();
      });
    });
  }

  deleteJob(id: string): Promise<IJob> {
    return new Promise<IJob>((resolve: any, reject: any) => {
      this.http.delete(`${this.BASE_URL}/job/${id}`).subscribe((response: IRestResponse<IJob>) => {
        this.handleHttpSuccess<IJob>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IJob>(error);
        reject();
      });
    });
  }

  getResearchesByJob(job: IJob): Promise<IResearch[]> {
    return new Promise<IResearch[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/job/${job.id}/researches`).subscribe((response: IRestResponse<IResearch[]>) => {
        this.handleHttpSuccess<IResearch[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IResearch[]>(error);
        reject();
      });
    });
  }

  /* EXTRACTION SCRIPTS */

  getExtractionScripts(): Promise<IExtractionScript[]> {
    return this.crudGetAll<IExtractionScript>('extraction-script');
  }

  getExtractionScript(id: string): Promise<IExtractionScript> {
    return this.crudGet<IExtractionScript>(id, 'extraction-script');
  }

  createExtractionScript(job: IExtractionScript): Promise<IExtractionScript> {
    return this.crudCreate<IExtractionScript>(job, 'extraction-script');
  }

  updateExtractionScript(id: string, job: IExtractionScript): Promise<IExtractionScript> {
    return this.crudUpdate<IExtractionScript>(id, job, 'extraction-script');
  }

  deleteExtractionScript(id: string): Promise<IExtractionScript> {
    return this.crudDelete<IExtractionScript>(id, 'extraction-script');
  }

  /* REPORTS */

  getReports(): Promise<IReport[]> {
    return this.crudGetAll<IReport>('report');
  }

  getReport(id: string): Promise<IReport> {
    return this.crudGet<IReport>(id, 'report');
  }

  createReport(data: IReport): Promise<IReport> {
    return this.crudCreate<IReport>(data, 'report');
  }

  updateReport(id: string, data: IReport): Promise<IReport> {
    return this.crudUpdate<IReport>(id, data, 'report');
  }

  deleteReport(id: string): Promise<IReport> {
    return this.crudDelete<IReport>(id, 'report');
  }

  getReportResults(reportId: string, resultId: string): Promise<IReportResults> {
    return new Promise<any>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/report/results/${reportId}/${resultId}`).subscribe((response: IRestResponse<IReportResults>) => {
        this.handleHttpSuccess<IReportResults>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IReportResults>(error);
        reject();
      });
    });
  }

  /* RESEARCH RESULTS */

  getResearchResults(): Promise<IResearchResult[]> {
    return this.crudGetAll<IResearchResult>('research/result');
  }

  getResearchResult(id: string): Promise<IResearchResult> {
    return this.crudGet<IResearchResult>(id, 'research/result');
  }

  createResearchResult(data: IResearchResult): Promise<IResearchResult> {
    return this.crudCreate<IResearchResult>(data, 'research/result');
  }

  updateResearchResult(id: string, data: IResearchResult): Promise<IResearchResult> {
    return this.crudUpdate<IResearchResult>(id, data, 'research/result');
  }

  deleteResearchResult(id: string): Promise<IResearchResult> {
    return this.crudDelete<IResearchResult>(id, 'research/result');
  }

  /* COMPANIES */

  getCompanies(): Promise<ICompany[]> {
    return this.crudGetAll<ICompany>('company');
  }

  getCompany(id: string): Promise<ICompany> {
    return this.crudGet<ICompany>(id, 'company');
  }

  createCompany(job: ICompany): Promise<ICompany> {
    return this.crudCreate<ICompany>(job, 'company');
  }

  updateCompany(id: string, job: ICompany): Promise<ICompany> {
    return this.crudUpdate<ICompany>(id, job, 'company');
  }

  deleteCompany(id: string): Promise<ICompany> {
    return this.crudDelete<ICompany>(id, 'company');
  }

  /* WORK SECTORS */

  getWorkSectors(): Promise<IWorkSector[]> {
    return this.crudGetAll<IWorkSector>('work/sector');
  }

  getWorkSector(id: string): Promise<IWorkSector> {
    return this.crudGet<IWorkSector>(id, 'work/sector');
  }

  createWorkSector(job: IWorkSector): Promise<IWorkSector> {
    return this.crudCreate<IWorkSector>(job, 'work/sector');
  }

  updateWorkSector(id: string, job: IWorkSector): Promise<IWorkSector> {
    return this.crudUpdate<IWorkSector>(id, job, 'work/sector');
  }

  deleteWorkSector(id: string): Promise<IWorkSector> {
    return this.crudDelete<IWorkSector>(id, 'work/sector');
  }

  /* WORK SKILLS */

  getSkills(): Promise<ISkill[]> {
    return this.crudGetAll<IWorkSector>('work/skill');
  }

  getSkill(id: string): Promise<ISkill> {
    return this.crudGet<IWorkSector>(id, 'work/skill');
  }

  createSkill(skill: ISkill): Promise<ISkill> {
    return this.crudCreate<ISkill>(skill, 'work/skill');
  }

  updateSkill(id: string, skill: ISkill): Promise<IWorkSector> {
    return this.crudUpdate<ISkill>(id, skill, 'work/skill');
  }

  deleteSkill(id: string): Promise<ISkill> {
    return this.crudDelete<ISkill>(id, 'work/skill');
  }

  /* WORK PROFESSIONS */

  getWorkProfessions(): Promise<IWorkProfession[]> {
    return this.crudGetAll<IWorkProfession>('work/profession');
  }

  getWorkProfession(id: string): Promise<IWorkProfession> {
    return this.crudGet<IWorkProfession>(id, 'work/profession');
  }

  createWorkProfession(job: IWorkProfession): Promise<IWorkProfession> {
    return this.crudCreate<IWorkProfession>(job, 'work/profession');
  }

  updateWorkProfession(id: string, job: IWorkProfession): Promise<IWorkProfession> {
    return this.crudUpdate<IWorkProfession>(id, job, 'work/profession');
  }

  deleteWorkProfession(id: string): Promise<IWorkProfession> {
    return this.crudDelete<IWorkProfession>(id, 'work/profession');
  }

  /* WORK CONTRACT TYPES */

  getWorkContractTypes(): Promise<IJobContractType[]> {
    return this.crudGetAll<IJobContractType>('work/contract-type');
  }

  getWorkContractType(id: string): Promise<IJobContractType> {
    return this.crudGet<IJobContractType>(id, 'work/contract-type');
  }

  createWorkContractType(job: IJobContractType): Promise<IJobContractType> {
    return this.crudCreate<IJobContractType>(job, 'work/contract-type');
  }

  updateWorkContractType(id: string, job: IJobContractType): Promise<IJobContractType> {
    return this.crudUpdate<IJobContractType>(id, job, 'work/contract-type');
  }

  deleteWorkContractType(id: string): Promise<IJobContractType> {
    return this.crudDelete<IJobContractType>(id, 'work/contract-type');
  }

  /* DATA */

  importFile(file: File, type: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      this.http.post(`${this.BASE_URL}/data/import/${type}`, formData).subscribe((response: IRestResponse<void>) => {
        this.handleHttpSuccess<void>(response);
        resolve(response.data);
      }, error => {
        this.handleHttpError<void>(error);
        reject();
      });
    });
  }

  /* COMMON */

  encryptFromMap(map: any): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.http.post(`${this.BASE_URL}/util/encrypt`, map).subscribe((response: IRestResponse<string>) => {
        this.handleHttpSuccess<string>(response);
        resolve(response.data);
      }, error => {
        this.handleHttpError<string>(error);
        reject();
      });
    });
  }

  decryptToMap(encryptedString: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${this.BASE_URL}/util/decrypt?enc=${encodeURIComponent(encryptedString)}`).subscribe((response: IRestResponse<any>) => {
        this.handleHttpSuccess<any>(response);
        resolve(response.data);
      }, error => {
        this.handleHttpError<any>(error);
        reject();
      });
    });
  }

  deconstructTrace(trace: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.post(`${this.BASE_URL}/auth/candidate/trace/deconstruct`, {
        trace
      }).subscribe((response: IRestResponse<any>) => {
        this.handleHttpSuccess<any>(response);
        resolve(response.data);
      }, error => {
        this.handleHttpError<any>(error);
        reject();
      });
    });
  }

  uploadFile(file: File): Promise<IFile> {
    return new Promise<IFile>((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      this.http.post(`${this.BASE_URL}/file/upload`, formData).subscribe((response: IRestResponse<IFile>) => {
        this.handleHttpSuccess<IFile>(response);
        resolve(response.data);
      }, error => {
        this.handleHttpError<IFile>(error);
        reject();
      });
    });
  }

  getEducationCourses(): Promise<IEducationCourse[]> {
    return new Promise<IEducationCourse[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/education/course`).subscribe((response: IRestResponse<IEducationCourse[]>) => {
        this.handleHttpSuccess<IEducationCourse[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<IEducationCourse[]>(error);
        reject();
      });
    });
  }

  sendRecoveryLink(email: string): Promise<void> {
    return new Promise<void>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/auth/recover`, {
        params: {
          email
        }
      }).subscribe((response: IRestResponse<void>) => {
        this.handleHttpSuccess<void>(response);
        resolve();
      }, (error: any) => {
        this.handleHttpError<void>(error);
        reject();
      });
    });
  }

  sendCandidateRecoveryLink(email: string): Promise<void> {
    return new Promise<void>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/auth/candidate/recover`, {
        params: {
          email
        }
      }).subscribe((response: IRestResponse<void>) => {
        this.handleHttpSuccess<void>(response);
        resolve();
      }, (error: any) => {
        this.handleHttpError<void>(error);
        reject();
      });
    });
  }

  validateOperationCode(traceid: string, purpose: string): Promise<boolean> {
    return new Promise<boolean>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/auth/operation/validate`, {
        params: {
          traceid,
          purpose
        }
      }).subscribe((response: IRestResponse<boolean>) => {
        this.handleHttpSuccess<boolean>(response);
        resolve(response);
      }, (error: any) => {
        this.handleHttpError<boolean>(error);
        reject();
      });
    });
  }

  recoverAccountPassword(traceid: string, password: string): Promise<void> {
    return new Promise<void>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/auth/operation/account-recover`, {
        traceid,
        password
      }).subscribe((response: IRestResponse<void>) => {
        this.handleHttpSuccess<void>(response);
        resolve(response);
      }, (error: any) => {
        this.handleHttpError<void>(error);
        reject();
      });
    });
  }

  recoverCandidateAccountPassword(traceid: string, password: string): Promise<void> {
    return new Promise<void>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/auth/operation/candidate/account-recover`, {
        traceid,
        password
      }).subscribe((response: IRestResponse<void>) => {
        this.handleHttpSuccess<void>(response);
        resolve(response);
      }, (error: any) => {
        this.handleHttpError<void>(error);
        reject();
      });
    });
  }

  getRecentProjects(): Promise<any> {
    return new Promise<any>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/dashboard/recent-projects`).subscribe((response: IRestResponse<any>) => {
        this.handleHttpSuccess<any>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<any>(error);
        reject();
      });
    });
  }

  getDailyActivity(): Promise<any> {
    return new Promise<any>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/dashboard/daily-activity`).subscribe((response: IRestResponse<any>) => {
        this.handleHttpSuccess<any>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<any>(error);
        reject();
      });
    });
  }

  getRecentResearches(): Promise<any> {
    return new Promise<any>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/dashboard/recent-researches`).subscribe((response: IRestResponse<any>) => {
        this.handleHttpSuccess<any>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<any>(error);
        reject();
      });
    });
  }

  getMostActiveClients(): Promise<any> {
    return new Promise<any>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/dashboard/most-active-clients`).subscribe((response: IRestResponse<any>) => {
        this.handleHttpSuccess<any>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<any>(error);
        reject();
      });
    });
  }

  getMostPopularTests(): Promise<any> {
    return new Promise<any>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/dashboard/most-popular-tests`).subscribe((response: IRestResponse<any>) => {
        this.handleHttpSuccess<any>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<any>(error);
        reject();
      });
    });
  }

  downloadResults(researchResultId: string, version: number): Observable<Blob> {
    return this.http.get(`${this.BASE_URL}/report/download/${researchResultId}/${version}`, { responseType: 'blob' });
  }

  private handleHttpSuccess<T>(response: IRestResponse<T>): void {
    if (response.showMsg) {
      this.snackBar.open(response.msg, $localize`Close`, { duration: 2000 });
    }
  }

  private handleHttpError<T>(error: any): void {
    if (error.error && error.error.msg) {
      this.snackBar.open(error.error.msg, $localize`Close`, { duration: 2000 });
    } else {
      this.snackBar.open($localize`Something went wrong with the server`, $localize`Close`, { duration: 2000 });
    }
  }

  private getGlobalHeaders(): any {
    const headers: any = {};

    const token = this.authService.getToken();

    if (token !== null) {
      headers.Authorization = token;
    }

    return headers;
  }

  private crudGetAll<T>(path: string): Promise<T[]> {
    return new Promise<T[]>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/${path}`).subscribe((response: IRestResponse<T[]>) => {
        this.handleHttpSuccess<T[]>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<T[]>(error);
        reject();
      });
    });
  }

  private crudGet<T>(id: string, path: string): Promise<T> {
    return new Promise<T>((resolve: any, reject: any) => {
      this.http.get(`${this.BASE_URL}/${path}/${id}`).subscribe((response: IRestResponse<T>) => {
        this.handleHttpSuccess<T>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<T>(error);
        reject();
      });
    });
  }

  private crudCreate<T>(data: T, path: string): Promise<T> {
    return new Promise<T>((resolve: any, reject: any) => {
      this.http.post(`${this.BASE_URL}/${path}`, data).subscribe((response: IRestResponse<T>) => {
        this.handleHttpSuccess<T>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<T>(error);
        reject();
      });
    });
  }

  private crudUpdate<T>(id: string, data: T, path: string): Promise<T> {
    return new Promise<T>((resolve: any, reject: any) => {
      this.http.put(`${this.BASE_URL}/${path}/${id}`, data).subscribe((response: IRestResponse<T>) => {
        this.handleHttpSuccess<T>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<T>(error);
        reject();
      });
    });
  }

  private crudDelete<T>(id: string, path: string): Promise<T> {
    return new Promise<T>((resolve: any, reject: any) => {
      this.http.delete(`${this.BASE_URL}/${path}/${id}`).subscribe((response: IRestResponse<T>) => {
        this.handleHttpSuccess<T>(response);
        resolve(response.data);
      }, (error: any) => {
        this.handleHttpError<T>(error);
        reject();
      });
    });
  }
}
