import {Component, OnInit, ViewChild} from '@angular/core';
import IJob from '../../interfaces/IJob';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RouteAction} from '../../definitions/RouteAction';
import {ContentType} from '../../definitions/ContentType';
import IItem from '../../interfaces/IItem';
import IRole from '../../interfaces/IRole';
import {SmartFormComponent} from '../../components/smart-form/smart-form.component';
import ISmartField from '../../interfaces/ISmartField';
import IDetailField from '../../interfaces/IDetailField';
import ISubject from '../../interfaces/ISubject';
import IUser from '../../interfaces/IUser';
import IGroup from '../../interfaces/IGroup';
import {MatDialog} from '@angular/material/dialog';
import {SurveyDialogComponent} from '../../components/survey-dialog/survey-dialog.component';
import {InputDialogComponent} from '../../components/input-dialog/input-dialog.component';
import IClient from '../../interfaces/IClient';
import IWorkSector from '../../interfaces/IWorkSector';
import IWorkPosition from '../../interfaces/IWorkPosition';
import IResearch from '../../interfaces/IResearch';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-job-screen',
  templateUrl: './jobs-screen.component.html',
  styleUrls: ['./jobs-screen.component.styl']
})
export class JobsScreenComponent implements OnInit {

  @ViewChild('addForm') addForm: SmartFormComponent;
  @ViewChild('editForm') editForm: SmartFormComponent;
  action: RouteAction = RouteAction.LOADING;
  routeAction = RouteAction;
  jobs: IJob[] = [];
  jobsTableColumnDefs = [
    { headerName: $localize`Title`, field: 'title' },
    { headerName: $localize`Reference`, field: 'reference' },
    { headerName: $localize`Client`, field: 'client.name' },
    { headerName: $localize`Client Note`, field: 'clientNote' },
    { headerName: $localize`Client Description`, field: 'clientDescription' },
    { headerName: $localize`Workplace`, field: 'workplace' },
    { headerName: $localize`Work Sector`, field: 'workSector.name' },
    { headerName: $localize`Work Position`, field: 'workPosition.name' },
    { headerName: $localize`Start Date`, field: 'startDate', cellRenderer: 'dateRenderer' },
    { headerName: $localize`End Date`, field: 'endDate', cellRenderer: 'dateRenderer' },
    { headerName: $localize`Active`, field: 'active', cellRenderer: 'checkboxRenderer' },
    { headerName: $localize`Hidden`, field: 'hidden', cellRenderer: 'checkboxRenderer' },
  ];
  jobFormFieldsCreate = [
    { key: 'title', label: $localize`Title`, required: true, type: ContentType.SHORT_TEXT },
    { key: 'reference', label: $localize`Reference`, required: false, type: ContentType.SHORT_TEXT },
    this.authService.getClientField(),
    { key: 'clientNote', label: $localize`Client Note`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'clientDescription', label: $localize`Client Description`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'workplace', label: $localize`Workplace`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'workSector', label: $localize`Work Sector`, required: false, type: ContentType.LIST, data: [], fetchData: () => this.fetchWorkSectorsAsItems(), compareWith: (o1: any, o2: any) => o1.id === o2.id },
    { key: 'workProfession', label: $localize`Work Position`, required: false, type: ContentType.LIST, data: [], fetchData: () => this.fetchWorkProfessionsAsItems(), compareWith: (o1: any, o2: any) => o1.id === o2.id },
    { key: 'startDate', label: $localize`Start Date`, required: false, type: ContentType.DATE },
    { key: 'endDate', label: $localize`End Date`, required: false, type: ContentType.DATE },
    { key: 'active', label: $localize`Active`, required: false, type: ContentType.BOOLEAN, displayAs: 'toggle', defaultValue: true },
    { key: 'hidden', label: $localize`Hidden`, required: false, type: ContentType.BOOLEAN, displayAs: 'toggle', defaultValue: false },
  ];
  jobFormFieldsEdit = [...this.jobFormFieldsCreate];
  jobDetailFields: IDetailField[] = this.jobFormFieldsCreate.map(field => ({
    key: field.key,
    label: field.label,
    type: field.type,
    render: field.render
  }));
  selectedJobRows = 0;
  private _jobEntity: IJob = null;
  connectedSubjects: ISubject[] = [];
  subjectsData: ISubject[] = [];
  usersData: IUser[] = [];
  groupsData: IGroup[] = [];
  researches: IResearch[] = [];
  researchColumnDefs = [
    { headerName: $localize`Title`, field: 'title' },
    { headerName: $localize`Anonymous`, field: 'anonymous', cellRenderer: 'checkboxRenderer' },
    { headerName: $localize`Job`, field: 'job.title' },
    { headerName: $localize`Groups`, field: 'groups', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 },
    { headerName: $localize`Projects`, field: 'projects', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 },
    { headerName: $localize`Candidates`, field: 'candidates', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 },
    { headerName: $localize`Anonymous Candidates`, field: 'anonymousCandidates', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 }
  ];

  constructor(private apiService: ApiService, private activatedRoute: ActivatedRoute, private router: Router, private dialog: MatDialog, public authService: AuthService) {
    this.onJobDelete = this.onJobDelete.bind(this);
    this.onJobEdit = this.onJobEdit.bind(this);
    this.onJobView = this.onJobView.bind(this);
    this.fetchClientsAsItems = this.fetchClientsAsItems.bind(this);
  }

  ngOnInit(): void {
    if (this.activatedRoute.firstChild === null) {
      this.action = RouteAction.LISTING;
      this.fetchJobs();
    } else {
      const snapshot = this.activatedRoute.firstChild.snapshot;

      if (snapshot.routeConfig.path.startsWith('add')) {
        this.goToAdd();
      } else if (snapshot.routeConfig.path.startsWith('view')) {
        this.goToView();
      } else if (snapshot.routeConfig.path.startsWith('edit')) {
        this.goToEdit();
      }
    }
  }

  fetchJobs(): void {
    this.apiService.getJobs().then((jobs: IJob[]) => {
      this.jobs = jobs.filter(job => this.authService.hasBoundingPermission('job', 'view', job));
    });
  }

  save(): void {
    if (this.action === RouteAction.ADD) {
      if (this.addForm.form.invalid) {
        this.addForm.form.markAllAsTouched();
        return;
      }

      const job: IJob = this.addForm.form.getRawValue();

      this.apiService.createJob(job).then(() => {
        this.goToListing();
      });
    } else if (this.action === RouteAction.EDIT) {
      if (this.editForm.form.invalid) {
        this.editForm.form.markAllAsTouched();
        return;
      }

      const data: IJob = {
        ...this.jobEntity,
        ...this.editForm.form.getRawValue()
      };

      this.apiService.updateJob(data.id, data).then((job: IJob) => {
        this.jobEntity = job;
        this.editForm.defaults = job;
        this.editForm.setValue({
          ...job,
          password: null
        });
      });
    }
  }

  reset(): void {
    if (this.action === RouteAction.EDIT) {
      this.editForm.reset();
    } else if (this.action === RouteAction.ADD) {
      this.addForm.reset();
    }
  }

  goToListing(): void {
    this.fetchJobs();
    this.router.navigateByUrl('/jobs');
    this.action = RouteAction.LISTING;
  }

  goToAdd(): void {
    this.router.navigateByUrl('/jobs/add');
    this.action = RouteAction.ADD;
  }

  goToEdit(data: IJob = null): void {
    if (data === null) {
      this.apiService.getJob(this.activatedRoute.firstChild.snapshot.params.id).then((job: IJob) => {
        this.jobEntity = job;
        this.action = RouteAction.EDIT;
      });
    } else {
      this.jobEntity = data;
      this.router.navigateByUrl('/jobs/edit/' + data.id);
      this.action = RouteAction.EDIT;
    }
  }

  goToView(data: IJob = null): void {
    if (data === null) {
      this.apiService.getJob(this.activatedRoute.firstChild.snapshot.params.id).then((job: IJob) => {
        this.jobEntity = job;
        this.fetchResearches();
        this.action = RouteAction.VIEW;
      });
    } else {
      this.jobEntity = data;
      this.fetchResearches();
      this.router.navigateByUrl('/jobs/view/' + data.id);
      this.action = RouteAction.VIEW;
    }
  }

  goBack(): void {
    switch (this.action) {
      case RouteAction.ADD:
      case RouteAction.EDIT:
      case RouteAction.VIEW:
        this.goToListing();
        break;

      default:
    }
  }

  onJobDelete(ev: any): void {
    this.apiService.deleteJob(ev.data.id).then(() => {
      this.fetchJobs();
    });
  }

  onJobEdit(ev: any): void {
    this.goToEdit(ev.data);
  }

  onJobView(ev: any): void {
    this.goToView(ev.data);
  }

  fetchClientsAsItems(): Promise<IItem[]> {
    return new Promise<IItem[]>(resolve => {
      this.apiService.getClients().then(clients => resolve(clients.map(client => ({
        value: client,
        label: client.name
      }))));
    });
  }

  onResearchView(ev: any): void {
    this.router.navigateByUrl(`/research/${ev.data.id}/job/${this.jobEntity.id}`, {
      state: {
        from: 'job',
        id: this.jobEntity.id
      }
    });
  }

  fetchWorkSectorsAsItems(): Promise<IItem[]> {
    return new Promise<IItem[]>(resolve => {
      this.apiService.getWorkSectors().then(entities => resolve(entities.map(entity => ({
        value: entity,
        label: entity.name
      })).sort((a, b) => a.label >= b.label ? 1 : -1 )));
    });
  }

  fetchWorkProfessionsAsItems(): Promise<IItem[]> {
    return new Promise<IItem[]>(resolve => {
      this.apiService.getWorkProfessions().then(entities => resolve(entities.map(entity => ({
        value: entity,
        label: entity.name
      })).sort((a, b) => a.label >= b.label ? 1 : -1 )));
    });
  }

  get jobEntity(): IJob {
    return this._jobEntity;
  }

  set jobEntity(value: IJob) {

    this._jobEntity = value;
  }

  private fetchResearches(): void {
    this.apiService.getResearchesByJob(this.jobEntity)
      .then(researches => this.researches = researches).catch();
  }
}


