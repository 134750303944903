import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import ISmartField from '../../interfaces/ISmartField';
import {SmartFormComponent} from '../../components/smart-form/smart-form.component';
import {ApiService} from '../../services/api.service';
import IRole from '../../interfaces/IRole';
import IEducationLevel from '../../interfaces/IEducationLevel';
import {ContentType} from '../../definitions/ContentType';
import IClient from '../../interfaces/IClient';
import IItem from '../../interfaces/IItem';
import IPermission from '../../interfaces/IPermission';

@Component({
  selector: 'app-settings-screen',
  templateUrl: './settings-screen.component.html',
  styleUrls: ['./settings-screen.component.styl']
})
export class SettingsScreenComponent implements OnInit {

  view: string = null;
  id: string = null;
  action: string = null;
  fields: ISmartField[] = [];
  defaults: any = {};
  records: any[] = [];
  title = '';
  itemTitle = '';
  @ViewChild('formComponent') formComponent: SmartFormComponent;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private api: ApiService) {
    const { what, id } = this.activatedRoute.snapshot.params;
    this.view = what || null;
    this.id = id || null;

    switch (this.view) {
      case 'roles':
        this.title = $localize`Roles`;
        this.itemTitle = $localize`Role`;
        break;

      case 'contract-types':
        this.title = $localize`Contract Types`;
        this.itemTitle = $localize`Contract Types`;
        break;

      case 'education-levels':
        this.title = $localize`Education Levels`;
        this.itemTitle = $localize`Education Level`;
        break;

      case 'education-categories':
        this.title = $localize`Education Categories`;
        this.itemTitle = $localize`Education Category`;
        break;

      case 'education-institutes':
        this.title = $localize`Education Institutes`;
        this.itemTitle = $localize`Education Institute`;
        break;

      case 'skills':
        this.title = $localize`Skils`;
        this.itemTitle = $localize`Skills`;
        break;

      case 'work-professions':
        this.title = $localize`Work Professions`;
        this.itemTitle = $localize`Work Professions`;
        break;

      case 'work-sectors':
        this.title = $localize`Work Sectors`;
        this.itemTitle = $localize`Work Sectors`;
        break;

      default:
    }

    if (this.view !== null && this.id === null) {
      this.fetchData();
    }
  }

  ngOnInit(): void {
  }

  goBack(): void {
    if (this.action !== null) {
      this.listing();
    } else {
      this.router.navigateByUrl('/settings');
    }
  }

  goTo(path: string): void {
    this.router.navigateByUrl('/settings/' + path);
  }

  fetchData(): void {
    switch (this.view) {
      case 'roles':
        this.api.getRoles().then(roles => {
          this.records = [...roles];
        }).catch(console.error);
        break;

      case 'contract-types':
        this.api.getWorkContractTypes().then(contractTypes => {
          this.records = [...contractTypes];
        }).catch(console.error);
        break;

      case 'education-levels':
        this.api.getEducationLevels().then(educationLevels => {
          this.records = [...educationLevels];
        }).catch(console.error);
        break;

      case 'education-categories':
        this.api.getEducationCategories().then(educationCategories => {
          this.records = [...educationCategories];
        }).catch(console.error);
        break;

      case 'education-institutes':
        this.api.getEducationInstitutes().then(educationInstitutes => {
          this.records = [...educationInstitutes];
        }).catch(console.error);
        break;

      case 'skills':
        this.api.getSkills().then(skills => {
          this.records = [...skills];
        }).catch(console.error);
        break;

      case 'work-professions':
        this.api.getWorkProfessions().then(workProfessions => {
          this.records = [...workProfessions];
        }).catch(console.error);
        break;

      case 'work-sectors':
        this.api.getWorkSectors().then(workSectors => {
          this.records = [...workSectors];
        }).catch(console.error);
        break;

      default:
    }
  }

  listing(): void {
    this.action = null;
    this.defaults = {};
    this.fields = [];

    this.fetchData();
  }

  add(): void {
    switch (this.view) {
      case 'roles':
        this.fields = [
          { key: 'name', label: $localize`Name`, required: true },
          { key: 'permissions', label: $localize`Permissions`, required: false, type: ContentType.LIST, data: [], fetchData: () => this.fetchPermissionsAsItems(), multiple: true },
        ];
        break;

      case 'contract-types':
        this.fields = [
          { key: 'name', label: $localize`Name`, required: true },
        ];
        break;

      case 'skills':
        this.fields = [
          { key: 'name', label: $localize`Name`, required: true },
          { key: 'shortName', label: $localize`Short Name`, required: true },
          { key: 'sector', label: $localize`Work Sector`, required: false, type: ContentType.LIST, data: [], fetchData: () => this.fetchWorkSectorsAsItems(), compareWith: (o1: any, o2: any) => o1.id === o2.id },
          { key: 'profession', label: $localize`Work Profession`, required: false, type: ContentType.LIST, data: [], fetchData: () => this.fetchWorkProfessionsAsItems(), compareWith: (o1: any, o2: any) => o1.id === o2.id },
        ];
        break;

      case 'work-professions':
        this.fields = [
          { key: 'name', label: $localize`Name`, required: true },
          { key: 'sector', label: $localize`Work Sector`, required: false, type: ContentType.LIST, data: [], fetchData: () => this.fetchWorkSectorsAsItems(), compareWith: (o1: any, o2: any) => o1.id === o2.id },
        ];
        break;

      case 'work-sectors':
        this.fields = [
          { key: 'name', label: $localize`Name`, required: true },
        ];
        break;

      case 'education-levels':
        this.fields = [
          { key: 'name', label: $localize`Name`, required: true },
          { key: 'priority', label: $localize`Priority`, required: true, defaultValue: 99 }
        ];
        break;

      case 'education-categories':
        this.fields = [
          { key: 'name', label: $localize`Name`, required: true },
          { key: 'macro', label: $localize`Macro`, required: true },
        ];
        break;

      case 'education-institutes':
        this.fields = [
          { key: 'name', label: $localize`Name`, required: true },
          { key: 'address', label: $localize`Address`, required: false }
        ];
        break;

      default:
    }

    this.action = 'add';
  }

  confirmAdd(): void {
    if (!this.formComponent || this.formComponent.form.invalid) {
      return;
    }

    const data: any = {
      ...this.formComponent.form.getRawValue()
    };

    switch (this.view) {
      case 'roles':
        this.api.createRole(data).then(record => {
          this.listing();
        }).catch(console.error);

        break;

      case 'skills':
        this.api.createSkill(data).then(record => {
          this.listing();
        }).catch(console.error);

        break;

      case 'contract-types':
        this.api.createWorkContractType(data).then(record => {
          this.listing();
        }).catch(console.error);

        break;

      case 'work-professions':
        this.api.createWorkProfession(data).then(record => {
          this.listing();
        }).catch(console.error);

        break;

      case 'work-sectors':
        this.api.createWorkSector(data).then(record => {
          this.listing();
        }).catch(console.error);

        break;

      case 'education-levels':
        this.api.createEducationLevel(data).then(() => {
          this.listing();
        }).catch(console.error);

        break;

      case 'education-categories':
        this.api.createEducationCategory(data).then(() => {
          this.listing();
        }).catch(console.error);

        break;

      case 'education-institutes':
        this.api.createEducationInstitute(data).then(() => {
          this.listing();
        }).catch(console.error);

        break;

      default:
    }
  }

  delete(record: any): void {
    switch (this.view) {
      case 'roles':
        this.api.deleteRole(record.id).then(() => {
          this.fetchData();
        }).catch(console.error);

        break;

      case 'skills':
        this.api.deleteSkill(record.id).then(() => {
          this.fetchData();
        }).catch(console.error);

        break;

      case 'contract-types':
        this.api.deleteWorkContractType(record.id).then(() => {
          this.fetchData();
        }).catch(console.error);

        break;

      case 'work-professions':
        this.api.deleteWorkProfession(record.id).then(() => {
          this.fetchData();
        }).catch(console.error);

        break;

      case 'work-sectors':
        this.api.deleteWorkSector(record.id).then(() => {
          this.fetchData();
        }).catch(console.error);

        break;

      case 'education-levels':
        this.api.deleteEducationLevel(record.id).then(() => {
          this.fetchData();
        }).catch(console.error);

        break;

      case 'education-categories':
        this.api.deleteEducationCategory(record.id).then(() => {
          this.fetchData();
        }).catch(console.error);

        break;

      case 'education-institutes':
        this.api.deleteEducationInstitute(record.id).then(() => {
          this.fetchData();
        }).catch(console.error);

        break;

      default:
    }
  }

  edit(record: any): void {

    switch (this.view) {
      case 'roles':
        this.fields = [
          { key: 'name', label: $localize`Name`, required: true },
          { key: 'permissions', label: $localize`Permissions`, required: false, type: ContentType.LIST, data: [], fetchData: () => this.fetchPermissionsAsItems(), multiple: true },
        ];
        break;

      case 'contract-types':
        this.fields = [
          { key: 'name', label: $localize`Name`, required: true },
        ];
        break;

      case 'skills':
        this.fields = [
          { key: 'name', label: $localize`Name`, required: true },
          { key: 'shortName', label: $localize`Short Name`, required: true },
          { key: 'sector', label: $localize`Work Sector`, required: false, type: ContentType.LIST, data: [], fetchData: () => this.fetchWorkSectorsAsItems(), compareWith: (o1: any, o2: any) => o1.id === o2.id },
          { key: 'profession', label: $localize`Work Profession`, required: false, type: ContentType.LIST, data: [], fetchData: () => this.fetchWorkProfessionsAsItems(), compareWith: (o1: any, o2: any) => o1.id === o2.id },
        ];
        break;

      case 'work-professions':
        this.fields = [
          { key: 'name', label: $localize`Name`, required: true },
          { key: 'sector', label: $localize`Work Sector`, required: false, type: ContentType.LIST, data: [], fetchData: () => this.fetchWorkSectorsAsItems(), compareWith: (o1: any, o2: any) => o1.id === o2.id },
        ];
        break;

      case 'work-sectors':
        this.fields = [
          { key: 'name', label: $localize`Name`, required: true },
        ];
        break;

      case 'education-levels':
        this.fields = [
          { key: 'name', label: $localize`Name`, required: true },
          { key: 'priority', label: $localize`Priority`, required: true, defaultValue: 99 }
        ];
        break;

      case 'education-categories':
        this.fields = [
          { key: 'name', label: $localize`Name`, required: true },
          { key: 'macro', label: $localize`Macro`, required: true },
        ];
        break;

      case 'education-institutes':
        this.fields = [
          { key: 'name', label: $localize`Name`, required: true },
          { key: 'address', label: $localize`Address`, required: false }
        ];
        break;

      default:
    }

    this.defaults = {...record};

    this.action = 'edit';

  }

  confirmEdit(): void {
    if (!this.formComponent || this.formComponent.form.invalid) {
      return;
    }

    const data: any = {
      ...this.defaults,
      ...this.formComponent.form.getRawValue()
    };

    switch (this.view) {
      case 'roles':
        this.api.updateRole(data.id, data).then(() => {
          this.listing();
        }).catch(console.error);

        break;

      case 'contract-types':
        this.api.updateWorkContractType(data.id, data).then(() => {
          this.listing();
        }).catch(console.error);

        break;

      case 'skills':
        this.api.updateSkill(data.id, data).then(() => {
          this.listing();
        }).catch(console.error);

        break;

      case 'work-professions':
        this.api.updateWorkProfession(data.id, data).then(() => {
          this.listing();
        }).catch(console.error);

        break;

      case 'work-sectors':
        this.api.updateWorkSector(data.id, data).then(() => {
          this.listing();
        }).catch(console.error);

        break;

      case 'education-levels':
        this.api.updateEducationLevel(data.id, data).then(() => {
          this.listing();
        }).catch(console.error);

        break;

      case 'education-categories':
        this.api.updateEducationCategory(data.id, data).then(() => {
          this.listing();
        }).catch(console.error);

        break;

      case 'education-institutes':
        this.api.updateEducationInstitute(data.id, data).then(() => {
          this.listing();
        }).catch(console.error);

        break;

      default:
    }
  }

  getLabel(record: any): string {

    switch (this.view) {
      case 'roles':
      case 'education-levels':
      case 'education-categories':
      case 'education-institutes':
      case 'skills':
      case 'work-professions':
      case 'work-sectors':
      case 'contract-types':
        return record.name;

      default:
        return '';
    }

  }

  fetchPermissionsAsItems(): Promise<IItem[]> {
    return new Promise<IItem[]>(resolve => {
      this.api.getPermissions().then(permissions => resolve(permissions.map(permission => ({
        value: permission.code,
        label: permission.name
      })).sort((a, b) => a.label >= b.label ? 1 : -1 )));
    });
  }

  fetchWorkSectorsAsItems(): Promise<IItem[]> {
    return new Promise<IItem[]>(resolve => {
      this.api.getWorkSectors().then(items => resolve(items.map(item => ({
        value: item,
        label: item.name
      })).sort((a, b) => a.label >= b.label ? 1 : -1 )));
    });
  }

  fetchWorkProfessionsAsItems(): Promise<IItem[]> {
    return new Promise<IItem[]>(resolve => {
      this.api.getWorkProfessions().then(items => resolve(items.map(item => ({
        value: item,
        label: item.name
      })).sort((a, b) => a.label >= b.label ? 1 : -1 )));
    });
  }

}
