<div class="container spacing-top-50" *ngIf="show === 'account-recover' && isValid">
  <img src="/assets/images/logo.svg" class="align-center d-block" />
  <mat-card class="spacing-top-10">
    <h1 class="text-center spacing-0 spacing-inner-0 text-bold" i18n>Password Recovery</h1>
    <p class="text-center spacing-0 spacing-inner-0 spacing-top-2 opacity-50" i18n>Please insert and confirm your new password for the account you requested the password recovery.</p>

    <form class="spacing-top-25" [formGroup]="recoveryForm">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label i18n>Password</mat-label>
        <input matInput i18n-placeholder placeholder="Insert new password" type="password" formControlName="password">
        <mat-icon matSuffix>lock</mat-icon>
        <mat-error *ngIf="recoveryForm.controls.password.invalid">{{getErrorMessage(recoveryForm.controls.password)}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width spacing-top-5">
        <mat-label i18n>Confirm password</mat-label>
        <input matInput i18n-placeholder placeholder="Insert the password again" type="password" formControlName="confirmPassword">
        <mat-icon matSuffix>check</mat-icon>
        <mat-error *ngIf="recoveryForm.controls.confirmPassword.invalid">{{getErrorMessage(recoveryForm.controls.confirmPassword)}}</mat-error>
      </mat-form-field>

      <button mat-flat-button color="primary" class="spacing-top-10 full-width" i18n (click)="submitRecoveryForm()" [disabled]="recoveryForm.invalid">Confirm</button>
    </form>
  </mat-card>
</div>

<div class="container spacing-top-50" *ngIf="show === 'candidate-account-recover' && isValid">
  <img src="/assets/images/logo.svg" class="align-center d-block" />
  <mat-card class="spacing-top-10">
    <h1 class="text-center spacing-0 spacing-inner-0 text-bold" i18n>Password Recovery</h1>
    <p class="text-center spacing-0 spacing-inner-0 spacing-top-2 opacity-50" i18n>Please insert and confirm your new password for the account you requested the password recovery.</p>

    <form class="spacing-top-25" [formGroup]="recoveryForm">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label i18n>Password</mat-label>
        <input matInput i18n-placeholder placeholder="Insert new password" type="password" formControlName="password">
        <mat-icon matSuffix>lock</mat-icon>
        <mat-error *ngIf="recoveryForm.controls.password.invalid">{{getErrorMessage(recoveryForm.controls.password)}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width spacing-top-5">
        <mat-label i18n>Confirm password</mat-label>
        <input matInput i18n-placeholder placeholder="Insert the password again" type="password" formControlName="confirmPassword">
        <mat-icon matSuffix>check</mat-icon>
        <mat-error *ngIf="recoveryForm.controls.confirmPassword.invalid">{{getErrorMessage(recoveryForm.controls.confirmPassword)}}</mat-error>
      </mat-form-field>

      <button mat-flat-button color="primary" class="spacing-top-10 full-width" i18n (click)="submitRecoveryForm()" [disabled]="recoveryForm.invalid">Confirm</button>
    </form>
  </mat-card>
</div>

<div class="container spacing-top-50" *ngIf="show === 'candidate-account-recover-success'">
  <img src="/assets/images/logo.svg" class="align-center d-block" />
  <mat-card class="spacing-top-10">
    <h1 class="text-center spacing-0 spacing-inner-0 text-bold" i18n>Password Recovery</h1>
    <p class="text-center spacing-0 spacing-inner-0 spacing-top-2 opacity-50" i18n>Your candidate account has been recovered and you can now use your new password to login.</p>
  </mat-card>
</div>
