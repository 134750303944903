import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '../../services/api.service';
import IQuestionElement from '../../interfaces/IQuestionElement';
import {QuestionType} from '../../definitions/QuestionType';
import IElement from '../../interfaces/IElement';
import IQuestionAnswer from '../../interfaces/IQuestionAnswer';
import {SmartTableComponent} from '../smart-table/smart-table.component';
import {ContentType} from '../../definitions/ContentType';
import ISmartField from '../../interfaces/ISmartField';

@Component({
  selector: 'app-question-answers-dialog',
  templateUrl: './question-answers-dialog.component.html',
  styleUrls: ['./question-answers-dialog.component.styl']
})
export class QuestionAnswersDialogComponent implements OnInit {

  questionType = QuestionType;
  columnDefs;
  defaultColDef;
  rowData = [];
  matrix: {
    fields: ISmartField[];
    base: any;
    items: any[];
    getItemLabel: (item) => string
  } = {
    fields: [],
    base: {},
    items: [],
    getItemLabel: null
  };
  groupParams: any = {};

  @ViewChild('answersGrid') answersGrid: SmartTableComponent;

  constructor(public dialogRef: MatDialogRef<QuestionAnswersDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { element: IElement<IQuestionElement> }, private apiService: ApiService) {
    this.buildQuestionAnswers();
  }

  ngOnInit(): void {
    this.groupParams.getLabel = (key: any) => {
      const item = this.matrix.items.find(o => o.code === key);

      if (!item) {
        return $localize`Label not found`;
      }

      return item.name;
    };
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    let data = this.answersGrid.getData();

    if (this.data.element.child.type === QuestionType.ARRAY_MATRIX) {
      data = data.filter(item => item !== undefined && item !== null);
    }

    this.dialogRef.close({
      data,
      extras: {
        columns: this.matrix.items
      }
    });
  }

  hasAnswers(): boolean {
    return [QuestionType.CHOICE_REGULAR, QuestionType.CHOICE_DROPDOWN, QuestionType.CHOICE, QuestionType.RANKING_ORDERING, QuestionType.ARRAY_MATRIX].includes(this.data.element.child.type);
  }

  addAnswer(): void {
    const answer: IQuestionAnswer = {
      code: $localize`Answer Code`,
      condition: '',
      isActive: true,
      label: 'Answer Label',
      score: 0,
      scoreFormula: '',
      value: null,
      group: null
    };

    if (this.data.element.child.type === QuestionType.ARRAY_MATRIX) {
      for (const column of this.matrix.items) {
        this.answersGrid.add({
          ...answer,
          group: column.code
        });
      }
    } else {
      this.answersGrid.add(answer);
    }
  }

  private buildQuestionAnswers(): void {
    let columnDefs = [];

    if (this.data.element.child.type === QuestionType.ARRAY_MATRIX) {
      columnDefs = [
        { field: 'group', rowGroup: true, hide: true }
      ];

      this.buildMatrix();
    }

    columnDefs = [
      ...columnDefs,
      {
        field: 'code',
        headerName: $localize`Code`,
        rowDrag: true,
      },
      { headerName: $localize`Label`, field: 'label' },
      { headerName: $localize`Value`, field: 'value' },
      { headerName: $localize`Score`, field: 'score' },
      { headerName: $localize`Score Formula`, field: 'scoreFormula' },
      { headerName: $localize`Condition`, field: 'condition' },
      { headerName: $localize`Active`, field: 'isActive', cellRenderer: 'checkboxRenderer' }
    ];

    this.columnDefs = columnDefs;

    this.defaultColDef = {
      filter: true,
      sortable: true,
      cellRenderer: 'standardRenderer',
      editable: true
    };

    if (Array.isArray(this.data.element.child.answers)) {
      this.rowData = [...this.data.element.child.answers];
    }
  }

  private buildMatrix(): void {
    this.matrix.fields = [
      { key: 'code', label: $localize`Code`, required: true, colSize: '12' },
      { key: 'name', label: $localize`Name`, required: true, colSize: '12' },
      { key: 'score', label: $localize`Score`, type: ContentType.NUMBER, defaultValue: 0, colSize: '12' },
      { key: 'scoreFormula', label: $localize`Score Formula`, colSize: '12' },
      { key: 'condition', label: $localize`Condition`, colSize: '12' }
    ];

    this.matrix.base = {
      code: null,
      name: null,
      condition: null,
      scoreFormula: null,
      score: 0
    };

    this.matrix.items = this.data.element.child.extras.columns || [];

    this.matrix.getItemLabel = (item) => item.name;
  }

  onAnswerDelete(ev: any): void {
    const removedNodes = [ev.data];

    this.answersGrid.gridApi.forEachNode((node, index) => {
      if (node.group === true || node.data.code !== ev.data.code || node.data.group === ev.data.group) {
        return;
      }

      removedNodes.push(node.data);
    });

    this.answersGrid.gridApi.applyTransaction({ remove: removedNodes });
  }

  onColumnsChange(items: any[]): void {
    this.updateNodes();
    this.matrix.items = [...items];
  }

  private updateNodes(): void {
    const codes = this.matrix.items.map(item => item.code);
    const existingCodes = [];

    let removedNodes = [];
    const addedNodes = [];
    let baseData = null;

    this.answersGrid.gridApi.forEachNode((node, index) => {
      if (node.group !== true) {
        return;
      }

      if (codes.indexOf(node.key) === -1) {
        removedNodes = [
          ...removedNodes,
          ...node.childrenAfterGroup.filter(value => value.group !== true).map(value => value.data)
        ];

        return;
      }

      if (existingCodes.indexOf(node.key) === -1) {
        existingCodes.push(node.key);
      }

      if (!baseData) {
        baseData = node.childrenAfterGroup.map(children => ({
          ...children.data,
          group: null
        }));
      }
    });

    const addedCodes = codes.filter(code => existingCodes.indexOf(code) === -1);

    if (baseData) {
      for (const code of addedCodes) {
        for (const record of baseData) {
          addedNodes.push({
            ...record,
            group: code
          });
        }
      }
    }

    this.answersGrid.gridApi.applyTransaction({ remove: removedNodes, add: addedNodes });
  }

}
