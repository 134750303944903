import {Component, OnInit} from '@angular/core';
import {AuthService} from './services/auth.service';
import {ApiService} from './services/api.service';
import IUser from './interfaces/IUser';
import {Subscription} from 'rxjs';
import ISubscriptionResponse from './interfaces/ISubscriptionResponse';
import {SubscriptionCode} from './definitions/SubscriptionCode';
import {select, Store} from '@ngrx/store';
import IStore from './interfaces/IStore';
import {setRoles, setToken, setUser} from './actions/auth.actions';
import IAuthResponse from './interfaces/IAuthResponse';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl']
})
export class AppComponent implements OnInit {
  title = 'sonia-app';
  loaded = false;

  constructor(
    private store: Store<IStore>,
    private authService: AuthService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    const token = this.authService.getToken();

    if (token !== null) {
      this.apiService.getUserByToken(token).then(async (user: IUser) => {
        this.authService.setUserRole(await this.apiService.getRole(user.role));
        if (user.client) {
          this.authService.setUserClient(await this.apiService.getClient(user.client));
        }
        this.authService.setUser(user);
        this.loaded = true;
      }).catch(console.error);

      this.apiService.getRoles().then(roles => this.store.dispatch(setRoles({ roles }))).catch(console.error);
    } else {
      this.loaded = true;
    }

    //
    // const token = this.authService.getToken();
    //
    // if (token !== null) {
    //   this.store.dispatch(setToken({ token }));
    //
    //   this.apiService.getUserByToken(token).then((user: IUser) => {
    //     if (user !== null) {
    //       this.store.dispatch(setUser(user));
    //     } else {
    //       this.authService.setToken(null);
    //       this.loaded = true;
    //     }
    //   });
    // } else {
    //   this.loaded = true;
    // }
  }

}
