<app-standard-layout>
  <div class="spacing-inner-10">
    <ng-container *ngIf="user && !user.client">
      <div class="row">
        <div class="col-md-6">
          <mat-card>
            <mat-card-title>
              <h2 class="text-upper">Most recent researches</h2>
            </mat-card-title>
            <mat-card-content>
              <div class="common-list">
                <div class="item pt-3 pb-3" *ngFor="let item of dashboardData.recentResearches">
                  <strong>{{item.researchName}}</strong>
                  <span class="ml-auto">{{item.count}}</span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-6">
          <mat-card>
            <mat-card-title>
              <h2 class="text-upper">Most recent projects</h2>
            </mat-card-title>
            <mat-card-content>
              <div class="common-list">
                <div class="item pt-3 pb-3" *ngFor="let item of dashboardData.recentProjects">
                  <strong>{{item.projectName}}</strong>
                  <span class="ml-auto">{{item.count}}</span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6">
          <mat-card>
            <mat-card-title>
              <h2 class="text-upper">Daily test activity</h2>
            </mat-card-title>
            <mat-card-content>
              <div *ngIf="dailyTestActivityChartLoaded" echarts [options]="dailyTestActivityChartOptions"></div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-6">
          <mat-card>
            <mat-card-title>
              <h2 class="text-upper">Most active clients</h2>
            </mat-card-title>
            <mat-card-content>
              <div *ngIf="mostActiveClientsLoaded" echarts [options]="mostActiveClientsChartOptions"></div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="user && user.client">
      <div class="row">
        <div class="col-md-6">
          <mat-card>
            <mat-card-title>
              <h2 class="text-upper">Most recent researches</h2>
            </mat-card-title>
            <mat-card-content>
              <div class="common-list">
                <div class="item pt-3 pb-3" *ngFor="let item of dashboardData.recentResearches">
                  <strong>{{item.researchName}}</strong>
                  <span class="ml-auto">{{item.count}}</span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-6">
          <mat-card>
            <mat-card-title>
              <h2 class="text-upper">Most recent projects</h2>
            </mat-card-title>
            <mat-card-content>
              <div class="common-list">
                <div class="item pt-3 pb-3" *ngFor="let item of dashboardData.recentProjects">
                  <strong>{{item.projectName}}</strong>
                  <span class="ml-auto">{{item.count}}</span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6">
          <mat-card>
            <mat-card-title>
              <h2 class="text-upper">Daily test activity</h2>
            </mat-card-title>
            <mat-card-content>
              <div echarts [options]="dailyTestActivityChartOptions"></div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-6">
          <mat-card>
            <mat-card-title>
              <h2 class="text-upper">Most popular tests</h2>
            </mat-card-title>
            <mat-card-content>
              <div *ngIf="mostPopularTestsLoaded" echarts [options]="mostPopularTestsChartOptions"></div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </ng-container>
  </div>
</app-standard-layout>
