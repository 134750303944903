import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buttons-cell-renderer',
  templateUrl: './buttons-cell-renderer.component.html',
  styleUrls: ['./buttons-cell-renderer.component.styl']
})
export class ButtonsCellRendererComponent {

  params: any;
  buttons: any[] = [];

  agInit(params: any): void {
    this.buttons = params.colDef.cellRendererParams.buttons || [];
    this.params = params;
  }

}
