import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.styl']
})
export class InputDialogComponent implements OnInit {

  value: any;
  searchInput = '';
  items: any[] = [];
  filteredItems: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<InputDialogComponent>) {
    this.items = [...data.items];
    this.filteredItems = [...this.items];
  }

  ngOnInit(): void {
  }

  emptyCallback(): void {}

  cancel(): void {
    this.dialogRef.close(null);
  }

  confirm(): void {
    this.dialogRef.close(this.value);
  }

  onSelectionChange(agGridEvent: any): void {
    const selectedRows = agGridEvent.api.getSelectedRows();

    if (selectedRows.length === 0) {
      this.value = this.data.multipleSelection ? [] : null;
      return;
    }

    this.value = this.data.multipleSelection ? selectedRows : selectedRows[0];
  }

  onSearchValueChange(value: any): void {
    if (value.length === 0) {
      this.filteredItems = [...this.items];
      return;
    }

    this.filteredItems = [...this.items].filter(item => this.data.filter(item, value));
  }

}
