<div class="placeholder"></div>
<div class="wrapper spacing-inner-left-10 spacing-inner-right-10" [ngStyle]="{backgroundColor: (userClient && userClient.primaryColor) || undefined}">
  <button mat-icon-button color="accent" (click)="toggleNavigationExpansion()">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="separator spacing-left-10 spacing-right-10"></span>
  <img [src]="(userClient && userClient.logo) || '/assets/images/logo-white.svg'" [alt]="((userClient && userClient.name) || 'Telema') + ' Logo'" [style.height]="userClient && userClient.logoHeight ? userClient.logoHeight + 'px' : undefined" />
  <div class="user-wrapper spacing-left-auto" *ngIf="user">
    <span>{{ user.firstName }} {{ user.lastName }}</span>
  </div>
  <button mat-icon-button color="accent" class="spacing-left-5" (click)="logout()" *ngIf="user">
    <mat-icon>logout</mat-icon>
  </button>
</div>
