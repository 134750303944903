import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import IElement from '../../interfaces/IElement';
import IGroupElement from '../../interfaces/IGroupElement';
import {ElementType} from '../../definitions/ElementType';
import IQuestionElement from '../../interfaces/IQuestionElement';
import ISurvey from '../../interfaces/ISurvey';
import IResearch from '../../interfaces/IResearch';
import {QuestionType} from '../../definitions/QuestionType';
import {GroupDisplayAs} from '../../definitions/GroupDisplayAs';

@Component({
  selector: 'app-process-group',
  templateUrl: './process-group.component.html',
  styleUrls: ['./process-group.component.styl']
})
export class ProcessGroupComponent implements OnInit {

  @Input() group: IElement<IGroupElement>;
  @Input() research: IResearch;
  @Input() parentCanGoPrev?: () => boolean;
  @Input() parentCanGoNext?: () => boolean;
  @Input() getRawValue?: () => any;
  @Output() handleValueChange: EventEmitter<{value: any, element: IElement<IQuestionElement>}> = new EventEmitter<{value: any, element: IElement<IQuestionElement>}>();
  groupPath: IElement<IGroupElement>[] = [];
  prevPath: IElement<any>[] = [];
  current: IElement<any> = null;
  currentIndex = -1;
  elementType = ElementType;
  groupDisplayAs = GroupDisplayAs;
  @Input() parentGoPrev?: () => void = () => {};
  @Input() parentGoNext?: () => void = () => {};

  constructor() { }

  ngOnInit(): void {
    this.parentCanGoNext = () => false;
    this.parentCanGoPrev = () => false;
    this.getRawValue = () => ({});
  }

  hasNext(): boolean {
    return this.group.child.children.length > this.currentIndex + 1;
  }

  hasPrev(): boolean {
    return this.prevPath.length > 0;
  }

  canGoPrev(): boolean {
    return this.prevPath.length > 0 || (this.prevPath.length === 0 && this.parentCanGoPrev());
  }

  canGoNext(): boolean {
    if (this.current === null && this.currentIndex === -1 && this.group.child.startMessage) {
      return true;
    }

    if (this.current && this.current.type !== ElementType.QUESTION) {
      return true;
    }

    if (this.current && (this.current.child.required !== true || (this.current.type === ElementType.QUESTION && this.current.child.type === QuestionType.RANKING_ORDERING))) {
      return true;
    }

    if (this.current && this.current.child.multiple === true && this.getRawValue()[this.current.code].length > 0) {
      return true;
    }

    if (this.current && this.current.child.multiple !== true && this.getRawValue()[this.current.code] !== null) {
      return true;
    }

    return this.parentCanGoNext();
  }

  next(): void {
    if (this.currentIndex + 1 < this.group.child.children.length) {
      if (this.current !== null) {
        this.prevPath.push(this.current);
      }

      this.currentIndex++;
      this.current = this.group.child.children[this.currentIndex];
    } else {
      this.parentGoNext();
    }
  }

  prev(): void {
    if (this.prevPath.length > 0) {
      this.current = this.prevPath[this.prevPath.length - 1];
      this.prevPath.slice(this.prevPath.length - 1, 1);
    } else {
      this.parentGoPrev();
    }
  }

  onValueChange(ev: {value: any, element: IElement<IQuestionElement>}): void {
    this.handleValueChange.emit(ev);
  }

}
