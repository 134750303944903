<div class="container spacing-top-50 spacing-bottom-50">
  <img src="/assets/images/logo.svg" class="align-center d-block" />
  <mat-card class="spacing-top-10">
    <ng-container *ngIf="!candidateFullName">
      <h1 class="text-center spacing-0 spacing-inner-0 text-bold" i18n>Create your Candidate Profile</h1>
      <p class="text-center spacing-0 spacing-inner-0 spacing-top-2 opacity-50 spacing-bottom-25" i18n>Even if you do not participate to one of our surveys, you can fill in information about you and in case there are open positions we will contact you directly.</p>

      <app-smart-form #form [fields]="fields" colSize="6"></app-smart-form>

      <button mat-flat-button color="primary" class="spacing-top-10 full-width" i18n (click)="submit()" [disabled]="!form || form.form.invalid">Create account</button>
    </ng-container>

    <ng-container *ngIf="candidateFullName">
      <h1 class="text-center spacing-0 spacing-inner-0 text-bold" i18n>Welcome on board!</h1>
      <p class="text-center spacing-0 spacing-inner-0 spacing-top-2 opacity-50" i18n>Thank you {{candidateFullName}} for signing up as a future candidate. We will let you know if we have any open positions as soon as we can.</p>
    </ng-container>
  </mat-card>
</div>
