import {Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import * as CKEditor from '../../../assets/resources/ckeditor/ckeditor';
import {environment} from '../../../environments/environment';
import {MatFormFieldControl} from '@angular/material/form-field';
import RichInputDataStructure from '../../definitions/RichInputDataStructure';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular';


@Component({
  selector: 'app-rich-input',
  templateUrl: './rich-input.component.html',
  styleUrls: ['./rich-input.component.styl'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RichInputComponent), multi: true }]
})
export class RichInputComponent implements ControlValueAccessor, OnInit {
  editor = CKEditor;
  editorConfig = {
    placeholder: '',
    toolbar: {
      items: [
        'heading',
        '|',
        'fontSize',
        'bold',
        'italic',
        'strikethrough',
        'underline',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'alignment',
        'indent',
        'outdent',
        'horizontalLine',
        '|',
        'imageUpload',
        'imageInsert',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
        '|',
        'removeFormat',
        'specialCharacters',
        'codeBlock',
        'exportPdf',
        'exportWord'
      ]
    },
    language: 'en',
    image: {
      toolbar: [
        'imageTextAlternative',
        'imageStyle:full',
        'imageStyle:side'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableCellProperties',
        'tableProperties'
      ]
    },
    simpleUpload: {
      uploadUrl: '/api/v1/file/upload/ckeditor',
      headers: {
      }
    },
  };

  value: any = null;
  @Input()
  disabled = false;
  @Input()
  placeholder = '';
  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  constructor() {}

  ngOnInit() {
    this.editorConfig.placeholder = this.placeholder;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj || null;
    this.onChange(this.value);
  }

  valueChanged({ editor }: ChangeEvent): void {
    this.writeValue(editor.getData());
    this.onTouched();
  }

}
