import {Component, Input, OnInit} from '@angular/core';
import IDetailField from '../../interfaces/IDetailField';
import {ContentType} from '../../definitions/ContentType';

@Component({
  selector: 'app-smart-detail',
  templateUrl: './smart-detail.component.html',
  styleUrls: ['./smart-detail.component.styl']
})
export class SmartDetailComponent implements OnInit {

  contentType = ContentType;

  @Input()
  fields: IDetailField[] = [];

  @Input()
  source: any = {};

  labelYes = 'Yes';
  labelNo = 'No';

  constructor() {
    this.labelYes = $localize`Yes`;
    this.labelNo = $localize`No`;
  }

  ngOnInit(): void {
  }

}
