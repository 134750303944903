import {Component, forwardRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {Observable} from 'rxjs';
import ISmartField from '../../interfaces/ISmartField';

interface Validation {
  add: (item: any, value: any) => string;
  edit: (item: any, value: any) => string;
  delete: (item: any, value: any) => string;
}

interface Callbacks {
  add: (item: any, value?: any) => Observable<any>;
  edit: (item: any, value?: any) => Observable<any>;
  delete: (item: any, value?: any) => Observable<any>;
}

@Component({
  selector: 'smart-crud',
  templateUrl: './smart-crud.component.html',
  styleUrls: ['./smart-crud.component.styl'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SmartCrudComponent)
    }
  ]
})
export class SmartCrudComponent implements OnInit, ControlValueAccessor {

  @Input() field: ISmartField;
  @Input() label = $localize`OPEN`;
  @Input() validation: Validation = {
    add: () => null,
    edit: () => null,
    delete: () => null
  };
  @Input() callbacks: Callbacks = {
    add: item => new Observable<any>(subscriber => {
      subscriber.next(item);
      subscriber.complete();
    }),
    edit: item => new Observable<any>(subscriber => {
      subscriber.next(item);
      subscriber.complete();
    }),
    delete: item => new Observable<any>(subscriber => {
      subscriber.next(item);
      subscriber.complete();
    })
  };
  private _value: any;
  private changed: any = () => {};
  private touched: any = () => {};

  constructor(private bottomSheet: MatBottomSheet) { }

  get value(): any {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
    this.changed(this.value);
    this.touched(this.value);
  }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  onItemAdd(item: any): Observable<any> {
    return new Observable<any>(subscriber => {
      const validation = this.validation.add(item, this.value);

      if (!validation) {
        subscriber.error(new Error(validation));
        subscriber.complete();
        return;
      }

      this.callbacks.add(item, this.value).subscribe(response => {
        this.value = [
          ...this.value,
          response
        ];

        subscriber.next({
          items: this.value,
          item: response
        });
        subscriber.complete();
      }, error => {
        subscriber.error(error);
        subscriber.complete();
      });
    });
  }

  onItemEdit(item: any): Observable<any> {
    return new Observable<any>(subscriber => {
      const validation = this.validation.edit(item, this.value);

      if (!validation) {
        subscriber.error(new Error(validation));
        subscriber.complete();
        return;
      }

      this.callbacks.edit(item, this.value).subscribe(response => {
        this.value = [
          ...this.value.slice(0, response.index),
          response.data,
          ...this.value.slice(response.index + 1)
        ];

        subscriber.next({
          items: this.value,
          item: response.data
        });
        subscriber.complete();
      }, error => {
        subscriber.error(error);
        subscriber.complete();
      });
    });
  }

  onItemDelete(item: any): Observable<any> {
    return new Observable<any>(subscriber => {
      const validation = this.validation.delete(item, this.value);

      if (!validation) {
        subscriber.error(new Error(validation));
        subscriber.complete();
        return;
      }

      this.callbacks.delete(item, this.value).subscribe(response => {
        this.value = [
          ...this.value.splice(response.index, 1)
        ];

        subscriber.next({
          items: this.value,
          item: response.data
        });
        subscriber.complete();
      }, error => {
        subscriber.error(error);
        subscriber.complete();
      });
    });
  }

  open(): void {
    this.bottomSheet.open(SmartCrudBottomSheet, {
      data: {
        items: this.value,
        onItemAdd: this.onItemAdd.bind(this),
        onItemEdit: this.onItemEdit.bind(this),
        onItemDelete: this.onItemDelete.bind(this),
        field: this.field
      },
      autoFocus: false,
      panelClass: 'smart-crud-bottom-sheet'
    });
  }

}

@Component({
  selector: 'smart-crud-bottom-sheet',
  templateUrl: './smart-crud-bottom-sheet.html',
  styleUrls: ['./smart-crud-bottom-sheet.styl']
})
export class SmartCrudBottomSheet {
  items: any[] = [];
  displayForm = false;
  field: ISmartField;

  @ViewChild('itemsList') itemsList: any;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<SmartCrudBottomSheet>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: any
  ) {
    if (data.getItemLabel) {
      this.getItemLabel = data.getItemLabel;
    }

    this.items = data.items || [];
    this.field = data.field;
  }

  getItemLabel(item: any): string {
    return item.label;
  }

  deleteSelected(): void {
    console.log(this.itemsList.selectedOptions.selected);
  }

  showForm(): void {
    this.displayForm = true;
  }
}
