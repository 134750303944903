<h1 mat-dialog-title i18n>Choose a report</h1>
<div mat-dialog-content>
  <mat-select [(ngModel)]="report" placeholder="Select one" i18n-placeholder>
    <mat-option *ngFor="let item of reports" [value]="item">{{item.name}}</mat-option>
  </mat-select>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()" i18n>Cancel</button>
  <button mat-button [mat-dialog-close]="report" [disabled]="!report" i18n>Confirm</button>
</div>
