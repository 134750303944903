<div *ngIf="!loading" id="page-wrapper">
  <div class="page page-1">
    <div *ngIf="!show" class="d-flex flex-row align-items-center">
      <img  src="/assets/images/logo.svg" alt="Telema Logo" />
      <h1 class="m-0 ml-auto text-upper opacity-50">{{report.name}}<br /><small class="text-right font-size-8 full-width d-block">{{candidate.firstName}} {{candidate.lastName}}</small></h1>
    </div>
    <div *ngIf="show" class="d-flex flex-row-reverse">
      <img  src="/assets/images/logo.svg" alt="Telema Logo" />
    </div>
    <div class="mt-5">
      <button mat-button class="mb-3" (click)="downloadReport()" data-html2canvas-ignore="true">
        <mat-icon>download</mat-icon>
        <span i18n class="ml-3">Download Report</span>
      </button>

      <button mat-button class="mb-3" (click)="testShow()" data-html2canvas-ignore="true">
        <span i18n class="ml-3">test Show</span>
      </button>
      <div *ngIf="show" class="mt-5">
        <table style="width: 20%; margin-left: auto; margin-right: auto;">
          <tr>
            <th>userId</th>
            <th>{{candidate.email}}</th>
          </tr>
          <tr>
            <th>Nome</th>
            <th>{{candidate.firstName}}</th> 
          </tr>
          <tr>
            <th>Cognome</th>
            <th>{{candidate.lastName}}</th> 
          </tr>
        </table>  
      </div>
      <span>&nbsp;</span> 
      <span>&nbsp;</span>
      <table>
        <tr>
          <th style="width: 15%">{{report.name}}</th>
          <th *ngFor="let field of report.fields" style="width: 5%"></th>
          <th *ngFor="let dataset of report.datasets" [style]="'width: ' + ((100 - 15 - (5 * report.fields)) / report.datasets.length) + '%'">
            <div class="dataset-title">{{dataset.title}}</div>
            <div class="dataset-description">{{datasetDescriptions[dataset.key]}}</div>
          </th>
        </tr>
        <tr>
          <td>
            <span style="display:block" *ngFor="let reportExtra of reportExtras">{{reportExtra.name}} = {{reportExtra.value}}</span>
          </td>
          <td class="text-center" *ngFor="let field of report.fields"><strong>{{field | uppercase}}</strong></td>
          <td *ngFor="let dataset of report.datasets" class="no-padding">
            <div class="d-flex flex-row floating-row">
              <div><strong>M</strong></div>
              <div><strong>&sigma;</strong></div>
              <div><strong>PST</strong></div>
            </div>
          </td>
        </tr>
        
        <tr *ngFor="let topic of reportTopics" [ngStyle]="{'background-color': topic.group === true ? 'rgba(0, 0, 0, 0.1)' : 'none'}">
          <td>{{topic.name}}</td>
          <td *ngFor="let field of report.fields" class="text-center">{{(topicResults[topic.name] && topicResults[topic.name].results[field]) || 0}}</td>
          <td *ngFor="let dataset of report.datasets" class="no-padding"> 
            <div class="d-flex flex-row floating-row">
              <div>{{((topicResults[topic.name] && topicResults[topic.name].datasetResults[dataset.key] && topicResults[topic.name].datasetResults[dataset.key].m) || 0) | number: '1.3-3'}}</div>
              <div>{{((topicResults[topic.name] && topicResults[topic.name].datasetResults[dataset.key] && topicResults[topic.name].datasetResults[dataset.key].stdev) || 0) | number: '1.3-3'}}</div>
              <div>{{((topicResults[topic.name] && topicResults[topic.name].datasetResults[dataset.key] && topicResults[topic.name].datasetResults[dataset.key].pst) || 0) | number: '1.3-3'}}</div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div *ngIf="show">
      <span>Livello di Coerenza<br></span>
      <span>Alto 0<=x<=10<br></span>
      <span>Medio 11<=x<=20<br></span>
      <span>Basso 21<=x<=30<br></span>
    </div>
  </div>

  <div class="page page-2" *ngIf="chartData.length > 0 && report">  
    <div *ngIf="show" class="d-flex flex-row-reverse">
      <img src="/assets/images/logo.svg" alt="Telema Logo" />
    </div>
    <div *ngIf="show" class="mt-1">
      <table style="width: 100%;">
        <tr>
          <th>userId</th>
          <th>{{candidate.email}}</th>
          <th style="border-top: none; border-bottom: none;"></th>
          <th>Livello Studi</th>
        </tr>
        <tr>
          <th>Nome</th>
          <th>{{candidate.firstName}}</th> 
          <th style="border-top: none; border-bottom: none;border-right: none;"></th>
          <th style="border-left: none; border-right: none; border-top: none; border-bottom: none;">N =</th>
        </tr>
        <tr>
          <th>Cognome</th>
          <th>{{candidate.lastName}}</th> 
          <th style="border-top: none; border-bottom: none;border-right: none;;"></th>
          <th style="border-left: none; border-right: none; border-top: none; border-bottom: none;">Cultura =</th>
        </tr>
        <tr>
          <th style="border-left: none; border-right: none; border-top: none; border-bottom: none;"></th>
          <th style="border-left: none; border-right: none; border-top: none; border-bottom: none;"></th> 
          <th style="border-left: none; border-right: none; border-top: none; border-bottom: none;"></th>
          <th style="border-left: none; border-right: none; border-top: none; border-bottom: none;">LivelloStudi =</th>
        </tr>  
      </table>
    </div>

    <div style="width: 100%;" class="clearbox">
        <div echarts [options]="chartOptions" style="height: 800px"></div>
    </div>
 </div> 
</div>

<div *ngIf="loading">
  <app-spinner></app-spinner>
</div>

