export enum ContentType {
  SHORT_TEXT,
  LONG_TEXT,
  NUMBER,
  INTEGER,
  BOOLEAN,
  DECIMAL,
  LIST,
  MULTILIST,
  EMAIL,
  PASSWORD,
  PHONE,
  DATE,
  BLANK,
  HIDDEN,
  RICH_TEXT,
  FILE,
  ADDRESS,
  CRUD,
  MAP,
  OBJECT
}
