import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentType} from '../../definitions/ContentType';
import {SmartFormComponent} from '../../components/smart-form/smart-form.component';
import {ApiService} from '../../services/api.service';
import ISubject from '../../interfaces/ISubject';
import {Store} from '@ngrx/store';
import IStore from '../../interfaces/IStore';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {CandidateEducationComponent} from '../../components/candidate-education/candidate-education.component';
import IItem from '../../interfaces/IItem';
import {CandidateCourseComponent} from '../../components/candidate-course/candidate-course.component';
import {CandidateWorkExperienceComponent} from '../../components/candidate-work-experience/candidate-work-experience.component';
import ISmartField from '../../interfaces/ISmartField';
import IResearch from '../../interfaces/IResearch';
import * as nationalitiesData from '../../../assets/data/nationalities.json';
import IClient from "../../interfaces/IClient";

@Component({
  selector: 'app-process-signup-screen',
  templateUrl: './process-signup-screen.component.html',
  styleUrls: ['./process-signup-screen.component.styl']
})
export class ProcessSignupScreenComponent implements OnInit {

  fieldsCreate = [
    { key: 'email', label: $localize`Email`, type: ContentType.EMAIL },
    { key: 'password', label: $localize`Password`, type: ContentType.PASSWORD },
    { key: 'firstName', label: $localize`First Name`, type: ContentType.SHORT_TEXT },
    { key: 'lastName', label: $localize`Last Name`, type: ContentType.SHORT_TEXT },
    { key: 'phone', label: $localize`Phone`, type: ContentType.PHONE },
    { key: 'fiscalCode', label: $localize`Fiscal Code`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'profession', label: $localize`Profession`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'birthDate', label: $localize`Birth Date`, required: false, type: ContentType.DATE },
    { key: 'birthPlace', label: $localize`Birth Place`, required: false, type: ContentType.SHORT_TEXT },
    { key: 'gender', label: $localize`Gender`, type: ContentType.LIST, data: [ { value: 'MALE', label: $localize`Male` }, { value: 'FEMALE', label: $localize`Female` }, { value: 'OTHER', label: $localize`Other` }] },
    { key: 'resume', label: $localize`Resume`, required: false, type: ContentType.FILE },
    { key: 'coverLetter', label: $localize`Cover Letter`, required: false, type: ContentType.FILE },
    { key: 'domicile', label: $localize`Domicile`, required: false, type: ContentType.ADDRESS },
    { key: 'residence', label: $localize`Residence`, required: false, type: ContentType.ADDRESS },
    { key: 'nationality', label: $localize`Nationality`, required: false, type: ContentType.LIST, data: nationalitiesData.data.map(item => ({ value: item, label: item })) },
    { key: 'privacyAgreements', label: $localize`Privacy Agreements`, required: false, type: ContentType.LIST, data: [], defaultValue: [] },
    {
      key: 'education',
      label: $localize`Education`,
      required: false,
      type: ContentType.CRUD,
      options: {
        title: $localize`Education`,
        renderer: CandidateEducationComponent,
        fields: [
          { key: 'title', label: $localize`Title` },
          {
            key: 'level',
            label: $localize`Level`,
            required: true,
            type: ContentType.LIST,
            data: [],
            fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getEducationLevels().then(educationLevels => {
                resolve(educationLevels.map(educationLevel => ({
                  value: educationLevel,
                  label: educationLevel.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id
          },
          { key: 'startDate', label: $localize`Start Date`, type: ContentType.DATE },
          { key: 'endDate', label: $localize`End Date`, type: ContentType.DATE },
          { key: 'institute', label: $localize`Institute`, type: ContentType.LIST, data: [], fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getEducationInstitutes().then(educationInstitutes => {
                resolve(educationInstitutes.map(educationInstitute => ({
                  value: educationInstitute,
                  label: educationInstitute.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id },
          {
            key: 'category',
            label: $localize`Category`,
            type: ContentType.LIST,
            data: [],
            fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getEducationCategories().then(educationCategories => {
                resolve(educationCategories.map(educationCategory => ({
                  value: educationCategory,
                  label: educationCategory.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id,
            options: {
              groupBy: 'value.macro'
            }
          },
          { key: 'grade', label: $localize`Grade` },
          { key: 'withDistinction', label: $localize`With Distinction`, type: ContentType.BOOLEAN, displayAs: 'toggle' },
          { type: ContentType.BLANK },
          { key: 'description', label: $localize`Description`, type: ContentType.LONG_TEXT, colSize: '12' },
        ]
      },
      defaultValue: []
    },
    {
      key: 'courses',
      label: $localize`Courses`,
      required: false,
      type: ContentType.CRUD,
      options: {
        title: $localize`Courses`,
        renderer: CandidateCourseComponent,
        fields: [
          {
            key: 'course',
            label: $localize`Course`,
            type: ContentType.SHORT_TEXT,
            required: true,
          },
          { key: 'startDate', label: $localize`Start Date`, type: ContentType.DATE },
          { key: 'endDate', label: $localize`End Date`, type: ContentType.DATE },
          { key: 'grade', label: $localize`Grade` },
          { key: 'withDistinction', label: $localize`With Distinction`, type: ContentType.BOOLEAN, displayAs: 'toggle' },
          { type: ContentType.BLANK },
          { key: 'description', label: $localize`Description`, type: ContentType.LONG_TEXT, colSize: '12' },
        ]
      },
      defaultValue: []
    },
    {
      key: 'workExperience',
      label: $localize`Work Experience`,
      required: false,
      type: ContentType.CRUD,
      options: {
        title: $localize`Work Experience`,
        renderer: CandidateWorkExperienceComponent,
        fields: [
          { key: 'position', label: $localize`Position` },
          {
            key: 'company',
            label: $localize`Company`,
            type: ContentType.SHORT_TEXT
          },
          { key: 'startDate', label: $localize`Start Date`, type: ContentType.DATE },
          { key: 'endDate', label: $localize`End Date`, type: ContentType.DATE },
          {
            key: 'workSector',
            label: $localize`Sector`,
            required: false,
            type: ContentType.LIST,
            data: [],
            fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getWorkSectors().then(workSectors => {
                resolve(workSectors.map(workSector => ({
                  value: workSector,
                  label: workSector.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id
          },
          {
            key: 'workProfession',
            label: $localize`Profession`,
            type: ContentType.LIST,
            data: [],
            fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getWorkProfessions().then(workProfessions => {
                resolve(workProfessions.map(workProfession => ({
                  value: workProfession,
                  label: workProfession.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id
          },
          {
            key: 'contractType',
            label: $localize`Contract Type`,
            type: ContentType.LIST,
            data: [],
            fetchData: () => new Promise<IItem[]>((resolve, reject) => {
              this.apiService.getWorkContractTypes().then(contractTypes => {
                resolve(contractTypes.map(contractType => ({
                  value: contractType,
                  label: contractType.name
                })));
              }).catch(() => {
                reject();
              });
            }),
            compareWith: (o1, o2) => o1.id === o2.id
          },
          { key: 'annualGrossIncome', label: $localize`Annual Gross Income` },
          { key: 'annualNetIncome', label: $localize`Annual Net Income` },
          { key: 'description', label: $localize`Description`, type: ContentType.LONG_TEXT, colSize: '12' },
        ]
      },
      defaultValue: []
    },
    { key: 'information', label: $localize`Information`, required: false, type: ContentType.OBJECT, defaultValue: {} },
  ];

  fieldsCreateFiltered: ISmartField[] = [];
  research: IResearch;
  client: IClient;

  @ViewChild('createForm') createForm: SmartFormComponent;

  constructor(private apiService: ApiService, private authService: AuthService, private router: Router, private store: Store) {
    store.pipe().subscribe((state: IStore) => {
      if (state.process.research === null) {
        router.navigateByUrl('/process/landing');
      } else if (!this.research) {
        this.research = state.process.research;
        this.client = state.process.client;
        this.buildFilteredFields();
      }
    });
  }

  ngOnInit(): void {
  }

  create(): void {
    if (this.createForm.form.invalid) {
      this.createForm.form.markAllAsTouched();
      return;
    }

    const rawValue: any = this.createForm.form.getRawValue();
    const data: ISubject = {courses: [], education: [], information: {}, privacyAgreements: {
        dataUsageForCandidateResearchAgreement: false,
        personalDataAgreement: false,
        personalSpecificDataAgreement: false,
        dataUsageForStatisticsAndReportsAgreement: false,
        marketingAgreement: false
      }, workExperience: []};

    const { baseFields, extraInformation } = this.research;
    const extraInformationMap = {};

    extraInformation.forEach(item => extraInformationMap[item.code] = item);

    Object.keys(rawValue).forEach(key => {
      const value = rawValue[key];

      if (baseFields.indexOf(key) > -1) {
        data[key] = value;
      }

      if (extraInformationMap.hasOwnProperty(key)) {
        const extraInformationField = extraInformationMap[key];

        let type = this.getTypeForNewField(extraInformationField.type);

        if (type === null || type === undefined || type === ContentType.LIST) {
          type = ContentType.SHORT_TEXT;
        }

        data.information[key] = {
          value,
          label: extraInformationField.name,
          code: extraInformationField.code,
          type
        };
      }
    });

    this.apiService.createSubject(data).then((subject: ISubject) => {
      this.router.navigateByUrl('/process/login');
    }).catch(() => {});
  }

  private getTypeForNewField(type: string) {
    switch (type) {
      case 'STRING':
        return ContentType.SHORT_TEXT;

      case 'NUMBER':
        return ContentType.NUMBER;

      case 'BOOL':
        return ContentType.BOOLEAN;

      case 'DATE':
        return ContentType.DATE;

      case 'FILE':
        return ContentType.FILE;

      case 'LIST':
        return ContentType.LIST;

      default:
        return null;
    }
  }

  private buildFilteredFields(): void {
    const { baseFields, requiredFields, extraInformation } = this.research;
    const filteredFields: ISmartField[] = this.fieldsCreate.filter(field => baseFields.indexOf(field.key) > -1).map(field => ({
      ...field,
      required: requiredFields.indexOf(field.key) > -1
    }));

    extraInformation.forEach(extraField => {

      const contentType = this.getTypeForNewField(extraField.type);

      if (contentType === undefined || contentType === null) {
        return;
      }

      const newField: ISmartField = {
        key: extraField.code,
        label: extraField.name,
        required: extraField.required === true,
        type: contentType
      };

      if (contentType === ContentType.LIST) {
        newField.defaultValue = [];
        newField.data = (extraField.values || '').split(',').map(value => ({
          value: value.trim(),
          label: value.trim()
        }));
      } else if (contentType === ContentType.BOOLEAN) {
        newField.displayAs = 'toggle';
      }

      filteredFields.push(newField);
    });

    this.fieldsCreateFiltered = filteredFields;
  }

}
