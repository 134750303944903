import {Component, OnDestroy, OnInit} from '@angular/core';
import {InputCrudDialogData} from '../smart-form/input-crud/input-crud.component';
import ISmartField from '../../interfaces/ISmartField';

@Component({
  selector: 'app-generic-crud-list',
  templateUrl: './generic-crud-list.component.html',
  styleUrls: ['./generic-crud-list.component.styl']
})
export class GenericCrudListComponent implements OnInit, OnDestroy {

  public data: InputCrudDialogData;
  private _items: any[];
  public selectedItems: any[] = [];
  private loaded = false;
  private onSelfDestroy: () => void;
  private onSelectedItemsChange: (selectedItems: any[], item: any, removed: boolean) => void;
  private onItemDelete: (item: any) => void;
  private onItemEdit: (item: any) => void;
  private onItemView: (item: any) => void;
  private field: ISmartField;
  private syncItems: any[];

  constructor() { }

  ngOnInit(): void {
  }

  get items(): any[] {
    return this._items;
  }

  set items(value: any[]) {
    this._items = value;
  }

  init(options: any): void {
    setTimeout(() => {
      const extendedOptions = {
        items: [],
        syncItems: [],
        onSelfDestroy: () => {},
        onSelectedItemsChange: () => {},
        onItemDelete: () => {},
        onItemEdit: () => {},
        onItemView: () => {},
        ...(options || {})
      };

      this.onSelfDestroy = extendedOptions.onSelfDestroy;
      this.onSelectedItemsChange = extendedOptions.onSelectedItemsChange;
      this.onItemDelete = extendedOptions.onItemDelete;
      this.onItemEdit = extendedOptions.onItemEdit;
      this.onItemView = extendedOptions.onItemView;
      this.items = extendedOptions.items;
      this.field = extendedOptions.field;
      this.syncItems = extendedOptions.syncItems;
    });
  }

  ngOnDestroy(): void {
    this.loaded = false;
    this.onSelfDestroy();
  }

  setItems(items: any[]): void {
    this.items = items;
  }

  selectItem(item: any): void {
    const index = this.selectedItems.indexOf(item);
    let removed = false;

    if (index > -1) {
      this.selectedItems.splice(index, 1);
      removed = true;
    } else {
      this.selectedItems.push(item);
    }

    this.onSelectedItemsChange(this.selectedItems, item, removed);
  }

  editItem(event: Event, item: any): void {
    this.onItemEdit(item);
    event.stopPropagation();
    event.preventDefault();
  }

  openItem(event: Event, item: any): void {
    this.onItemView(item);
    event.stopPropagation();
    event.preventDefault();
  }

  hasChildren(item: any): boolean {
    return this.syncItems.filter(o => o.parent === item.code).length > 0;
  }
}
