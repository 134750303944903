import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StandardCellRendererComponent} from '../standard-cell-renderer/standard-cell-renderer.component';
import {CheckboxCellRendererComponent} from './checkbox-cell-renderer/checkbox-cell-renderer.component';
import {ActionsCellRendererComponent} from './actions-cell-renderer/actions-cell-renderer.component';
import '../../custom-modules/ag-grid-enterprise';
import {LicenseManager} from '../../custom-modules/ag-grid-enterprise';
import {DateCellRendererComponent} from './date-cell-renderer/date-cell-renderer.component';
import {GroupCellRendererComponent} from './group-cell-renderer/group-cell-renderer.component';
import * as moment from 'moment';
import {ObjectCellRendererComponent} from './object-cell-renderer/object-cell-renderer.component';
import {QuestionAnswersCellRendererComponent} from './question-answers-cell-renderer/question-answers-cell-renderer.component';
import {ButtonsCellRendererComponent} from './buttons-cell-renderer/buttons-cell-renderer.component';
import {ResearchReportCellRendererComponent} from './research-report-cell-renderer/research-report-cell-renderer.component';
import {LongListCellRendererComponent} from './long-list-cell-renderer/long-list-cell-renderer.component';
import {ObjectListFilterComponent} from './object-list-filter/object-list-filter.component';
import {AuthService} from '../../services/auth.service';

LicenseManager.setLicenseKey("CIAO");

@Component({
  selector: 'app-smart-table',
  templateUrl: './smart-table.component.html',
  styleUrls: ['./smart-table.component.styl']
})
export class SmartTableComponent implements OnInit {
  @Input() viewPermissions: string[] = [];
  @Input() editPermissions: string[] = [];
  @Input() deletePermissions: string[] = [];
  @Input() permissionGroup: string = null;
  @Input() columnDefs = [];
  @Input() rowData = [];
  @Input() defaultColDef?: any = {
    filter: true,
    sortable: true,
    cellRenderer: 'standardRenderer'
  };
  @Input() showActions = true;
  @Output() rowView: EventEmitter<any> = new EventEmitter();
  @Output() rowEdit: EventEmitter<any> = new EventEmitter();
  @Output() rowDelete: EventEmitter<any> = new EventEmitter();
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() rowDragManaged = false;
  @Input() animateRows = false;
  @Input() pagination = true;
  @Input() paginationSize = 100;
  @Input() hideEdit = false;
  @Input() hideView = false;
  @Input() hideDelete = false;
  @Input() hideActions = false;
  @Input() gridHeight = 500;
  @Input() customFrameworkComponents = {};
  @Input() customGridContext = {};
  @Input() rowSelection?: string;
  @Input() groupParams: any = {};

  gridApi: any;
  gridColumnApi: any;
  gridContext: any = {};

  frameworkComponents = {
    standardRenderer: StandardCellRendererComponent,
    checkboxRenderer: CheckboxCellRendererComponent,
    actionsRenderer: ActionsCellRendererComponent,
    dateRenderer: DateCellRendererComponent,
    groupRenderer: GroupCellRendererComponent,
    objectRenderer: ObjectCellRendererComponent,
    questionAnswersRenderer: QuestionAnswersCellRendererComponent,
    researchReportRenderer: ResearchReportCellRendererComponent,
    longListRenderer: LongListCellRendererComponent,
    buttons: ButtonsCellRendererComponent,
    objectListFilter: ObjectListFilterComponent
  };

  groupRowRendererParams = {
    innerRenderer: 'groupRenderer',
    suppressCount: true
  };

  statusBar = {
    statusPanels: [
      { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'center' },
      { statusPanel: 'agSelectedRowCountComponent', align: 'left' }
    ]
  };

  columnTypes = {
    datetime: {
      valueGetter: params => params.data[params.colDef.field] ? moment(params.data[params.colDef.field]).format('YYYY-MM-DD HH:mm:ss') : ''
    },
    array: {
      valueFormatter: params => params.value.join(',')
    },
    object: {
      cellRenderer: 'objectRenderer'
    }
  };

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.groupRowRendererParams = {
      ...this.groupRowRendererParams,
      ...this.groupParams
    };
    this.frameworkComponents = {
      ...this.frameworkComponents,
      ...this.customFrameworkComponents
    };
    this.gridContext.onView = this.rowView;
    this.gridContext.onEdit = this.rowEdit;
    this.gridContext.onDelete = this.rowDelete;
    this.gridContext.hideEdit = this.hideEdit;
    this.gridContext.hideView = this.hideView;
    this.gridContext.hideDelete = this.hideDelete;

    this.gridContext = {
      ...this.gridContext,
      ...this.customGridContext
    };

    let columnDefs = [];

    if (this.rowSelection) {
      columnDefs.push({
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        width: 70,
        editable: false,
        suppressMenu: true,
        suppressMovable: true,
        suppressNavigable: true,
        suppressSizeToFit: true,
        sortable: false,
        cellClass: 'no-border text-center'
      });
    }

    columnDefs = [
      ...columnDefs,
      ...this.columnDefs
    ];

    if (this.permissionGroup) {
      const viewPermissions = [`${this.permissionGroup}_view`, `${this.permissionGroup}_view_own`, `${this.permissionGroup}_view_org`];
      const deletePermissions = [`${this.permissionGroup}_delete`, `${this.permissionGroup}_delete_own`, `${this.permissionGroup}_delete_org`];
      const editPermissions = [`${this.permissionGroup}_edit`, `${this.permissionGroup}_edit_own`, `${this.permissionGroup}_edit_org`];

      viewPermissions.forEach(permission => {
        if (!this.viewPermissions.includes(permission)) {
          this.viewPermissions.push(permission);
        }
      });

      deletePermissions.forEach(permission => {
        if (!this.deletePermissions.includes(permission)) {
          this.deletePermissions.push(permission);
        }
      });

      editPermissions.forEach(permission => {
        if (!this.editPermissions.includes(permission)) {
          this.editPermissions.push(permission);
        }
      });
    }

    this.gridContext.viewPermissions = this.viewPermissions;
    this.gridContext.deletePermissions = this.deletePermissions;
    this.gridContext.editPermissions = this.editPermissions;
    this.gridContext.deletePermissions = this.deletePermissions;
    this.gridContext.permissionGroup = this.permissionGroup;

    if (!this.hideActions) {
      let requiredWidth = 23 * 2 + (40 * 3);

      if (this.hideView) {
        requiredWidth -= 40;
      }

      if (this.hideDelete) {
        requiredWidth -= 40;
      }

      if (this.hideEdit) {
        requiredWidth -= 40;
      }

      if (this.authService.hasPermission(this.viewPermissions) || this.authService.hasPermission(this.deletePermissions) || this.authService.hasPermission(this.editPermissions)) {
        columnDefs.push({
          cellRenderer: 'actionsRenderer',
          pinned: 'right',
          width: requiredWidth,
          editable: false,
          suppressMenu: true,
          suppressMovable: true,
          suppressNavigable: true,
          suppressSizeToFit: true,
          sortable: false,
          cellClass: 'no-border'
        });
      }
    }

    this.columnDefs = columnDefs;
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  refresh(): void {
    this.gridApi.setRowData(this.rowData);
  }

  add(data: any): void {
    this.gridApi.applyTransaction({
      add: [data]
    });
  }

  remove(data: any): void {
    this.gridApi.applyTransaction({
      remove: [data]
    });
  }

  getData(): any[] {
    const data: any[] = [];

    this.gridApi.forEachNode((node, index) => {
      data.push(node.data);
    });

    return data;
  }

  onSelectionChanged(event: any): void {
    this.selectionChanged.emit(event);
  }

}
