import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '../../services/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.styl']
})
export class ImportDialogComponent implements OnInit {

  file: any;

  constructor(private dialogRef: MatDialogRef<ImportDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private api: ApiService, private snackbar: MatSnackBar) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    this.api.importFile(this.file, 'candidates').then(() => {
      this.snackbar.open($localize`Dati importati con successo`, $localize`Close`, {
        duration: 2000
      });

      this.dialogRef.close(true);
    }).catch(() => {});
  }

}
