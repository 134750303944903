import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'asDateTime'
})
export class AsDateTimePipe implements PipeTransform {

  transform(value: string): unknown {
    return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
  }

}
