<h1 mat-dialog-title>{{data.title}}</h1>
<mat-dialog-content>
  <div class="button-bar spacing-bottom-10">
    <button mat-stroked-button color="primary" (click)="showCreate()" *ngIf="data.hideCreate !== true && view === null">
      <mat-icon class="spacing-right-5">add_box</mat-icon>
      <span i18n>CREATE</span>
    </button>
    <button mat-stroked-button color="primary" (click)="save()" *ngIf="view === 'create' || view === 'edit'" [disabled]="canCreate !== true">
      <mat-icon class="spacing-right-5">save</mat-icon>
      <span i18n>SAVE</span>
    </button>
    <button mat-stroked-button [ngClass]="{'spacing-left-5': data.hideDelete !== true}" (click)="delete()" [disabled]="selectedItems.length === 0" *ngIf="data.hideDelete !== true && (view === null || view === 'edit')">
      <mat-icon class="spacing-right-5">delete</mat-icon>
      <span i18n>DELETE</span>
    </button>
    <button mat-stroked-button (click)="back()" *ngIf="view !== null || selectedItem" class="spacing-left-5">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>

  <ng-container #customTemplate *ngIf="view === null"></ng-container>
  <app-smart-form #formComponent *ngIf="view === 'create' || view === 'edit'" [fields]="fields" (valueChange)="onFormValueChange($event)" [defaults]="defaults" [params]="data.params"></app-smart-form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true">Close</button>
</mat-dialog-actions>
