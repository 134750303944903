import {
  Component,
  ComponentFactoryResolver, ComponentRef,
  ElementRef, EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import ISmartField from '../../../interfaces/ISmartField';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import Communication from '../../../definitions/Communication';
import {Observable, Subject} from 'rxjs';
import IAction from '../../../interfaces/IAction';
import {SmartFormComponent} from '../smart-form.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import ISubject from '../../../interfaces/ISubject';
import IEducationLevel from '../../../interfaces/IEducationLevel';
import IEducationInstitute from '../../../interfaces/IEducationInstitute';
import IEducationCategory from '../../../interfaces/IEducationCategory';

@Component({
  selector: 'app-input-crud',
  templateUrl: './input-crud.component.html',
  styleUrls: ['./input-crud.component.styl'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCrudComponent),
      multi: true
    }
  ]
})
export class InputCrudComponent implements OnInit, ControlValueAccessor {

  @Input() field: ISmartField;
  private _value: any[] = [];
  public inputBus: EventEmitter<IAction> = new EventEmitter<IAction>();
  public params: any = {};
  onChange: any = () => {};
  onTouch: any = () => {};
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  get value(): any[] {
    return this._value;
  }

  set value(value: any[]) {
    if (value !== undefined && value !== this.value) {
      this._value = value;
      this.params.value = this._value;
      this.onChange(this.value);
      this.onTouch(this.value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any[]): void {
    this.value = obj;
  }

  onItemRemove = (item: any) => new Promise((resolve, reject) => {
    const index = this.value.indexOf(item);

    if (index > -1) {
      this.value.splice(index, 1);
      this.onChange(this.value);
      this.onTouch(this.value);
    }

    resolve(this.value);
  })

  onItemAdd = (item: any) => new Promise((resolve, reject) => {
    if (this.value.indexOf(item) === -1) {
      this.value.push(item);
      this.onChange(this.value);
      this.onTouch(this.value);
    }

    resolve(this.value);
  })

  onItemUpdate = (originalItem: any, updatedItem: any) => new Promise(resolve => {
    const index = this.value.indexOf(originalItem);

    if (index > -1) {
      this.value[index] = updatedItem;
      this.onChange(this.value);
      this.onTouch(this.value);
    }

    resolve(this.value);
  })

  openDialog(): void {
    this.dialog.open(InputCrudDialog, {
      data: {
        title: this.field.options.title || $localize`Manage data`,
        renderer: this.field.options.renderer || null,
        onItemRemove: this.onItemRemove,
        onItemAdd: this.onItemAdd,
        onItemUpdate: this.onItemUpdate,
        items: this.value,
        fields: this.field.options.fields || [],
        field: this.field,
        defaults: this.field.options.defaults || {},
        save: this.field.options.crudSave,
        process: this.field.options.process || ((item: any) => new Promise(resolve => resolve(item))),
        bus: this.inputBus,
        params: this.params
      },
      width: '90vw',
      autoFocus: false
    });
  }

}

export interface InputCrudDialogData {
  title: string;
  renderer: any;
  hideCreate?: boolean;
  hideDelete?: boolean;
  items: any;
  fields: ISmartField[];
  field: ISmartField;
  defaults: any;
  onItemSelect: (item: any) => Promise<any>;
  onItemRemove: (item: any) => Promise<any>;
  onItemAdd: (item: any) => Promise<any>;
  onItemUpdate: (originalItem: any, updatedItem: any) => Promise<any>;
  save: (data: any) => Observable<any>;
  update: (id: any, data: any) => Observable<any>;
  process: (item: any) => Promise<any>;
  bus: EventEmitter<IAction>;
  params?: any;
}

@Component({
  selector: 'input-crud-dialog',
  templateUrl: './input-crud.html',
  styleUrls: ['./input-crud.styl']
})
export class InputCrudDialog implements OnInit {
  private viewContainerRef: ViewContainerRef;
  private component: any;
  public selectedItems: any[] = [];
  public view = null;
  public defaults: any = {};
  public fields: ISmartField[] = [];
  public componentInstance: any;
  public componentRef: ComponentRef<any>;
  public formBus: EventEmitter<IAction> = new EventEmitter<IAction>();
  public canCreate = false;
  public items: any[] = [];
  public filterBy: (item: any, params?: any) => boolean;
  private _selectedItem: any;

  @ViewChild('formComponent') formComponent: SmartFormComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: InputCrudDialogData, private dialogRef: MatDialogRef<InputCrudDialog>, private componentFactory: ComponentFactoryResolver, private snackbar: MatSnackBar) { }

  @ViewChild('customTemplate', { static: false, read: ViewContainerRef })
  set content(content: any) {
    if (content) {
      this.viewContainerRef = content;
      this.initialiseRenderer();
    }
  }

  ngOnInit(): void {
    this.component = this.data.renderer;
    this.defaults = this.data.defaults;
    this.fields = this.data.fields;
    this.items = this.data.items;
    this.filterBy = this.data.field.options.filterBy || ((item: any) => true);
    this.initialiseRenderer();
  }

  initialiseRenderer(): void {
    if (this.component && this.viewContainerRef && !this.componentInstance) {
      this.componentRef = this.viewContainerRef.createComponent(
        this.componentFactory.resolveComponentFactory(this.component)
      );

      this.componentInstance = (this.componentRef.instance as any);
      this.componentInstance.init({
        syncItems: this.items,
        items: [...this.items.filter(o => {
          if (this.selectedItem) {
            return o.parent === this.selectedItem.code;
          }

          return !o.parent;
        })],
        onSelfDestroy: this.onComponentDestroy.bind(this),
        onSelectedItemsChange: this.onComponentSelectedItemsChange.bind(this),
        onItemDelete: this.delete.bind(this),
        onItemEdit: this.edit.bind(this),
        onItemView: this.onItemView.bind(this),
        field: this.data.field
      });
    }
  }

  showCreate(): void {
    this.defaults = {};
    this.view = 'create';
  }

  async save(): Promise<any> {
    if (!this.formComponent) {
      console.error($localize`Form Component not initialised`);
      return;
    }

    if (this.formComponent.form.invalid) {
      this.snackbar.open($localize`Filling data is not valid to proceed with the saving`, $localize`Close`);
      return;
    }

    const index = this.items.indexOf(this.defaults);
    let item: any;

    if (index > -1) {
      item = this.items[index];
    }

    let raw = this.formComponent.getRawValue();

    if (item) {
      raw = {
        ...item,
        ...raw
      };
    }

    raw = await this.data.process(raw);

    if (item) {
      this.data.onItemUpdate(item, raw).then(items => {
        this.items = items;
        this.view = null;
      }).catch();
    } else {
      this.data.onItemAdd(raw).then(items => {
        this.items = items;
        this.view = null;
      }).catch();
    }
  }

  delete(item: any = null): void {
    if (item) {
      this.data.onItemRemove(item).then(items => {
        this.items = items;

        if (this.componentInstance) {
          this.componentInstance.items = this.items;
        }
      }).catch();
    } else {
      const promises = [];

      this.selectedItems.forEach(selectedItem => {
        promises.push(this.data.onItemRemove(selectedItem));
      });

      if (promises.length > 0) {
        Promise.all(promises).then(results => {
          this.items = results[results.length - 1];
        }).catch();

        this.componentInstance.items = this.items;
      }
    }
  }

  back(): void {
    if (this.view !== null) {
      this.view = null;
    } else if (this.selectedItem) {
      this.selectedItem = null;
    }
  }

  onFormValueChange(event: any): void {
    this.canCreate = event.form.valid;
  }

  onComponentDestroy(): void {
    this.componentInstance = null;
    this.selectedItems = [];
  }

  onComponentSelectedItemsChange(selectedItems: any): void {
    this.selectedItems = selectedItems;
  }

  edit(item: any): void {
    this.defaults = item;
    this.view = 'edit';
  }

  onItemView(item: any): void {
    this.selectedItem = item;
  }


  get selectedItem(): any {
    return this._selectedItem;
  }

  set selectedItem(value: any) {
    this._selectedItem = value;

    if (value && this.componentInstance) {
      this.componentInstance.items = this.items.filter(o => o.parent === value.code);
    } else if (this.componentInstance) {
      this.componentInstance.items = this.items.filter(o => !o.parent);
    }
  }
}
