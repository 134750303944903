import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams, RowNode} from 'ag-grid-community';

@Component({
  selector: 'app-group-cell-renderer',
  templateUrl: './group-cell-renderer.component.html',
  styleUrls: ['./group-cell-renderer.component.styl']
})
export class GroupCellRendererComponent implements ICellRendererAngularComp {

  node: RowNode;
  params: any;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.node = params.node;
  }

  refresh(params: any): boolean {
    return false;
  }

  getLabel(): string {
    if (typeof this.params.getLabel === 'function') {
      return this.params.getLabel(this.node.key);
    }

    return this.node.key;
  }

}
