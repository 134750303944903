import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ContentType} from '../../definitions/ContentType';
import {ApiService} from '../../services/api.service';
import IPair from '../../interfaces/IPair';
import IItem from '../../interfaces/IItem';
import IClient from '../../interfaces/IClient';
import IWorkSector from '../../interfaces/IWorkSector';
import IWorkPosition from '../../interfaces/IWorkPosition';
import IJob from '../../interfaces/IJob';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import ISubject from '../../interfaces/ISubject';
import IResearch from '../../interfaces/IResearch';
import {SmartFormComponent} from '../smart-form/smart-form.component';
import {SmartTableComponent} from '../smart-table/smart-table.component';
import {allCandidateFieldsList, dataTypesList} from '../../definitions/constants';
import {Content} from '@angular/compiler/src/render3/r3_ast';
import {GenericCrudListComponent} from '../generic-crud-list/generic-crud-list.component';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-run-survey-dialog',
  templateUrl: './run-survey-dialog.component.html',
  styleUrls: ['./run-survey-dialog.component.styl']
})
export class RunSurveyDialogComponent implements OnInit {

  fields: any = [
    { key: 'title', label: $localize`Title`, required: true, type: ContentType.SHORT_TEXT },
    { key: 'projectIds', label: $localize`Projects`, required: false, type: ContentType.LIST, data: [], fetchData: () => this.fetchProjectsAsItems(), defaultValue: [], multiple: true },
    { key: 'groupIds', label: $localize`Groups`, required: false, type: ContentType.LIST, data: [], fetchData: () => this.fetchGroupsAsItems(), defaultValue: [], multiple: true },
    {
      key: 'baseFields',
      label: $localize`Base Fields`,
      required: true,
      type: ContentType.LIST,
      multiple: true,
      defaultValue: [...allCandidateFieldsList.filter(item => ['information', 'privacyAgreements'].indexOf(item.value) === -1).map(item => item.value)],
      data: [...allCandidateFieldsList.filter(item => ['information', 'privacyAgreements'].indexOf(item.value) === -1)]
    },
    {
      key: 'requiredFields',
      label: $localize`Required Fields`,
      required: true,
      type: ContentType.LIST,
      multiple: true,
      defaultValue: [...allCandidateFieldsList.filter(item => ['information', 'privacyAgreements'].indexOf(item.value) === -1).map(item => item.value)],
      data: [...allCandidateFieldsList.filter(item => ['information', 'privacyAgreements'].indexOf(item.value) === -1)]
    },
    { key: 'emailInvitationSubject', label: $localize`Invitation Subject`, required: false, type: ContentType.SHORT_TEXT, defaultValue: $localize`(inserire qui l'oggetto della mail che arriverà ai soggetti)` },
    { key: 'emailReminderSubject', label: $localize`Reminder Subject`, required: false, type: ContentType.SHORT_TEXT, defaultValue: $localize`(inserire qui l'oggetto della mail di remind che arriverà ai soggetti)` },
    { key: 'emailInvitation', label: $localize`Invitation Email`, required: false, type: ContentType.LONG_TEXT, defaultValue: 'Buongiorno,<br /><br />la invitiamo a completare il seguente questionario/test/survey.<br /><br /> E’ importante, per non invalidare la prova, che il test/questionario/survey venga completato senza interruzioni temporali. Si prenda un tempo sufficiente, che sarà indicativamente dai 15 ai 30 minuti. <br /><br />Il link non è compatibile con Internet Explorer, pertanto le suggeriamo di utilizzare Chrome, Firefox o Safari.<br />Le suggeriamo di accedere al link che trova di seguito da pc e non da cellulare.<br /><br /><a href="{link}">Cliccare qui per accedere al questionario.</a><br />Grazie per la collaborazione.' },
    { key: 'emailReminder', label: $localize`Reminder Email`, required: false, type: ContentType.LONG_TEXT, defaultValue: `Buongiorno,<br /><br />se non l'avesse già fatto, la invitiamo a completare il seguente questionario/test/indagine.<br /><br /> E’ importante, per non invalidare la prova, che il test/questionario/survey venga completato senza interruzioni temporali. Si prenda un tempo sufficiente, che sarà indicativamente dai 15 ai 30 minuti. <br /><br />Il link non è compatibile con Internet Explorer, pertanto le suggeriamo di utilizzare Chrome, Firefox o Safari.<br />Le suggeriamo di accedere al link che trova di seguito da pc e non da cellulare.<br /><br />
<br /><br /><a href="{link}">Cliccare qui per accedere al questionario.</a><br /><br />La ringraziamo,<br />cordialmente.`},
    { key: 'startDate', label: $localize`Start Date`, type: ContentType.DATE },
    { key: 'endDate', label: $localize`End Date`, type: ContentType.DATE },
    { ...this.authService.getClientField(), defaultValue: this.authService.getClientField().defaultValue ? this.authService.getClientField().defaultValue : this.data && this.data.survey && Array.isArray(this.data.survey.clients) && this.data.survey.clients.length === 1 ? this.data.survey.clients[0] : null },
    {
      key: 'extraInformation',
      label: $localize`Extra Candidate Information`,
      required: false,
      type: ContentType.CRUD,
      defaultValue: [],
      options: {
        renderer: GenericCrudListComponent,
        filterBy: (item: any, params: any) => {
          if (params.selectedItem) {
            return params.selectedItem.code === item.parent.code;
          }

          return item.parent === null;
        },
        fields: [
          { key: 'code', label: $localize`Code`, required: true, type: ContentType.SHORT_TEXT },
          { key: 'name', label: $localize`Name`, required: true, type: ContentType.SHORT_TEXT },
          {
            key: 'type',
            label: $localize`Type`,
            required: true,
            type: ContentType.LIST,
            data: dataTypesList
          },
          { key: 'values', label: $localize`Items (separated by comma)`, type: ContentType.SHORT_TEXT, required: false },
          { key: 'required', label: $localize`Required`, type: ContentType.BOOLEAN, required: true, defaultValue: true, displayAs: 'toggle' }
        ]
      }
    },
    { key: 'anonymous', label: $localize`Anonymous`, required: false, type: ContentType.BOOLEAN, displayAs: 'toggle', defaultValue: false },
    { key: 'showStepNavigation', label: $localize`Show Step Navigation`, required: false, type: ContentType.BOOLEAN, displayAs: 'toggle', defaultValue: false },
  ];

  jobColumnDefs = [
    { headerName: $localize`Title`, field: 'title' },
    { headerName: $localize`Reference`, field: 'reference' },
    { headerName: $localize`Client`, field: 'client.name' },
    { headerName: $localize`Client Note`, field: 'clientNote' },
    { headerName: $localize`Client Description`, field: 'clientDescription' },
    { headerName: $localize`Workplace`, field: 'workplace' },
    { headerName: $localize`Work Sector`, field: 'workSector.name' },
    { headerName: $localize`Work Position`, field: 'workPosition.name' },
    { headerName: $localize`Start Date`, field: 'startDate', cellRenderer: 'dateRenderer' },
    { headerName: $localize`End Date`, field: 'endDate', cellRenderer: 'dateRenderer' },
    { headerName: $localize`Active`, field: 'active', cellRenderer: 'checkboxRenderer' },
    { headerName: $localize`Hidden`, field: 'hidden', cellRenderer: 'checkboxRenderer' },
  ];

  candidateColumnDefs = [
    { headerName: $localize`Email`, field: 'email' },
    { headerName: $localize`First Name`, field: 'firstName' },
    { headerName: $localize`Last Name`, field: 'lastName' },
    { headerName: $localize`Phone`, field: 'phone' },
    { headerName: $localize`Fiscal Code`, field: 'fiscalCode' },
    { headerName: $localize`Profession`, field: 'profession' },
    { headerName: $localize`Birth Date`, field: 'birthDate', cellRenderer: 'dateRenderer' },
    { headerName: $localize`Birth Place`, field: 'phone' },
    { headerName: $localize`Gender`, field: 'gender' },
    { headerName: $localize`Client`, field: 'clients' },
    { headerName: $localize`Groups`, field: 'groups', cellRenderer: 'longListRenderer', cellRendererParams: { displayField: item => item.name }, filter: 'objectListFilter', filterParams: { fetchData: () => this.apiService.getGroups(), getItemLabel: item => item.name, filterItems: (item, value) => item.name.toLowerCase().startsWith(value.toLowerCase()) } },
    { headerName: $localize`Projects`, field: 'projects', cellRenderer: 'longListRenderer', cellRendererParams: { displayField: item => item.name }, filter: 'objectListFilter', filterParams: { fetchData: () => this.apiService.getProjects(), getItemLabel: item => item.name, filterItems: (item, value) => item.name.toLowerCase().startsWith(value.toLowerCase()) } }
  ];

  jobs: IJob[] = [];
  candidates: ISubject[] = [];

  @ViewChild('smartForm') smartForm: SmartFormComponent;
  @ViewChild('jobsTable') jobsTable: SmartTableComponent;
  @ViewChild('candidatesTable') candidatesTable: SmartTableComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private apiService: ApiService, private dialogRef: MatDialogRef<RunSurveyDialogComponent>, public dialog: MatDialog, private authService: AuthService) {
    this.fetchGroupsAsItems = this.fetchGroupsAsItems.bind(this);
    this.fetchProjectsAsItems = this.fetchProjectsAsItems.bind(this);

    this.apiService.getJobs().then(jobs => this.jobs = jobs);
    this.apiService.getSubjects().then(subjects => this.candidates = subjects);
  }

  ngOnInit(): void {
    if (this.authService.getUser().client !== null) {
      this.fields = [
        ...this.fields.filter(o => o.key !== 'client'),
        { key: 'client', type: ContentType.HIDDEN, defaultValue: this.authService.getUser().client },
      ];
    }
  }

  fetchProjectsAsItems(): Promise<IItem[]> {
    return new Promise<IItem[]>(resolve => {
      this.apiService.getProjects().then(projects => resolve(projects.map(project => ({
        value: project.id,
        label: project.name
      }))));
    });
  }

  fetchGroupsAsItems(): Promise<IItem[]> {
    return new Promise<IItem[]>(resolve => {
      this.apiService.getGroups().then(groups => resolve(groups.map(group => ({
        value: group.id,
        label: group.name
      }))));
    });
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  confirm(): void {
    const research: any = {
      anonymous: false,
      candidateIds: [],
      groupIds: [],
      clientId: null,
      jobId: null,
      projectIds: [],
      surveyId: this.data.survey.id,
      title: null,
      extraInformation: [],
      baseFields: [],
      requiredFields: [],
      ...this.smartForm.form.getRawValue()
    };

    research.extraInformation = research.extraInformation.map(o => {
      const newO = {...o};
      newO.code = newO.code.toLowerCase().split(' ').map((word, index) => {
        if (index === 0) {
          return word;
        }

        const chars = word.split('');
        chars[0] = chars[0].toUpperCase();

        return chars.join('');
      }).join('');

      return newO;
    });

    const selectedJobs: IJob[] = this.jobsTable.gridApi.getSelectedRows();

    if (selectedJobs.length > 0) {
      research.jobId = selectedJobs[0].id;
    }

    research.candidateIds = this.candidatesTable.gridApi.getSelectedRows().map(o => o.id);

    this.apiService.startResearch(research).then(data => this.dialogRef.close(data)).catch(console.error);
  }

  askForConfirmation(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: {
        title: $localize`Are you sure you want to start a research?`,
        message: $localize`Once you click on the confirmation button, the research will start automatically and all the processes will run accordingly`,
        cancelText: $localize`No`,
        okText: $localize`Yes`
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirm();
      }
    });
  }

  private fetchClientsAsItems(): Promise<IItem[]> {
    return new Promise<IItem[]>(resolve => {
      this.apiService.getClients().then(clients => resolve(clients.map(client => ({
        value: client.code,
        label: client.name
      }))));
    });
  }

}
