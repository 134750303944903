import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import IAuthResponse from '../../interfaces/IAuthResponse';

@Component({
  selector: 'app-operation-screen',
  templateUrl: './operation-screen.component.html',
  styleUrls: ['./operation-screen.component.styl']
})
export class OperationScreenComponent implements OnInit {

  isValid = false;
  show: string = null;

  recoveryForm = new FormGroup({
    password: new FormControl('', [Validators.required]),
    confirmPassword: new FormControl('', [Validators.required])
  });

  constructor(private activatedRoute: ActivatedRoute, private apiService: ApiService, private router: Router) {
    this.show = this.activatedRoute.snapshot.routeConfig.path;

    let purpose;

    if (this.show === 'account-recover') {
      purpose = 'PASSWORD_RECOVERY';
    } else if (this.show === 'candidate-account-recover') {
      purpose = 'CANDIDATE_PASSWORD_RECOVERY';
    }

    if (purpose) {
      this.apiService.validateOperationCode(this.activatedRoute.snapshot.queryParams.traceid, purpose).then((response: any) => {
        if (!response.data) {
          this.router.navigateByUrl('/login');
        } else {
          this.isValid = true;
        }
      }).catch(error => console.error(error));
    }
  }

  ngOnInit(): void {
  }

  getErrorMessage(field: AbstractControl): string {
    if (field.errors.required) {
      return $localize`The field is required`;
    }

    if (field.errors.email) {
      return $localize`The field is supposed to be a valid email address`;
    }

    return '';
  }

  submitRecoveryForm(): void {
    if (this.recoveryForm.invalid) {
      return;
    }

    const value = this.recoveryForm.getRawValue();

    if (this.show === 'account-recover') {
      this.apiService.recoverAccountPassword(this.activatedRoute.snapshot.queryParams.traceid, value.password).then((response: any) => {
        this.router.navigateByUrl('/login');
      });
    } else if (this.show === 'candidate-account-recover') {
      this.apiService.recoverCandidateAccountPassword(this.activatedRoute.snapshot.queryParams.traceid, value.password).then((response: any) => {
        this.show = 'candidate-account-recover-success';
      });
    }
  }

}
