import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContentType} from '../../definitions/ContentType';
import ISmartField from '../../interfaces/ISmartField';
import * as languages from '../../../assets/data/languages.json';
import * as questionTypes from '../../../assets/data/question-types.json';
import {SmartFormComponent} from '../smart-form/smart-form.component';
import IItem from '../../interfaces/IItem';
import ISurveyGroup from '../../interfaces/ISurveyGroup';
import {ApiService} from '../../services/api.service';
import ISurvey from '../../interfaces/ISurvey';
import {AuthService} from "../../services/auth.service";
import {ElementType} from '../../definitions/ElementType';

@Component({
  selector: 'app-survey-dialog',
  templateUrl: './survey-dialog.component.html',
  styleUrls: ['./survey-dialog.component.styl']
})
export class SurveyDialogComponent implements OnInit {

  title: string;
  fields: ISmartField[] = [
    { key: 'type', label: $localize`Type`, required: true, type: ContentType.LIST, data: [ { value: 'STANDARD', label: $localize`Standard` }, { value: 'TEST', label: $localize`Test` }], tab: 'general' },
    { key: 'title', label: $localize`Title`, required: true, type: ContentType.SHORT_TEXT, tab: 'general' },
    { key: 'language', label: $localize`Language`, required: true, type: ContentType.LIST, data: (languages as any).default.map(language => ({ value: language.shortCode, label: language.name })), tab: 'general' },
    { key: 'group', label: $localize`Group`, required: false, type: ContentType.LIST, fetchData: () => this.fetchGroupsAsItem(), compareWith: (o1: any, o2: any) => o1.id === o2.id, tab: 'general' },
    { key: 'startMessage', label: $localize`Start Message`, required: false, type: ContentType.LONG_TEXT, tab: 'general' },
    { key: 'endMessage', label: $localize`End Message`, required: false, type: ContentType.LONG_TEXT, tab: 'general' },
    { key: 'description', label: $localize`Description`, required: false, type: ContentType.LONG_TEXT, tab: 'general', colSize: '12' },
    { ...this.authService.getClientField({ multiple: true }), tab: 'general' },
    { key: 'anonymous', label: $localize`Anonymous`, required: false, type: ContentType.BOOLEAN, tab: 'general', displayAs: 'toggle' },
    { key: 'displayMode', label: $localize`Display Mode`, required: true, type: ContentType.LIST, data: (questionTypes as any).default, tab: 'presentation' },
    { key: 'timeLimit', label: $localize`Time Limit (minutes)`, required: false, type: ContentType.INTEGER, tab: 'presentation' },
    { key: 'timeLimitStartPoint', label: $localize`Time Limit Start Point`, required: false, type: ContentType.LIST, tab: 'presentation', data: [], fetchData: () => this.fetchQuestionsForList() },
    { key: 'answerTimeLimit', label: $localize`Answer Time Limit (minutes)`, required: false, type: ContentType.INTEGER, tab: 'presentation' },
    { type: ContentType.BLANK, tab: 'presentation' },
    { key: 'showProgress', label: $localize`Show Progress`, required: false, type: ContentType.BOOLEAN, tab: 'presentation', displayAs: 'toggle' },
    { key: 'allowAnswerChange', label: $localize`Allow Answer Change`, required: false, type: ContentType.BOOLEAN, tab: 'presentation', displayAs: 'toggle' },
    { key: 'nextStepConfirmation', label: $localize`Next Step Confirmation`, required: false, type: ContentType.BOOLEAN, tab: 'presentation', displayAs: 'toggle' },
  ];
  createTabs = [
    { key: 'general', name: $localize`GENERAL` },
    { key: 'presentation', name: $localize`PRESENTATION` },
    { key: 'notifications', name: $localize`NOTIFICATIONS` }
  ];

  @ViewChild('formcomp') formcomp: SmartFormComponent;

  survey: ISurvey = null;

  constructor(public dialogRef: MatDialogRef<SurveyDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private apiService: ApiService, private authService: AuthService) {
    this.title = data.title;
    this.survey = data.survey;
  }

  ngOnInit(): void {
  }

  fetchQuestionsForList(): Promise<IItem[]> {
    return new Promise<IItem[]>((resolve: any) => {
      const survey: ISurvey = this.survey;

      if (!survey) {
        resolve([]);
      }

      resolve(survey.elements.filter(element => element.type === ElementType.QUESTION).map(element => ({
        value: element.code,
        label: `${element.code} - ${element.child.question}`
      })));
    });
  }

  fetchGroupsAsItem(): Promise<IItem[]> {
    return new Promise<IItem[]>((resolve: any, reject: any) => {
      this.apiService.getSurveyGroups().then((groups: ISurveyGroup[]) => {
        resolve(groups.map((group: ISurveyGroup) => ({
          value: group,
          label: group.name
        })));
      });
    });
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onConfirm(): void {
    if (this.formcomp.form.invalid) {
      this.formcomp.form.markAllAsTouched();
      return;
    }

    if (this.survey !== null) {
      const updatedSurvey = {
        ...this.survey,
        ...this.formcomp.form.getRawValue()
      };

      this.apiService.updateSurvey(this.survey.id, updatedSurvey).then((survey: ISurvey) => {
        this.dialogRef.close(survey);
      });
    } else {
      this.apiService.createSurvey(this.formcomp.form.getRawValue()).then((survey: ISurvey) => {
        this.dialogRef.close(survey);
      });
    }
  }

  private fetchClientsAsItems(): Promise<IItem[]> {
    return new Promise<IItem[]>(resolve => {
      this.apiService.getClients().then(clients => resolve(clients.map(client => ({
        value: client,
        label: client.name
      }))));
    });
  }

}
