import { Component, OnInit } from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'app-object-cell-renderer',
  templateUrl: './object-cell-renderer.component.html',
  styleUrls: ['./object-cell-renderer.component.styl']
})
export class ObjectCellRendererComponent {

  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

}
