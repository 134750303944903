import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'randomizeArray'
})
export class RandomizeArrayPipe implements PipeTransform {

  transform(value: any[], randomize: boolean): any[] {
    if (randomize) {
      return _.shuffle(value);
    }

    return value;
  }

}
