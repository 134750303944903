import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../services/api.service';
import IFile from '../../interfaces/IFile';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {noop} from 'rxjs';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.styl'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {

  @Input() model: any;
  @Input() multiple?: boolean;
  @Input() inline?: boolean;
  @Input() autoUpload = true;
  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
  onChangeCallback: (value: any) => void = noop;
  onTouchedCallback: () => void = noop;
  isDisabled = false;

  @Input() label: string;
  uploading = false;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    if (!this.label) {
      if (this.multiple === true) {
        this.label = $localize`Choose files to upload`;
      } else {
        this.label = $localize`Choose file to upload`;
      }
    }
  }

  onChange(ev: any): any {
    this.uploading = true;

    if (this.multiple) {
      if (this.autoUpload) {
        let uploadedFiles = 0;

        for (const fileToUpload of ev.target.files) {
          this.api.uploadFile(fileToUpload).then(uploadedFile => {
            if (Array.isArray(this.model)) {
              this.model.push(uploadedFile);
              uploadedFiles++;

              if (uploadedFiles >= ev.target.files) {
                this.modelChange.emit(this.model);
              }
            }
          }).catch(() => {
            uploadedFiles++;
            if (uploadedFiles >= ev.target.files) {
              this.modelChange.emit(this.model);
            }
          });
        }
      } else {
        this.model = ev.target.files;
        this.modelChange.emit(this.model);
        this.uploading = false;
      }
    } else {
      if (this.autoUpload) {
        this.api.uploadFile(ev.target.files[0]).then(uploadedFile => {
          this.model = uploadedFile;
          this.modelChange.emit(this.model);
          this.onChangeCallback(this.model);
          this.uploading = false;
        }).catch();
      } else {
        this.model = ev.target.files[0];
        this.modelChange.emit(this.model);
        this.onChangeCallback(this.model);
        this.uploading = false;
      }
    }
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  removeItem(item: IFile): void {
    if (Array.isArray(this.model)) {
      this.model = this.model.filter(o => o.id !== item.id);
    } else {
      this.model = null;
    }

    this.modelChange.emit(this.model);
    this.onChangeCallback(this.model);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (this.model !== obj) {
      this.model = obj;
      this.onChangeCallback(this.model);
    }
  }

}
