import {Component, OnInit, ViewChild} from '@angular/core';
import IProject from '../../interfaces/IProject';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RouteAction} from '../../definitions/RouteAction';
import {ContentType} from '../../definitions/ContentType';
import IItem from '../../interfaces/IItem';
import IRole from '../../interfaces/IRole';
import {SmartFormComponent} from '../../components/smart-form/smart-form.component';
import ISmartField from '../../interfaces/ISmartField';
import IDetailField from '../../interfaces/IDetailField';
import ISubject from '../../interfaces/ISubject';
import IUser from '../../interfaces/IUser';
import IGroup from '../../interfaces/IGroup';
import {MatDialog} from '@angular/material/dialog';
import {SurveyDialogComponent} from '../../components/survey-dialog/survey-dialog.component';
import {InputDialogComponent} from '../../components/input-dialog/input-dialog.component';
import IResearch from '../../interfaces/IResearch';
import IClient from '../../interfaces/IClient';
import {SmartTableComponent} from '../../components/smart-table/smart-table.component';
import {AuthService} from '../../services/auth.service';
import {update} from "lodash";

@Component({
  selector: 'app-project-screen',
  templateUrl: './projects-screen.component.html',
  styleUrls: ['./projects-screen.component.styl']
})
export class ProjectsScreenComponent implements OnInit {

  @ViewChild('addForm') addForm: SmartFormComponent;
  @ViewChild('editForm') editForm: SmartFormComponent;
  @ViewChild('candidatesTable') candidatesTable: SmartTableComponent;
  @ViewChild('usersTable') usersTable: SmartTableComponent;
  @ViewChild('groupsTable') groupsTable: SmartTableComponent;

  action: RouteAction = RouteAction.LOADING;
  routeAction = RouteAction;
  projects: IProject[] = [];
  projectsTableColumnDefs = [
    { headerName: $localize`Code`, field: 'code', editable: false, flex: 1 },
    { headerName: $localize`Name`, field: 'name', editable: false, flex: 1 },
  ];
  projectFormFieldsCreate = [
    { key: 'code', label: $localize`Code`, required: true, type: ContentType.SHORT_TEXT },
    { key: 'name', label: $localize`Name`, required: true, type: ContentType.SHORT_TEXT },
    this.authService.getClientField(),
    { key: 'candidates', type: ContentType.HIDDEN, defaultValue: [] },
    { key: 'users', type: ContentType.HIDDEN, defaultValue: [] },
    { key: 'groups', type: ContentType.HIDDEN, defaultValue: [] },
  ];
  projectFormFieldsEdit = [...this.projectFormFieldsCreate.filter(field => !['candidates', 'users', 'groups'].includes(field.key))];
  projectDetailFields: IDetailField[] = this.projectFormFieldsCreate.filter(field => !['candidates', 'users', 'groups'].includes(field.key)).map(field => ({
    key: field.key,
    label: field.label,
    type: field.type
  }));
  selectedProjectRows = 0;
  private _projectEntity: IProject = null;
  subjectsData: ISubject[] = [];
  usersData: IUser[] = [];
  groupsData: IGroup[] = [];
  researches: IResearch[] = [];
  connectedSubjects: ISubject[] = [];
  connectedGroups: IGroup[] = [];
  researchColumnDefs = [
    { headerName: $localize`Title`, field: 'title' },
    { headerName: $localize`Anonymous`, field: 'anonymous', cellRenderer: 'checkboxRenderer' },
    { headerName: $localize`Job`, field: 'job.title' },
    { headerName: $localize`Groups`, field: 'groups', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 },
    { headerName: $localize`Projects`, field: 'projects', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 },
    { headerName: $localize`Candidates`, field: 'candidates', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 },
    { headerName: $localize`Anonymous Candidates`, field: 'anonymousCandidates', valueGetter: (params) => (Array.isArray(params.data[params.colDef.field])  && params.data[params.colDef.field].length) || 0 }
  ];
  candidatesColumnDefs: any[] = [
    { headerName: $localize`Email`, field: 'email', flex: 1 },
    { headerName: $localize`First Name`, field: 'firstName', flex: 1 },
    { headerName: $localize`Last Name`, field: 'lastName', flex: 1 },
    { headerName: $localize`Phone`, field: 'phone' },
    { headerName: $localize`Fiscal Code`, field: 'fiscalCode' }
  ];
  usersColumnDefs: any[] = [
    { headerName: $localize`Email`, field: 'email', flex: 1 },
    { headerName: $localize`First Name`, field: 'firstName', flex: 1 },
    { headerName: $localize`Last Name`, field: 'lastName', flex: 1 },
    { headerName: $localize`Phone`, field: 'phone' },
  ];
  groupsColumnDefs: any[] = [
    { headerName: $localize`Code`, field: 'code', editable: false, flex: 1 },
    { headerName: $localize`Name`, field: 'name', editable: false, flex: 1 }
  ];

  constructor(private apiService: ApiService, private activatedRoute: ActivatedRoute, private router: Router, private dialog: MatDialog, public authService: AuthService) {
    this.onProjectDelete = this.onProjectDelete.bind(this);
    this.onProjectEdit = this.onProjectEdit.bind(this);
    this.onProjectView = this.onProjectView.bind(this);
  }

  ngOnInit(): void {
    this.loadScreen().then(() => {}).catch(console.error);
  }

  async loadScreen(): Promise<any> {
    this.subjectsData = await this.apiService.getSubjects();
    this.groupsData = await this.apiService.getGroups();

    if (this.activatedRoute.firstChild === null) {
      this.action = RouteAction.LISTING;
      this.fetchProjects();
    } else {
      const snapshot = this.activatedRoute.firstChild.snapshot;

      if (snapshot.routeConfig.path.startsWith('add')) {
        this.goToAdd();
      } else if (snapshot.routeConfig.path.startsWith('view')) {
        this.goToView();
      } else if (snapshot.routeConfig.path.startsWith('edit')) {
        this.goToEdit();
      }
    }
  }

  fetchProjects(): void {
    this.apiService.getProjects().then((projects: IProject[]) => {
      this.projects = projects.filter(project => this.authService.hasBoundingPermission('project', 'view', project));
    });
  }

  save(): void {
    if (this.action === RouteAction.ADD) {
      if (this.addForm.form.invalid) {
        this.addForm.form.markAllAsTouched();
        return;
      }

      const project: IProject = this.addForm.form.getRawValue();

      this.apiService.createProject(project).then(() => {
        this.goToListing();
      });
    } else if (this.action === RouteAction.EDIT) {
      if (this.editForm.form.invalid) {
        this.editForm.form.markAllAsTouched();
        return;
      }

      const data: IProject = {
        ...this.projectEntity,
        ...this.editForm.form.getRawValue()
      };

      this.apiService.updateProject(data.id, data).then((project: IProject) => {
        this.projectEntity = project;
        this.editForm.defaults = project;
        this.editForm.setValue({
          ...project,
          password: null
        });
      });
    }
  }

  reset(): void {
    if (this.action === RouteAction.EDIT) {
      this.editForm.reset();
    } else if (this.action === RouteAction.ADD) {
      this.addForm.reset();
    }
  }

  goToListing(): void {
    this.fetchProjects();
    this.router.navigateByUrl('/projects');
    this.action = RouteAction.LISTING;
  }

  goToAdd(): void {
    this.router.navigateByUrl('/projects/add');
    this.action = RouteAction.ADD;
  }

  goToEdit(data: IProject = null): void {
    if (data === null) {
      this.apiService.getProject(this.activatedRoute.firstChild.snapshot.params.id).then((project: IProject) => {
        this.projectEntity = project;
        this.action = RouteAction.EDIT;
      });
    } else {
      this.projectEntity = data;
      this.router.navigateByUrl('/projects/edit/' + data.id);
      this.action = RouteAction.EDIT;
    }
  }

  goToView(data: IProject = null): void {
    if (data === null) {
      this.apiService.getProject(this.activatedRoute.firstChild.snapshot.params.id).then((project: IProject) => {
        this.projectEntity = project;
        this.fetchResearches();
        this.action = RouteAction.VIEW;
      });
    } else {
      this.projectEntity = data;
      this.fetchResearches();
      this.router.navigateByUrl('/projects/view/' + data.id);
      this.action = RouteAction.VIEW;
    }
  }

  goBack(): void {
    switch (this.action) {
      case RouteAction.ADD:
      case RouteAction.EDIT:
      case RouteAction.VIEW:
        this.goToListing();
        break;

      default:
    }
  }

  onProjectDelete(ev: any): void {
    this.apiService.deleteProject(ev.data.id).then(() => {
      this.fetchProjects();
    });
  }

  onProjectEdit(ev: any): void {
    this.goToEdit(ev.data);
  }

  onProjectView(ev: any): void {
    this.goToView(ev.data);
  }

  connectCandidate(): void {
    const existingSubjects = this.projectEntity.candidates || [];

    const dialogRef = this.dialog.open(InputDialogComponent, {
      data: {
        title: $localize`Select a Candidate`,
        label: null,
        multipleSelection: true,
        items: this.subjectsData.filter(candidate => !existingSubjects.includes(candidate.id)),
        showAsTable: true,
        columnDefs: [
          { headerName: $localize`Email`, field: 'email', editable: false },
          { headerName: $localize`First Name`, field: 'firstName', editable: false },
          { headerName: $localize`Last Name`, field: 'lastName', editable: false },
          { headerName: $localize`Phone`, field: 'phone', editable: false },
          { headerName: $localize`Fiscal Code`, field: 'fiscalCode', editable: false }
        ]
      },
      width: '95vw'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== null && result.length > 0) {
        const candidateIdsToAdd = result.filter(o => !this.projectEntity.candidates.includes(o.id)).map(o => o.id);
        this.apiService.updateProject(this.projectEntity.id, {
          ...this.projectEntity,
          candidates: [
            ...this.projectEntity.candidates,
            ...candidateIdsToAdd
          ]
        }).then(updatedProject => this.projectEntity = updatedProject).catch(console.error);
      }
    });
  }

  connectUser(): void {
    const existingUsers = this.projectEntity.users || [];

    const dialogRef = this.dialog.open(InputDialogComponent, {
      data: {
        title: $localize`Select a User`,
        label: null,
        items: this.usersData.filter(user => !existingUsers.includes(user.id)),
        showAsTable: true,
        columnDefs: [
          { headerName: $localize`Email`, field: 'email', editable: false },
          { headerName: $localize`First Name`, field: 'firstName', editable: false },
          { headerName: $localize`Last Name`, field: 'lastName', editable: false },
          { headerName: $localize`Phone`, field: 'phone', editable: false },
          { headerName: $localize`Active`, field: 'isActive', cellRenderer: 'checkboxRenderer' },
          { headerName: $localize`Role`, field: 'role.name' }
        ]
      },
      width: '95vw'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== null) {
        this.projectEntity.users.push(result);
        this.apiService.updateProject(this.projectEntity.id, this.projectEntity).then(() => {
          this.usersTable.gridApi.applyTransaction({
            add: [result]
          });
        }).catch(console.error);
      }
    });
  }

  connectGroup(): void {
    const existingGroups = this.projectEntity.groups || [];

    const dialogRef = this.dialog.open(InputDialogComponent, {
      data: {
        title: $localize`Select a Group`,
        label: null,
        showSearch: true,
        filter: (item, value) => item.label.toLowerCase().startsWith(value.toLowerCase()),
        items: this.groupsData.filter(group => !existingGroups.includes(group.id)).map(group => ({ value: group, label: `${group.name}` }))
      },
      width: '400px'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== null && !this.projectEntity.groups.includes(result.id)) {
        this.apiService.updateProject(this.projectEntity.id, {
          ...this.projectEntity,
          groups: [
            ...this.projectEntity.groups,
            result.id
          ]
        }).then(updatedProject => this.projectEntity = updatedProject).catch(console.error);
      }
    });
  }

  deleteCandidate(event: any): void {
    this.apiService.updateProject(this.projectEntity.id, {
      ...this.projectEntity,
      candidates: this.projectEntity.candidates.filter(id => id !== event.data.id)
    }).then(updatedProject => {
      this.projectEntity = updatedProject;
    });
  }

  deleteUser(event: any): void {
    this.projectEntity.users.splice(event.node.rowIndex, 1);
    this.apiService.updateProject(this.projectEntity.id, this.projectEntity).then(() => {
      this.usersTable.gridApi.applyTransaction({
        remove: [event.data]
      });
    }).catch(console.error);
  }

  deleteGroup(event: any): void {
    this.apiService.updateProject(this.projectEntity.id, {
      ...this.projectEntity,
      groups: this.projectEntity.groups.filter(id => id !== event.data.id)
    }).then(updatedProject => {
      this.projectEntity = updatedProject;
    });
  }

  onResearchView(ev: any): void {
    this.router.navigateByUrl(`/research/${ev.data.id}/project/${this.projectEntity.id}`, {
      state: {
        from: 'project',
        id: this.projectEntity.id
      }
    });
  }

  get projectEntity(): IProject {
    return this._projectEntity;
  }

  set projectEntity(value: IProject) {

    this._projectEntity = value;

    this.fetchConnectedSubjects();
    this.fetchConnectedGroups();

  }

  private fetchConnectedGroups(): void {
    if (!this.projectEntity) {
      this.connectedGroups = [];
      return;
    }

    this.connectedGroups = this.groupsData.filter(group => this.projectEntity.groups.includes(group.id));
  }

  private fetchConnectedSubjects(): void {
    if (!this.projectEntity) {
      this.connectedSubjects = [];
      return;
    }

    this.connectedSubjects = this.subjectsData.filter(subject => this.projectEntity.candidates.includes(subject.id));
  }

  private fetchResearches(): void {
    this.apiService.getResearchesByProject(this.projectEntity)
      .then(researches => this.researches = researches).catch();
  }

  private fetchClientsAsItems(): Promise<IItem[]> {
    return new Promise<IItem[]>(resolve => {
      this.apiService.getClients().then(clients => resolve(clients.map(client => ({
        value: client,
        label: client.name
      }))));
    });
  }
}


