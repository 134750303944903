<app-standard-layout>
  <div class="spacing-inner-10">
    <mat-card *ngIf="action === routeAction.LISTING">
      <mat-card-header>
        <mat-card-title i18n>Tests and Surveys Listing</mat-card-title>
        <mat-card-subtitle i18n>On current page you are able to view, edit, delete and create tests and surveys further used to either administer them to candidates or track progress</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-5">
          <button mat-stroked-button color="primary" (click)="openSurveyDialog()" *ngIf="authService.hasPermission(['survey_create', 'survey_create_org'])">
            <mat-icon class="spacing-right-5">add</mat-icon>
            <span i18n>ADD NEW TEST OR SURVEY</span>
          </button>
          <app-smart-list *ngIf="authService.hasPermission(['survey_create', 'survey_create_org'])" i18n-buttonTitle buttonTitle="MANAGE GROUPS" buttonIcon="groups" [get]="fetchGroupsAsPair" [save]="saveSurveyGroups" dialogTitle="Manage Groups"></app-smart-list>
        </div>
        <app-smart-table [rowData]="surveys" [columnDefs]="productTableColumnDefs" (rowView)="onRowView($event)" (rowDelete)="onRowDelete($event)" (rowEdit)="onRowEdit($event)" [customFrameworkComponents]="productFrameworkComponents" [customGridContext]="productGridContext" permissionGroup="survey"></app-smart-table>
      </mat-card-content>
    </mat-card>

    <ng-container *ngIf="action === routeAction.VIEW">
      <mat-card class="spacing-bottom-6">
        <mat-card-header>
          <mat-card-title i18n>View Survey</mat-card-title>
          <mat-card-subtitle i18n class="spacing-inner-bottom-0 spacing-bottom-0">Tests and Surveys Listing</mat-card-subtitle>
          <button mat-stroked-button (click)="goBack()" class="spacing-left-auto">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
        </mat-card-header>
      </mat-card>

      <mat-accordion>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title i18n>
              <strong>General Information</strong>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-smart-detail [fields]="fieldsDetail" [source]="selectedSurvey"></app-smart-detail>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="authService.hasBoundingPermission('survey', 'edit', selectedSurvey)">
          <mat-expansion-panel-header>
            <mat-panel-title i18n>
              <strong>Survey Elements</strong>
            </mat-panel-title>
            <mat-panel-description *ngIf="selectedGroupIndex > -1">{{selectedGroup.child.description}}</mat-panel-description>
          </mat-expansion-panel-header>

          <div class="button-bar spacing-bottom-5">
            <button mat-stroked-button color="primary" (click)="openQuestionDialog()">
              <mat-icon class="spacing-right-5">add</mat-icon>
              <span i18n>ADD NEW ELEMENT</span>
            </button>
            <button mat-stroked-button (click)="goBackFromGroup()" class="spacing-left-5" *ngIf="selectedGroup !== null">
              <mat-icon class="spacing-right-5">arrow_back</mat-icon>
              <span i18n>GO BACK</span>
            </button>
          </div>

          <div cdkDropList class="elements-list" (cdkDropListDropped)="dropElement($event)">
            <div class="element-box" *ngFor="let element of elements; let i = index" cdkDrag>
              <span>{{element.code}} - {{element.child.question || element.child.description}}<br /><small i18n>Last update at {{element.updatedAt | asDateTime}}</small></span>
              <button mat-icon-button [matMenuTriggerFor]="menu" class="spacing-left-auto">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item *ngIf="element.type === elementType.GROUP" (click)="openGroup(i)">
                  <mat-icon>visibility</mat-icon>
                  <span i18n>Open</span>
                </button>
                <button mat-menu-item (click)="openQuestionDialog(i)">
                  <mat-icon>edit</mat-icon>
                  <span i18n>Edit</span>
                </button>
                <button mat-menu-item (click)="openAnswersDialog(i)" *ngIf="element.type === elementType.QUESTION && hasAnswers(element.child.type)">
                  <mat-icon>question_answer</mat-icon>
                  <span i18n>Answers</span>
                </button>
                <button mat-menu-item (click)="deleteElement(i)">
                  <mat-icon>delete</mat-icon>
                  <span i18n>Delete</span>
                </button>
                <button mat-menu-item (click)="duplicateElement(i)">
                  <mat-icon>content_copy</mat-icon>
                  <span i18n>Duplicate</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title i18n>
              <strong>Researches</strong>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <app-smart-table #researchesTable [rowData]="researches" [columnDefs]="researchColumnDefs" [hideEdit]="true" (rowView)="onResearchView.bind(this)($event)" (rowDelete)="onResearchDelete.bind(this)($event)" permissionGroup="research"></app-smart-table>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  </div>
</app-standard-layout>
