import { Injectable } from '@angular/core';
import ISurvey from '../interfaces/ISurvey';
import ISubject from '../interfaces/ISubject';
import {ApiService} from './api.service';
import IResearch from '../interfaces/IResearch';
import {Store} from '@ngrx/store';
import IStore from '../interfaces/IStore';
import {setCandidate, setClient, setResearch, setSurvey, setTrace} from '../actions/process.actions';
import IAnonymousCandidate from '../interfaces/IAnonymousCandidate';
import {SessionService} from './session.service';
import IPrivacyAgreements from "../interfaces/IPrivacyAgreements";
import IClient from "../interfaces/IClient";

@Injectable({
  providedIn: 'root'
})
export class ProcessService {

  constructor(private apiService: ApiService, private store: Store<IStore>, private sessionService: SessionService) { }

  createLink(researchId: string, surveyId: string, candidateId: string): Promise<string> {
    const map = {
      researchId,
      surveyId,
      candidateId
    };

    return this.apiService.encryptFromMap(map);
  }

  fillSession(trace: string, research: IResearch, candidate: ISubject|IAnonymousCandidate, survey: ISurvey, client: IClient): void {
    this.store.dispatch(setResearch(research));
    this.store.dispatch(setCandidate(candidate));
    this.store.dispatch(setTrace({ trace }));
    this.store.dispatch(setSurvey(survey));
    if (client) {
      this.store.dispatch(setClient(client));
    }
    this.sessionService.setTrace(trace);
  }

  setPrivacyAgreements(privacyAgreements: IPrivacyAgreements): void {
    this.sessionService.setPrivacyAgreements(privacyAgreements);
  }

  getPrivacyAgreements(): IPrivacyAgreements {
    return this.sessionService.getPrivacyAgreements();
  }

  getTrace(): string {
    return this.sessionService.getTrace();
  }

  setCandidate(candidate: ISubject|IAnonymousCandidate): void {
    this.store.dispatch(setCandidate(candidate));
  }

  getResearchFromTraceId(traceId: string): Promise<{trace: string, candidateId: string, researchId: string, surveyId: string, research: IResearch, survey: ISurvey, candidate: ISubject, anonymousCandidate: IAnonymousCandidate}> {
    return new Promise<{trace: string, candidateId: string, researchId: string, surveyId: string, research: IResearch, survey: ISurvey, candidate: ISubject, anonymousCandidate: IAnonymousCandidate}>(async (resolve) => {
      let returnObj = {
        trace: null,
        candidateId: null,
        researchId: null,
        surveyId: null,
        research: null,
        survey: null,
        candidate: null,
        anonymousCandidate: null
      };

      if (traceId !== null) {
        const response = await this.apiService.deconstructTrace(traceId).catch(() => {});
        returnObj = {
          ...returnObj,
          ...response
        };

        // returnObj.trace = traceId;
        //
        // const map = await this.apiService.decryptToMap(traceId).catch();
        // returnObj.candidateId = map.candidateId;
        // returnObj.researchId = map.researchId;
        // returnObj.surveyId = map.surveyId;
        //
        // if (returnObj.researchId !== null) {
        //   returnObj.research = await this.apiService.getResearch(returnObj.researchId).catch();
        // }
        //
        // if (returnObj.candidateId !== null && returnObj.research !== null) {
        //   returnObj.candidate = await this.apiService.getSubject(returnObj.candidateId).catch();
        // }
      }

      resolve(returnObj);
    });
  }
}
