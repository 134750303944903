<app-standard-layout>
  <div class="spacing-inner-10">

    <ng-container *ngIf="action === routeAction.LOADING">
      <app-spinner></app-spinner>
    </ng-container>

    <mat-card class="spacing-bottom-6" *ngIf="action !== routeAction.LOADING">
      <mat-card-header>
        <mat-card-title>{{research.title}}</mat-card-title>
        <mat-card-subtitle i18n class="spacing-inner-bottom-0 spacing-bottom-0">Viewing research results and details</mat-card-subtitle>

        <button mat-stroked-button (click)="goBack()" class="spacing-left-auto" *ngIf="resourceType && resourceId">
          <mat-icon class="spacing-right-5">arrow_back</mat-icon>
          <span i18n>GO BACK</span>
        </button>

        <button mat-stroked-button (click)="sendEveryoneReminder()" class="spacing-left-5">
          <mat-icon class="spacing-right-5">notifications_active</mat-icon>
          <span i18n>REMIND EVERYONE</span>
        </button>

        <button mat-stroked-button (click)="getShareLink()" class="spacing-left-5">
          <mat-icon class="spacing-right-5">link</mat-icon>
          <span i18n>GET LINK</span>
        </button>

        <button mat-stroked-button (click)="exportHorizontally()" class="spacing-left-5">
          <mat-icon class="spacing-right-5">file_download</mat-icon>
          <span i18n>EXPORT</span>
        </button>
      </mat-card-header>
    </mat-card>

    <!-- LISTING -->
    <ng-container *ngIf="action === routeAction.LISTING">
      <mat-card>
        <mat-card-header>
          <mat-card-title i18n>Candidates</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="button-bar spacing-bottom-5">
            <button mat-stroked-button color="primary" *ngIf="false">
              <mat-icon class="spacing-right-5">add</mat-icon>
              <span i18n>ADD NEW RESULT</span>
            </button>
          </div>
          <app-smart-table [rowData]="candidates" [columnDefs]="resultsColDefs" [hideActions]="true" permissionGroup="candidate"></app-smart-table>
        </mat-card-content>
      </mat-card>
    </ng-container>

    <!-- ADD -->
    <mat-card *ngIf="action === routeAction.ADD">
      <mat-card-header>
        <mat-card-title i18n>Add</mat-card-title>
        <mat-card-subtitle i18n>Results</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
          <button mat-stroked-button class="spacing-left-5">
            <mat-icon class="spacing-right-5">refresh</mat-icon>
            <span i18n>RESET</span>
          </button>
          <button mat-stroked-button color="primary" class="spacing-left-5">
            <mat-icon class="spacing-right-5">save</mat-icon>
            <span i18n>SAVE</span>
          </button>
        </div>
        <app-smart-form #addForm [fields]="[]"></app-smart-form>
      </mat-card-content>
    </mat-card>

    <!-- VIEW -->
    <mat-card *ngIf="action === routeAction.VIEW">
      <mat-card-header>
        <mat-card-title i18n>View</mat-card-title>
        <mat-card-subtitle i18n>Results</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
        </div>
        <app-smart-detail [fields]="[]" [source]="{}"></app-smart-detail>
      </mat-card-content>
    </mat-card>

    <!-- EDIT -->
    <mat-card *ngIf="action === routeAction.EDIT">
      <mat-card-header>
        <mat-card-title i18n>Edit</mat-card-title>
        <mat-card-subtitle i18n>Results</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-bar spacing-bottom-10">
          <button mat-stroked-button (click)="goBack()">
            <mat-icon class="spacing-right-5">arrow_back</mat-icon>
            <span i18n>GO BACK</span>
          </button>
          <button mat-stroked-button class="spacing-left-5">
            <mat-icon class="spacing-right-5">refresh</mat-icon>
            <span i18n>RESET</span>
          </button>
          <button mat-stroked-button color="primary" class="spacing-left-5">
            <mat-icon class="spacing-right-5">save</mat-icon>
            <span i18n>SAVE</span>
          </button>
        </div>
        <app-smart-form #editForm [fields]="[]" [defaults]="{}"></app-smart-form>
      </mat-card-content>
    </mat-card>
  </div>
</app-standard-layout>
