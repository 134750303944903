<form [formGroup]="form">
  <div class="row" *ngIf="useTabs">
    <div class="col">
      <mat-tab-group mat-align-tabs="center" headerPosition="below" animationDuration="0ms">
        <mat-tab *ngFor="let tab of tabs" [label]="tab.name" class="spacing-inner-bottom-10">
          <div *ngTemplateOutlet="templateRef; context: { tab: tab }"></div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <ng-container *ngIf="!useTabs">
    <ng-container *ngTemplateOutlet="templateRef; context: { tab: { key: null } }"></ng-container>
  </ng-container>

  <ng-template #templateRef let-tab='tab'>
    <div class="row">
      <div class="col-{{field.colBreakpoint ? field.colBreakpoint : colBreak}}-{{field.colSize ? field.colSize : colSize}} d-flex flex-column" *ngFor="let field of fields | arrayFilterBy: filterFields: {tab: tab.key}">
        <ng-container [ngSwitch]="field.type">
          <div class="blank-field" *ngSwitchCase="contentType.BLANK"></div>
          <mat-form-field color="primary" appearance="outline" class="full-width" *ngSwitchCase="contentType.DATE">
            <mat-label i18n>{{field.label}}</mat-label>
            <input
              matInput
              i18n-placeholder [placeholder]="field.placeholder"
              [formControlName]="field.key"
              [autocomplete]="field.autocomplete"
              [required]="field.required"
              [readonly]="true"
              [matDatepicker]="datePicker"
            >
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker [touchUi]="true" #datePicker></mat-datepicker>
            <mat-error *ngIf="form.controls[field.key].invalid">{{getErrorMessage(form.controls[field.key])}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full-width" *ngSwitchCase="contentType.LIST">
            <mat-label i18n>{{field.label}}</mat-label>
            <mat-select
              *ngIf="!field.options.groupBy"
              [compareWith]="field.compareWith ? field.compareWith : compareWith"
              i18n-placeholder [placeholder]="field.placeholder"
              [formControlName]="field.key"
              [required]="field.required"
              [multiple]="field.multiple === true"
            >
              <button *ngIf="field.multiple === true" mat-button class="full-width mb-3" (click)="toggleAllSelection(field)">Select/Deselect All</button>
              <mat-option *ngIf="field.multiple !== true" [value]="null" i18n>No selection</mat-option>
              <mat-option *ngFor="let item of field.data" [value]="item.value">
                {{item.label}}
              </mat-option>
            </mat-select>
            <mat-select
              *ngIf="field.options.groupBy"
              [compareWith]="field.compareWith ? field.compareWith : compareWith"
              i18n-placeholder [placeholder]="field.placeholder"
              [formControlName]="field.key"
              [required]="field.required"
              [multiple]="field.multiple === true"
            >
              <mat-optgroup *ngFor="let group of field.data | groupArray: field.options.groupBy | keyvalue" [label]="group.key">
                <mat-option *ngFor="let item of group.value" [value]="item.value">
                  {{item.label}}
                </mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-error *ngIf="form.controls[field.key].invalid">{{getErrorMessage(form.controls[field.key])}}</mat-error>
          </mat-form-field>
          <div class="field-wrapper" *ngSwitchCase="contentType.BOOLEAN">
            <mat-slide-toggle
              *ngIf="field.displayAs === 'toggle'"
              color="primary"
              [formControlName]="field.key"
              [required]="field.required">
              {{field.label}}
            </mat-slide-toggle>
            <mat-checkbox
              *ngIf="field.displayAs === null"
              color="primary"
              [formControlName]="field.key"
              [required]="field.required"
            >{{field.label}}</mat-checkbox>
          </div>
          <mat-form-field appearance="outline" class="full-width" *ngSwitchCase="contentType.LONG_TEXT">
            <mat-label i18n>{{field.label}}</mat-label>
            <textarea
              matInput
              i18n-placeholder [placeholder]="field.placeholder"
              [formControlName]="field.key"
              [required]="field.required"
              [readonly]="field.readonly"
            ></textarea>
            <mat-icon *ngIf="field.icon !== null" matSuffix>{{field.icon}}</mat-icon>
            <mat-error *ngIf="form.controls[field.key].invalid">{{getErrorMessage(form.controls[field.key])}}</mat-error>
          </mat-form-field>
          <div *ngSwitchCase="contentType.RICH_TEXT">
            <app-rich-input [formControlName]="field.key" [placeholder]="field.label"></app-rich-input>
            <mat-error *ngIf="form.controls[field.key].invalid">{{getErrorMessage(form.controls[field.key])}}</mat-error>
          </div>
          <app-form-field-wrap class="full-width" *ngSwitchCase="contentType.FILE">
            <app-file-upload [label]="field.label" [inline]="true" [formControlName]="field.key" style="min-height: 55.25px"></app-file-upload>
          </app-form-field-wrap>
          <app-form-field-wrap class="full-width" *ngSwitchCase="contentType.CRUD">
            <app-input-crud [field]="field" [formControlName]="field.key"></app-input-crud>
          </app-form-field-wrap>
          <app-form-field-wrap class="full-width" *ngSwitchCase="contentType.OBJECT">
            <app-input-object [field]="field" [formControlName]="field.key"></app-input-object>
          </app-form-field-wrap>
          <mat-form-field appearance="outline" class="full-width" *ngSwitchCase="contentType.ADDRESS">
            <mat-label i18n>{{field.label}}</mat-label>
            <input
              matInput
              i18n-placeholder [placeholder]="field.placeholder"
              [formControlName]="field.key"
              type="text"
              [required]="field.required"
              [readonly]="field.readonly"
              [matAutocomplete]="auto"
            >
            <mat-icon *ngIf="field.icon !== null" matSuffix>{{field.icon}}</mat-icon>
            <mat-error *ngIf="form.controls[field.key].invalid">{{getErrorMessage(form.controls[field.key])}}</mat-error>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let item of field.filteredData | async" [value]="item.value">
                {{item.label}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full-width" *ngSwitchDefault>
            <mat-label i18n>{{field.label}}</mat-label>
            <input
              *ngIf="[contentType.INTEGER, contentType.NUMBER, contentType.DECIMAL].indexOf(field.type) > -1"
              matInput
              i18n-placeholder [placeholder]="field.placeholder"
              [formControlName]="field.key"
              type="number"
              [autocomplete]="field.autocomplete"
              [required]="field.required"
              [readonly]="field.readonly"
            >
            <input
              *ngIf="field.type === contentType.EMAIL"
              matInput
              i18n-placeholder [placeholder]="field.placeholder"
              [formControlName]="field.key"
              type="email"
              [autocomplete]="field.autocomplete"
              [required]="field.required"
              [readonly]="field.readonly"
            >
            <input
              *ngIf="field.type === contentType.PASSWORD"
              matInput
              i18n-placeholder [placeholder]="field.placeholder"
              [formControlName]="field.key"
              type="password"
              [autocomplete]="field.autocomplete"
              [required]="field.required"
              [readonly]="field.readonly"
            >
            <input
              *ngIf="[contentType.INTEGER, contentType.NUMBER, contentType.DECIMAL, contentType.EMAIL, contentType.PASSWORD].indexOf(field.type) === -1"
              matInput
              i18n-placeholder [placeholder]="field.placeholder"
              [formControlName]="field.key"
              type="text"
              [autocomplete]="field.autocomplete"
              [required]="field.required"
              [readonly]="field.readonly"
            >
            <mat-icon *ngIf="field.icon !== null" matSuffix>{{field.icon}}</mat-icon>
            <mat-error *ngIf="form.controls[field.key].invalid">{{getErrorMessage(form.controls[field.key])}}</mat-error>
          </mat-form-field>
        </ng-container>
      </div>
    </div>
  </ng-template>
</form>
