import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ICellRendererParams} from 'ag-grid-community';
import {ElementType} from '../../../../definitions/ElementType';
import {QuestionType} from '../../../../definitions/QuestionType';
import {ApiService} from '../../../../services/api.service';
import * as moment from 'moment';

@Component({
  selector: 'app-question-answers-cell-renderer-dialog',
  templateUrl: './question-answers-cell-renderer-dialog.component.html',
  styleUrls: ['./question-answers-cell-renderer-dialog.component.styl']
})
export class QuestionAnswersCellRendererDialogComponent implements OnInit {
  QA = [];
  questionType = QuestionType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { params: any },
    private dialogRef: MatDialogRef<QuestionAnswersCellRendererDialogComponent>,
    private apiService: ApiService
    ) { }

  ngOnInit(): void {
    this.buildQuestionsFromData();
  }

  download(): void {
    const researchResultId = this.data.params.data.id;
    this.apiService.downloadResults(researchResultId, 1).subscribe(content => {
      const newBlob = new Blob([content], { type: 'application/pdf' });
      const fileName = `${researchResultId}-1-${moment().format('YYYYMMDDHHmmss')}.pdf`;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob, fileName);
        this.dialogRef.close();
        return;
      }

      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      link.download = fileName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
      this.dialogRef.close();
    })
  }

  close(): void {
    this.dialogRef.close();
  }

  private buildQuestionsFromData(): void {
    const obj = [];

    const process = (elements) => {
      for (const element of elements) {
        if (element.type === ElementType.QUESTION) {
          const multiple = element.child.multiple === true;
          if (element.child.type === QuestionType.ARRAY_MATRIX) {
            const codes = [];

            for (const answer of element.child.answers) {
              if (codes.indexOf(answer.code) === -1) {
                codes.push(answer.code);
              }
            }

            obj.push({
              code: element.code,
              title: element.child.question,
              type: element.child.type,
              multiple,
              value: multiple ? codes.reduce((o, v) => ({ ...o, [v]: []}), {}) : codes.reduce((o, v) => ({ ...o, [v]: null}), {})
            });
          } else {
            obj.push({
              code: element.code,
              title: element.child.question,
              type: element.child.type,
              multiple,
              value: multiple ? [] : null
            });
          }
        } else if (element.type === ElementType.GROUP) {
          process(element.child.children || []);
        }
      }
    };

    if (this.data.params.survey) {
      process(this.data.params.survey.elements);
    }

    obj.forEach(objValue => {
      const objKey = objValue.code;
      const answerValue = (this.data.params.data && this.data.params.data.answers[objKey]) || undefined;

      if (objValue.type === QuestionType.ARRAY_MATRIX && typeof answerValue === 'object') {
        for (const answerValueKey in answerValue) {
          if (!answerValue.hasOwnProperty(answerValueKey)) {
            continue;
          }

          for (const code of answerValue[answerValueKey]) {
            if (!objValue.value.hasOwnProperty(code)) {
              continue;
            }

            if (Array.isArray(objValue.value[code]) && objValue.value[code].indexOf(answerValueKey)) {
              objValue.value[code].push(answerValueKey);
            } else if (!Array.isArray(objValue.value[code])) {
              objValue.value[code] = answerValueKey;
            }
          }
        }
      } else if (objValue.type !== QuestionType.ARRAY_MATRIX) {
        objValue.value = answerValue;
      }
    });

    this.QA = obj;
  }

}
