<h1 mat-dialog-title i18n>Manage object data</h1>
<mat-dialog-content>
  <div class="button-bar spacing-bottom-10">
    <button mat-button color="primary" (click)="openAddDialog()">
      <mat-icon class="spacing-right-5">add_box</mat-icon>
      <span i18n>ADD</span>
    </button>
  </div>

  <div class="items">
    <header class="row align-items-stretch">
      <div class="col-md d-flex flex-column justify-content-center">
        <strong i18n>Property Name</strong>
      </div>
      <div class="col-md d-flex flex-column justify-content-center">
        <strong i18n>Property Label</strong>
      </div>
      <div class="col-md d-flex flex-column justify-content-center">
        <strong i18n>Property Value</strong>
      </div>
      <div class="col-md d-flex flex-column justify-content-center">
        <strong i18n>Property Type</strong>
      </div>
      <div class="col-md-2 actions d-flex flex-column justify-content-center">
      </div>
    </header>
    <div class="item row align-items-stretch" *ngFor="let item of value | keyvalue">
      <div class="col-md d-flex flex-column justify-content-center">
        <span>{{item.key}}</span>
      </div>
      <div class="col-md d-flex flex-column justify-content-center">
        <span *ngIf="editing !== item.key">{{item.value.label}}</span>
        <mat-form-field appearance="outline" class="w-100" *ngIf="editing === item.key">
          <mat-label i18n>Property Label</mat-label>
          <input matInput [(ngModel)]="editingPropertyLabel" />
        </mat-form-field>
      </div>
      <div class="col-md d-flex flex-column justify-content-center">
        <span *ngIf="editing !== item.key">{{getDisplayValue(item.value.value, item.value.type)}}</span>
        <ng-container *ngIf="editing === item.key">
          <ng-container [ngSwitch]="editingPropertyType">
            <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="contentType.DATE">
              <mat-label i18n>Property Value</mat-label>
              <input
                matInput
                placeholder="Property Value"
                [(ngModel)]="editingPropertyValue"
                i18n-placeholder
                [readonly]="true"
                [matDatepicker]="datePicker"
              />
              <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
              <mat-datepicker [touchUi]="true" #datePicker></mat-datepicker>
            </mat-form-field>
            <mat-slide-toggle *ngSwitchCase="contentType.BOOLEAN"
              color="primary"
              [checked]="editingPropertyValue === true" [(ngModel)]="editingPropertyValue"></mat-slide-toggle>
            <mat-form-field appearance="outline" class="w-100" *ngSwitchDefault>
              <mat-label i18n>Property Value</mat-label>
              <input matInput [(ngModel)]="editingPropertyValue" />
            </mat-form-field>
          </ng-container>
        </ng-container>
      </div>
      <div class="col-md d-flex flex-column justify-content-center">
        <span *ngIf="editing !== item.key">{{getTypeDisplayValue(item.value.type)}}</span>
        <mat-form-field appearance="outline" class="w-100" *ngIf="editing === item.key">
          <mat-label i18n>Property Type</mat-label>
          <mat-select [(ngModel)]="editingPropertyType" (ngModelChange)="onPropertyTypeChange()">
            <mat-option *ngFor="let availableType of availableTypes" [value]="availableType.value">
              {{availableType.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2 actions d-flex flex-row justify-content-center align-items-center">
        <button mat-icon-button (click)="startEditing(item.key)" *ngIf="editing !== item.key">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button class="ml-1" *ngIf="editing !== item.key" (click)="onDeleteItem(item.key)">
          <mat-icon>delete</mat-icon>
        </button>

        <button mat-icon-button (click)="stopEditing(item.key)" color="primary" *ngIf="editing === item.key">
          <mat-icon>check</mat-icon>
        </button>
        <button mat-icon-button class="ml-1" color="primary" (click)="stopEditing(item.key, true)" *ngIf="editing === item.key">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" i18n>Close</button>
</mat-dialog-actions>
