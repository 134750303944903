<h2 mat-dialog-title i18n>{{data.title}}</h2>
<mat-dialog-content class="space-inner-top-10 space-inner-bottom-10">
  <mat-radio-group [(ngModel)]="selectedElementType" color="primary" class="spacing-bottom-10">
    <mat-radio-button [value]="elementType.QUESTION" i18n>Question</mat-radio-button>
    <mat-radio-button [value]="elementType.TEXT" i18n>Text</mat-radio-button>
    <mat-radio-button [value]="elementType.RICH_MEDIA" i18n>Rich Media</mat-radio-button>
    <mat-radio-button [value]="elementType.GROUP" *ngIf="data.allowGroup === true" i18n>Group</mat-radio-button>
  </mat-radio-group>
  <app-smart-form *ngIf="selectedElementType === elementType.QUESTION" #formComponent [fields]="fieldsCreateQuestion" [defaults]="defaultData" colBreak="sm" colSize="6"></app-smart-form>
  <app-smart-form *ngIf="selectedElementType === elementType.RICH_MEDIA" #formComponent [fields]="fieldsCreateRichMedia" [defaults]="defaultData" colBreak="sm" colSize="6"></app-smart-form>
  <app-smart-form *ngIf="selectedElementType === elementType.TEXT" #formComponent [fields]="fieldsCreateText" [defaults]="defaultData" colBreak="sm" colSize="6"></app-smart-form>
  <app-smart-form *ngIf="selectedElementType === elementType.GROUP" #formComponent [fields]="fieldsCreateGroup" [defaults]="defaultData" colBreak="sm" colSize="6"></app-smart-form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close i18n (click)="onCancel()">Cancel</button>
  <button mat-button i18n (click)="onConfirm()" [disabled]="!formComponent || formComponent.form.invalid">Confirm</button>
</mat-dialog-actions>
