import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {SmartListDialogComponent} from '../smart-list-dialog/smart-list-dialog.component';
import IPair from '../../interfaces/IPair';

@Component({
  selector: 'app-smart-list',
  templateUrl: './smart-list.component.html',
  styleUrls: ['./smart-list.component.styl']
})
export class SmartListComponent implements OnInit {

  @Input() buttonTitle: string;
  @Input() buttonIcon: string;
  @Input() dialogTitle: string;
  @Input() get: (data: IPair[]) => Promise<any[]>;
  @Input() save: (pairs: IPair[], originalPairs: IPair[]) => Promise<void>;

  data: any[] = [];

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  open(): void {
    this.dialog.open(SmartListDialogComponent, {
      width: '400px',
      data: {
        get: this.get,
        save: this.save,
        title: this.dialogTitle
      }
    });
  }

}
