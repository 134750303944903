import { Component } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-actions-cell-renderer',
  templateUrl: './actions-cell-renderer.component.html',
  styleUrls: ['./actions-cell-renderer.component.styl']
})
export class ActionsCellRendererComponent {

  params: any;

  constructor(public dialog: MatDialog, public authService: AuthService) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  onView(): void {
    if (this.params.context.onView) {
      this.params.context.onView.emit({data: this.params.data, node: this.params.node});
    }
  }

  onDelete(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: {
        title: $localize`Are you sure you want to delete this record?`,
        message: $localize`Once you click on the confirmation button, the record will be permanently deleted, please take precautions when deleting any record.`,
        cancelText: $localize`No`,
        okText: $localize`Yes`
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.params.context.onDelete) {
          this.params.context.onDelete.emit({data: this.params.data, node: this.params.node});
        }
      }
    });
  }

  onEdit(): void {
    if (this.params.context.onEdit) {
      this.params.context.onEdit.emit({data: this.params.data, node: this.params.node});
    }
  }

  hasAccessToView(): boolean {
    return this.authService.hasBoundingPermission(this.params.context.permissionGroup, 'view', this.params.data);
  }

  hasAccessToDelete(): boolean {
    return this.authService.hasBoundingPermission(this.params.context.permissionGroup, 'delete', this.params.data);
  }

  hasAccessToEdit(): boolean {
    return this.authService.hasBoundingPermission(this.params.context.permissionGroup, 'edit', this.params.data);
  }

}
