import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';
import IStore from '../../interfaces/IStore';
import {Observable} from 'rxjs';
import IAuthState from '../../interfaces/IAuthState';
import IUser from '../../interfaces/IUser';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import IClient from "../../interfaces/IClient";
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.styl']
})
export class TopbarComponent implements OnInit {

  user: IUser = null;
  userClient: IClient;
  @Input() navigationExpanded: boolean;
  @Output() updateNavigationExpansion: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private store: Store<IStore>, private authService: AuthService, private router: Router, private apiService: ApiService) {
  }

  ngOnInit(): void {
    this.store.pipe(select('auth')).subscribe((state: IAuthState) => {
      this.user = state.user;
      this.userClient = state.userClient;
    });
  }

  toggleNavigationExpansion(): void {
    this.updateNavigationExpansion.emit(!this.navigationExpanded);
    this.updateNavigationExpansion.emit(!this.navigationExpanded);
  }

  logout(): void {
    this.authService.setToken(null);
    this.authService.setUser(null);
    this.authService.setUserRole(null);
    this.authService.setUserClient(null);
    this.router.navigateByUrl('/login');
  }

}
