<div class="row">
  <div class="col-sm-4 spacing-bottom-10" *ngFor="let field of fields">
    <strong>{{field.label}}</strong><br />
    <ng-container [ngSwitch]="field.type">
      <span *ngSwitchCase="contentType.DATE">{{field.render ? field.render(source[field.key], source) : source[field.key] | toDate}}</span>
      <span *ngSwitchCase="contentType.BOOLEAN">{{field.render ? field.render(source[field.key], source) : (source[field.key] === true ? labelYes : labelNo)}}</span>
      <a *ngSwitchCase="contentType.FILE" target="_blank" [href]="source[field.key] ? 'https://sonia-uploads-prod.s3.eu-south-1.amazonaws.com/' + source[field.key].name : '#'">{{field.render ? field.render(source[field.key], source) : source[field.key] ? source[field.key].originalName : ''}}</a>
      <span i18n *ngSwitchDefault>{{field.render ? field.render(source[field.key], source) : source[field.key]}}</span>
    </ng-container>
  </div>
</div>
