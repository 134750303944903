import {Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {select, Store} from '@ngrx/store';
import IStore from '../../interfaces/IStore';
import IAuthState from '../../interfaces/IAuthState';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.styl']
})
export class NavigationComponent implements OnInit, OnChanges {

  @Input() expanded = true;
  @HostBinding('style.width') hostWidth = '240px';

  items = [
    {
      label: $localize`Dashboard`,
      icon: 'dashboard',
      target: '/dashboard'
    },
    {
      label: $localize`User Management`,
      icon: 'people',
      target: '/user-management',
      permissions: ['user_view', 'user_view_own', 'user_view_org', 'user_client_view']
    },
    {
      label: $localize`Tests & Surveys`,
      icon: 'assignment',
      target: '/tests-surveys',
      permissions: ['survey_view', 'survey_view_own', 'survey_view_org', 'survey_client_view']
    },
    {
      label: $localize`Candidates`,
      icon: 'face',
      target: '/candidates',
      permissions: ['candidate_view', 'candidate_view_own', 'candidate_view_org', 'candidate_client_view']
    },
    // {
    //   label: $localize`Reports & KPI`,
    //   icon: 'bar_chart',
    //   target: '/reports'
    // },
    {
      label: $localize`Jobs`,
      icon: 'article',
      target: '/jobs',
      permissions: ['job_view', 'job_view_own', 'job_view_org', 'job_client_view']
    },
    {
      label: $localize`Clients`,
      icon: 'people_outline',
      target: '/clients',
      permissions: ['client_view']
    },
    {
      label: $localize`Projects`,
      icon: 'work',
      target: '/projects',
      permissions: ['project_view', 'project_view_own', 'project_view_org', 'project_client_view']
    },
    {
      label: $localize`Groups`,
      icon: 'group_work',
      target: '/groups',
      permissions: ['group_view', 'group_view_own', 'group_view_org', 'group_client_view']
    },
    {
      label: $localize`Settings`,
      icon: 'settings',
      target: '/settings',
      permissions: ['manage_settings']
    }
  ];

  filteredItems: any[] = [];

  constructor(private store: Store<IStore>) {
    store.pipe(select('auth')).subscribe(async (response: IAuthState) => {
      const rolePermissions = (response.userRole && response.userRole.permissions) || [];
      const permissions = (response.user && response.user.permissions && response.user.permissions) || [];

      this.filteredItems = this.items.filter(item => {
        if (!item.permissions) {
          return true;
        }

        for (const permissionRequired of item.permissions) {
          if (rolePermissions.includes(permissionRequired) || permissions.includes(permissionRequired)) {
            return true;
          }
        }

        return false;
      });
    });
  }

  ngOnInit(): void {
    if (this.expanded) {
      this.hostWidth = '240px';
    } else {
      this.hostWidth = 'auto';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('expanded')) {
      if (changes.expanded.currentValue) {
        this.hostWidth = '240px';
      } else {
        this.hostWidth = 'auto';
      }
    }
  }

}
