import {Component, OnInit} from '@angular/core';
import {RouteAction} from '../../definitions/RouteAction';
import IResearch from '../../interfaces/IResearch';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import IResearchResult from '../../interfaces/IResearchResult';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ElementType} from '../../definitions/ElementType';
import {QuestionType} from '../../definitions/QuestionType';
import {MatSnackBar} from '@angular/material/snack-bar';
import IReport from '../../interfaces/IReport';
import {MatDialog} from '@angular/material/dialog';
import {ResearchScreenExportConfirmationComponent} from './research-screen-export-confirmation/research-screen-export-confirmation.component';
import ISurvey from "../../interfaces/ISurvey";

@Component({
  selector: 'app-research-screen',
  templateUrl: './research-screen.component.html',
  styleUrls: ['./research-screen.component.styl']
})
export class ResearchScreenComponent implements OnInit {

  action: RouteAction = RouteAction.LOADING;
  routeAction = RouteAction;
  research: IResearch = null;
  survey: ISurvey = null;
  results: any = {};
  resultsColDefs = [];
  showGoBackButton = false;
  resourceType: string = null;
  resourceId: string = null;
  candidates: any[] = [];

  constructor(private api: ApiService, private activatedRoute: ActivatedRoute, private router: Router, private snackbar: MatSnackBar, private matDialog: MatDialog) {
    this.resourceType = this.activatedRoute.snapshot.params.resourceType || null;
    this.resourceId = this.activatedRoute.snapshot.params.resourceId || null;
  }

  ngOnInit(): void {
    this.fetchResearch();
  }

  fetchResearch(): void {
    this.api.getResearch(this.activatedRoute.snapshot.params.id).then(async (research) => {
      this.research = research;

      try {
        this.survey = await this.api.getSurvey(research.survey);
        const researchCandidates = await this.api.getResearchCandidates(research.id);

        if (this.resourceType === 'candidate') {
          this.candidates = researchCandidates.filter(researchCandidate => researchCandidate.id === this.resourceId);
        } else {
          this.candidates = researchCandidates;
        }

        const resultsAsArray = await this.api.getResultsByResearch(this.research);
        const resultsAsMap = {};

        resultsAsArray.forEach(result => {
          resultsAsMap[result.candidate] = result;
        });

        this.candidates.forEach((candidate, index) => {
          if (!resultsAsMap.hasOwnProperty(candidate.id)) {
            return;
          }

          const result = resultsAsMap[candidate.id];

          this.candidates[index] = {
            ...candidate,
            ...result
          };
        });

        this.results = resultsAsMap;
        this.buildColumnDefs();
        this.action = RouteAction.LISTING;
      } catch (e) {
        console.error(e);
      }

    }).catch(console.error);
  }

  fetchResults(): void {
  }

  goBack(): void {
    switch (this.resourceType) {
      case 'survey':
        this.router.navigateByUrl(`/tests-surveys/view/${this.resourceId}`);
        break;

      case 'project':
        this.router.navigateByUrl(`/projects/view/${this.resourceId}`);
        break;

      case 'group':
        this.router.navigateByUrl(`/groups/view/${this.resourceId}`);
        break;

      case 'candidate':
        this.router.navigateByUrl(`/candidates/view/${this.resourceId}`);
        break;

      case 'job':
        this.router.navigateByUrl(`/jobs/view/${this.resourceId}`);
        break;

      case 'user':
        this.router.navigateByUrl(`/users/view/${this.resourceId}`);
        break;

      default:
    }
  }

  onResearchDelete(ev: any): void {
    this.api.deleteResearch(ev.data.id).then(() => {
      this.goBack();
    });
  }

  private buildColumnDefs(): void {
    let colDefs: any[] = [
      {
        headerName: $localize`Candidate ID`,
        valueGetter: (params) => {
          if (this.research.anonymous === true) {
            return params.data.id;
          }

          return params.data.id;
        },
        width: 300
      }
    ];

    if (this.research.anonymous !== true) {
      colDefs = [
        ...colDefs,
        { headerName: $localize`First Name`, valueGetter: params => params.data.firstName},
        { headerName: $localize`Last Name`, valueGetter: params => params.data.lastName},
        { headerName: $localize`Email`, valueGetter: params => params.data.email},
        { headerName: $localize`Phone`, valueGetter: params => params.data.phone}
      ];
    }

    colDefs = [
      ...colDefs,
      { headerName: $localize`Completed`, field: 'completed', cellRenderer: 'checkboxRenderer' },
      { headerName: $localize`Score`, field: 'score' },
      { headerName: $localize`Lost Focus`, valueGetter: params => (params.data.trackingActivity && params.data.trackingActivity.length) || 0 },
      { headerName: $localize`Start`, field: 'startDate', type: 'datetime' },
      { headerName: $localize`End`, field: 'endDate', type: 'datetime' },
      { headerName: $localize`Answers`, field: 'answers', cellRenderer: 'questionAnswersRenderer', cellRendererParams: { survey: this.survey, results: this.results }, width: 150, cellClass: 'no-border text-center', suppressMenu: true,
        suppressMovable: true,
        suppressNavigable: true,
        suppressSizeToFit: true,
        sortable: false },
      { headerName: $localize`Report`, field: 'report', cellRenderer: 'researchReportRenderer', width: 150, cellClass: 'no-border text-center', suppressMenu: true,
        suppressMovable: true,
        suppressNavigable: true,
        suppressSizeToFit: true,
        cellRendererParams: {
          onReportSelection: (report: IReport, params: any) => {
            const data = {
              researchId: this.activatedRoute.snapshot.params.id,
              resourceType: this.resourceType,
              resourceId: this.resourceId
            };

            const jsonData = JSON.stringify(data);
            const urlData = encodeURIComponent(btoa(jsonData));

            this.router.navigateByUrl(`/report/${report.id}/${this.results[params.data.candidate].id}/${urlData}`);
          }
        },
        sortable: false },
      {
        headerName: $localize`Actions`,
        cellRenderer: 'buttons',
        pinned: 'right',
        suppressMenu: true,
        suppressMovable: true,
        suppressNavigable: true,
        suppressSizeToFit: true,
        sortable: false,
        cellClass: 'no-border text-center',
        cellRendererParams: {
          buttons: [
            { icon: 'content_copy', onClick: params => this.copyCandidateLink(params), color: 'primary', tooltip: $localize`Copy invitation link` },
            { icon: 'notifications_active', onClick: params => this.sendCandidateReminder(params), color: 'primary', tooltip: $localize`Send reminder` }
          ]
        }
      }
    ];

    this.resultsColDefs = colDefs;
  }

  copyCandidateLink(params: any): void {
    this.api.getResearchCandidateLink(this.research.id, params.data.id).then(link => {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = link;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);

      this.snackbar.open($localize`Invitation link copied to clipboard`, undefined, { duration: 2000 });
    }).catch(() => {});
  }

  sendCandidateReminder(params: any): void {
    this.api.sendCandidateReminder(this.research.id, params.data.id).then(() => {
      this.snackbar.open($localize`Reminder sent to candidate`, undefined, { duration: 2000 });
    }).catch(() => {});
  }

  sendEveryoneReminder(): void {
    this.api.sendEveryoneReminder(this.research.id).then(() => {
      this.snackbar.open($localize`Reminder sent to every candidate who did not complete the test yet`, undefined, { duration: 2000 });
    });
  }

  getShareLink(): void {
    this.api.getResearchShareLink(this.research.id).then(shareLink => {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = shareLink;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);

      this.snackbar.open($localize`The share link of the research has been copied to your clipboard`, undefined, { duration: 2000 });
    });
  }

  exportHorizontally(): void {
    this.matDialog.open(ResearchScreenExportConfirmationComponent).afterClosed().subscribe(response => {
      this.api.exportResearchHorizontally(this.research.id, response).then(() => {
        console.log('File Exported');
      });
    });
  }
}
