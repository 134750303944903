<div class="wrapper">
  <button mat-icon-button (click)="onView()" *ngIf="params.context.hideView !== true && hasAccessToView()">
    <mat-icon>remove_red_eye</mat-icon>
  </button>
  <button mat-icon-button (click)="onEdit()" *ngIf="params.context.hideEdit !== true && hasAccessToEdit()">
    <mat-icon>edit</mat-icon>
  </button>
  <button mat-icon-button (click)="onDelete()" *ngIf="params.context.hideDelete !== true && hasAccessToDelete()">
    <mat-icon>delete</mat-icon>
  </button>
</div>
